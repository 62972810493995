import React from 'react'
import { TextField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const CallReasonList = (props) => (
  <ListGuesser
    {...props}
    filter={{ properties: ['name', 'slug'] }}
    rowClick="edit"
  >
    <TextField source="name" />
    <TextField source="slug" />
  </ListGuesser>
)
