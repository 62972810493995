import React from 'react'
import { TextField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const DreamlogisticList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="show"
    bulkActionButtons={false}
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="sku" />
    <TextField source="description" />
    <TextField source="quantity" />
  </ListGuesser>
)
