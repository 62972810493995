import React from 'react'
import { TextField, Switch, FormControlLabel } from '@material-ui/core'

import { formatDateForChargebee } from 'utils/string'

export const StartDateInput = ({ startDate, setStartDate }) => {
  const tomorrow = new Date().setDate(new Date().getDate() + 1)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      <FormControlLabel
        control={
          <Switch
            checked={!startDate}
            onChange={() => {
              setStartDate(() =>
                startDate ? null : formatDateForChargebee(tomorrow)
              )
            }}
            name="Start immediately"
            color="primary"
          />
        }
        label="Start immediately"
      />

      {!!startDate && (
        <TextField
          size="small"
          variant="outlined"
          label="Start date"
          type="date"
          InputLabelProps={{ shrink: true }}
          value={startDate}
          inputProps={{ min: formatDateForChargebee(tomorrow) }}
          onChange={(e) => {
            e.persist()
            setStartDate(e?.target?.value || null)
          }}
        />
      )}
    </div>
  )
}
