import React from 'react'
import { CreateGuesser, ResourceCreateToolbar } from 'theme/components'

import { TextInput, BooleanInput, useRefresh, useNotify } from 'react-admin'

export const CreateVariationPropertyValueCard = React.memo(({ record }) => {
  const refresh = useRefresh()
  const notify = useNotify()

  if (!record) {
    return null
  }

  const onSuccess = () => {
    notify('resources.variation.valueCreated')
    refresh()
  }

  return (
    <CreateGuesser
      variant="outlined"
      redirect={false}
      resource="variation-property-values"
      basePath="/variation-property-values"
      initialValues={{
        property: record['@id'],
      }}
      toolbar={<ResourceCreateToolbar showBack={false} />}
      onSuccess={onSuccess}
    >
      <TextInput label="New property value" source="name" />
      <TextInput label="Woocommerce Name" source="nameWc" />
      <BooleanInput
        label="Visible to customer"
        source="visibleToCustomer"
        defaultValue={true}
      />
    </CreateGuesser>
  )
})
