import React from 'react'
import { TextField } from 'react-admin'
import { ListGuesser, DateField } from 'theme/components'

export const PostponeReasonList = (props) => {
  return (
    <ListGuesser {...props} rowClick="edit">
      <TextField source="name" />
      <TextField source="slug" />
      <DateField source="createdAt" short={false} />
    </ListGuesser>
  )
}
