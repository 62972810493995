import React, { useState, useEffect, useMemo, useCallback } from 'react'
import {
  useInput,
  useDataProvider,
  SimpleForm,
  TextInput,
  required,
  email,
} from 'react-admin'
import { FormSpy } from 'react-final-form'
import styled from 'styled-components'
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core'
import { ExpandMore, NavigateNext, NavigateBefore } from '@material-ui/icons'

import { PersonalDetailsStep } from './steps/PersonalDetailsStep'
import { AddressStep } from './steps/address/AddressStep'
import { SubscriptionsProvider } from './contexts/subscriptions'
import { CustomerProvider, useCustomer } from './contexts/customer'
import {
  CurrentSessionProvider,
  useCurrentSession,
} from './contexts/currentSession'
import { StoreProvider, useStore } from './contexts/store'
import { CommentStep } from './steps/CommentStep'
import { isEmail } from 'utils/string'
import { debounce } from 'lodash'
import { ErrorsStep } from './steps/ErrorsStep'
import { getPimcoreSubscriptions } from './subscriptions/utils'
import { CreateAppointmentModal } from 'components/createAppointmentModal'
import { PreviewStep } from './steps/PreviewStep'
import { PageStateProvider, usePageState } from './contexts/pageState'
import { CartStep } from './steps/CartStep'
import useApi from 'hooks/useApi'
import { getUrlParams } from 'utils/uri'

const DetailsBlock = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  width: 100%;
`

const Buttons = styled.div`
  display: flex;
  gap: 20px;
  width: 400px;
  padding: 20px;
  margin: 20px auto;
`
const PageWrapper = styled.div`
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: 0.7;
    display: ${({ loading }) => (loading ? 'block' : 'none')};
  }
`

const CustomerEmailForm = styled(SimpleForm)`
  width: 300px;
  position: absolute !important;
  top: -79px;
  left: 308px;

  & > div {
    padding: 0 !important;
  }
`

// Definition of steps for the multi-step process with respective components
const steps = [
  {
    id: 'personal_details',
    label: 'Personal details',
    component: <PersonalDetailsStep />,
  },
  {
    id: 'cart',
    label: 'Cart',
    component: <CartStep />,
  },
  {
    id: 'address',
    label: 'Address',
    component: <AddressStep />,
  },
  {
    id: 'comment',
    label: 'Comment',
    component: <CommentStep />,
  },
  {
    id: 'errors',
    label: 'Errors',
    component: <ErrorsStep />,
  },
  {
    id: 'preview',
    label: 'Preview',
    component: <PreviewStep />,
  },
]

const COMMON_FIELDS = ['firstName', 'lastName', 'phone'] // fields are reused across all multiple forms

// Determines if target is missing any common field present in source
const isTargetMissingCommonField = ({ source, target }) => {
  const arr = COMMON_FIELDS.filter((field) => !!source[field] && !target[field])
  return !!arr.length
}

// Returns a new object after removing properties with falsy values
const removeEmpty = (obj) => {
  /* eslint-disable no-unused-vars */
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => !!v))
  /* eslint-enable no-unused-vars */
}


const parseSurgeryDateUnknown= (input) => {
  if(!input) {
    return input
  }
  return input.toLowerCase() === 'true';
}

const Page = ({ customerEmail }) => {
  const { storeId } = useStore()
  const {
    setBillingAddress,
    setShippingAddress,
    setPersonalInfo,
    personalInfo,
    billingAddress,
    shippingAddress,
    personalInfoChanged,
    customerMidlayerId,
  } = useCustomer()
  const { api, isSubmitting } = useApi()
  const { activeStep, setActiveStep } = usePageState()
  const { session, disabled } = useCurrentSession()

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedUpdateEmail = useCallback(
    debounce(
      (email) => setPersonalInfo((cur) => ({ ...cur, email }), true),
      2000
    ),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    debouncedUpdateEmail(!isEmail(customerEmail) ? '' : customerEmail)
  }, [customerEmail, debouncedUpdateEmail])

  const filteredSteps = useMemo(() => {
    const exclude = new Set()
    if (!session) exclude.add('errors')
    return steps.filter((item) => !exclude.has(item.id))
  }, [session])

  const updateExistingCustomer = async () => {
    await api
      .updateCustomer(customerMidlayerId, {
        email: personalInfo?.email,
        first_name: personalInfo?.firstName,
        last_name: personalInfo?.lastName,
        phone: personalInfo?.phone,
        cf_surgery_date: personalInfo?.surgeryDate,
        cf_surgery_type_v2: personalInfo?.surgeryType,
        cf_clinic_v2: personalInfo?.clinic,
        cf_surgeon_name: personalInfo?.surgeonName,
        cf_surgery_date_unknown: personalInfo?.surgeryDateUnknown,
        cs_marketing: personalInfo?.marketingConsent,
      })
      .then((res) => {
        setPersonalInfo(
          {
            email: res?.data?.email,
            firstName: res?.data?.first_name,
            lastName: res?.data?.last_name,
            phone: res?.data?.phone,
            surgeryDate: res?.data?.cf_surgery_date,
            surgeryType: res?.data?.cf_surgery_type_v2,
            clinic: res?.data?.cf_clinic_v2,
            surgeonName: res?.data?.cf_surgeon_name,
            surgeryDateUnknown: parseSurgeryDateUnknown(res?.data?.cf_surgery_date_unknown),
            marketingConsent: res?.data?.cs_marketing,
          },
          true
        )
      })
  }

  const updateCommonFields = () => {
    const fieldsMap = {}
    const dataItems = [personalInfo, shippingAddress, billingAddress]

    // collect common values stored across all forms
    for (let field of COMMON_FIELDS) {
      for (let dataItem of dataItems) {
        if (!fieldsMap[field] && dataItem[field]) {
          fieldsMap[field] = dataItem[field]
        }
      }
    }

    // returns updated state if existing common field is missing
    const updateCurrentIfNeeded = (cur) => {
      if (isTargetMissingCommonField({ source: fieldsMap, target: cur })) {
        return { ...fieldsMap, ...removeEmpty(cur) }
      }
      return cur
    }

    setBillingAddress(updateCurrentIfNeeded)
    setShippingAddress(updateCurrentIfNeeded)
    setPersonalInfo(updateCurrentIfNeeded)
  }

  const shouldUpdateCustomer =
    personalInfoChanged &&
    customerMidlayerId &&
    activeStep === 'personal_details'

  const saveChanges = async () => {
    if (shouldUpdateCustomer) {
      await updateExistingCustomer()
    }

    updateCommonFields()
  }

  const changeStep = (step) => (_, updated) => {
    saveChanges().then(() => setActiveStep(updated ? step : false))
  }

  return (
    <Grid container spacing={2} direction="column">
      <Buttons style={{ margin: '-24px auto 10px auto' }}>
        <CreateAppointmentModal
          customerId={customerMidlayerId}
          phone={personalInfo?.phone}
        />
      </Buttons>

      {!!storeId && (
        <div>
          {filteredSteps.map(({ id, label, component }, index) => (
            <Accordion
              key={id}
              expanded={activeStep === id}
              onChange={changeStep(id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`${id}-controls`}
                id={id}
              >
                <Typography
                  style={{ fontSize: '1.1rem', width: '100%' }}
                  align="center"
                  variant="h6"
                >
                  {label}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DetailsBlock disabled={disabled}>
                  {component}
                  <Buttons>
                    {filteredSteps[index - 1] && (
                      <Button
                        size="medium"
                        onClick={() =>
                          setActiveStep(filteredSteps[index - 1].id)
                        }
                        color="primary"
                        variant="outlined"
                        fullWidth
                        startIcon={<NavigateBefore />}
                      >
                        prev
                      </Button>
                    )}
                    {filteredSteps[index + 1] && (
                      <Tooltip
                        title={
                          shouldUpdateCustomer
                            ? 'An existing customer will be updated'
                            : ''
                        }
                      >
                        <Button
                          size="medium"
                          onClick={() =>
                            saveChanges().then(() =>
                              setActiveStep(filteredSteps[index + 1].id)
                            )
                          }
                          color="primary"
                          variant="contained"
                          fullWidth
                          endIcon={<NavigateNext />}
                        >
                          {isSubmitting ? (
                            <CircularProgress color="white" size={24} />
                          ) : (
                            'next'
                          )}
                        </Button>
                      </Tooltip>
                    )}
                  </Buttons>
                </DetailsBlock>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </Grid>
  )
}

export const CheckoutSessionInput = ({
  source,
  store,
  session,
  disabled,
  ...props
}) => {
  const {
    input: { onChange, value },
  } = useInput({ source, ...props })
  const dataProvider = useDataProvider()
  const storeId = store?.id
  const [customerEmail, setCustomerEmail] = useState(
    session?.data?.customerEmail || session?.customerEmail
  )
  const customerCBId = value?.customerCBId
  const customerMidlayerId = value?.customerMidlayerId
  const hasValidPaymentMethod = value?.hasValidPaymentMethod

  useEffect(() => {
    const source = getUrlParams().source

    if (source) {
      setCustomerEmail(JSON.parse(source).customerEmail)
    }
  }, [])

  const [subscriptions, setSubscriptions] = useState(
    value?.subscriptions ? Object.values(value.subscriptions) : []
  )

  const [initialPersonalInfo, setInitialPersonalInfo] = useState('{}')

  const [personalInfo, _setPersonalInfo] = useState(
    value?.customerPersonalInfo || {}
  )
  const setPersonalInfo = useCallback((info, updateInitial) => {
    if (updateInitial) {
      setInitialPersonalInfo(JSON.stringify(info))
    }
    _setPersonalInfo((cur) => {
      if (info instanceof Function) return info(cur)
      return info
    })
  }, [])

  const [shippingAddress, setShippingAddress] = useState(
    value?.customerShippingAddress || {}
  )
  const [billingAddress, setBillingAddress] = useState(
    value?.customerBillingAddress || {}
  )
  const [comment, setComment] = useState(value?.comment || '')
  const [coupon, setCoupon] = useState(value?.coupon || '')
  const [subscriptionsFetching, _setSubscriptionsFetching] = useState()
  const [activeStep, setActiveStep] = useState(
    value?.activeStep || 'personal_details'
  )

  const [customersLoading, _setCustomersLoading] = useState(false)
  const [lastCheckedEmail, setLastCheckedEmail] = useState()
  const [lastFetchedCustomer, setLastFetchedCustomer] = useState(null)

  /* eslint-disable react-hooks/exhaustive-deps */
  const setSubscriptionsFetching = useCallback(
    debounce((val) => _setSubscriptionsFetching(val), 200),
    []
  )

  const setCustomersLoading = useCallback(
    debounce((val) => _setCustomersLoading(val), 200),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const fetchCustomers = useCallback(
    async ({ email }) => {
      setCustomersLoading(true)
      const { data } = await dataProvider.getList('customers', {
        pagination: {},
        sort: {},
        filter: {
          itemsPerPage: 10,
          'store.id': storeId,
          email,
          properties: [
            'id',
            'chargeBeeId',
            'data',
            'defaultPaymentMethod',
            'paymentFlow',
          ],
        },
      })

      setCustomersLoading(false)
      return data
    },
    [dataProvider, storeId, setCustomersLoading]
  )

  const productFlow = session?.productFlow || store?.productFlow

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedOnChange = useCallback(
    debounce((values) => onChange(values), 1000),
    []
  )

  const handleChange = useCallback(
    (customer) => {
      const isNewCustomer = customer === null
      const newCustomerCBId = customer?.chargeBeeId
      const newCustomerMidlayerId = customer?.originId

      const cbMethodValid =
        customer?.data?.payment_method?.status === 'valid' ||
        customer?.data?.payment_method?.status === 'expiring'
      const adyenMethodValid = customer?.defaultPaymentMethod?.valid

      const paymentFlow =
        customer?.paymentFlow?.slug || store?.paymentFlow?.slug

      const newCustomerHasValidPaymentMethod =
        paymentFlow === 'adyen' ? adyenMethodValid : cbMethodValid

      const pimcoreCustomer = isNewCustomer
        ? { ...personalInfo }
        : `/api/customers/${newCustomerMidlayerId || customerMidlayerId}`

      const valueStringified = JSON.stringify(value)

      const nextValue = {
        productFlow,
        subscriptions:
          productFlow === 'v2'
            ? getPimcoreSubscriptions(subscriptions)
            : subscriptions,
        customerPersonalInfo: personalInfo,
        customerShippingAddress: shippingAddress,
        customerBillingAddress: billingAddress,
        customerEmail,
        customerMidlayerId: isNewCustomer
          ? null
          : newCustomerMidlayerId || customerMidlayerId,
        hasValidPaymentMethod: isNewCustomer
          ? false
          : !!newCustomerHasValidPaymentMethod || !!hasValidPaymentMethod,
        customerCBId: isNewCustomer ? null : newCustomerCBId || customerCBId,
        lang: session?.data?.lang || store?.language || 'en',
        comment,
        coupon,
        activeStep,
        ...(productFlow === 'v2' && { customer: pimcoreCustomer }),
      }

      const nextValueStringified = JSON.stringify(nextValue)

      if (valueStringified !== nextValueStringified) {
        debouncedOnChange(nextValue)
      }
    },
    [
      subscriptions,
      personalInfo,
      shippingAddress,
      billingAddress,
      customerEmail,
      comment,
      customerCBId,
      customerMidlayerId,
      coupon,
      activeStep,
      hasValidPaymentMethod,
    ]
  )

  const checkIfCustomerExist = useCallback(
    debounce((email) => {
      setLastCheckedEmail(email)
      if (!email) {
        setPersonalInfo(value?.customerPersonalInfo || {}, true)
        return
      }
      fetchCustomers({ email }).then((customers) => {
        const fetchedCustomer = customers?.length
          ? customers.find((c) => c.data?.email === email)
          : null

        setLastFetchedCustomer(fetchedCustomer)

        if (fetchedCustomer && fetchedCustomer.data?.email === email) {
          setPersonalInfo(
            {
              email: fetchedCustomer.data?.email,
              firstName: fetchedCustomer.data?.first_name,
              lastName: fetchedCustomer.data?.last_name,
              phone: fetchedCustomer.data?.phone,
              surgeryDate: fetchedCustomer.data?.cf_surgery_date,
              surgeryType: fetchedCustomer.data?.cf_surgery_type_v2,
              clinic: fetchedCustomer.data?.cf_clinic_v2,
              surgeonName: fetchedCustomer.data?.cf_surgeon_name,
              surgeryDateUnknown: parseSurgeryDateUnknown(fetchedCustomer.data?.cf_surgery_date_unknown),
              marketingConsent: fetchedCustomer.data?.cs_marketing,
            },
            true
          )

          setBillingAddress({
            city: fetchedCustomer.data?.billing_address?.city,
            country: fetchedCustomer.data?.billing_address?.country,
            firstName: fetchedCustomer.data?.billing_address?.first_name,
            lastName: fetchedCustomer.data?.billing_address?.last_name,
            line1: fetchedCustomer.data?.billing_address?.line1,
            line2: fetchedCustomer.data?.billing_address?.line2,
            line3: fetchedCustomer.data?.billing_address?.line3,
            phone: fetchedCustomer.data?.billing_address?.phone,
            state: fetchedCustomer.data?.billing_address?.state,
            zip: fetchedCustomer.data?.billing_address?.zip,
          })
        } else {
          setPersonalInfo(value?.customerPersonalInfo || {}, true)
          setBillingAddress(value?.customerBillingAddress || {})
        }

        handleChange(fetchedCustomer)
      })
    }, 1000),
    [fetchCustomers]
  )

  useEffect(() => {
    if (!storeId) return

    if (lastCheckedEmail !== customerEmail) {
      checkIfCustomerExist(!isEmail(customerEmail) ? '' : customerEmail)
    } else {
      handleChange(lastFetchedCustomer)
    }
  }, [
    handleChange,
    customerEmail,
    storeId,
    lastCheckedEmail,
    lastFetchedCustomer,
    checkIfCustomerExist,
  ])

  // causes input to be revalidated, if needed
  const debouncedValidate = useCallback(
    debounce((target) => {
      if (document.activeElement === target) {
        target.blur()
        target.focus()
      }
    }, 500),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const personalInfoChanged = useMemo(
    () => JSON.stringify(personalInfo) !== initialPersonalInfo,
    [personalInfo, initialPersonalInfo]
  )

  if (!store) return null

  return (
    <CurrentSessionProvider
      session={session}
      productFlow={productFlow}
      disabled={disabled}
    >
      <StoreProvider store={store}>
        <CustomerProvider
          personalInfo={personalInfo}
          shippingAddress={shippingAddress}
          billingAddress={billingAddress}
          comment={comment}
          setPersonalInfo={setPersonalInfo}
          setShippingAddress={setShippingAddress}
          setBillingAddress={setBillingAddress}
          setComment={setComment}
          customerMidlayerId={customerMidlayerId}
          hasValidPaymentMethod={hasValidPaymentMethod}
          customerCBId={customerCBId}
          personalInfoChanged={personalInfoChanged}
        >
          <SubscriptionsProvider
            subscriptions={subscriptions}
            setSubscriptions={setSubscriptions}
            coupon={coupon}
            setCoupon={setCoupon}
            subscriptionsFetching={subscriptionsFetching}
            setSubscriptionsFetching={setSubscriptionsFetching}
          >
            <PageStateProvider
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            >
              <PageWrapper loading={customersLoading}>
                <CustomerEmailForm
                  variant="outlined"
                  toolbar={<></>}
                  initialValues={{ email: customerEmail }}
                >
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={({ values }) => setCustomerEmail(values.email)}
                  />
                  <TextInput
                    validate={[required(), email()]}
                    source="email"
                    fullWidth
                    onChange={(e) => {
                      e.persist()
                      debouncedValidate(e.target)
                    }}
                  />
                </CustomerEmailForm>
                <Page customerEmail={customerEmail} />
              </PageWrapper>
            </PageStateProvider>
          </SubscriptionsProvider>
        </CustomerProvider>
      </StoreProvider>
    </CurrentSessionProvider>
  )
}
