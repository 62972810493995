import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'
import {
  PSI_SCREENS,
  generateProductStoreIntervalData,
  reloadPage,
} from '../utils'
import useApi from 'hooks/useApi'
import { parseUrlIdsParam } from 'utils/uri'

export default (productId, addedProductStores, setLoading) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const { api, handleError } = useApi()

  const [screen, setScreen] = useState(PSI_SCREENS.MISSED)

  const [addedProductStoreIntervals, setAddedProductStoreIntervals] = useState(
    []
  )
  const [addedIntervalsIds, setAddedIntervalsIds] = useState([])
  const [
    initialProductStoreIntervals,
    setInitialProductStoreIntervals,
  ] = useState([])
  const [
    optionsProductStoreIntervals,
    setOptionsProductStoreIntervals,
  ] = useState([])
  const [selectedProductStores, setSelectedProductStores] = useState([])

  const [confirmationData, setConfirmationData] = useState([])
  const [currencies, setCurrencies] = useState(null)

  // const [missingProductStores, setMissingProductStores] = useState([])
  const [pricingModels, setPricingModels] = useState(null)
  const [intervals, setIntervals] = useState([])

  const addedProductStoreIntervalsIds = useMemo(
    () => addedProductStoreIntervals.map((item) => item['@id']),
    [addedProductStoreIntervals]
  )

  const fetchCurrencies = useCallback(async () => {
    const { data } = await dataProvider.getList('currencies', {
      pagination: {},
      sort: {},
    })

    setCurrencies(data)
  }, [dataProvider])

  const fetchPricingModels = useCallback(async () => {
    const { data } = await dataProvider.getList('pricing-models', {
      pagination: {},
      sort: {},
      filter: {
        itemsPerPage: 999,
      },
    })

    setPricingModels(data)
  }, [dataProvider])

  const fetchIntervals = useCallback(async () => {
    const { data } = await dataProvider.getList('intervals', {
      pagination: {},
      sort: {},
    })

    setIntervals(data.map((item) => ({ ...item, id: item.originId })))
  }, [dataProvider])

  const syncPSI = useCallback(async () => {
    try {
      const { data } = await dataProvider.getManyReference(
        'product-store-intervals',
        {
          target: 'productStore.product.id',
          id: productId,
          pagination: {},
          sort: {},
          filter: {
            itemsPerPage: 999,
            properties: [
              'id',
              'price',
              'qty',
              'name',
              'description',
              'chargeBeeId',
              'invoiceName',
              'shippable',
            ],
            'properties[pricingModel]': ['id', 'slug'],
            'properties[interval]': ['id', 'name'],
            'properties[productStore]': ['id', 'sku', 'store'],
            'properties[currency]': ['id', 'code'],
          },
        }
      )

      setAddedProductStoreIntervals(data)
    } catch (error) {
      console.error(error)
    }
  }, [dataProvider, productId])

  const fetchPSI = useCallback(
    async (filter = {}) => {
      try {
        const { data } = await dataProvider.getList('product-store-intervals', {
          pagination: {},
          sort: {},
          filter: {
            itemsPerPage: 10,
            properties: [
              'id',
              'price',
              'qty',
              'name',
              'description',
              'chargeBeeId',
              'invoiceName',
              'shippable',
            ],
            'properties[pricingModel]': ['id', 'slug'],
            'properties[interval]': ['id', 'name'],
            'properties[currency]': ['id', 'code'],
            'properties[productStore]': ['id', 'sku', 'store'],
            ...filter,
          },
        })

        return data
      } catch (error) {
        console.error(error)
      }
    },
    [dataProvider]
  )

  const fetchRelatedStoreIntervalPSI = useCallback(async () => {
    const storeIds = []
    const interevalIds = []

    addedProductStores.forEach((ps) => {
      storeIds.push(ps.store['@id'])
      interevalIds.push(ps.store['@id'])
    })

    const data = await fetchPSI({
      'productStore.store.id': parseUrlIdsParam(storeIds),
      'iterval.id': parseUrlIdsParam(addedIntervalsIds),
    })

    setInitialProductStoreIntervals(data)
    setOptionsProductStoreIntervals(data)
  }, [addedProductStores, fetchPSI, addedIntervalsIds])

  const onAddProductStoreIntervalsClick = useCallback(
    async (psiData) => {
      if (psiData.some((item) => !item.price)) {
        return notify('Price field is required', 'error')
      }

      if (psiData.some((item) => !parseInt(item.qty))) {
        return notify('Qty should be minimum 1', 'error')
      }

      try {
        await Promise.all(
          psiData.map((item) =>
            api.createProductStoreInterval({
              productStore: item.productStore,
              pricingModel: item.pricingModel,
              invoiceName: item.invoiceName,
              currency: item.currency,
              qty: parseInt(item.qty),
              price: item.price,
              interval: item.interval,
              shippable: item.shippable,
              name: item.name,
              enabledInPortal: item.enabledInPortal,
            })
          )
        )

        notify('Success', 'info')

        await syncPSI()

        reloadPage()
        redirect(`/products/${encodeURIComponent(productId)}/3`)
        setLoading(true)
      } catch (error) {
        handleError(error)
      }
    },
    [notify, syncPSI, redirect, productId, setLoading, api, handleError]
  )

  const onSubmitPsiClick = useCallback(
    (_selectedIntervals) => {
      const generatedProductStoreIntervalData = generateProductStoreIntervalData(
        {
          productStores: addedProductStores,
          intervals: _selectedIntervals,
        }
      )

      setConfirmationData(generatedProductStoreIntervalData)

      setScreen(PSI_SCREENS.CONFIRMATION)
      setSelectedProductStores(_selectedIntervals)
    },
    [setScreen, setSelectedProductStores, addedProductStores]
  )

  const currencySelectOptions = useMemo(
    () =>
      currencies &&
      currencies.length &&
      currencies.map((item) => ({
        value: item['@id'],
        label: item.code,
      })),
    [currencies]
  )

  const intervalSelectOptions = useMemo(
    () =>
      intervals &&
      intervals.length &&
      intervals.map((item) => ({
        value: item['@id'],
        label: item.name,
      })),
    [intervals]
  )

  const pricingModelSelectOptions = useMemo(
    () =>
      pricingModels &&
      pricingModels.length &&
      pricingModels.map((item) => ({
        value: item['@id'],
        label: item.slug,
      })),
    [pricingModels]
  )

  const onBackClickPSI = useCallback(() => {
    setScreen(PSI_SCREENS.MISSED)
  }, [setScreen])

  const onChangePSI = useCallback(
    async (value) => {
      const storeIds = []
      const interevalIds = []

      addedProductStores.forEach((ps) => {
        storeIds.push(ps.store['@id'])
        interevalIds.push(ps.store['@id'])
      })

      const data = await fetchPSI({
        'productStore.store.id': parseUrlIdsParam(storeIds),
        'iterval.id': parseUrlIdsParam(addedIntervalsIds),
        name: value || '',
      })

      setOptionsProductStoreIntervals(data)
    },
    [addedIntervalsIds, addedProductStores, fetchPSI]
  )

  useEffect(() => {
    if (addedProductStores.length) {
      fetchPricingModels()
      fetchCurrencies()
      fetchIntervals()
    }
  }, [addedProductStores, fetchPricingModels, fetchCurrencies, fetchIntervals])

  useEffect(() => {
    if (productId && addedProductStores.length) {
      syncPSI()
    }
  }, [productId, addedProductStores, syncPSI])

  useEffect(() => {
    if (addedProductStores.length && addedIntervalsIds.length) {
      fetchRelatedStoreIntervalPSI()
    }
  }, [fetchRelatedStoreIntervalPSI, addedProductStores, addedIntervalsIds])

  useEffect(() => {
    if (addedProductStoreIntervals.length) {
      setAddedIntervalsIds(
        addedProductStoreIntervals
          .filter((psi) => psi.interval)
          .map((psi) => psi.interval['@id'])
      )
    }
  }, [addedProductStoreIntervals])

  return {
    selectedProductStores,
    screen,
    intervals,
    addedProductStoreIntervals,
    confirmationData,
    currencySelectOptions,
    intervalSelectOptions,
    pricingModelSelectOptions,
    addedProductStoreIntervalsIds,
    addedIntervalsIds,
    initialProductStoreIntervals,
    optionsProductStoreIntervals,
    setSelectedProductStores,
    onAddProductStoreIntervalsClick,
    onSubmitPsiClick,
    onBackClickPSI,
    onChangePSI,
  }
}
