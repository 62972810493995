import React, { createContext, useContext } from 'react'

const pageStateContext = createContext()

const PageStateProvider = ({ children, activeStep, setActiveStep }) => {
  return (
    <pageStateContext.Provider value={{ activeStep, setActiveStep }}>
      {children}
    </pageStateContext.Provider>
  )
}

const usePageState = () => useContext(pageStateContext)

export { usePageState, PageStateProvider }
