import React from 'react'
import { Layout as BaseLayout } from 'react-admin'
import AppBar from './AppBar'
import Notification from './Notification'
import Menu from 'theme/components/resource/Menu'
// import Menu from './ra-menu-tree/Menu'

const Layout = (props) => (
  <BaseLayout
    {...props}
    appBar={AppBar}
    notification={Notification}
    menu={Menu}
  />
)

export default Layout
