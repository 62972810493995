import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { Confirm, useNotify } from 'react-admin'
import useApi from 'hooks/useApi'

export const ResendEmailButton = ({ record }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { api } = useApi()
  const notify = useNotify()

  return (
    <>
      <Button
        onClick={() => setConfirmOpen(true)}
        color="primary"
        variant="contained"
      >
        resend
      </Button>
      <Confirm
        isOpen={confirmOpen}
        title="Resend email"
        content="Are you sure you want to resend this email?"
        onConfirm={() => {
          api.resendFlowmailerEmail(record.id).then((res) => {
            if (res) {
              setConfirmOpen(false)
              notify('resend was requested')
            }
          })
        }}
        onClose={() => setConfirmOpen(false)}
      />
    </>
  )
}
