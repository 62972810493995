import Icon from '@material-ui/icons/ChromeReaderMode'

import { PlanCreate } from './PlanCreate'
import { PlanList } from './PlanList'
import { PlanEdit } from './PlanEdit'
import { ROLES } from 'utils/user'

export default {
  create: PlanCreate,
  list: PlanList,
  edit: PlanEdit,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
