import React from 'react'
import { required, TextInput, BooleanInput } from 'react-admin'

import { EditGuesser, ResourceEditToolbar } from 'theme/components'

export const VariationPropertyValueEdit = (props) => {
  return (
    <EditGuesser
      variant="outlined"
      redirect={() => window.history.back()}
      toolbar={
        <ResourceEditToolbar showBack={props.showBack} showDelete={false} />
      }
      {...props}
    >
      <TextInput
        source="name"
        label="Name of property value"
        validate={[required()]}
      />
      <TextInput source="nameWc" label="Woocommerce Name" />
      <BooleanInput label="Visible to customer" source="visibleToCustomer" />
    </EditGuesser>
  )
}
