import Icon from '@material-ui/icons/AccessAlarm'
import { ROLES } from 'utils/user'

import { IntervalList } from './IntervalList'
import { IntervalShow } from './IntervalShow'

export default {
  list: IntervalList,
  show: IntervalShow,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN],
}
