import React from 'react'
import { TextField, Filter, TextInput } from 'react-admin'
import { ListGuesser } from 'theme/components'

const VariationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
  </Filter>
)

export const VariationList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="edit"
    bulkActionButtons={false}
    filters={<VariationFilter />}
    filter={{
      properties: ['name', 'id', 'nameWc'],
    }}
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <TextField source="nameWc" label="Woocommerce Name" />
  </ListGuesser>
)
