import React from 'react'
import {
  TextField,
  Filter,
  SelectArrayInput,
  ReferenceArrayInput,
  NumberInput,
  DateInput,
  TextInput,
} from 'react-admin'
import { DateField, ListGuesser } from 'theme/components'
import { LongTextField } from 'theme/components/field/LongTextField'

const ListFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Error id" source="id" />
    <TextInput label="Chargebee id" source="order.displayName" />
    <NumberInput label="Order id" source="order.id" />
    <ReferenceArrayInput
      source="fulfillment.id"
      reference="fulfillments"
      label="Fulfillment"
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <DateInput
      label="Created (from date)"
      defaultValue={new Date()}
      source="createdAt<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Created (to date)"
      defaultValue={new Date()}
      source="createdAt<before>"
      locales="nl-Nl"
    />
  </Filter>
)

export const FulfillmentErrorList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    hasEdit={false}
    rowClick="show"
    bulkActionButtons={false}
    filter={{
      properties: ['createdAt', 'message', 'id'],
      'properties[order]': ['displayName', 'id'],
      'properties[fulfillment]': ['name'],
    }}
  >
    <TextField
      source={'originId'}
      label={'Error id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source={'order.displayName'} label={'Chargebee id'} />
    <TextField source={'order.id'} label={'Order id'} />
    <TextField source={'fulfillment.name'} label={'Fulfillment'} />
    <LongTextField source="message" />
    <DateField source="createdAt" />
  </ListGuesser>
)
