import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import {
  required,
  ReferenceInput,
  AutocompleteInput,
  useNotify,
  useDataProvider,
} from 'react-admin'
import { Button, LinearProgress } from '@material-ui/core'

import {
  getSaveButtonClass,
  CreateOrEditForm,
} from 'components/createOrEditForm'
import { getNumberFromStringId, getProductVariationTitle } from 'utils/string'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
const VariationCard = styled.div`
  position: relative;
`

export const EditWPProductVariationStoresCard = React.memo((props) => {
  const notify = useNotify()
  const [wpProductVariations, setWpProductVariations] = useState([])
  const [fetched, setFetched] = useState(false)
  const dataProvider = useDataProvider()

  const getWpProductVariations = useCallback(async () => {
    const response = await dataProvider.getList('wp-product-variations', {
      pagination: {},
      sort: {},
      filter: {
        'product.id': getNumberFromStringId(props.wpProduct.id),
        properties: ['id', 'name'],
        'properties[interval]': ['id', 'name'],
        'properties[product]': ['name'],
        'properties[propertyValue]': ['name'],
        'properties[image][file][data]': ['cloudFrontUrl'],
      },
    })
    setFetched(true)

    if (response) {
      setWpProductVariations(response.data)
    }
  }, [dataProvider, props.wpProduct])

  useEffect(() => {
    if (!fetched) {
      getWpProductVariations()
    }
  }, [fetched, getWpProductVariations])

  if (!fetched) return <LinearProgress style={{ margin: '20px 0' }} />

  return (
    <Wrapper>
      {wpProductVariations.length ? (
        <>
          {wpProductVariations.map((wpProductVariation, index) => (
            <VariationCard key={`${wpProductVariation.interval.name}:${index}`}>
              <h5>{getProductVariationTitle(wpProductVariation)}</h5>
              <CreateOrEditForm
                resource="wp-product-variation-stores"
                strictFilterWithProperties={{
                  'productVariation.product.id': getNumberFromStringId(
                    props.wpProduct.id
                  ),
                  'productVariation.id': getNumberFromStringId(
                    wpProductVariation.id
                  ),
                  'store.id': getNumberFromStringId(props.id),
                  properties: ['psi'],
                }}
                initialValues={{
                  store: props.id,
                  productVariation: wpProductVariation.id,
                }}
                storeId={props.id}
              >
                <ReferenceInput
                  source="psi"
                  reference="product-store-intervals"
                  label="Product Store Interval"
                  filterToQuery={(searchText) => ({
                    productNameOrChargebeeId: searchText,
                  })}
                  filter={{
                    'productStore.store.id': getNumberFromStringId(props.id),
                    intervalOrNull: getNumberFromStringId(
                      wpProductVariation.interval.id
                    ),
                    properties: ['id', 'name'],
                  }}
                  format={(v) => (v && v['@id'] ? v['@id'] : v)}
                  validate={[required()]}
                  resettable
                >
                  <AutocompleteInput />
                </ReferenceInput>
              </CreateOrEditForm>
            </VariationCard>
          ))}
          <Button
            onClick={() => {
              const saveButtons = document.querySelectorAll(
                `.${getSaveButtonClass(props.id)}`
              )

              if (saveButtons.length) {
                saveButtons.forEach((button) => button.click())
                notify('Save initiated')
              } else {
                notify('No changes to save')
              }
            }}
          >
            save store settings
          </Button>
        </>
      ) : (
        <VariationCard>
          <h4>You have to create wpProductStoreVariation first</h4>
        </VariationCard>
      )}
    </Wrapper>
  )
})
