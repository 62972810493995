import React, { useEffect } from 'react'
import styled from 'styled-components'
import { getNumberFromStringId } from 'utils/string'
import { useRecordContext } from 'react-admin'
import Comment from './Comment'
import CommentModal from './CommentModal'
import { DataGrid } from 'theme/components'
import { ReferenceManyField, FunctionField } from 'react-admin'

const Wrapper = styled.div`
  margin-top: 8px;
  & thead {
    display: none;
  }
`

export const ShortCustomerCommentsList = ({
  ListStats,
  setTotal,
  reloadShortView,
  ...props
}) => {
  const record = useRecordContext()
  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  const customerId = getNumberFromStringId(record?.id)

  return (
    <>
      <CommentModal refetchList={reloadShortView} />
      <Wrapper>
        <ReferenceManyField
          addLabel={false}
          reference="customer-comments"
          target=""
          filter={{
            'customer.id': customerId,
            properties: ['notes', 'createdAt', 'file'],
            'properties[createdBy]': ['email'],
          }}
          {...props}
        >
          <DataGrid showEmpty emptyText="No options available">
            <FunctionField
              render={(record) => (
                <>
                  {ListStats}
                  <Comment refetchList={reloadShortView} comment={record} />
                </>
              )}
            />
          </DataGrid>
        </ReferenceManyField>
      </Wrapper>
    </>
  )
}
