import React, { useMemo, memo, useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'

import { DateField, PaperCard } from 'theme/components'
import UserSession from 'service/UserSession'
import useApi from 'hooks/useApi'
import { hasRoles } from 'utils/user'

const StyledTypography = styled(Typography)`
  margin-right: 10px;
`

export const ExactOnline = memo(() => {
  const { api } = useApi()
  const [data, setData] = useState(null)
  const translate = useTranslate()
  /* eslint-disable react-hooks/exhaustive-deps */
  const { hasAdminRole, hasFinanceRole } = useMemo(
    () => hasRoles(UserSession.getUser().roles),
    [UserSession.getUser().roles]
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const fetchData = async () => {
      const response = await api.fetchExactProperties()

      setData(response.data)
    }

    if ((hasAdminRole || hasFinanceRole) && !data) {
      fetchData()
    }
  }, [api, hasAdminRole, hasFinanceRole])
  /* eslint-enable react-hooks/exhaustive-deps */

  if (!hasAdminRole && !hasFinanceRole) {
    return <Redirect to="/dashboard" />
  }

  if (!data) {
    return null
  }

  return (
    <PaperCard title="resources.exactOnline.title" variant="outlined" square>
      {data.lastLoginAt && (
        <StyledTypography display="inline" variant="caption">
          {translate('resources.exactOnline.lastLogin')}{' '}
          <DateField record={data} source="lastLoginAt" />
        </StyledTypography>
      )}
      <Button
        variant="outlined"
        color="primary"
        size="small"
        href={data.loginUrl}
      >
        {translate(
          data.lastLoginAt
            ? 'resources.exactOnline.reLogin'
            : 'resources.exactOnline.login'
        )}
      </Button>
    </PaperCard>
  )
})
