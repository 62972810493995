export const getDecodedStringFromObject = (object) => {
  return Object.keys(object)
    .map((k) => {
      if (Array.isArray(object[k])) {
        const url = object[k].reduce(
          (accumulator, currentValue) =>
            accumulator + `${k}[]=${currentValue}&`,
          ''
        )

        return url.slice(0, -1)
      }

      return `${encodeURIComponent(k)}=${encodeURIComponent(object[k])}`
    })
    .join('&')
}

export const getUrlParams = () => {
  const params = {}

  window.location.href.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    function (m, key, value) {
      const parsedKey = key.split('%').shift()

      if (params[parsedKey]) {
        params[parsedKey] = [params[parsedKey], decodeURIComponent(value)]
      } else {
        params[parsedKey] = decodeURIComponent(value).split('%').shift()
      }
    }
  )

  return params
}

export const setUrlParam = (key, value) => {
  key = encodeURIComponent(key)
  value = encodeURIComponent(value)

  // kvp looks like ['key1=value1', 'key2=value2', ...]
  var kvp = document.location.search.substr(1).split('&')
  let i = 0

  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + '=')) {
      let pair = kvp[i].split('=')
      pair[1] = value
      kvp[i] = pair.join('=')
      break
    }
  }

  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join('=')
  }

  // can return this or...
  let params = kvp.join('&')

  // reload page with new params
  document.location.search = params
}

export const parseUrlIdsParam = (paramIds = []) => {
  const result = []

  paramIds.forEach((item) => {
    const splittedItem = item.split('/').pop()

    if (!result.includes(splittedItem)) {
      result.push(splittedItem)
    }
  })

  return result
}

