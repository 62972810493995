export default {
  error: {
    '& .MuiDropzoneArea-root': {
      borderColor: '#f44336',
    },
  },
  normal: {
    marginBottom: '20px',

    '& .MuiDropzoneArea-root': {
      marginBottom: '0 !important',
      position: 'relative',
      display: 'block',
      minHeight: '50px',
      '& .MuiDropzoneArea-text': {
        fontSize: '1em',
        marginBottom: '0',
      },
      '& .MuiDropzoneArea-icon': {
        width: '30px',
      },
    },
  },
  errorText: {
    fontSize: '0.75rem',
    marginLeft: '14px',
    marginRight: '14px',
    marginTop: '6px',
  },
  helperText: {
    padding: '0 20px',
    marginBottom: '10px',
  },
}
