import React from 'react'
import { AutocompleteInput, ReferenceInput, BooleanInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const AddonCreate = (props) => {
  return (
    <CreateGuesser {...props} variant="outlined" redirect="list">
      <ReferenceInput
        source="plan"
        reference="plans"
        label="resources.plan.field"
        filterToQuery={(searchText) => ({ chargeBeeId: searchText })}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
      >
        <AutocompleteInput optionText="chargeBeeId" />
      </ReferenceInput>

      <ReferenceInput
        source="productStoreInterval"
        reference="product-store-intervals"
        label="Product Store Interval"
        filterToQuery={(searchText) => ({
          productNameOrChargebeeId: searchText,
        })}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
      >
        <AutocompleteInput
          optionText={(option) =>
            option
              ? `${option.chargeBeeId} (${option.name})`
              : option?.chargeBeeId
          }
        />
      </ReferenceInput>

      <BooleanInput label="Mandatory" source="mandatory" defaultValue={false} />
      <BooleanInput
        label="Recommended"
        source="recommended"
        defaultValue={false}
      />
    </CreateGuesser>
  )
}
