import React, { useCallback } from 'react'
import { Button } from '@material-ui/core'
import { useNotify } from 'react-admin'
import useApi from 'hooks/useApi'

const RetryTaskButton = React.memo(({ record }) => {
  const { status, originId } = record
  const { api, isSubmitting, handleError } = useApi()
  const notify = useNotify()

  const onRetryClick = useCallback(async () => {
    try {
      await api.retryTask({ id: originId })
      notify(`Task ${originId} was retried`)
    } catch (error) {
      handleError(error)
    }
  }, [api, handleError, originId, notify])

  if (!originId || !['failed', 'failed_processing'].includes(status.slug)) {
    return null
  }

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onRetryClick}
      disabled={isSubmitting}
    >
      Retry task
    </Button>
  )
})

export default RetryTaskButton
