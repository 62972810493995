import React from 'react'
import { TextField, Filter, BooleanInput, TextInput, DateInput } from 'react-admin'
import { DateField, ListGuesser } from 'theme/components'

const ListFilters = (props) => (
  <Filter {...props} >
    <BooleanInput label="Has error" source="exists<error>" />
    <TextInput label="Payment Id" source="payment.id" />
    <TextInput label="Checkout session id" source="payment.checkoutSession.id" />
    <TextInput label="Invoice number" source="payment.invoice.chargeBeeId" />
    <DateInput
      label="Created (from date)"
      defaultValue={new Date()}
      source="createdAt<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Created (to date)"
      defaultValue={new Date()}
      source="createdAt<before>"
      locales="nl-Nl"
    />
  </Filter>
)

export const AdyenNotificationList = (props) => {
  return (
    <ListGuesser
      {...props}
      hasEdit={false}
      rowClick="show"
      bulkActionButtons={false}
      filters={<ListFilters />}
      filter={{
        properties: ['id', 'createdAt', 'processedAt', 'error'],
        'properties[payment]': ['id'],
        'properties[data]': ['eventCode'],
      }}
    >
      <TextField source="originId" label="id" />
      <TextField source="data.eventCode" label="Event code" />
      <DateField source="createdAt" short={false} />
      <DateField source="processedAt" short={false} />
      <TextField source="payment.id" label="payment id" />
      <TextField source="error" />
    </ListGuesser>
  )
}
