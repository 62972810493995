import React from 'react'
import { TextInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const SurgeryTypeOptionCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <TextInput source="name" />
    <TextInput source="slug" />
  </CreateGuesser>
)
