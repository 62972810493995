import React, { Component } from 'react'
import { BooleanInput, required } from 'react-admin'
import { CreateGuesser, FileInput } from 'theme/components'
import { sanitizeProps } from 'utils/sanitizer'

class GroupImageCreate extends Component {
  render() {
    let props = { ...this.props }
    const groupId = `/api/groups/${props.match.params.id}`

    return (
      <CreateGuesser
        {...sanitizeProps(props, ['staticContext'])}
        resource="group-images"
        basePath=""
        variant="outlined"
        redirect={`/groups/${encodeURIComponent(groupId)}/1`}
        initialValues={{ group: groupId }}
      >
        <FileInput source="file" label="File" validate={[required()]} />
        <BooleanInput source={'main'} defaultValue={false} />
        <BooleanInput source={'wooCommerce'} defaultValue={false} />
      </CreateGuesser>
    )
  }
}

export default GroupImageCreate
