import { ResourceEditToolbar } from 'theme/components'
import {
  ReferenceInput,
  SelectInput,
  useEditController,
  EditContextProvider,
  SimpleForm,
  useRefresh,
} from 'react-admin'
import React, { cloneElement } from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'
import useApi from 'hooks/useApi'
import _ from 'lodash'

const MyEdit = (props) => {
  const controllerProps = useEditController(props)
  const refresh = useRefresh()
  const { api, isSubmitting } = useApi()
  const {
    basePath,
    loading,
    record,
    redirect,
    resource,
    version,
  } = controllerProps

  const mySave = async (form) => {
    const payload = _.omitBy(form, (value, key) => !['status'].includes(key))

    await api.patchOrder(form.originId, payload)
    refresh()

    return true
  }

  if (loading) {
    return <LinearProgress />
  }

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save: mySave,
          saving: isSubmitting,
          version,
        })}
      </div>
    </EditContextProvider>
  )
}

export const OrderEdit = (props) => (
  <MyEdit {...props} undoable={false}>
    <SimpleForm
      variant="outlined"
      toolbar={<ResourceEditToolbar showDelete={false} showBack={false} />}
    >
      <ReferenceInput
        source="status"
        reference="order-statuses"
        label="resources.order.status"
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
        perPage={50}
      >
        <SelectInput optionText="display" />
      </ReferenceInput>
    </SimpleForm>
  </MyEdit>
)
