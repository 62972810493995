import React, { useState } from 'react'
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined'
import { Button, Dialog } from '@material-ui/core'
import {
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  required,
  TextInput,
  AutocompleteInput,
} from 'react-admin'
import { CreateGuesser, ResourceCreateToolbar } from 'theme/components'
import { formatDateTimeForAPI } from 'utils/string'
import styled from 'styled-components'

const StyledCreate = styled(CreateGuesser)`
  & > div {
    margin: 0;
  }
`

export const CreateAppointmentModal = ({
  customerId,
  phone: phoneNumber,
  showCustomerSelect,
}) => {
  const [open, setOpen] = useState(false)
  const [disableBackdropClick, setDisableBackdropClick] = useState(false)
  const customer = customerId ? `/api/customers/${customerId}` : undefined

  const validateDate = (value) => {
    const now = new Date()
    const selectedDate = new Date(value)
    if (selectedDate < now) {
      return 'Selected date cannot be in the past'
    }
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="outlined"
        color="primary"
        endIcon={<CalendarIcon />}
        fullWidth={!showCustomerSelect}
      >
        appointment
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        disableBackdropClick={disableBackdropClick}
        onBackdropClick={() => setDisableBackdropClick(false)}
      >
        <StyledCreate
          variant="outlined"
          transform={(record) => {
            return {
              ...record,
              customer: showCustomerSelect ? record?.customer?.id : customer,
              startTime: formatDateTimeForAPI(record.startTime),
            }
          }}
          redirect={false}
          resource="appointments"
          basePath="/appointments"
          initialValues={{ customer, phoneNumber }}
          toolbar={<ResourceCreateToolbar showBack={false} />}
          onSuccess={() => setOpen(false)}
        >
          {showCustomerSelect ? (
            <ReferenceInput
              source="customer.id"
              reference="customers"
              label="Customer"
              referenceSource="id"
              filterToQuery={(searchText) => ({ email: searchText })}
            >
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: { style: { zIndex: 99999 } },
                }}
                optionText="email"
              />
            </ReferenceInput>
          ) : null}
          <DateTimeInput
            source="startTime"
            validate={[required(), validateDate]}
            onChange={() => setDisableBackdropClick(true)}
          />
          <TextInput source="phoneNumber" validate={required()} />
          <ReferenceInput
            source="reason"
            reference="call-reasons"
            filterToQuery={(searchText) => ({ name: searchText })}
            validate={required()}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </StyledCreate>
      </Dialog>
    </>
  )
}
