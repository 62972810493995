import React from 'react'
import ImageIcon from '@material-ui/icons/Image'
import { Button } from 'react-admin'
import { Link } from 'react-router-dom'

export const AddImageButton = ({ record }) => (
  <Button
    variant="contained"
    component={Link}
    to={{
      pathname: `/groups/${record.originId}/gallery/add`,
    }}
    label="resources.groupImage.add"
  >
    <ImageIcon />
  </Button>
)
