import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import {
  required,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const ProductStoreCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <ReferenceInput
      source="store"
      reference="stores"
      label="resources.store.field"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
      filter={{ active: 1 }}
    >
      <SelectInput optionText="name" validate={required()} />
    </ReferenceInput>
    <ReferenceInput
      source="product"
      reference="products"
      label="resources.product.field"
      filterToQuery={(searchText) => ({ name: searchText })}
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <AutocompleteInput optionText="name" validate={required()} />
    </ReferenceInput>
    <InputGuesser source="sku" validate={required()} />
  </CreateGuesser>
)
