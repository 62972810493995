import GlobeIcon from '@material-ui/icons/Public'

import { ShippingCountryStoreList } from './ShippingCountryStoreList'
import { ShippingCountryStoreCreate } from './ShippingCountryStoreCreate'
import { ShippingCountryStoreEdit } from './ShippingCountryStoreEdit'
import { ROLES } from 'utils/user'

export default {
  list: ShippingCountryStoreList,
  create: ShippingCountryStoreCreate,
  edit: ShippingCountryStoreEdit,
  icon: GlobeIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
