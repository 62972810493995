import React, { useEffect, useState } from 'react'

import { useCustomer } from 'components/pages/customer/contexts/customer'
import { formatDateTime, pluralize } from 'utils/string'
import styled from 'styled-components'
import { ListItem } from '../commonComponents'
import useApi from 'hooks/useApi'
import { getApiRoute } from 'service/Api/routes'
import { getFormattedResponse } from 'utils/hydra'
import { useRecordContext } from 'react-admin'

const List = styled.ul`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
`

export const Status = () => {
  const record = useRecordContext()

  const { customerId, chargeBeeId } = useCustomer()

  const { api } = useApi()
  const [regularData, setRegularData] = useState([])
  const [urgentData, setUrgentData] = useState([])

  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    if (!chargeBeeId || !customerId || fetched) return
    setFetched(true)

    api
      .get(getApiRoute('customer-calls'), {
        itemsPerPage: 1,
        'customer.id': customerId,
        properties: ['createdAt'],
      })
      .then((res) => {
        const { total, items } = getFormattedResponse(res)
        if (!total) return
        setRegularData((cur) => [
          ...cur,
          {
            label: 'Last call',
            value: formatDateTime(items[0].createdAt),
          },
        ])
      })

    api
      .get(getApiRoute('appointments'), {
        itemsPerPage: 1,
        'customer.id': customerId,
        properties: ['startTime'],
        'order[startTime]': 'DESC',
      })
      .then((res) => {
        const { total, items } = getFormattedResponse(res)
        if (!total) return
        setRegularData((cur) => [
          ...cur,
          {
            label: 'Last appointment',
            value: formatDateTime(items[0].startTime),
          },
        ])
      })

    api
      .get(getApiRoute('invoices'), {
        itemsPerPage: 999,
        'customer.chargeBeeId': chargeBeeId,
        'neq[chargeBeeStatus]': ['paid'], // filtering not paid invoices
        properties: ['chargeBeeId'],
      })
      .then((res) => {
        const { total, items } = getFormattedResponse(res)
        if (!total) return
        setUrgentData((cur) => [
          ...cur,
          {
            label: `${pluralize(total, 'unpaid invoice')} (${total})`,
            value: items.map((item) => item.chargeBeeId).join(', '),
          },
        ])
      })

    api
      .get(getApiRoute('invoices'), {
        itemsPerPage: 999,
        'customer.chargeBeeId': chargeBeeId,
        inDunning: 1,
      })
      .then((res) => {
        const { total, items } = getFormattedResponse(res)
        if (!total) return
        setUrgentData((cur) => [
          ...cur,
          {
            label: `${pluralize(total, 'dunning invoice')} (${total})`,
            value: items.map((item) => item.chargeBeeId).join(', '),
          },
        ])
      })

    api
      .get(getApiRoute('orders'), {
        itemsPerPage: 999,
        'customer.id': customerId,
        'neq[status.id]': [13], // 13 - delivered. filtering not delivered orders
        properties: ['chargeBeeId'],
      })
      .then((res) => {
        const { total, items } = getFormattedResponse(res)
        if (!total) return
        setUrgentData((cur) => [
          ...cur,
          {
            label: `${pluralize(total, 'pending order')} (${total})`,
            value: items.map((item) => item.chargeBeeId).join(', '),
          },
        ])
      })

    if (record?.data?.cf_trial_status) {
      setRegularData((cur) => [
        ...cur,
        {
          label: 'Trial status',
          value: record?.data?.cf_trial_status,
        },
      ])
    }
    if (record?.data?.cf_reason_trial_not_converted) {
      setRegularData((cur) => [
        ...cur,
        {
          label: 'Not converted reason',
          value: record?.data?.cf_reason_trial_not_converted,
        },
      ])
    }

    setUrgentData((cur) => [
      ...cur,
      {
        label: 'Refundable credits',
        value: record?.data?.refundable_credits / 100,
      },
    ])

    setRegularData((cur) => [
      ...cur,
      {
        label: 'Excess payments',
        value: record?.data?.excess_payments / 100,
      },
    ])

    api
      .get(getApiRoute('appointments'), {
        itemsPerPage: 999,
        'customer.id': customerId,
        'neq[status]': [2], // 2 - completed. filtering not completed appointments
        properties: ['startTime', 'status'],
      })
      .then((res) => {
        const { total, items } = getFormattedResponse(res)
        if (!total) return
        setUrgentData((cur) => [
          ...cur,
          {
            label: `${pluralize(total, 'open appointment')} (${total})`,
            value: formatDateTime(items[0].startTime),
          },
        ])
      })
  }, [record, api, chargeBeeId, customerId, fetched])

  return (
    <List>
      {urgentData.map((item, i) => {
        return (
          <ListItem
            urgent
            key={`urgent-status-item-${i}`}
            title={item.label}
            value={item.value}
          />
        )
      })}

      {regularData.map((item, i) => {
        return (
          <ListItem
            key={`status-item-${i}`}
            title={item.label}
            value={item.value}
          />
        )
      })}
    </List>
  )
}
