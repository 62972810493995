import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { ROLES } from 'utils/user'

import { NotificationList } from './NotificationList'
import { NotificationEdit } from './NotificationEdit'

export default {
  list: NotificationList,
  edit: NotificationEdit,
  icon: NotificationsActiveIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
