import MenuBookIcon from '@material-ui/icons/MenuBook'

import { ROLES } from 'utils/user'

import { WPProductList } from './WPProductList'
import { WPProductEdit } from './WPProductEdit'
import { WPProductCreate } from './WPProductCreate'

export default {
  list: WPProductList,
  edit: WPProductEdit,
  create: WPProductCreate,
  icon: MenuBookIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
