import React, { useCallback, useState } from 'react'

import styled from 'styled-components'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CustomTable from 'components/customTable'

const StyledTypography = styled(Typography)`
  display: block;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px;
`

const AddDataTable = React.memo(
  ({
    data = [],
    headCells = [],
    pageSize = 10,
    onSubmitClick,
    record,
    title = '',
    submitText = 'Add',
    selectable = false,
    setSelectedData,
    selectedData,
  }) => {
    const [_selectedData, _setSelectedData] = useState(selectedData || [])

    const onChange = useCallback(
      (selectedIds) => {
        const filtereData = data.filter((item) => selectedIds.includes(item.id))

        _setSelectedData(filtereData)

        if (setSelectedData) {
          setSelectedData()
        }
      },
      [data, setSelectedData]
    )

    const _onSubmitClick = useCallback(() => {
      onSubmitClick(_selectedData, record)
    }, [_selectedData, onSubmitClick, record])

    return (
      <div>
        {title && (
          <StyledTypography display="inline" variant="h6">
            {title}
          </StyledTypography>
        )}

        {Boolean(data.length) && (
          <CustomTable
            selectedData={_selectedData}
            onChange={onChange}
            data={data}
            headCells={headCells}
            pageSize={pageSize}
            selectable={selectable}
          />
        )}

        <Button
          color="primary"
          variant="contained"
          disabled={!_selectedData.length}
          onClick={_onSubmitClick}
        >
          {submitText}
        </Button>
      </div>
    )
  }
)

export default AddDataTable
