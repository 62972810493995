import React from 'react'
import { SaveButton, DeleteButton } from 'react-admin'
import PropTypes from 'prop-types'
import BackButton from '../button/BackButton'
import { withStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar'
import { sanitizeProps } from 'utils/sanitizer'

const styles = {
  toolbar: {
    justifyContent: 'space-between',
    // '& .ra-delete-button': {
    //   marginRight: '3em',
    // },
  },

  rightButtons: {
    display: 'flex',
    alignItems: 'center',
  },

  deleteWrapper: {
    marginRight: '2em',
  },
}

const sanitizeButton = (props) => sanitizeProps(props, ['mutationMode'])

export const ResourceToolbar = withStyles(styles)(
  ({ showBack, showDelete, customDeleteButton, classes, ...props }) => {
    return (
      <Toolbar className={classes.toolbar}>
        {showBack && <BackButton {...sanitizeButton(props)} />}
        <Box className={classes.rightButtons}>
          {(showDelete || customDeleteButton) && (
            <Box className={classes.deleteWrapper}>
              {customDeleteButton || (
                <DeleteButton {...sanitizeButton(props)} />
              )}
            </Box>
          )}
          <SaveButton {...sanitizeButton(props)} />
        </Box>
      </Toolbar>
    )
  }
)

ResourceToolbar.defaultProps = {
  showDelete: false,
  showBack: true,
}

ResourceToolbar.propTypes = {
  showDelete: PropTypes.bool,
  showBack: PropTypes.bool,
}
