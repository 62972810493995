import React from 'react'
import {
  SelectInput,
  ReferenceInput,
  TextInput,
  FunctionField,
  TextField,
} from 'react-admin'
import { EditGuesser } from 'theme/components'

export const CancelReasonEdit = (props) => (
  <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
    <TextInput source="name" />
    <TextInput source="slug" />
    <ReferenceInput
      source="parent"
      reference="cancellation-reasons"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
      filter={{ 'exists[parent]': false }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <FunctionField
      label="Version"
      render={(record) => {
        const history = record?.data?.history
        const newRecord = {
          ...record,
          version: history ? history[history.length - 1]?.version : null,
        }

        return <TextField record={newRecord} source="version" />
      }}
    />
  </EditGuesser>
)
