import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import { ReferenceManyField, Pagination, TextField } from 'react-admin'

import { DataGrid, DateField } from 'theme/components'

export const RelatedOrdersTab = (props) => (
  <ReferenceManyField
    {...props}
    addLabel={false}
    reference="orders"
    target="store.id"
    source="id"
    pagination={<Pagination />}
    filter={{
      properties: [
        'id',
        'chargeBeeId',
        'description',
        'createdAt',
        'updatedAt',
      ],
      'properties[chargeBeeStatus]': ['id', 'slug'],
      'properties[status]': ['id', 'display'],
      'properties[montaStatus]': ['id', 'slug'],
    }}
  >
    <DataGrid empty="resources.product.list.productStore" rowClick="show">
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <FieldGuesser source="chargeBeeId" label="resources.order.chargeBee" />
      <TextField source="status.display" label="resources.orderStatus.field" />
      <TextField
        source="chargeBeeStatus.slug"
        label="resources.chargeBeeStatus.field"
      />
      <TextField
        source="montaStatus.slug"
        label="resources.montaStatus.field"
      />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </DataGrid>
  </ReferenceManyField>
)
