import React, { useEffect } from 'react'
import { ReferenceManyField, FunctionField, TextField } from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { useCustomer } from 'components/pages/customer/contexts/customer'

import styled from 'styled-components'
import { CALL_STATUSES } from './CallsList'

const MultilineTextField = styled(TextField)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  max-width: 90px;
`

export const ShortCallsList = ({
  ListStats,
  setTotal,
  reloadShortView,
  ...props
}) => {
  const { customerId } = useCustomer()

  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  return (
    <ReferenceManyField
      addLabel={false}
      reference="customer-calls"
      target=""
      filter={{
        'customer.id': customerId,
        properties: ['description', 'createdAt', 'reason', 'status'],
      }}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={() => {}}>
        <DateField source="createdAt" label />
        <MultilineTextField source="reason.name" label="Reason" />
        <MultilineTextField source="description" label="Description" />
        <FunctionField
          label="Status"
          render={(record) => (
            <>
              {ListStats}
              {CALL_STATUSES[record.status]}
            </>
          )}
        />
      </DataGrid>
    </ReferenceManyField>
  )
}
