import React from 'react'
import {
  TextField,
  Filter,
  NumberInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  FunctionField,
  DateInput,
  useRefresh,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import { LongTextField } from 'theme/components/field/LongTextField'
import { LogCallModal } from 'components/logCallModal'
import { getNumberFromStringId } from 'utils/string'
import { trialStatuses } from './tiles/editCustomerFIelds/CustomerEdit'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput source="email" />
    <TextInput source="zip" />
    <TextInput source="firstName" />
    <TextInput source="lastName" />
    <TextInput source="phone" />
    <NumberInput label="Id" source="id" />
    <ReferenceInput
      source="store.id"
      reference="stores"
      label="resources.store.field"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput
      source="single_order"
      defaultValue={false}
      parse={(v) => v || undefined}
    />
    <BooleanInput
      source="recurring_subscription"
      defaultValue={false}
      parse={(v) => v || undefined}
    />
    <BooleanInput
      source="starter_kit_only"
      defaultValue={false}
      parse={(v) => v || undefined}
    />
    <DateInput
      label="Appointment Date"
      defaultValue={new Date()}
      source="appointmentDate"
      locales="nl-Nl"
    />
    <TextInput source="all" label="resources.customer.searchByAll" />
    <SelectInput
      source="trialStatus"
      optionText="name"
      choices={trialStatuses}
    />

    <DateInput
      label="First order date (from date)"
      defaultValue={new Date()}
      source="firstOrderDate<after>"
    />
    <DateInput
      label="First order date (to date)"
      defaultValue={new Date()}
      source="firstOrderDate<before>"
    />
  </Filter>
)

export const CustomerList = (props) => {
  const refresh = useRefresh()

  return (
    <ListGuesser
      {...props}
      filters={<ListFilter />}
      hasEdit={false}
      rowClick="show"
      bulkActionButtons={false}
      filter={{
        properties: [
          'id',
          'firstName',
          'lastName',
          'email',
          'country',
          'phone',
          'chargeBeeId',
          'createdAt',
          'firstOrderDate',
          'nextRenewalDate',
        ],
        'properties[store]': ['name', 'id', 'chargeBeeWebsite', 'productFlow'],
        'properties[data]': ['cf_trial_status'],
      }}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <LongTextField maxWidth={130} source="firstName" />
      <LongTextField maxWidth={130} source="lastName" />
      <LongTextField maxWidth={150} source="email" />
      <FunctionField
        render={(record) => (
          <LogCallModal
            customerId={getNumberFromStringId(record?.id)}
            removeFromList
            onSuccess={refresh}
          />
        )}
      />
      <TextField source="country" />

      <ReferenceListFilterField
        label="Store"
        listUri="/stores"
        titlePath="store.name"
        valuePath="store.id"
      />
      <LongTextField maxWidth={150} source="phone" />
      <TextField source="chargeBeeId" />
      <TextField label="Trial status" source="data.cf_trial_status" />
      <DateField source="firstOrderDate" />
      <DateField source="nextRenewalDate" />
      <DateField source="createdAt" />
    </ListGuesser>
  )
}
