import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'

export const CustomToolbar = (props) => {
  const saveButtonProps = props.saveButtonProps || {}
  const toolbarProps = { ...props }
  delete toolbarProps.saveButtonProps

  return (
    <Toolbar {...toolbarProps} style={props.hidden ? { display: 'none' } : {}}>
      <SaveButton
        className={!props.pristine ? props.saveButtonClass : ''}
        {...saveButtonProps}
      />
    </Toolbar>
  )
}
