import React, { useCallback, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useDataProvider, useNotify } from 'react-admin'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useCustomer } from '../contexts/customer'
import { useStore } from '../contexts/store'
import { PhoneInput } from 'theme/components/input/PhoneInput'
import { ClinicCreate } from 'components/pages/clinic/ClinicCreate'
import { formatDateForAPI } from 'utils/string'

const FormWrapper = styled.div`
  display: grid;
  column-gap: 8px;
  row-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
`

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

const TextButton = styled.button`
  border: none;
  background: transparent;
  margin-right: auto;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  left: 0;
  font-size: 12px;
  line-height: 12px;
  bottom: -12px;
`

export const PersonalDetailsStep = () => {
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const { personalInfo, setPersonalInfo } = useCustomer()
  const { storeId, store } = useStore()
  const [addClinicOpen, setAddClinicOpen] = useState(false)
  const isCustomerSurgeryTypeEnabled = store?.customerSurgeryTypeEnabled
  const isCustomerClinicEnabled = store?.customerClinicEnabled
  const isCustomerSurgeonEnabled = store?.customerSurgeonEnabled
  const isCustomerSurgeryDateEnabled = store?.customerSurgeryDateEnabled
  const [clinicsFetched, setClinicsFetched] = useState()
  const [clinics, setClinics] = useState([])
  const [surgeryTypes, setSurgeryTypes] = useState([])
  const [surgeryTypesMap, setSurgeryTypesMap] = useState({})

  useEffect(() => {
    if (!surgeryTypes.length) return
    const map = {}

    surgeryTypes.forEach(({ surgeryType }) => {
      map[surgeryType.id] = surgeryType
    })

    setSurgeryTypesMap(map)
  }, [surgeryTypes])

  const fetchClinics = useCallback(async () => {
    const { data } = await dataProvider.getList('clinics', {
      pagination: {},
      sort: {},
      filter: {
        itemsPerPage: 999,
        'stores.id': [storeId],
        'status.id': [2], // 2 - live
      },
    })
    setClinics(data)
    setClinicsFetched(true)
  }, [dataProvider, storeId])

  const fetchSurgeryTypes = useCallback(async () => {
    const { data } = await dataProvider.getList('surgery-type-option-stores', {
      pagination: {},
      sort: {},
      filter: {
        itemsPerPage: 999,
        'store.id': storeId,
      },
    })
    setSurgeryTypes(
      data.map((item) => ({
        surgeryType: { ...item.surgeryType, name: item.name },
      }))
    )
  }, [dataProvider, storeId])

  useEffect(() => {
    if (storeId && !clinicsFetched) {
      fetchClinics()
    }
  }, [storeId, fetchClinics, clinicsFetched])

  useEffect(() => {
    if (isCustomerSurgeryTypeEnabled && storeId) {
      fetchSurgeryTypes()
    }
  }, [isCustomerSurgeryTypeEnabled, storeId, fetchSurgeryTypes])

  const surgeryTypeValue = Object.values(surgeryTypesMap).find(
    (item) => item.name === personalInfo?.surgeryType
  )

  const clinicValue = useMemo(() => {
    return personalInfo?.clinic
      ? { name: personalInfo?.clinic, val: personalInfo?.clinic }
      : null
  }, [personalInfo])

  return (
    <>
      <FormWrapper>
        <TextField
          size="small"
          variant="outlined"
          label="First name"
          value={personalInfo?.firstName || ''}
          onChange={(e) => {
            e.persist()
            setPersonalInfo((prev) => {
              return { ...prev, firstName: e.target.value }
            })
          }}
        />

        <TextField
          size="small"
          variant="outlined"
          label="Last name"
          value={personalInfo?.lastName || ''}
          onChange={(e) => {
            e.persist()
            setPersonalInfo((prev) => {
              return { ...prev, lastName: e.target.value }
            })
          }}
        />

        <TextField
          size="small"
          variant="outlined"
          label="Email"
          InputLabelProps={{ shrink: !!personalInfo?.email }}
          value={personalInfo?.email || ''}
          onChange={(e) => {
            e.persist()
            setPersonalInfo((prev) => {
              return { ...prev, email: e.target.value }
            })
          }}
        />

        <PhoneInput
          label="Telephone number"
          value={personalInfo?.phone || ''}
          onChange={(e) => {
            e.persist && e.persist()
            setPersonalInfo((prev) => {
              return { ...prev, phone: e.target.value }
            })
          }}
          defaultCountry={store?.countryCode}
        />

        {isCustomerClinicEnabled && !!clinics?.length && (
          <Autocomplete
            size="small"
            value={clinicValue}
            options={clinics}
            getOptionSelected={(option, selected) => option.name === selected.val } 
            onChange={(event, newValue) => {
              event.persist()
              setPersonalInfo((prev) => {
                return {
                  ...prev,
                  clinic: newValue?.name,
                }
              })
            }}
            getOptionLabel={(option) => option?.name || JSON.stringify(option)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Clinic"
                variant="outlined"
                helperText={
                  <TextButton
                    type="button"
                    onClick={() => setAddClinicOpen(true)}
                  >
                    add new clinic
                  </TextButton>
                }
              />
            )}
          />
        )}

        {isCustomerSurgeryTypeEnabled && !!surgeryTypes?.length && (
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel htmlFor="surgery-type">Surgery type</InputLabel>
            <Select
              value={surgeryTypeValue?.id || ''}
              onChange={(e) => {
                e.persist()
                setPersonalInfo((prev) => {
                  return {
                    ...prev,
                    surgeryType: surgeryTypesMap[e.target.value].name,
                  }
                })
              }}
              input={
                <OutlinedInput
                  name="surgery-type"
                  id="surgery-type"
                  label="Surgery type"
                />
              }
            >
              {surgeryTypes.map(({ surgeryType }) => (
                <MenuItem key={surgeryType.id} value={surgeryType.id}>
                  {surgeryType.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {isCustomerSurgeryDateEnabled && (
          <TextField
            size="small"
            variant="outlined"
            label="Surgery date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={
              personalInfo?.surgeryDate
                ? formatDateForAPI(personalInfo.surgeryDate)
                : ''
            }
            onChange={(e) => {
              e.persist()
              setPersonalInfo((prev) => {
                return {
                  ...prev,
                  surgeryDate: e.target.value,
                  surgeryDateUnknown: e.target.value
                    ? false
                    : prev.surgeryDateUnknown,
                }
              })
            }}
          />
        )}

        {isCustomerSurgeonEnabled && (
          <TextField
            size="small"
            variant="outlined"
            label="Surgeon name"
            value={personalInfo?.surgeonName || ''}
            onChange={(e) => {
              e.persist()
              setPersonalInfo((prev) => {
                return { ...prev, surgeonName: e.target.value }
              })
            }}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={personalInfo.marketingConsent}
              onChange={() =>
                setPersonalInfo((prev) => ({
                  ...prev,
                  marketingConsent: !prev.marketingConsent,
                }))
              }
            />
          }
          label="Agree to receive marketing content"
        />
      </FormWrapper>
      <Dialog open={addClinicOpen} onClose={() => setAddClinicOpen(false)}>
        <DialogTitle>Add new clinic</DialogTitle>
        <DialogBody>
          <ClinicCreate
            resource="clinics"
            basePath="/clinics"
            showBack={false}
            redirect={false}
            initialValues={{
              status: '/api/clinic-statuses/2', // 1 - pending;  2 - live; 3 - archived
              stores: [store.id],
            }}
            onSuccess={(res) => {
              setAddClinicOpen(false)
              setClinicsFetched(false)

              if (res) {
                setPersonalInfo((prev) => {
                  return { ...prev, clinic: res?.data?.name }
                })
              }

              notify('New clinic created')
            }}
          />
        </DialogBody>
      </Dialog>
    </>
  )
}
