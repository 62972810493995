import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import {
  minLength,
  PasswordInput,
  CheckboxGroupInput,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import { EditGuesser } from 'theme/components'
import { roleOptions } from 'utils/user'

export const UserEdit = (props) => {
  return (
    <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
      <InputGuesser source="email" inputProps={{ autoComplete: 'off' }} />
      <InputGuesser source={'firstName'} inputProps={{ autoComplete: 'off' }} />
      <InputGuesser source={'lastName'} inputProps={{ autoComplete: 'off' }} />
      <ReferenceArrayInput
        label="Stores"
        source="stores"
        reference="stores"
        format={(items) => {
          return items ? items.map((v) => (v && v['@id'] ? v['@id'] : v)) : []
        }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <CheckboxGroupInput
        source="roles"
        choices={roleOptions}
      />
      <PasswordInput
        label="Password"
        source="plainPassword"
        inputProps={{ autoComplete: 'no' }}
        validate={minLength(
          8,
          `Check if password is not less then 8 characters long`
        )}
      />
    </EditGuesser>
  )
}
