import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import {
  BooleanField,
  TextField,
  Filter,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
} from 'react-admin'
import { AddonEdit } from './AddonEdit'
import { ListGuesser, ReferenceListFilterField } from 'theme/components'

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      key="addons-plan-cb-id-filter"
      source="plan.id"
      reference="plans"
      label="Plan ChargeBee ID"
      filterToQuery={(searchText) => ({ chargeBeeId: searchText })}
    >
      <AutocompleteInput optionText="chargeBeeId" />
    </ReferenceInput>
    <ReferenceInput
      source="productStoreInterval.id"
      reference="product-store-intervals"
      label="resources.productStoreInterval.field"
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceArrayInput
      source="productStoreInterval.productStore.store.id"
      reference="stores"
      label="Store"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <NumberInput label="Id" source="id" />
    <BooleanInput label="Mandatory" source="mandatory" />
    <BooleanInput label="Recommended" source="recommended" />
    <BooleanInput
      label="Mandatory or Recommended"
      source="mandatoryOrRecommended"
    />
    <TextInput
      source="productStoreInterval.chargeBeeId"
      label="PSI Chargebee Id"
    />
  </Filter>
)

export const AddonList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    hasEdit={true}
    hasShow={false}
    filter={{
      properties: ['id', 'mandatory', 'recommended'],
      'properties[plan]': ['id', 'chargeBeeId'],
      'properties[productStoreInterval]': ['id', 'name', 'enabledInPortal'],
    }}
    expand={<AddonEdit defaultToolbar />}
    rowClick="edit"
    bulkActionButtons={false}
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <ReferenceListFilterField
      {...props}
      listUri="/plans"
      titlePath="plan.chargeBeeId"
      valuePath="plan.id"
      label="resources.plan.field"
    />
    <ReferenceListFilterField
      {...props}
      listUri="/product-store-intervals"
      titlePath="productStoreInterval.name"
      valuePath="productStoreInterval.id"
      label="resources.productStoreInterval.field"
    />
    <FieldGuesser source="mandatory" />
    <FieldGuesser source="recommended" />
    <BooleanField
      label="Enabled in portal"
      source="productStoreInterval.enabledInPortal"
    />
  </ListGuesser>
)
