import React from 'react'
import {
  minLength,
  PasswordInput,
  ReferenceInput,
  SelectArrayInput,
} from 'react-admin'
import { InputGuesser } from '@api-platform/admin'
import { CheckboxGroupInput } from 'react-admin'
import { roleOptions } from 'utils/user'
import { CreateGuesser } from 'theme/components'
import { generatePassword } from 'utils/string'

export const UserCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <InputGuesser source="email" inputProps={{ autoComplete: 'off' }} />
    <InputGuesser source={'firstName'} inputProps={{ autoComplete: 'off' }} />
    <InputGuesser source={'lastName'} inputProps={{ autoComplete: 'off' }} />
    <ReferenceInput
      label="Stores"
      source="stores"
      reference="stores"
      format={(items) => {
        return items ? items.map((v) => (v && v['@id'] ? v['@id'] : v)) : []
      }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceInput>
    <CheckboxGroupInput
      source="roles"
      choices={roleOptions}
    />
    <PasswordInput
      label="Password"
      source="plainPassword"
      defaultValue={generatePassword()}
      inputProps={{ autoComplete: 'no' }}
      validate={minLength(
        8,
        `Check if password is not less then 8 characters long`
      )}
    />
  </CreateGuesser>
)
