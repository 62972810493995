import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const EnhancedTableHead = React.memo(
  ({
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    selectable,
    headCells = [],
  }) => {
    const createSortHandler = useCallback(
      (property) => (event) => {
        onRequestSort(event, property)
      },
      [onRequestSort]
    )

    return (
      <TableHead>
        <TableRow>
          {selectable && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}

          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    )
  }
)

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 300,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))

const CustomTable = React.memo(
  ({
    data = [],
    pageSize = 10,
    selectable = false,
    headCells = [],
    selectedData,
    onChange,
    handleRowClick,
    onPageChange,
    totalItems,
  }) => {
    const classes = useStyles()
    const [order, setOrder] = React.useState('asc')
    const [orderBy, setOrderBy] = React.useState('calories')
    const [selected, setSelected] = React.useState(
      (selectedData && selectedData.map((item) => item.id)) || []
    )
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize)

    const handleRequestSort = useCallback(
      (event, property) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
      },
      [orderBy, order]
    )

    const handleSelectAllClick = useCallback(
      (event) => {
        if (event.target.checked) {
          const newSelecteds = data.map((n) => n.id)

          if (onChange) {
            onChange(newSelecteds)
          }

          return setSelected(newSelecteds)
        }

        if (onChange) {
          onChange([])
        }

        setSelected([])
      },
      [data, onChange]
    )

    const handleClick = useCallback(
      (event, id) => {
        if (selectable) {
          const selectedIndex = selected.indexOf(id)
          let newSelected = []

          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id)
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selected.slice(0, selectedIndex),
              selected.slice(selectedIndex + 1)
            )
          }

          setSelected(newSelected)

          if (onChange) {
            onChange(newSelected)
          }
        } else if (handleRowClick) {
            handleRowClick(id)
          }
      },
      [selectable, selected, setSelected, onChange, handleRowClick]
    )

    const handleChangePage = useCallback(
      (event, newPage) => {
        setPage(newPage)

        if (onPageChange) {
          onPageChange(newPage)
        }
      },
      [onPageChange]
    )

    const handleChangeRowsPerPage = useCallback(
      (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
      },
      [setRowsPerPage, setPage]
    )

    const isSelected = useCallback((id) => selected.indexOf(id) !== -1, [
      selected,
    ])

    const emptyRows = useMemo(
      () =>
        rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage),
      [rowsPerPage, data, page]
    )

    const filterRow = useCallback(
      (row) => {
        const keysSet = new Set(Object.keys(row))
        return headCells
          .map((item) => item.id)
          .filter((key) => keysSet.has(key))
      },
      [headCells]
    )

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'small'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                headCells={headCells}
                selectable={selectable}
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
              />
              <TableBody>
                {stableSort(data, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {selectable && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </TableCell>
                        )}

                        {filterRow(row).map((rowKey, i) => (
                          <TableCell
                            key={i}
                            align="left"
                            style={{
                              maxWidth: '400px',
                              wordWrap: 'anywhere',
                            }}
                          >
                            {rowKey === 'image' ? (
                              <img
                                width={60}
                                height={60}
                                src={row[rowKey]}
                                alt=""
                              />
                            ) : (
                              row[rowKey]
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalItems || data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )
  }
)

export default CustomTable
