import React, { cloneElement, useMemo } from 'react'
import { FieldGuesser } from '@api-platform/admin'
import {
  TextField,
  Filter,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteInput,
  ReferenceInput,
  NumberInput,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  ExportButton,
  CreateButton,
  FunctionField,
} from 'react-admin'
import Tooltip from '@material-ui/core/Tooltip'
import { ProductStoreIntervalEdit } from './ProductStoreIntervalEdit'
import { ListGuesser, ReferenceListFilterField } from 'theme/components'
import { exporter } from './exporter'
import ImportButton from 'components/csv/import'
import UserSession from 'service/UserSession'
import { hasRoles } from 'utils/user'

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="productStore.id"
      reference="product-stores"
      label="resources.productStore.field"
      filterToQuery={(searchText) => ({ sku: searchText })}
    >
      <AutocompleteInput
        optionText={(option) => {
          if (!option?.store?.name) return option?.sku
          return `${option.sku} (${option.store.name})`
        }}
      />
    </ReferenceInput>
    <ReferenceInput
      source="productStore.product.id"
      reference="products"
      label="resources.productStore.fieldSku"
      filterToQuery={(searchText) => ({ sku: searchText })}
    >
      <AutocompleteInput optionText="sku" />
    </ReferenceInput>
    <ReferenceArrayInput
      source="productStore.store.id"
      reference="stores"
      label="Store"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="currency.id"
      reference="currencies"
      label="Currency"
      perPage={500}
    >
      <SelectArrayInput optionText="code" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="interval.id"
      reference="intervals"
      label="Interval"
      perPage={500}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <NumberInput label="Id" source="id" />
    <TextInput label="Price" source="price" />
    <TextInput label="Quantity" source="qty" />
    <TextInput label="Name" source="name" />
    <TextInput label="ChargeBee ID" source="chargeBeeId" />
  </Filter>
)

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        label="Export CSV"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <ImportButton resource="product-store-intervals" />
    </TopToolbar>
  )
}

export const ProductStoreIntervalList = (props) => {
  const { roles } = UserSession.getUser()
  const { hasAdminRole } = useMemo(() => hasRoles(roles), [roles])
  return (
    <ListGuesser
      {...props}
      filters={<ListFilter />}
      hasEdit={false}
      filter={{
        properties: [
          'id',
          'price',
          'qty',
          'name',
          'description',
          'chargeBeeId',
          'invoiceName',
          'shippable',
          'chargeBeeSyncStatus',
          'chargeBeeSyncError',
        ],
        'properties[productStore]': ['id', 'sku', 'store'],
        'properties[interval]': ['id', 'name'],
        'properties[currency]': ['id', 'code'],
        'properties[pricingModel]': ['id', 'slug'],
      }}
      rowClick="edit"
      expand={<ProductStoreIntervalEdit defaultToolbar />}
      bulkActionButtons={false}
      exporter={exporter}
      actions={<ListActions />}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      {hasAdminRole ? (
        <ReferenceListFilterField
          label="resources.store.field"
          listUri="/stores"
          titlePath="productStore.store.name"
          valuePath="productStore.store.name"
        />
      ) : (
        <TextField
          source="productStore.store.name"
          label="resources.store.field"
        />
      )}
      <ReferenceListFilterField
        label="resources.productStore.field"
        listUri="/product-stores"
        titlePath="productStore.sku"
        valuePath="productStore.sku"
      />
      <ReferenceListFilterField
        label="resources.interval.field"
        listUri="/intervals"
        titlePath="interval.name"
        valuePath="interval.id"
      />
      <ReferenceListFilterField
        label="resources.currency.field"
        listUri="/currencies"
        titlePath="currency.code"
        valuePath="currency.id"
      />
      <FieldGuesser source="chargeBeeId" label="resources.order.chargeBee" />
      <FieldGuesser source="name" />
      <FunctionField
        label="ChargeBee Sync Status"
        render={(record) => {
          const error = record?.chargeBeeSyncError
          const status = record?.chargeBeeSyncStatus

          return error ? (
            <Tooltip title={error}>
              <div>{status}</div>
            </Tooltip>
          ) : (
            status
          )
        }}
      />
      <FieldGuesser source="price" />
      <FieldGuesser source="qty" />
      <TextField
        source="pricingModel.slug"
        label="resources.productStoreInterval.pricingModel"
      />
      <FieldGuesser source="shippable" />
    </ListGuesser>
  )
}
