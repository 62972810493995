import React from 'react'
import { AppBar as BaseAppBar } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import UserSession from 'service/UserSession'

import styled from 'styled-components'

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  username: {
    padding: '0 10px',
  },
}

const StyledTitle = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: white;
  flex: 1;

  span:nth-child(2) {
    display: none;
  }
`

const StyledAppBar = styled(BaseAppBar)`
  background-color: rgb(144, 197, 60) !important;
`

const AppBar = withStyles(styles)(({ classes, ...props }) => (
  <StyledAppBar {...props}>
    <StyledTitle id="react-admin-title" variant="h6" />
    <Typography color="inherit" className={classes.username}>
      <span>{UserSession.getUser().name}</span>
    </Typography>
  </StyledAppBar>
))

export default AppBar
