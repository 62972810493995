import React, { useState, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { FormSpy } from 'react-final-form'
import { EditGuesser } from 'theme/components'
import { CheckoutSessionInput } from './checkoutSessionInput'
import { ReferenceInput, DateTimeInput, SelectInput } from 'react-admin'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { CustomToolbar } from './CustomToolbar'
import { debounce } from 'lodash'
import { getPimcoreSubscriptions } from './checkoutSessionInput/subscriptions/utils'
import { isEmail } from 'utils/string'

export const TopInputs = styled.div`
  display: flex;
  gap: 8px;
  width: 100% !important;
`

export const CustomTextField = styled(TextField)`
  width: 300px;
`

export const CheckoutSessionEdit = (props) => {
  const [canSubmit, setCanSubmit] = useState(false)
  const [storeId, setStoreId] = useState()
  const stores = useSelector((state) => state.admin.resources.stores?.data)
  const store = useMemo(() => stores[storeId], [stores, storeId])
  const checkoutSessions = useSelector(
    (state) => state.admin.resources['checkout-sessions']?.data
  )
  const checkoutSession = checkoutSessions[props.id]

  const disabled = !!checkoutSession?.payment || !!checkoutSession?.completed

  /* eslint-disable react-hooks/exhaustive-deps */
  const onFormChange = useCallback(
    debounce((form) => {
      form.values.store && setStoreId(form.values.store['@id'])

      setCanSubmit({
        hasStore: !!form.values.store,
        hasEmail: isEmail(form?.values?.customerEmail),
        hasSubscriptions: !!form?.values?.data?.subscriptions?.length,
      })
    }, 500),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <EditGuesser
      {...props}
      variant="outlined"
      redirect="list"
      transform={(record) => {
        delete record.completedAt
        delete record.viewedAt
        delete record.customerEmail
        const productFlow = record?.productFlow || record.store?.productFlow
        const store = record.store['@id'] || record.store

        if (productFlow === 'v2') {
          record.data.subscriptions = getPimcoreSubscriptions(
            record.data.subscriptions
          )
        }
        return { ...record, id: record.originId, store }
      }}
      initialValues={{ customerEmail: checkoutSession?.data?.customerEmail }}
      toolbar={<CustomToolbar canSubmit={canSubmit} />}
    >
      <FormSpy subscription={{ values: true }} onChange={onFormChange} />
      <TopInputs>
        <ReferenceInput
          disabled
          variant="outlined"
          label="Store"
          source="store"
          reference="stores"
          format={(v) => (v && v['@id'] ? v['@id'] : v)}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <div style={{ width: '300px' }}>
          {/* this div is needed here to reserve a space for absolute element from checkout session input */}
        </div>

        {store && checkoutSession?.viewedAt && (
          <DateTimeInput variant="outlined" source="viewedAt" disabled />
        )}
        {store && checkoutSession?.completedAt && (
          <DateTimeInput variant="outlined" source="completedAt" disabled />
        )}
      </TopInputs>

      <CheckoutSessionInput
        source="data"
        store={store}
        session={checkoutSession}
        disabled={disabled}
      />
    </EditGuesser>
  )
}
