import items from './items'
import { capitalizeFirstLetter } from 'utils/string'

const hasChildren = (item) => item.subItems && item.subItems.length > 0
const stateName = (item) => `menu${capitalizeFirstLetter(item.resource)}`
const hasPermissions = ({ permissions, item }) =>
  permissions &&
  item.permissions &&
  item.permissions.filter((_item) => permissions.includes(_item)).length

export const menuItems = (permissions) => {
  return items
    .map((item) => {
      item.hasChildren = hasChildren(item)

      if (item.hasChildren) {
        item.stateName = stateName(item)
      }

      return item
    })
    .filter((item) => hasPermissions({ permissions, item }))
}

export const menuStates = (items) =>
  items
    .filter((item) => item.stateName)
    .map((item) => item.stateName)
    .reduce((obj, item) => ({ ...obj, [item]: false }), {})
