import React from 'react'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const SurgeryTypeOptionStoreCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <ReferenceInput
      source="surgeryType"
      reference="surgery-type-options"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="store"
      reference="stores"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="name" label="Translated surgery type" />
    <BooleanInput source="enabledInPortal" />
  </CreateGuesser>
)
