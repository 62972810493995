import VisitorIcon from '@material-ui/icons/People'

import { UserList } from './UserList'
import { UserCreate } from './UserCreate'
import { UserEdit } from './UserEdit'
import { ROLES } from 'utils/user'

export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  icon: VisitorIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
