import React from 'react'
import { AutocompleteInput, ReferenceInput, SelectInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const BundleStoreCreate = (props) => {
  return (
    <CreateGuesser {...props} variant="outlined" redirect="list">
      <ReferenceInput
        source="store"
        reference="stores"
        filter={{ properties: ['id', 'name'] }}
        format={(v) => (v ? v['@id'] || v : v)}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        reference="bundles"
        source="bundle"
        format={(v) => (v ? v['@id'] || v : v)}
        filter={{ properties: ['id', 'name'] }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={50}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        reference="bundles"
        source="upgradeTo"
        format={(v) => (v ? v['@id'] || v : v)}
        filter={{ properties: ['id', 'name'] }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={50}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        reference="bundles"
        source="downgradeTo"
        format={(v) => (v ? v['@id'] || v : v)}
        filter={{ properties: ['id', 'name'] }}
        sort={{ field: 'name', order: 'ASC' }}
        perPage={50}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </CreateGuesser>
  )
}
