import Icon from '@material-ui/icons/Money'
import { ROLES } from 'utils/user'

import { InvoiceShow } from './InvoiceShow'
import { InvoiceList } from './InvoiceList'

export default {
  show: InvoiceShow,
  list: InvoiceList,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
