import React from 'react'
import PropTypes from 'prop-types'
import { Create, SimpleForm } from 'react-admin'
import Introspecter from '@api-platform/admin/lib/Introspecter'
import { ResourceCreateToolbar } from '../toolbar/ResourceCreateToolbar'

const validateForm = (values) => {
  const format = /^[A-Za-z0-9|_|\-|+]+$/
  const errors = {}

  if (values.chargeBeeId && !format.test(values.chargeBeeId)) {
    errors.chargeBeeId = 'ChargeBeeId is not a valid'
  }

  return errors
}

export const IntrospectedCreateGuesser = ({
  fields,
  readableFields,
  writableFields,
  schema,
  schemaAnalyzer,
  children,
  toolbar,
  redirect,
  variant,
  initialValues,
  ...props
}) => {
  return (
    <Create {...props}>
      <SimpleForm
        variant={variant}
        toolbar={toolbar}
        redirect={redirect}
        initialValues={initialValues}
        validate={validateForm}
      >
        {children}
      </SimpleForm>
    </Create>
  )
}

export const CreateGuesser = ({ basePath, ...props }) => (
  <Introspecter
    component={IntrospectedCreateGuesser}
    basePath={basePath}
    {...props}
  />
)

CreateGuesser.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.bool,
  ]),
  resource: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

CreateGuesser.defaultProps = {
  toolbar: <ResourceCreateToolbar />,
  redirect: 'list',
  actions: null,
  variant: 'outlined',
  defaultValue: {},
}
