import { parseRoles } from 'utils/user'

class UserSession {
  isRefreshTokenValid = () => {
    const token = this.getRefreshToken()
    const exp = localStorage.getItem('refreshTokenExpiration')
    const now = new Date().getTime() / 1000 + 10

    return exp && token && exp > now
  }

  isTokenValid = () => {
    const token = this.getToken()
    const exp = localStorage.getItem('tokenExpiration')
    const now = new Date().getTime() / 1000 + 10

    return exp && token && exp > now
  }

  getToken = () => {
    return localStorage.getItem('token')
  }

  getRefreshToken = () => {
    return localStorage.getItem('refreshToken')
  }

  getUser = () => {
    return {
      uri: localStorage.getItem('userUri'),
      name: localStorage.getItem('userName'),
      roles: localStorage.getItem('userRoles'),
    }
  }

  getPermissions = () => {
    const roles = localStorage.getItem('userRoles')

    if (!roles) {
      return []
    }

    return parseRoles(roles)
  }

  refresh = (token, refreshToken, user, expiration) => {
    localStorage.setItem('token', token)
    localStorage.setItem('tokenExpiration', expiration.token)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('refreshTokenExpiration', expiration.refreshToken)
    localStorage.setItem('userName', user.fullName || '')
    localStorage.setItem(
      'userRoles',
      JSON.stringify(user.roles && user.roles.length ? user.roles : [])
    )
    localStorage.setItem('userUri', user['@id'] || null)
  }

  clear = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('tokenExpiration')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('refreshTokenExpiration')
    localStorage.removeItem('userName')
    localStorage.removeItem('userRoles')
    localStorage.removeItem('userUri')
  }
}

export default new UserSession()
