import { downloadCSV } from 'react-admin'
import { api } from 'service/Api'

export const exporter = async (clinics) => {
  const ids = clinics.map((item) => item.originId)

  const data = await api.exportCsv('clinics', {
    ids,
  })

  downloadCSV(data, 'clinics')
}
