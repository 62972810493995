import Icon from '@material-ui/icons/AddCircle'
import { BundleStoreCreate } from './BundleStoreCreate'
import { BundleStoreList } from './BundleStoreList'
import { BundleStoreEdit } from './BundleStoreEdit'
import { ROLES } from 'utils/user'

export default {
  create: BundleStoreCreate,
  list: BundleStoreList,
  edit: BundleStoreEdit,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
