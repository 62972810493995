import React from 'react'
import { Link, ChipField } from 'react-admin'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer',
    },
  },
}))

export const ResourceLinkField = ({ record, source, referencePath, label }) => {
  const classes = useStyles()
  const id = get(record, source)

  if (!record || !id) {
    return
  }

  return (
    <Link
      to={{
        pathname: `/${referencePath}/${encodeURIComponent(id)}/show`,
      }}
      onClick={(event) => event.stopPropagation()}
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ChipField source={label ?? source} record={record} clickable={false} />
    </Link>
  )
}

ResourceLinkField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  referencePath: PropTypes.string.isRequired,
}
