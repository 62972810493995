import React, { useState, useMemo, useEffect } from 'react'
import { Button, useRecordContext } from 'react-admin'
import styled from 'styled-components'
import { Switch, FormControlLabel } from '@material-ui/core'
import { MidlayerSubscriptionItem } from './MidlayerSubscription/MidlayerSubscriptionItem'
import { Link } from 'react-router-dom'
import { TransparentShowGuesser } from '../commonComponents'
import { useSubscriptions } from 'components/pages/customer/contexts/subscriptions'
import { PimcoreSubscriptionItem } from './PimcoreSubscription/PimcoreSubscriptionItem'
import { PimcoreListsProvider } from 'contexts/pimcoreLists'
import { useCustomer } from '../../contexts/customer'

const SubscriptionsList = styled.ol`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const CreateSubscriptionButton = () => {
  const record = useRecordContext()
  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      component={Link}
      to={{
        pathname: '/checkout-sessions/create',
        search: `?source=${JSON.stringify({
          customerEmail: record?.email?.replace(/\+/g, '%2B'),
          store: record?.store ? record?.store['@id'] : undefined,
        })}`,
      }}
      label="Create new subscription"
      fullWidth
    />
  )
}

export const ShortCustomerSubscriptionsList = (props) => {
  const { reloadShortView } = props
  const record = useRecordContext()
  const [showCancelled, setShowCancelled] = useState(false)
  const { subscriptions } = useSubscriptions()
  const { store } = useCustomer()

  const filteredSubscriptions = useMemo(() => {
    if (!subscriptions?.length) return []
    return subscriptions
      .filter((item) => showCancelled || item.status !== 'cancelled')
      .sort((a) => (a.status === 'cancelled' ? 1 : -1))
  }, [subscriptions, showCancelled])

  useEffect(() => {
    if (subscriptions.length === 0) {
      reloadShortView()
    }
    // eslint-disable-next-line
  }, [filteredSubscriptions.length])

  if (!record) return '...'

  return (
    <TransparentShowGuesser {...props} hasEdit={false}>
      <div>
        <CreateSubscriptionButton record={record} />
        <FormControlLabel
          control={
            <Switch
              checked={showCancelled}
              onChange={() => setShowCancelled((cur) => !cur)}
              color="primary"
            />
          }
          label="show cancelled"
        />
      </div>

      <PimcoreListsProvider store={store}>
        <SubscriptionsList>
          {filteredSubscriptions.map((subscription, index) =>
            subscription.cf_product_flow === 'v2' ? (
              <PimcoreSubscriptionItem
                key={`subscription-${index}`}
                subscription={subscription}
                index={index}
              />
            ) : (
              <MidlayerSubscriptionItem
                key={`subscription-${index}`}
                subscription={subscription}
                index={index}
              />
            )
          )}
        </SubscriptionsList>
      </PimcoreListsProvider>
    </TransparentShowGuesser>
  )
}
