export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_FINANCE: 'ROLE_FINANCE',
  ROLE_PRODUCT: 'ROLE_PRODUCT',
  ROLE_CUSTOMER_SUPPORT: 'ROLE_CUSTOMER_SUPPORT',
  ROLE_WORDPRESS: 'ROLE_WORDPRESS',
  ROLE_ORDER_MANAGEMENT: 'ROLE_ORDER_MANAGEMENT',
  ROLE_COUNTRY_MANAGER: 'ROLE_COUNTRY_MANAGER',
}

export const ROLE_LABELS = {
  ROLE_ADMIN: 'Admin',
  ROLE_FINANCE: 'Finance',
  ROLE_PRODUCT: 'Product',
  ROLE_CUSTOMER_SUPPORT: 'Customer support',
  ROLE_WORDPRESS: 'WordPress Manager',
  ROLE_ORDER_MANAGEMENT: 'Order Manager',
  ROLE_COUNTRY_MANAGER: 'Country Manager',
}

export const roleOptions = [
  { id: ROLES.ROLE_ADMIN, name: ROLE_LABELS.ROLE_ADMIN },
  { id: ROLES.ROLE_FINANCE, name: ROLE_LABELS.ROLE_FINANCE },
  { id: ROLES.ROLE_PRODUCT, name: ROLE_LABELS.ROLE_PRODUCT },
  { id: ROLES.ROLE_CUSTOMER_SUPPORT, name: ROLE_LABELS.ROLE_CUSTOMER_SUPPORT },
  { id: ROLES.ROLE_WORDPRESS, name: ROLE_LABELS.ROLE_WORDPRESS },
  { id: ROLES.ROLE_ORDER_MANAGEMENT, name: ROLE_LABELS.ROLE_ORDER_MANAGEMENT },
  { id: ROLES.ROLE_COUNTRY_MANAGER, name: ROLE_LABELS.ROLE_COUNTRY_MANAGER },
]

export const parseRoles = (string) => {
  if (!string) return []
  try {
    return JSON.parse(string)
  } catch (e) {
    if (string.indexOf(',') > -1) return string.split(',')
    return [string]
  }
}

export const hasRoles = (roles) => {
  if (!roles) return {}
  const rolesSet = new Set(parseRoles(roles))

  return {
    hasAdminRole: rolesSet.has(ROLES.ROLE_ADMIN),
    hasFinanceRole: rolesSet.has(ROLES.ROLE_FINANCE),
    hasProductRole: rolesSet.has(ROLES.ROLE_PRODUCT),
    hasCustomerSupportRole: rolesSet.has(ROLES.ROLE_CUSTOMER_SUPPORT),
    hasWordPressRole: rolesSet.has(ROLES.ROLE_WORDPRESS),
    hasOrderManagerRole: rolesSet.has(ROLES.ROLE_ORDER_MANAGEMENT),
    hasCountryManagerRole: rolesSet.has(ROLES.ROLE_COUNTRY_MANAGER),
  }
}
