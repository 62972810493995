import React, { useEffect, useState } from 'react'
import {
  NumberInput,
  SimpleForm,
  SelectInput,
  TextInput,
  required,
  useNotify,
  useRedirect,
  useDataProvider,
  minValue,
} from 'react-admin'
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import { ResourceCreateToolbar } from 'theme/components'
import { getUrlParams } from 'utils/uri'
import useApi from 'hooks/useApi'
import { formatPrice } from 'utils/string'
import styled from 'styled-components'

const Checkboxes = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
`

const creditNoteReasons = [
  'Order Cancellation',
  'Order Return',
  'Lost Parcel',
  '30 Day Money Back',
  'Other',
  // 'Order Change',
  // 'Cancellation',
  // 'Product Unsatisfactory',
  // 'Service Unsatisfactory',
  // 'Waiver',
]

export const CreditNoteCreate = () => {
  const dataProvider = useDataProvider()
  const invoiceId = getUrlParams().invoiceId
  const { api, isSubmitting } = useApi()
  const [lineItems, setLineItems] = useState([])
  const redirect = useRedirect()
  const notify = useNotify()
  const [selected, setSelected] = useState([])
  const [initialAmount, setInitialAmount] = useState(0)
  const [currency, setCurrency] = useState('EUR')
  const [isFullAmount, setFullAmount] = useState(true)
  const [totalAmount, setTotalAmount] = useState(0)
  const handleSave = (values) => {
    api
      .createCreditNoteFromInvoice(invoiceId, {
        ...values,
        amount: Math.floor(values.amount * 100),
      })
      .then((res) => {
        if (res) {
          redirect('/credit-notes')
          notify('Credit note created', 'info')
        }
      })
  }

  useEffect(() => {
    if (!invoiceId) return

    dataProvider
      .getOne('invoices', { id: `/api/invoices/${invoiceId}` })
      .then(({ data }) => {
        setLineItems(data?.data?.line_items)
        setCurrency(data?.data?.currency_code)
        const credited = data?.data?.issued_credit_notes.reduce((total, cn) => total + cn.cn_total, 0)
        const total = data?.data?.total
        const creditable = total - credited
        setTotalAmount(creditable / 100)
        setInitialAmount(creditable / 100)
      })
  }, [invoiceId, dataProvider])

  useEffect(() => {
    const selectedSet = new Set(selected)
    if(selected.length === lineItems.length) {
      setFullAmount(true)
    } else {
      setInitialAmount(
        lineItems
          .filter(({ id }) => selectedSet.has(id))
          .reduce((total, cur) => total + cur.amount, 0) / 100
      )
    }
  }, [lineItems, selected])

  useEffect(() => {
    if(isFullAmount) {
      setInitialAmount(totalAmount)
    } else {
      setSelected([])
    }
  }, [isFullAmount])

  if (!invoiceId) {
    redirect('/invoices')
    notify('invoiceId is manddatory', 'error')
  }

  return (
    <Paper style={{ marginTop: '1em' }}>
      <Checkboxes>
        {!!lineItems.length && (
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={isFullAmount}
                onChange={() => {
                 setFullAmount((current) => !current)
                }}
              />
            }
            label="Create credit for full amount"
          />
        )}
        {!isFullAmount && lineItems.map(({ quantity, description, amount, id }) => (
          <FormControlLabel
            key={id}
            control={
              <Checkbox
                color="primary"
                checked={selected.includes(id)}
                onChange={() => {
                  if (selected.includes(id)) {
                    setSelected((cur) => cur.filter((item) => item !== id))
                  } else {
                    setSelected((cur) => [id, ...cur])
                  }
                }}
              />
            }
            label={
              <span style={{ display: 'flex' }}>
                {quantity}
                &nbsp;x&nbsp;
                <b>{description}</b>
                &nbsp;&nbsp;&nbsp;
                {formatPrice(amount / 100, currency)}
              </span>
            }
          />
        ))}
      </Checkboxes>
      <SimpleForm
        margin="normal"
        save={handleSave}
        saving={isSubmitting}
        variant="outlined"
        redirect="list"
        toolbar={<ResourceCreateToolbar />}
        initialValues={{ amount: initialAmount }}
      >
        <SelectInput
          style={{ width: '300px' }}
          source="type"
          label={"Credit type"}
          choices={[
            { id: 'adjustment', label: 'Adjustment' },
            { id: 'refundable', label: 'Refundable' },
          ]}
          optionText="label"
          validate={[required()]}
        />
        <NumberInput
          style={{ width: '300px' }}
          source="amount"
          validate={[required(), minValue(1)]}
        />
        <SelectInput
          style={{ width: '300px' }}
          source="reasonCode"
          label="Reason"
          choices={creditNoteReasons.map((id) => ({ id }))}
          optionText="id"
          validate={[required()]}
        />
        <TextInput
          fullWidth
          multiline
          rows={5}
          source="customerNotes"
          label="Note (optional)"
          placeholder="Example text"
        />
      </SimpleForm>
    </Paper>
  )
}
