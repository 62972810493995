import React from 'react'
import { FunctionField } from 'react-admin'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'

export const EllipsisText = styled.p`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const getNestedValue = (record, source) => {
  const keys = source.split('.')
  let value = record

  for (const key of keys) {
    if (value[key] === undefined) return undefined
    value = value[key]
  }
  return value
}

export const LongTextField = ({ maxWidth, ...props }) => {
  const { record, source } = props

  if (!record || !source) {
    return null
  }

  return (
    <FunctionField
      record={record}
      source={source}
      label={source}
      render={(record) => (
        <Tooltip title={getNestedValue(record, source) || ''}>
          <EllipsisText
            style={{
              maxWidth: maxWidth ? `${maxWidth}px` : '300px',
            }}
          >
            {getNestedValue(record, source) || ''}
          </EllipsisText>
        </Tooltip>
      )}
      {...props}
    />
  )
}
