import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { ROLES } from 'utils/user'

import { CheckoutSessionList } from './CheckoutSessionList'
import { CheckoutSessionEdit } from './CheckoutSessionEdit'
import { CheckoutSessionCreate } from './CheckoutSessionCreate'

export default {
  list: CheckoutSessionList,
  edit: CheckoutSessionEdit,
  create: CheckoutSessionCreate,
  icon: ShoppingCartIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
