import axios from 'axios'
import { BASE_URL, getApiRoute } from './routes'
import { getFormattedError } from 'utils/hydra'
import authInterceptor from './auth.interceptor'
import _ from 'lodash'
import { token } from './tokens'
import { getNumberFromStringId } from 'utils/string'

class Api {
  notify
  translate
  setIsSubmitting
  withCredentials
  headers
  bag = {}

  constructor(
    notify,
    translate,
    setIsSubmitting,
    withCredentials = true,
    headers = {}
  ) {
    this.notify = notify
    this.translate = translate
    if (setIsSubmitting) {
      this.setIsSubmitting = setIsSubmitting
    }
    this.withCredentials = withCredentials
    this.headers = headers
  }

  api = (method) => _.get(this.bag, method, this.up(method))

  up = (method) => {
    let config = {
      headers: this.getHeaders(method),
      withCredentials: this.withCredentials,
    }

    const api = axios.create(config)

    api.interceptors.response.use(undefined, authInterceptor(api, this))

    this.bag[method] = api
  }

  getHeaders = (method) => {
    let headers = {
      accept: 'application/ld+json',
    }

    if (['patch', 'put'].includes(method)) {
      headers['content-type'] = 'application/merge-patch+json'
    } else {
      headers['content-type'] = 'application/ld+json'
    }

    return { ...headers, ...this.headers }
  }

  handleErrorMessage = (error) => {
    let message = _.get(error, 'response.data', 'Undefined Error')

    if (typeof message === 'object') {
      return getFormattedError(message, false)
    }

    if (typeof message !== 'string') {
      return 'Undefined Error'
    }

    if (message.length < 512) {
      return message
    }

    const res = /<!--(.*?)-->/gm.exec(message)

    if (_.has(res, 1)) {
      return res[1]
    }

    return message.substring(0, 512) + '..'
  }

  post = (url, body, successMessage, errorMessage, onError) => {
    this.setIsSubmitting && this.setIsSubmitting(true)

    return this.api('post')
      .post(url, body || {})
      .then((response) => {
        if (!response || response.status < 200 || response.status >= 300) {
          if (onError) {
            return onError(response)
          }

          this.notify(response.statusText, 'error')

          throw new Error(response.statusText)
        }

        if (successMessage) {
          this.notify(successMessage)
        }

        this.setIsSubmitting && this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        console.error(error)

        this.notify(
          errorMessage || this.handleErrorMessage(error),
          'error',
          {},
          false,
          10000
        )
        this.setIsSubmitting && this.setIsSubmitting(false)

        throw error
      })
  }

  patch = (url, body, successMessage, onError) => {
    this.setIsSubmitting && this.setIsSubmitting(true)

    return this.api('patch')
      .patch(url, body)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          if (onError) {
            return onError(response)
          }
          this.notify(response.statusText, 'error')

          throw new Error(response.statusText)
        }

        if (successMessage) {
          this.notify(successMessage)
        }

        this.setIsSubmitting && this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        console.error(error)
        this.setIsSubmitting && this.setIsSubmitting(false)

        if (onError) {
          return onError(error)
        }

        this.notify(this.handleErrorMessage(error), 'error', {}, false, 10000)

        throw error
      })
  }

  get = (url, params, onError) => {
    this.setIsSubmitting && this.setIsSubmitting(true)

    return this.api('get')
      .get(url, { params })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          if (onError) {
            return onError(response)
          }
          this.notify(response.statusText, 'error')

          throw new Error(response.statusText)
        }

        this.setIsSubmitting && this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        console.error(error)
        this.setIsSubmitting && this.setIsSubmitting(false)

        if (onError) {
          return onError(error)
        }

        this.notify(this.handleErrorMessage(error), 'error', {}, false, 10000)

        throw error
      })
  }

  put = (url, body, successMessage, errorMessage, onError) => {
    this.setIsSubmitting && this.setIsSubmitting(true)

    return this.api('put')
      .put(url, body || {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          if (onError) {
            return onError(response)
          }
          this.notify(response.statusText, 'error')
          throw new Error(response.statusText)
        }

        if (successMessage) {
          this.notify(successMessage)
        }

        this.setIsSubmitting && this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        console.error(error)
        this.setIsSubmitting && this.setIsSubmitting(false)

        if (onError) {
          return onError(error)
        }

        this.notify(
          errorMessage || this.handleErrorMessage(error),
          'error',
          {},
          false,
          10000
        )

        throw error
      })
  }

  delete = (url, body, successMessage, errorMessage, onError) => {
    this.setIsSubmitting && this.setIsSubmitting(true)

    return this.api('delete')
      .delete(url, body || {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          if (onError) {
            return onError(response)
          }
          this.notify(response.statusText, 'error')
          throw new Error(response.statusText)
        }

        if (successMessage) {
          this.notify(successMessage)
        }

        this.setIsSubmitting && this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        console.error(error)
        this.setIsSubmitting && this.setIsSubmitting(false)

        if (onError) {
          return onError(error)
        }

        this.notify(
          errorMessage || this.handleErrorMessage(error),
          'error',
          {},
          false,
          10000
        )

        throw error
      })
  }

  authorize = async (username, password) =>
    this.api('post').post(getApiRoute('token'), {
      username,
      password,
    })

  logout = async () => this.api('post').post(getApiRoute('token/kill'))

  refreshApiToken = async () =>
    this.api('post').post(getApiRoute('token/refresh'))

  fetchExactProperties = async () =>
    this.get(getApiRoute('exact-online/properties'))

  fetchMissingProductStores = async (productId) =>
    this.get(`${BASE_URL}${productId}/missing/stores`).then(
      (response) => response?.data
    )

  fetchMissingProductStoreIntervals = async (productId) =>
    this.get(`${BASE_URL}${productId}/missing/intervals`).then(
      (response) => response?.data
    )

  fetchIntervals = async () =>
    this.get(getApiRoute('intervals')).then((response) => response?.data)

  createProductStore = async (params) =>
    this.post(getApiRoute('product-stores'), params).then(
      (response) => response?.data
    )

  createProductStoreInterval = async (params) =>
    this.post(getApiRoute('product-store-intervals'), params).then(
      (response) => response?.data
    )

  createPlan = async (params) =>
    this.post(getApiRoute('plans'), params).then((response) => response?.data)

  clonePlan = async (planId, params) =>
    this.post(getApiRoute('plans') + `/clone/${planId}`, params).then(
      (response) => response?.data
    )

  createAddon = async (params) =>
    this.post(getApiRoute('addons'), params, 'resources.addon.created').then(
      (response) => response?.data
    )

  createGroup = async (params) =>
    this.post(getApiRoute('groups'), params).then((response) => response?.data)

  createGroupImage = async (params) =>
    this.post(getApiRoute('group-images'), params).then(
      (response) => response?.data
    )

  patchOrder = async (id, payload) =>
    this.patch(
      getApiRoute(`orders/${id}`),
      payload,
      'resources.order.updated'
    ).then((response) => response?.data)

  resync = async ({ resource, id }) =>
    this.post(getApiRoute(`sync/${resource}/${id}`)).then(
      (response) => response?.data
    )

  retryTask = async ({ id }) =>
    this.post(getApiRoute(`tasks/${id}/retry`)).then(
      (response) => response?.data
    )

  exportCsv = async (resource, params = {}) =>
    this.post(getApiRoute(`${resource}/export`), params).then(
      (response) => response?.data
    )

  importCsv = async (resource, params = {}) =>
    this.post(
      getApiRoute(`${resource}/import`),
      params,
      'File has been imported successfully'
    ).then((response) => response?.data)

  importSubscriptionCancellations = async (params = {}) => {
    return this.post(
      getApiRoute('subscriptions/import-cancellations'),
      params,
      'File has been imported successfully',
      'File must be in CSV format'
    ).then((response) => response?.data)
  }

  importAddonsForPlans = async (params = {}) =>
    this.post(
      getApiRoute('plans/import-addons'),
      params,
      'File has been imported successfully'
    ).then((response) => response?.data)

  removeGroupFromPlan = async (planId) =>
    this.delete(
      getApiRoute(`woo/sync/plan/${planId}`),
      {},
      'Group was removed from plan'
    ).then((response) => response?.data)

  wooSync = async (resource, id) =>
    this.post(
      getApiRoute(`woo/sync/${resource}/${id}`),
      {},
      'resources.woo.scheduled'
    )

  wooSyncWPProducts = async () =>
    this.post(getApiRoute(`woo/sync`), {}, 'Sync scheduled successfully')

  copyGroups = async ({ storeId, groupIds }) =>
    this.post(getApiRoute(`groups/copy`), {
      store: storeId,
      ids: groupIds,
    }).then((response) => response?.data)

  resend = async ({ resource, ids }) =>
    this.post(getApiRoute(`${resource}/resend`), { ids }).then(
      (response) => response?.data
    )

  syncProducts = async ({ id, env }) =>
    this.post(getApiRoute(`stores/${id}/sync-products`), {
      chargeBeeEnv: env,
    }).then((response) => response?.data)

  requestPasswordRecovery = async ({ email, baseUrl }) => {
    this.post(
      getApiRoute('recover-password/request'),
      { email, baseUrl },
      'Please check your inbox',
      'Something went wrong'
    )
  }

  setNewPassword = async ({ password, token }) => {
    return this.post(
      getApiRoute('recover-password'),
      {
        plainPassword: password,
        token,
      },
      'A new password has been set',
      'Something went wrong'
    ).then((response) => response)
  }

  bulkUpdatePlans = async ({
    ids,
    allowUnpaid,
    enabledInPortal,
    enabledInHostedPages,
  }) => {
    return this.put(
      getApiRoute('plans/bulk-update'),
      {
        ids,
        allowUnpaid,
        enabledInPortal,
        enabledInHostedPages,
      },
      'Plans were updated',
      'Something went wrong'
    ).then((response) => response)
  }

  createCheckoutSession = async ({ body, chargeBeeWebsite }) => {
    return this.post(
      `${BASE_URL}/checkout-session?chargeBeeWebsite=${chargeBeeWebsite}`,
      body
    )
  }

  fetchCheckoutSubscriptionsInfo = async ({
    sessionSubscriptions,
    storeId,
  }) => {
    return this.post(
      getApiRoute(`customer-support/${storeId}/order-data`),
      sessionSubscriptions
    )
  }

  getSubscriptionEstimateInfo = async (id) => {
    return this.get(
      getApiRoute(`customer-support/subscription/${id}/renewal-estimate`)
    )
  }

  checkoutEstimateTotalPrice = async ({
    cbSubscription,
    storeId,
    customerCBId,
  }) => {
    let url = `${BASE_URL}/checkout/subscription-estimate?multiple=1`
    const params = { token, storeId, customerId: customerCBId }

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        url += `&${key}=${params[key]}`
      }
    })

    return this.post(url, cbSubscription)
  }

  sendSessionToCustomer = ({ sesssionId, email, language }) => {
    return this.post(
      getApiRoute(`checkout-sessions/${sesssionId}/send`),
      { email, language },
      'Email was sent to customer',
      'Something went wrong'
    )
  }

  resendSessionToCustomer = ({ sesssionId, language }) => {
    return this.post(
      getApiRoute(`checkout-sessions/${sesssionId}/resend`),
      { language },
      'Email was re-sent to customer',
      'Something went wrong'
    )
  }

  createInvoiceSubscription = (body, storeId, sessionId) => {
    let url = getApiRoute(`customer-support/${storeId}/subscription`)
    if (sessionId) url += `?sessionId=${sessionId}`
    return this.post(url, body)
  }

  completeSubscription = (sessionId, offline = false) => {
    let getParams = `?sessionId=${sessionId}`
    if (offline) getParams += '&offline=1'
    return this.post(`${BASE_URL}/checkout-session/complete${getParams}`)
  }

  updateCustomer = (id, body) => {
    return this.put(
      getApiRoute(`customer-support/customer/${id}`),
      body,
      'Customer changes were saved',
    )
  }

  createSubscriptionForExistingCustomer = (body, midlayerId, sessionId) => {
    let url = getApiRoute(
      `customer-support/customer/${midlayerId}/subscription`
    )
    if (sessionId) url += `?sessionId=${sessionId}`
    return this.post(url, body)
  }

  createPimcoreSubscription = (sessionId) => {
    return this.post(getApiRoute(`checkout/${sessionId}/subscribe`))
  }

  getCustomerSubscriptions = (customerMidlayerId) => {
    return this.get(
      getApiRoute(
        `customer-support/customer/${customerMidlayerId}/subscription`
      )
    )
  }

  updateChargebeeSubscription = (internaId, body) => {
    return this.put(
      getApiRoute(`customer-support/subscription/${internaId}`),
      body
    )
  }

  updatePimcoreSubscription = (internaId, body) => {
    return this.put(getApiRoute(`customer/subscriptions/${internaId}`), {
      items: null,
      startDate: null,
      shippingAddress: null,
      ...body,
    })
  }

  cancelSubscription = (id, body) => {
    return this.put(getApiRoute(`subscriptions/${id}/cancel`), body)
  }

  registerCancelSubscriptionAttempt = (body) => {
    return this.post(getApiRoute('events/cancel-attempt'), body)
  }

  updateCustomerSubscriptionNextShippingDate = (
    subscriptionMidlayerId,
    inputTimestamp,
    reason
  ) => {
    const now = new Date()
    let inputDate = new Date(inputTimestamp)

    if (inputDate.toDateString() === now.toDateString()) {
      const five_min_in_msec = 5 * 60 * 1000
      inputDate.setTime(now.getTime() + five_min_in_msec)
    } else {
      inputDate.setHours(9, 0, 0)
    }

    const formattedDate = Math.floor(inputDate.getTime() / 1000)

    return this.post(
      getApiRoute(
        `customer-support/subscription/${subscriptionMidlayerId}/change-term-end`
      ),
      { term_ends_at: formattedDate, reason }
    )
  }
  addOneTimeAddonToSubscription = (subscriptionMidlayerId, item) => {
    return this.post(
      getApiRoute(
        `customer-support/subscriptions/${subscriptionMidlayerId}/add-one-time`
      ),
      { item }
    )
  }
  getFreshdeskGroups = () =>
    this.get(getApiRoute('freshdesk/groups'), { per_page: 99 })
  getFreshdeskAgents = () =>
    this.get(getApiRoute('freshdesk/agents'), { per_page: 99 })

  generateInvoiceLink = (invoiceId) => {
    return this.post(getApiRoute(`invoices/${invoiceId}/payment-link/generate`))
  }

  sendPaymentLink = (invoiceId) => {
    return this.post(getApiRoute(`invoices/${invoiceId}/payment-link/send`))
  }

  reshipOrder = (orderId, payload) => {
    return this.post(
      getApiRoute(`customer-support/order/${orderId}/resend`),
      payload,
      'Order was reshipped'
    )
  }

  requestPaymentSource = (id) => {
    return this.post(
      getApiRoute(`customer-support/customer/${id}/request-payment-method`)
    )
  }
  logCustomerCall = (customerId, payload) => {
    return this.post(
      getApiRoute(`customer-support/customer/${customerId}/call`),
      payload
    )
  }
  getCustomerPaymentMethod = (customerId) => {
    return this.get(getApiRoute(`customers/${customerId}/payment-method`))
  }
  fetchCoupons = (store_id) => {
    return this.get(getApiRoute('coupons'), { store_id })
  }

  fetchGenderByStoreId = (storeId) => {
    return this.get(
      `${BASE_URL}/checkout/genders?storeId=${storeId}&token=${token}`
    )
  }
  createCreditNoteFromInvoice = (invoiceId, body) => {
    return this.post(
      getApiRoute(`credit-notes/from-invoice/${invoiceId}`),
      body
    )
  }
  getPimcorePropositions = (storeId) => {
    return this.get(getApiRoute(`checkout/${storeId}/propositions`), {}, () =>
      console.error('could not fetch pimcore propositions')
    )
  }
  getPimcoreBundles = (storeId) => {
    return this.get(getApiRoute(`checkout/${storeId}/bundles`), {}, () =>
      console.error('could not fetch pimcore bundles')
    )
  }
  getPimcoreSubscriptionsInfo = async ({ pimcoreSubscriptions, storeId }) => {
    return this.post(
      getApiRoute(`checkout/${storeId}/cart`),
      pimcoreSubscriptions
    )
  }

  createAppointment = (customerId, body) => {
    return this.post(getApiRoute(`customer/${customerId}/appointment`), body)
  }
  migrateCustomerToAdyen = (customerId) => {
    return this.post(
      getApiRoute(`customer-support/customer/${customerId}/adyen-payment-flow`)
    )
  }
  collectInvoiceNow = (id) => this.post(getApiRoute(`invoices/${id}/collect`))

  sendInvoiceToCustomer = (id) => this.post(getApiRoute(`invoices/${id}/send`))

  getAmountRefund = (id) =>
    this.get(getApiRoute(`invoices/${id}/refund-amount`))

  updateSessionStatus = (id, payload) => {
    return this.patch(getApiRoute(`checkout-sessions/${id}`), payload)
  }

  syncPimcorePropositionsChargeeBee = (pimcoreId) => {
    return this.get(getApiRoute(`pimcore-propositions/${pimcoreId}/sync`))
  }

  getSelligentEmails = (customerId) => {
    return this.get(
      getApiRoute(`selligent/${customerId}/emails`) +
        `?limit=9999&properties[]=MASTER.SENT_DT&properties[]=MASTER.CAMPAIGN_CATEGORY&properties[]=MASTER.CAMPAIGN_NAME&properties[]=MASTER.MAIL_NAME`
    )
  }

  resendFlowmailerEmail = (emailId) => {
    return this.post(
      getApiRoute(`email-logs/${getNumberFromStringId(emailId)}/resend`)
    )
  }

  deleteDefaultPaymentMethod = (customerId) => {
    return this.delete(getApiRoute(`customers/${customerId}/payment-method`))
  }

  reprocessAdyenNotification = (notificationId) => {
    return this.post(getApiRoute(`adyen-notifications/${notificationId}/reprocess`))
  }
}

export const api = new Api()

export default Api
