export function getAllResult(record) {
  let result = []

  if (record.result?.cancelled) {
    result = [
      ...result,
      ...record.result.cancelled.map((item) => {
        return { ...item, type: 'success' }
      }),
    ]
  }

  if (record.result?.errors) {
    result = [
      ...result,
      ...record.result.errors.map((item) => {
        return { ...item, type: 'error' }
      }),
    ]
  }

  return result
}
