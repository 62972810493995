import React from 'react'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  maxLength,
} from 'react-admin'
import { EditGuesser } from 'theme/components'

export const GenderStoreEdit = (props) => (
  <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
    <ReferenceInput
      source="gender"
      reference="genders"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="store"
      reference="stores"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput
      source="name"
      label="Translated gender"
      validate={maxLength(99)}
    />
    <BooleanInput source="enabledInPortal" />
  </EditGuesser>
)
