import React, { useCallback, useMemo } from 'react'
import { useRedirect } from 'react-admin'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import { getDecodedStringFromObject } from 'utils/uri'
import { GROUP_SCREENS, ADDON_SCREENS, PLAN_SCREENS } from '../utils'

const StyledButton = styled(Button)`
  font-size: 11px;
  padding: 5px;
`

const BaseFromListBtn = React.memo((props) => {
  const redirect = useRedirect()

  const onClick = useCallback(() => {
    redirect(props.url)

    props.setLoading(true)
  }, [props, redirect])

  return (
    <StyledButton color="primary" variant="contained" onClick={onClick}>
      {props.text}
    </StyledButton>
  )
})

const CreateFromListPlanBtn = React.memo((props) => {
  const url = useMemo(() => {
    let paramsData = {
      screen: PLAN_SCREENS.ADD_PLAN,
      psiId: props.record['@id'],
      psiName: props.record.name,
      storeName: props.record.productStore.store.name,
    }

    if (props.record.productStore.store.redirectUrl) {
      paramsData.storeRedirectUrl = props.record.productStore.store.redirectUrl
    }

    if (props.group) {
      paramsData.groupId = props.group.id
      paramsData.groupName = props.group.name
    }

    return `/products/${encodeURIComponent(
      props.productId
    )}/4?${getDecodedStringFromObject(paramsData)}`
  }, [props.group, props.productId, props.record])

  return <BaseFromListBtn {...props} text="Add plan" url={url} />
})

const CreateFromListAddonBtn = React.memo((props) => {
  const url = useMemo(() => {
    let paramsData = {
      screen: ADDON_SCREENS.ADD_ADDON,
      psiId: props.record && props.record['@id'],
      psiName: props.record && props.record.name,
      storeId: props.record && props.record.productStore.store['@id'],
    }

    return `/products/${encodeURIComponent(
      props.productId
    )}/5?${getDecodedStringFromObject(paramsData)}`
  }, [props.productId, props.record])

  return <BaseFromListBtn {...props} text="Add as addon" url={url} />
})

const CreateFromPlanListAddongBtn = React.memo((props) => {
  const url = useMemo(() => {
    return `/plans/${encodeURIComponent(props.record['@id'])}/1`
  }, [props.record])

  return <BaseFromListBtn {...props} text="Add addon" url={url} />
})

const CreateFromListGroupBtn = React.memo((props) => {
  const url = useMemo(() => {
    let paramsData = {
      screen: GROUP_SCREENS.ADD_GROUP,
      storeId: props.record && props.record.productStore.store['@id'],
      storeName: props.record && props.record.productStore.store.name,
    }

    return `/products/${encodeURIComponent(
      props.productId
    )}/3?${getDecodedStringFromObject(paramsData)}`
  }, [props.productId, props.record])

  return <BaseFromListBtn {...props} text="Add WP product" url={url} />
})

const CreatePlanFromListGroupBtn = React.memo((props) => {
  const url = useMemo(() => {
    let paramsData = {
      screen: PLAN_SCREENS.ADD_PLAN,
      groupId: props.record && props.record['@id'],
      groupName: props.record && props.record.name,
    }

    return `/products/${encodeURIComponent(
      props.productId
    )}/4?${getDecodedStringFromObject(paramsData)}`
  }, [props.productId, props.record])

  return <BaseFromListBtn {...props} text="Add plan" url={url} />
})

export {
  CreateFromListPlanBtn,
  CreateFromListAddonBtn,
  CreateFromPlanListAddongBtn,
  CreateFromListGroupBtn,
  CreatePlanFromListGroupBtn,
}
