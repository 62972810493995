import React from 'react'
import { ShowGuesser } from '@api-platform/admin'
import { TextField, FunctionField } from 'react-admin'
import { ReferenceListFilterField, DateField } from 'theme/components'

export const isChangeEvent = (eventType) => {
  return eventType?.indexOf('updated') > -1 || eventType?.indexOf('changed')
}

export const pretify = (val) => {
  try {
    return JSON.stringify(val, null, 2)
  } catch {
    return String(val)
  }
}

const isObject = (v) => v !== null && typeof v === 'object' && !Array.isArray(v)

export const renderChanges = (data) => {
  const changes = []

  function processChanges(prefix, newValues, oldValues) {
    if (Array.isArray(newValues) || Array.isArray(oldValues)) {
      const maxLength = Math.max(newValues?.length || 0, oldValues?.length || 0)
      for (let i = 0; i < maxLength; i++) {
        const newExists = newValues && i in newValues
        const oldExists = oldValues && i in oldValues
        const newPath = `${prefix}[${i}]`

        if (newExists && oldExists) {
          processChanges(newPath, newValues[i], oldValues[i])
        } else if (newExists) {
          changes.push(`${newPath}: Added '${pretify(newValues[i])}'`)
        } else if (oldExists) {
          changes.push(`${newPath}: Removed '${pretify(oldValues[i])}'`)
        }
      }
    } else if (isObject(newValues) || isObject(oldValues)) {
      const allKeys = new Set([
        ...Object.keys(newValues || {}),
        ...Object.keys(oldValues || {}),
      ])

      allKeys.forEach((key) => {
        const newKey = prefix ? `${prefix} - ${key}` : key
        const newExists = newValues && key in newValues
        const oldExists = oldValues && key in oldValues

        if (newExists && oldExists) {
          processChanges(newKey, newValues[key], oldValues[key])
        } else if (newExists) {
          changes.push(`${newKey}: Added '${newValues[key]}'`)
        } else if (oldExists) {
          changes.push(`${newKey}: Removed '${oldValues[key]}'`)
        }
      })
    } else if (newValues !== oldValues) {
      changes.push(`${prefix}: '${oldValues}' changed to '${newValues}'`)
    }
  }

  for (const property in data) {
    if (
      isObject(data[property].new) ||
      isObject(data[property].old) ||
      Array.isArray(data[property].new) ||
      Array.isArray(data[property].old)
    ) {
      processChanges(property, data[property].new, data[property].old)
    } else if (data[property].new !== data[property].old) {
      changes.push(
        `${property}: '${data[property].old}' changed to '${data[property].new}'`
      )
    }
  }

  return changes.join('\n')
}

export const EventShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <h1>Event details</h1>

      <TextField
        source="entityType.id"
        label="Event id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <TextField source="store.name" label="Store" />
      <TextField source="user" />
      <TextField source="type.name" label="Event" />
      <TextField source="source.name" label="Source" />
      <TextField source="entityType.name" label="Entity" />
      <TextField source="entityId" label="Entity ID" />
      <FunctionField
        label="Customer"
        render={(record) => {
          const modifiedRecord = {
            ...record,
            chargeBeeId: record.customerChargeBeeId,
          }

          return (
            <ReferenceListFilterField
              record={modifiedRecord}
              listUri="/customers"
              titlePath="chargeBeeId"
              valuePath="chargeBeeId"
              label="Customer"
            />
          )
        }}
      />
      <DateField source="createdAt" addLabel short={false} />
      <FunctionField
        label="Changes"
        render={(record) => {
          if (isChangeEvent(record.type.name.toLowerCase())) {
            return (
              <span>
                <pre>{renderChanges(record.data)}</pre>
              </span>
            )
          }

          return (
            <div>
              <pre>{pretify(record.data, null, 2)}</pre>
            </div>
          )
        }}
      />
    </ShowGuesser>
  )
}
