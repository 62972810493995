import React, { useState, useCallback, useEffect } from 'react'
import {
  ReferenceInput,
  SelectInput,
  useDataProvider,
  required,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { FormSpy } from 'react-final-form'
import { CreateGuesser } from 'theme/components'
import { CheckoutSessionInput } from './checkoutSessionInput'
import { TopInputs } from './CheckoutSessionEdit'
import { debounce } from 'lodash'
import { CustomToolbar } from './CustomToolbar'

export const CheckoutSessionCreate = (props) => {
  const dataProvider = useDataProvider()
  const [store, setStore] = useState()
  const [storeId, setStoreId] = useState()
  const stores = useSelector((state) => state.admin.resources.stores?.data)
  const [checkedIfSingleStore, setCheckedIfSingleStore] = useState(false)
  const [canSubmit, setCanSubmit] = useState({})

  useEffect(() => {
    if (stores && storeId && storeId !== store?.id) {
      setStore(stores[storeId])
    }
  }, [storeId, store, stores])

  const fetchStores = useCallback(async () => {
    const response = await dataProvider.getList('stores', {
      pagination: {},
      sort: {},
      filter: { onlyPermitted: true },
    })

    setCheckedIfSingleStore(true)
    if (response) {
      if (response.data.length === 1) {
        setStore(response.data[0])
      }
    }
  }, [dataProvider])

  useEffect(() => {
    if (!checkedIfSingleStore) {
      fetchStores()
    }
  }, [checkedIfSingleStore, fetchStores])

  /* eslint-disable react-hooks/exhaustive-deps */
  const onFormChange = useCallback(
    debounce((form) => {
      setStoreId(form.values.store)
      setCanSubmit({
        hasStore: !!form.values.store,
        hasEmail: !!form?.values?.data?.customerEmail,
        hasSubscriptions: !!form?.values?.data?.subscriptions?.length,
      })
    }, 500),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <CreateGuesser
      {...props}
      variant="outlined"
      redirect="edit"
      initialValues={{ store: store ? store['@id'] : null }}
      transform={(record) => ({
        ...record,
        customerEmail: undefined,
        store: record.store['@id'] || record.store,
      })}
      toolbar={<CustomToolbar canSubmit={canSubmit} create />}
    >
      <FormSpy subscription={{ values: true }} onChange={onFormChange} />
      <TopInputs>
        <ReferenceInput
          variant="outlined"
          label="Store"
          source="store"
          reference="stores"
          disabled={!!store}
          filter={{ onlyPermitted: true }}
          format={(v) => (v && v['@id'] ? v['@id'] : v)}
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </TopInputs>
      <CheckoutSessionInput source="data" store={store} />
    </CreateGuesser>
  )
}
