import React from 'react'
import { TextField } from 'react-admin'
import { DateField, ShowGuesser } from 'theme/components'

export const FulfillmentErrorShow = (props) => (
  <ShowGuesser {...props}>
    <TextField source={'originId'} label="Error id" addLabel={true} />
    <TextField source={'order.id'} label="Order id" addLabel={true} />
    <TextField
      source={'order.chargeBeeId'}
      label="Chargebee id"
      addLabel={true}
    />
    <TextField
      source={'fulfillment.name'}
      label="Fulfillment"
      addLabel={true}
    />
    <TextField source={'order.store.name'} label="Store" addLabel={true} />
    <TextField source={'message'} addLabel={true} />
    <DateField source="createdAt" addLabel={true} />
  </ShowGuesser>
)
