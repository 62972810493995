import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'
import { formatDateTime } from 'utils/string'

export const exporter = (sessions) => {
  const rows = sessions.map((session) => {
    return {
      id: session.originId,
      utm_source: session.data.utm_source,
      utm_medium: session.data.utm_medium,
      'Customer email': session.data.customerEmail,
      'Store name': session.store.name,
      'Created at': formatDateTime(session.createdAt),
      'Vieved at': session.viewedAt ? formatDateTime(session.viewedAt) : '',
      'Completed at': session.completedAt
        ? formatDateTime(session.completedAt)
        : '',
    }
  })

  jsonExport(
    rows,
    {
      headers: [
        'id',
        'utm_source',
        'utm_medium',
        'Customer email',
        'Store name',
        'Created at',
        'Vieved at',
        'Completed at',
      ],
      rowDelimiter: ';',
    },
    (err, csv) => {
      downloadCSV(csv, `sessions`)
    }
  )
}
