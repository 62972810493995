import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import { useCustomer } from '../../contexts/customer'
import { PhoneInput } from 'theme/components/input/PhoneInput'
import { useStore } from '../../contexts/store'
import { ZipInput } from 'theme/components/input/ZipInput'

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr;
`

const BillingAddress = ({ countries, allowedFields }) => {
  const { billingAddress, setBillingAddress } = useCustomer()
  const { store } = useStore()

  useEffect(() => {
    if (!billingAddress?.country && countries?.length === 1) {
      setBillingAddress((prev) => {
        return {
          ...prev,
          country: countries[0].id,
        }
      })
    }
  }, [countries, billingAddress, setBillingAddress])

  return (
    <>
      <Typography variant="body1" style={{ margin: '20px 0' }}>
        Billing address
      </Typography>
      <FormWrapper>
        <TextField
          size="small"
          variant="outlined"
          label="First name"
          value={billingAddress?.firstName || ''}
          onChange={(e) => {
            e.persist()
            setBillingAddress((prev) => {
              return { ...prev, firstName: e.target.value }
            })
          }}
        />

        <TextField
          size="small"
          variant="outlined"
          label="Last name"
          value={billingAddress?.lastName || ''}
          onChange={(e) => {
            e.persist()
            setBillingAddress((prev) => {
              return { ...prev, lastName: e.target.value }
            })
          }}
        />

        <PhoneInput
          label="Telephone number"
          value={billingAddress?.phone || ''}
          onChange={(e) => {
            e.persist && e.persist()
            setBillingAddress((prev) => {
              return { ...prev, phone: e.target.value }
            })
          }}
          defaultCountry={store?.countryCode}
        />
        <TextField
          size="small"
          variant="outlined"
          label="Street"
          value={billingAddress?.line1 || ''}
          onChange={(e) => {
            e.persist()
            setBillingAddress((prev) => {
              return { ...prev, line1: e.target.value }
            })
          }}
        />
        <TextField
          size="small"
          variant="outlined"
          label="House number"
          value={billingAddress?.line2 || ''}
          onChange={(e) => {
            e.persist()
            setBillingAddress((prev) => {
              return { ...prev, line2: e.target.value }
            })
          }}
        />

        {allowedFields.line3 && (
          <TextField
            size="small"
            variant="outlined"
            label="Address line 3"
            value={billingAddress?.line3 || ''}
            onChange={(e) => {
              e.persist()
              setBillingAddress((prev) => {
                return { ...prev, line3: e.target.value }
              })
            }}
          />
        )}
        <ZipInput
          value={billingAddress?.zip || ''}
          onChange={(e) => {
            e.persist()
            setBillingAddress((prev) => {
              return { ...prev, zip: e.target.value }
            })
          }}
          country={billingAddress?.country}
        />
        <TextField
          size="small"
          variant="outlined"
          label="City"
          value={billingAddress?.city || ''}
          onChange={(e) => {
            e.persist()
            setBillingAddress((prev) => {
              return { ...prev, city: e.target.value }
            })
          }}
        />
        {!!countries?.length && (
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel htmlFor="billing-countries">Country</InputLabel>

            <Select
              size="small"
              value={billingAddress?.country || ''}
              onChange={(e) => {
                e.persist()
                setBillingAddress((prev) => {
                  return {
                    ...prev,
                    country: e.target.value,
                  }
                })
              }}
              input={
                <OutlinedInput
                  name="billing-countries"
                  id="billing-countries"
                  label="Country"
                />
              }
            >
              {countries.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {allowedFields.state && (
          <TextField
            size="small"
            variant="outlined"
            label="State"
            value={billingAddress?.state || ''}
            onChange={(e) => {
              e.persist()
              setBillingAddress((prev) => {
                return { ...prev, state: e.target.value }
              })
            }}
          />
        )}
      </FormWrapper>
    </>
  )
}

export default BillingAddress
