import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { SimpleForm, ReferenceInput, SelectInput } from 'react-admin'
import { getNumberFromStringId } from 'utils/string'
import { getProductVariationTitle } from 'utils/string'
import { FormSpy } from 'react-final-form'
import useApi from 'hooks/useApi'
import { getApiRoute } from 'service/Api/routes'
import { getFormattedResponse } from 'utils/hydra'

const StyledForm = styled(SimpleForm)`
  height: 64px !important;

  .MuiCardContent-root {
    padding: 0;
  }

  .MuiFormControl-root {
    margin: 0 8px;
  }
`

export const VariationForm = ({
  itemIndex,
  productIndex,
  setMetaData,
  variationId,
  storeId,
  intervalSlug,
}) => {
  const [mandatoryMap, setMandatoryMap] = useState({})
  const [mandatoryFetched, setMandatoryFetched] = useState(false)
  const { api, isSubmitting } = useApi()

  useEffect(() => {
    if (isSubmitting || mandatoryFetched) return
    api
      .get(getApiRoute('wp-product-variation-stores'), {
        itemsPerPage: 999,

        ...(storeId && { 'store.id': storeId }),
        ...(variationId && { wpProductVariationStoreId: variationId }),
        ...(intervalSlug && {
          'productVariation.interval.slug': intervalSlug,
        }),

        properties: ['id'],
        'properties[mandatory]': ['id'],
        'properties[mandatory][productVariation][product]': ['id'],
      })
      .then((res) => {
        setMandatoryFetched(true)
        const { items } = getFormattedResponse(res)

        const map = {}

        items.forEach((variation) => {
          if (variation?.mandatory) {
            map[variation.id] = {
              variationId: variation.mandatory.id,
              productId: variation.mandatory.productVariation.product.id,
            }
          }
        })

        setMandatoryMap(map)
      })
  }, [api, intervalSlug, storeId, variationId, isSubmitting, mandatoryFetched])

  return (
    <StyledForm
      key={productIndex || itemIndex}
      variant="outlined"
      toolbar={<></>}
      initialValues={{
        variationId: `/api/wp-product-variation-stores/${variationId}`,
      }}
    >
      <FormSpy
        subscription={{ values: true, dirty: true }}
        onChange={({ values, dirty }) => {
          if (dirty) {
            const newVariationId = getNumberFromStringId(values.variationId)

            let newMandatoryVariation = mandatoryMap[newVariationId]
            let oldMandatoryVariation = null
            let quantity = 0
            setMetaData((cur) => {
              let items = []

              items = cur.items?.map((item, index) => {
                if (index === itemIndex) {
                  const updatedItem = { ...item }
                  if (productIndex !== undefined) {
                    updatedItem.products = item.products.map(
                      (productItem, productItemIndex) => {
                        if (productIndex === productItemIndex) {
                          oldMandatoryVariation =
                            mandatoryMap[productItem.variationId]
                          quantity = productItem.quantity
                          return {
                            ...productItem,
                            variationId: newVariationId,
                          }
                        }
                        return productItem
                      }
                    )

                    if (oldMandatoryVariation) {
                      updatedItem.products = updatedItem.products.filter(
                        (item) => {
                          return !(
                            item.mandatory ||
                            item.variationId ===
                              oldMandatoryVariation.variationId
                          )
                        }
                      )
                      oldMandatoryVariation = null
                    }

                    if (newMandatoryVariation) {
                      const mandatoryItem = {
                        ...newMandatoryVariation,
                        mandatory: true,
                        quantity,
                      }

                      updatedItem.products.push(mandatoryItem)

                      newMandatoryVariation = null
                    }
                  } else {
                    oldMandatoryVariation =
                      mandatoryMap[updatedItem.variationId]
                    updatedItem.variationId = newVariationId
                    quantity = updatedItem.quantity
                  }

                  return updatedItem
                }
                return item
              })

              if (oldMandatoryVariation) {
                items = items.filter((item) => {
                  return !(
                    item.mandatory &&
                    item.variationId === oldMandatoryVariation.variationId
                  )
                })
                oldMandatoryVariation = null
              }

              if (newMandatoryVariation) {
                const mandatoryItem = {
                  ...newMandatoryVariation,
                  mandatory: true,
                  quantity,
                }

                items.push(mandatoryItem)
                newMandatoryVariation = null
              }

              return { ...cur, items }
            })
          }
        }}
      />
      <ReferenceInput
        reference="wp-product-variation-stores"
        label="Product variation"
        source="variationId"
        format={(v) => (v ? v['@id'] || v : v)}
        filter={{
          ...(storeId && { 'store.id': storeId }),
          ...(variationId && { wpProductVariationStoreId: variationId }),
          ...(intervalSlug && {
            'productVariation.interval.slug': intervalSlug,
          }),
          properties: ['id'],
          'properties[productVariation]': [
            'interval',
            'propertyValue',
            'product',
          ],
        }}
      >
        <SelectInput
          optionText={(item) => getProductVariationTitle(item.productVariation)}
        />
      </ReferenceInput>
    </StyledForm>
  )
}
