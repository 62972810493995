import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { Button, useNotify, useRedirect } from 'react-admin'
import { useCustomer } from '../contexts/customer'
import { useSubscriptions } from '../contexts/subscriptions'
import { useStore } from '../contexts/store'
import {
  getCreateSubscriptionIssues,
  getSessionSubscriptions,
  getSubscriptionsRequestBody,
} from './utils'
import { colors } from 'theme/MuiTheme'
import { useCurrentSession } from '../contexts/currentSession'

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

export const CreateSubscriptionForExistingCustomerDialog = ({ totalPrice }) => {
  const {
    personalInfo,
    shippingAddress,
    customerMidlayerId,
    hasValidPaymentMethod,
  } = useCustomer()
  const { subscriptions, coupon } = useSubscriptions()
  const { store, storeId } = useStore()

  const [confirmOpen, setConfirmOpen] = useState(false)
  const { comment } = useCustomer()
  const { api, isSubmitting } = useApi()
  const notify = useNotify()
  const redirect = useRedirect()
  const { session } = useCurrentSession()

  const handleCreateSubscriptions = useCallback(async () => {
    const subscriptionInfoResponse = await api.fetchCheckoutSubscriptionsInfo({
      sessionSubscriptions: getSessionSubscriptions(subscriptions),
      storeId,
    })

    const requestBody = getSubscriptionsRequestBody({
      subscriptions: subscriptionInfoResponse?.data?.subscriptions,
      coupon,
      comment,
      shippingAddress,
    })

    if (requestBody.length) {
      api
        .createSubscriptionForExistingCustomer(
          requestBody,
          customerMidlayerId,
          session?.originId
        )
        .then(async () => {
          notify(
            (subscriptions.length > 1 ? 'subscriptions' : 'subscription') +
              ' created'
          )

          if (session?.originId) {
            await api.completeSubscription(session.originId)
          }

          redirect('/checkout-sessions')
        })
    }
  }, [
    customerMidlayerId,
    api,
    subscriptions,
    shippingAddress,
    comment,
    redirect,
    notify,
    coupon,
    session,
    storeId,
  ])

  if (!customerMidlayerId || !hasValidPaymentMethod || totalPrice === 0) {
    return null
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        label="Create subscription"
        fullWidth
        onClick={() => {
          const issues = getCreateSubscriptionIssues({
            shippingAddress,
            personalInfo,
            customerMidlayerId,
            store,
          })

          if (!issues) {
            setConfirmOpen(true)
          } else {
            notify(issues, { type: 'error', multiLine: true })
          }
        }}
      />

      <Dialog open={!!confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          Are you sure you want to create a Subscription for Customer{' '}
          <span style={{ color: colors.primary }}>{personalInfo?.email}</span>?
        </DialogTitle>
        <DialogBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '20px',
            }}
          >
            <Button
              label="No"
              onClick={() => setConfirmOpen(false)}
              size="medium"
              variant="outlined"
              fullWidth
            />
            <Button
              label="Yes"
              disabled={isSubmitting}
              onClick={handleCreateSubscriptions}
              variant="contained"
              fullWidth
            />
          </div>
        </DialogBody>
      </Dialog>
    </>
  )
}
