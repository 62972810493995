import React from 'react'
import { TextField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const NotificationList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="edit"
    bulkActionButtons={false}
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <TextField source="description" />
  </ListGuesser>
)
