import React, { useState } from 'react'
import { DialogTitle, Dialog, Button, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ViewIcon from '@material-ui/icons/Visibility'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { useNotify, Create, SimpleForm, TextInput, Edit } from 'react-admin'
import { CustomToolbar } from 'theme/components/toolbar/CustomToolbar'
import { FileInput } from 'theme/components'
import { FileField } from 'components/fileField'
import { formatDateTime } from 'utils/string'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`

const Preview = styled.div`
  padding: 8px 24px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Note = styled.span`
  white-space: pre-wrap;
  word-break: break-word;
  max-height: 500px;
  display: flex;
  overflow: scroll;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2px;
  width: 278px;
  padding: 0 20px;
`

const DateText = styled.span`
  flex-shrink: 0;
  scale: 0.9;
`

const Author = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  color: darkgray;
`

const CommentModal = ({ comment, refetchList, canEdit = true }) => {
  const [open, setOpen] = useState(false)
  const id = comment?.id
  const { customerId } = useCustomer()
  const notify = useNotify()

  const authorEmail = comment?.createdBy?.email

  let title = 'Create comment'
  let view = (
    <Create
      redirect={false}
      resource="customer-comments"
      basePath="/customer-comments"
      onSuccess={() => {
        notify('Comment created')
        refetchList && refetchList()
        setOpen(false)
      }}
    >
      <SimpleForm
        redirect={false}
        variant="outlined"
        toolbar={<CustomToolbar />}
        initialValues={{ customer: `/api/customers/${customerId}` }}
      >
        <TextInput
          multiline
          InputProps={{ rows: 4, rowsMax: 20 }}
          source="notes"
          fullWidth
        />
        <FileInput
          maxFileSize={990000}
          source="file"
          label="File"
          acceptedFiles={[
            '.pdf',
            '.png',
            '.jpg',
            '.jpeg',
            '.txt',
            '.doc',
            '.docx',
          ]}
        />
      </SimpleForm>
    </Create>
  )

  if (id) {
    title = canEdit ? 'Edit comment' : 'Preview'
    view = canEdit ? (
      <Edit
        redirect={false}
        resource="customer-comments"
        basePath="/customer-comments"
        mutationMode="pessimistic"
        onSuccess={() => {
          notify('Comment updated')
          setOpen(false)
        }}
        id={id}
        transform={(values) => ({
          ...values,
          file: values.file?.content ? values.file : undefined,
        })}
      >
        <SimpleForm
          redirect={false}
          variant="outlined"
          toolbar={<CustomToolbar />}
          initialValues={{ customer: `/api/customers/${customerId}` }}
        >
          <TextInput
            multiline
            InputProps={{ rows: 4, rowsMax: 20 }}
            source="notes"
            fullWidth
          />
          <FileField file={comment.file} />
          <FileInput
            maxFileSize={990000}
            source="file"
            label="File"
            acceptedFiles={[
              '.pdf',
              '.png',
              '.jpg',
              '.jpeg',
              '.txt',
              '.doc',
              '.docx',
            ]}
          />
        </SimpleForm>
      </Edit>
    ) : (
      <Preview>
        <FileField file={comment.file} />
        <Note>{comment.notes}</Note>
      </Preview>
    )
  }

  return (
    <>
      {id ? (
        <IconButton onClick={() => setOpen(true)}>
          {canEdit ? (
            <EditIcon color="primary" />
          ) : (
            <ViewIcon color="primary" />
          )}
        </IconButton>
      ) : (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => setOpen(true)}
        >
          Add comment
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Header>
          <DialogTitle>{title}</DialogTitle>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Header>
        <Row>
          <Author>{authorEmail}</Author>

          {comment?.createdAt && (
            <DateText>{formatDateTime(new Date(comment.createdAt))}</DateText>
          )}
        </Row>
        {view}
      </Dialog>
    </>
  )
}

export default CommentModal
