import React, { useState } from 'react'
import { ShowGuesser } from '@api-platform/admin'
import {
  TextField,
  FunctionField,
  Tab,
  ReferenceManyField,
  Pagination,
} from 'react-admin'
import { DateField, ReferenceListFilterField, DataGrid } from 'theme/components'
import { formatPrice } from 'utils/string'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

export const TransactionShow = (props) => {
  const [payment, setPayment] = useState()
  return (
    <ShowGuesser {...props}>
      <FunctionField
        label=" "
        render={(record) => {
          if (payment) return null
          setPayment(record.payment)
          return null
        }}
      />

      <OverflowingTabbedShowLayout>
        <Tab label="Transaction Details">
          <TextField source="originId" label="id" />
          <TextField source="payment.invoice.id" label="Invoice ID" />
          <TextField
            source="payment.invoice.chargeBeeId"
            label="Invoice Chargebee ID"
          />
          <FunctionField
            label="Amount"
            source="amount"
            render={({ amount, currency }) =>
              formatPrice(amount / 100, currency.code)
            }
          />
          <ReferenceListFilterField
            listUri="/customers"
            titlePath="payment.customer.email"
            valuePath="payment.customer.email"
            label="Customer"
          />
          <ReferenceListFilterField
            listUri="/stores"
            titlePath="payment.store.name"
            valuePath="payment.store.name"
            label="Store"
          />
          <ReferenceListFilterField
            listUri="/orders"
            titlePath="payment.invoice.order.id"
            valuePath="payment.invoice.order.id"
            label="Order"
          />
          <TextField source="payment.status.name" label="Status" />
          <DateField
            source="createdAt"
            short={false}
            addLabel
            label="Created at"
          />
          <TextField source="pspReference" />
        </Tab>
        <Tab label="resources.adyenNotifications.menu">
          <ReferenceManyField
            addLabel={false}
            reference="adyen-notifications"
            source="id"
            pagination={<Pagination />}
            filter={{
              'payment.id': payment?.id,
              properties: ['id', 'createdAt', 'processedAt', 'error'],
              'properties[payment]': ['id'],
            }}
            target=""
          >
            <DataGrid rowClick="show">
              <TextField source="originId" label="id" />
              <DateField source="createdAt" />
              <DateField source="processedAt" />
              <TextField source="payment.id" label="payment id" />
              <TextField source="error" />
            </DataGrid>
          </ReferenceManyField>
        </Tab>
      </OverflowingTabbedShowLayout>
    </ShowGuesser>
  )
}
