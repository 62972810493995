import React, { useMemo } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { usePimcoreLists } from 'contexts/pimcoreLists'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

const getOptionLabel = (option) =>
  `${option?.name} - ${option?.interval} (${option?.id})`

export const ListingForm = ({
  itemIndex,
  productIndex,
  setMetaData,
  listingId,
  bundleId,
  interval,
}) => {
  const { propositions, bundles } = usePimcoreLists()

  const filteredListings = useMemo(() => {
    if (bundleId !== undefined) {
      for (let bundle of bundles) {
        if (bundle.id === bundleId) {
          for (let proposition of bundle.products) {
            for (let listingItem of proposition.listing) {
              if (listingItem.id === listingId) {
                return proposition.listing.filter(
                  (item) => item.interval === interval
                )
              }
            }
          }
        }
      }
    } else {
      for (let proposition of propositions) {
        for (let listingItem of proposition.listing) {
          if (listingItem.id === listingId) {
            return proposition.listing.filter(
              (item) => item.interval === interval
            )
          }
        }
      }
    }

    return []
  }, [propositions, interval, listingId, bundleId, bundles])

  const selectedListing = useMemo(() => {
    return filteredListings.find((item) => item.id === listingId)
  }, [filteredListings, listingId])

  return (
    <Autocomplete
      style={{ flex: 1, marginRight: '30px' }}
      options={filteredListings}
      getOptionLabel={getOptionLabel}
      value={selectedListing || null}
      onChange={(e, newListing) => {
        if (!newListing?.id) return
        setMetaData((cur) => {
          let items = []

          items = cur.items?.map((item, index) => {
            if (index === itemIndex) {
              const updatedItem = { ...item }
              if (productIndex !== undefined) {
                updatedItem.product.products[productIndex].id = newListing.id
              } else {
                updatedItem.product.id = newListing.id
              }

              return updatedItem
            }
            return item
          })

          return { ...cur, items }
        })
      }}
      disabled={!filteredListings?.length}
      renderInput={(params) => (
        <TextField {...params} label="Listing" variant="outlined" />
      )}
      renderOption={(option, { inputValue }) => {
        const optionLabel = getOptionLabel(option)
        const matches = match(optionLabel, inputValue)
        const parts = parse(optionLabel, matches)

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        )
      }}
    />
  )
}
