import React, { createContext, useContext, useState, useEffect } from 'react'
import useApi from 'hooks/useApi'
import { getNumberFromStringId } from 'utils/string'

const pimcoreListsContext = createContext({ propositions: [], bundles: [] })

const PimcoreListsProvider = ({ children, store }) => {
  const [propositions, setPropositions] = useState([])
  const [bundles, setBundles] = useState([])
  const { api } = useApi()

  useEffect(() => {
    if (!store?.id) return

    api.getPimcoreBundles(getNumberFromStringId(store.id)).then((res) => {
      if (!res) return
      const { data } = res
      setBundles(data)
    })
  }, [api, store])

  useEffect(() => {
    if (!store?.id) return

    api.getPimcorePropositions(getNumberFromStringId(store.id)).then((res) => {
      if (!res) return
      const { data } = res
      setPropositions(data)
    })
  }, [api, store])

  return (
    <pimcoreListsContext.Provider value={{ propositions, bundles }}>
      {children}
    </pimcoreListsContext.Provider>
  )
}

const usePimcoreLists = () => useContext(pimcoreListsContext)

export { usePimcoreLists, PimcoreListsProvider }
