import React from 'react'
import {
  AutocompleteInput,
  ReferenceInput,
  required,
  BooleanInput,
} from 'react-admin'
import { InputGuesser } from '@api-platform/admin'
import { CreateGuesser } from 'theme/components'

export const PlanCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <ReferenceInput
      source="group"
      reference="groups"
      label="resources.group.field"
      filterToQuery={(searchText) => ({ name: searchText })}
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
      perPage={50}
    >
      <AutocompleteInput
        optionText={(option) => {
          if (!option?.store?.name) return option?.name
          return `${option.name} (${option.store.name})`
        }}
      />
    </ReferenceInput>
    <BooleanInput source="allowUnpaid" />
    <BooleanInput source="enabledInPortal" defaultValue={true} />
    <BooleanInput source="enabledInHostedPages" defaultValue={true} />
    <InputGuesser source="chargeBeeId" validate={required()} />
    <InputGuesser source="redirectUrl" />

    <ReferenceInput
      source="productStoreInterval"
      reference="product-store-intervals"
      label="Product store interval"
      filterToQuery={(searchText) => ({ productNameOrChargebeeId: searchText })}
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <AutocompleteInput
        optionText={(option) =>
          option
            ? `${option.chargeBeeId} (${option.name})`
            : option?.chargeBeeId
        }
      />
    </ReferenceInput>
  </CreateGuesser>
)
