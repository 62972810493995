import { formatDateTimeForAPI } from 'utils/string'
import { downloadCSV } from 'react-admin'
import { api } from 'service/Api'

export const exporter = async ({ startDate, endDate }) => {
  const data = await api.exportCsv('events', {
    startDate: formatDateTimeForAPI(startDate),
    endDate: formatDateTimeForAPI(endDate),
  })

  downloadCSV(data, `events`)
}
