import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import {
  TextField,
  Filter,
  TextInput,
  DateInput,
  NumberInput,
  BooleanInput,
} from 'react-admin'
import { ProductEdit } from './ProductEdit'
import { DateField, ListGuesser } from 'theme/components'
import { exporter } from './exporter'

const ListFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Id" source="id" />
    <TextInput label="Name" source="name" />
    <TextInput label="SKU" source="sku" />
    <DateInput
      label="Created (from date)"
      defaultValue={new Date()}
      source="createdAt<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Created (to date)"
      defaultValue={new Date()}
      source="createdAt<before>"
      locales="nl-Nl"
    />
    <BooleanInput source="starterKit" />
  </Filter>
)

export const ProductList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    exporter={exporter}
    filter={{
      properties: [
        'id',
        'name',
        'sku',
        'pcs',
        'createdAt',
        'updatedAt',
        'weight',
        'hsCode',
      ],
    }}
    rowClick="edit"
    expand={<ProductEdit defaultToolbar />}
    bulkActionButtons={false}
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <FieldGuesser source="name" />
    <FieldGuesser source="sku" />
    <FieldGuesser source="pcs" />
    <FieldGuesser source="weight" />
    <FieldGuesser source="hsCode" />
    <DateField source="createdAt" />
    <DateField source="updatedAt" />
  </ListGuesser>
)
