import React, { cloneElement } from 'react'
import { FieldGuesser } from '@api-platform/admin'
import CopyPlanButton from 'components/copyPlanButton'
import {
  TextField,
  Filter,
  TextInput,
  DateInput,
  NumberInput,
  AutocompleteInput,
  ReferenceInput,
  SelectArrayInput,
  BooleanInput,
  useListContext,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
  ExportButton,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import ImportButton from 'components/csv/import'
import ImportAddonsButton from './importAddonsButton'
import { exporter } from './exporter'
import BulkUpdatePlansButton from './bulkUpdatePlansButton'

const ListActions = (props) => {
  const { className, exporter, filters, maxResults, ...rest } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    currentSort,
    total,
    showFilter,
  } = useListContext()
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ImportButton resource="plans" text="Import Plans" />
      <ImportAddonsButton />
      <ExportButton
        label="Export CSV"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  )
}

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="productStoreInterval.id"
      reference="product-store-intervals"
      label="Product Interval"
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="group.id"
      reference="groups"
      label="Group"
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="productStoreInterval.productStore.store.id"
      reference="stores"
      label="Store"
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceInput>
    <NumberInput label="Id" source="id" />
    <TextInput label="Slug" source="slug" />
    <TextInput
      label="Product SKU"
      source="productStoreInterval.productStore.product.sku"
    />
    <TextInput
      label="ProductStore SKU"
      source="productStoreInterval.productStore.sku"
    />
    <TextInput
      label="ProductStoreInterval Name"
      source="productStoreInterval.name"
    />
    <TextInput label="ChargeBeeId" source="chargeBeeId" />
    <DateInput
      label="Created (from date)"
      defaultValue={new Date()}
      source="createdAt<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Created (to date)"
      defaultValue={new Date()}
      source="createdAt<before>"
      locales="nl-Nl"
    />
    <BooleanInput source="allowUnpaid" />
    <BooleanInput
      source="enabledInPortal"
      label="resources.plan.list.enabledInPortal"
    />
    <BooleanInput
      source="enabledInHostedPages"
      label="resources.plan.list.enabledInHostedPages"
    />
  </Filter>
)

export const PlanList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    exporter={exporter}
    filter={{
      properties: [
        'id',
        'allowUnpaid',
        'chargeBeeId',
        'enabledInPortal',
        'enabledInHostedPages',
        'createdAt',
      ],
      'properties[productStoreInterval]': ['id', 'name'],
      'properties[group]': ['id', 'name'],
    }}
    bulkActionButtons={<BulkUpdatePlansButton />}
    actions={<ListActions />}
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <FieldGuesser source="chargeBeeId" />
    <FieldGuesser source="allowUnpaid" />
    <FieldGuesser
      source="enabledInPortal"
      label="resources.plan.list.enabledInPortal"
    />
    <FieldGuesser
      source="enabledInHostedPages"
      label="resources.plan.list.enabledInHostedPages"
    />
    <ReferenceListFilterField
      {...props}
      label="resources.productStoreInterval.field"
      listUri="/plans"
      titlePath="productStoreInterval.name"
      valuePath="productStoreInterval.name"
      filterType="collection"
    />
    <ReferenceListFilterField
      {...props}
      label="resources.group.field"
      listUri="/plans"
      titlePath="group.name"
      valuePath="group.id"
      filterType="collection"
    />
    <DateField source="createdAt" />
    <CopyPlanButton />
  </ListGuesser>
)
