import React from 'react'
import {
  TextField,
  ReferenceManyField,
  Pagination,
  FunctionField,
} from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { formatPrice } from 'utils/string'
import { useCustomer } from 'components/pages/customer/contexts/customer'

export const ChargebeeTransactionsList = (props) => {
  const { customerId } = useCustomer()

  return (
    <ReferenceManyField
      addLabel={false}
      reference="chargebee-payments"
      source="id"
      pagination={<Pagination />}
      target=""
      filter={{
        'customer.id': customerId,
        properties: [
          'originId',
          'createdAt',
          'amount',
          'currencyCode',
          'type',
          'status',
          'invoiceIds',
          'referenceNumber',
          'idAtGateway',
          'chargeBeeId',
        ],
        'properties[paymentMethod]': ['slug'],
      }}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick="show">
        <TextField source="idAtGateway" label="ID at gateway" />
        <TextField source="chargeBeeId" label="ChargeBee ID" />
        <TextField source="type" label="Transaction type" />
        <TextField source="status" />
        <FunctionField
          label="Amount"
          source="amount"
          render={(record) => {
            const { amount, currencyCode } = record

            return formatPrice(amount / 100, currencyCode)
          }}
        />
        <DateField source="createdAt" short={false} label="Created at" />

        <TextField source="paymentMethod.slug" label="Payment Method" />
        <TextField source="referenceNumber" />
        <FunctionField
          source="invoiceIds"
          label="Invoice chargebee ID"
          render={(record) => {
            const { invoiceIds } = record
            if (!invoiceIds) return '-'
            return invoiceIds.join(', ')
          }}
        />
      </DataGrid>
    </ReferenceManyField>
  )
}
