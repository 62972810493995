import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { Button, useNotify, useRedirect, useSaveContext } from 'react-admin'
import { useForm } from 'react-final-form'
import { useCurrentSession } from '../contexts/currentSession'
import { useCustomer } from '../contexts/customer'
import { useSubscriptions } from '../contexts/subscriptions'
import { useStore } from '../contexts/store'
import {
  getCreateSubscriptionIssues,
  getSubscriptionsRequestBody,
} from './utils'
import { colors } from 'theme/MuiTheme'

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

const CreateInvoiceSubscriptionButton = styled(Button)`
  margin: 0 0 15px 0;
  width: 100%;
`

export const CreateInvoiceSubscriptionDialog = ({
  intervalsMap,
  totalPrice,
}) => {
  const { save, saving } = useSaveContext()
  const form = useForm()
  var formdata = form.getState().values
  const {
    personalInfo,
    shippingAddress,
    billingAddress,
    comment,
    customerMidlayerId,
  } = useCustomer()
  const { subscriptions, coupon } = useSubscriptions()
  const { storeId, store } = useStore()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { session, isInvoice } = useCurrentSession()
  const { api, isSubmitting } = useApi()
  const notify = useNotify()
  const redirect = useRedirect()

  const handleCreateInvoiceSubscriptionsForExistingCustomer = useCallback(() => {
    const requestBody = getSubscriptionsRequestBody({
      subscriptions,
      isInvoice,
      intervalsMap,
      coupon,
    })
      .map((item) => ({
        ...item,
        shippingAddress,
      }))
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))

    if (requestBody.length) {
      requestBody[0].comment = comment

      api
        .createSubscriptionForExistingCustomer(
          requestBody,
          customerMidlayerId,
          session?.originId
        )
        .then(async (createRes) => {
          if (createRes) {
            notify(
              (subscriptions?.length > 1 ? 'subscriptions' : 'subscription') +
                ' created'
            )

            if (session?.originId) {
              await api.completeSubscription(session.originId, true)
            }

            redirect('/checkout-sessions')
          }
        })
    }
  }, [
    customerMidlayerId,
    api,
    subscriptions,
    shippingAddress,
    comment,
    isInvoice,
    session,
    intervalsMap,
    redirect,
    notify,
    coupon,
  ])

  const handleCreateInvoiceSubscriptions = useCallback(async () => {
    const requestBody = getSubscriptionsRequestBody({
      subscriptions,
      isInvoice,
      intervalsMap,
      coupon,
    })
      .map((item) => ({
        ...item,
        shippingAddress,
        billingAddress,
        customer: {
          email: personalInfo?.email,
          firstName: personalInfo?.firstName,
          lastName: personalInfo?.lastName,
          phone: personalInfo?.phone,
          cf_surgery_date: personalInfo?.surgeryDate,
          cf_surgery_type_v2: personalInfo?.surgeryType,
          cf_clinic_v2: personalInfo?.clinic,
          cf_surgeon_name: personalInfo?.surgeonName,
          cf_surgery_date_unknown: personalInfo?.surgeryDateUnknown,
          cs_marketing: personalInfo?.marketingConsent,
          netTermDays: 14,
          autoCollection: 'off',
        },
      }))
      .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))

    if (requestBody.length) {
      requestBody[0].comment = comment

      const createRes = await api.createInvoiceSubscription(
        requestBody,
        storeId,
        session?.originId
      )

      if (createRes) {
        notify(
          (subscriptions?.length > 1 ? 'subscriptions' : 'subscription') +
            ' created'
        )

        if (session?.originId) {
          await api.completeSubscription(session.originId, true)
        }

        redirect('/checkout-sessions')
      }
    }
  }, [
    storeId,
    billingAddress,
    api,
    subscriptions,
    personalInfo,
    shippingAddress,
    redirect,
    notify,
    session,
    comment,
    isInvoice,
    intervalsMap,
    coupon,
  ])

  const email = useMemo(() => personalInfo?.email, [personalInfo])

  if ((!isInvoice && totalPrice !== 0) || !email) {
    return null
  }

  const title = totalPrice || isInvoice ? 'Invoice' : 'Free'

  return (
    <>
      <CreateInvoiceSubscriptionButton
        disabled={!!session?.completed || saving || confirmOpen}
        variant="outlined"
        color="primary"
        size="medium"
        label={`Create ${title} subscription`}
        onClick={() => {
          const issues = getCreateSubscriptionIssues({
            billingAddress,
            shippingAddress,
            personalInfo,
            customerMidlayerId,
            store,
          })

          if (!issues) {
            if (session?.id) {
              save({ ...formdata }, false, {
                onSuccess: () => setConfirmOpen(true),
              })
            } else {
              setConfirmOpen(true)
            }
          } else {
            notify(issues, { type: 'error', multiLine: true })
          }
        }}
      />

      <Dialog open={!!confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          Are you sure you want to create {title} Subscription for Customer{' '}
          <span style={{ color: colors.primary }}>{email}</span>?
        </DialogTitle>
        <DialogBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '20px',
            }}
          >
            <Button
              label="No"
              onClick={() => setConfirmOpen(false)}
              size="medium"
              variant="outlined"
              fullWidth
            />
            <Button
              label="Yes"
              disabled={isSubmitting}
              onClick={() =>
                customerMidlayerId
                  ? handleCreateInvoiceSubscriptionsForExistingCustomer()
                  : handleCreateInvoiceSubscriptions()
              }
              variant="contained"
              fullWidth
            />
          </div>
        </DialogBody>
      </Dialog>
    </>
  )
}
