import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import { TextField, BooleanField } from 'react-admin'
import {
  DateField,
  ReferenceListFilterField,
  ShowGuesser,
} from 'theme/components'
import RetryTaskButton from 'components/retryTaskButton'

export const TaskShow = (props) => (
  <ShowGuesser {...props}>
    <TextField source="originId" label="id" addLabel={true} />
    <FieldGuesser
      source="eventHandler"
      label="resources.task.handler"
      addLabel={true}
    />
    <ReferenceListFilterField
      {...props}
      label="resources.task.status"
      listUri="/tasks"
      titlePath="status.slug"
      valuePath="status.id"
      filterType="collection"
      addLabel={true}
    />
    <BooleanField source="serial" addLabel={true} />
    <DateField source="createdAt" addLabel={true} />
    <DateField source="startedAt" addLabel={true} />
    <DateField source="processedAt" addLabel={true} />
    <TextField source="input" label="resources.task.input" addLabel={true} />
    <TextField source="output" label="resources.task.output" addLabel={true} />
    <RetryTaskButton record={props.record} />
  </ShowGuesser>
)
