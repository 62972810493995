import React from 'react'
import { CreateGuesser, ResourceCreateToolbar } from 'theme/components'

import {
  useRefresh,
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  required,
  minValue,
  maxValue,
} from 'react-admin'

export const CreateBundleProductCard = React.memo(({ record }) => {
  const notify = useNotify()
  const refresh = useRefresh()

  if (!record) {
    return null
  }

  const onSuccess = () => {
    notify('Bundle product created')
    refresh()
  }

  return (
    <CreateGuesser
      variant="outlined"
      redirect={false}
      resource="bundle-products"
      basePath="/bundle-products"
      initialValues={{
        bundle: record['@id'],
      }}
      toolbar={<ResourceCreateToolbar showBack={false} />}
      onSuccess={onSuccess}
      transform={(record) => ({
        ...record,
        discountPercentage: `${record.discountPercentage || 0}.00`,
      })}
    >
      <ReferenceInput
        source="product"
        reference="wp-products"
        label="WP Product"
        filterToQuery={(searchText) => ({
          name: searchText,
          'variations.productVariationStores.store.id': record?.stores?.map(
            (item) => item.id
          ),
          properties: ['name', 'id'],
        })}
        validate={[required()]}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="quantityMin" validate={[required()]} />
      <NumberInput source="quantityMax" validate={[required()]} />
      <NumberInput
        source="discountPercentage"
        validate={[required(), minValue(0), maxValue(100)]}
        min={0}
        max={100}
      />
    </CreateGuesser>
  )
})
