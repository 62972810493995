import React, { useCallback, useState } from 'react'
import {
  FormControlLabel,
  Switch,
  Button,
  TextField,
  FormGroup,
} from '@material-ui/core'

import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getInitialPsiFromUrl, getInitialPlanFromUrl } from '../utils'

const SpaceSeparator = styled.div`
  height: 15px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`

const AddonCreate = React.memo(
  ({ plans, productStoreIntervals, onChangePSI, onBackClick, onSubmit }) => {
    const [loading, setLoading] = useState(false)
    const [plan, setPlan] = useState(getInitialPlanFromUrl())
    const [productStoreInterval, setProductStoreInterval] = useState(
      getInitialPsiFromUrl()
    )
    const [mandatory, setMandatory] = useState(false)
    const [recommended, setRecommended] = useState(false)

    const onBack = useCallback(() => {
      onChangePSI('')
      onBackClick()
    }, [onBackClick, onChangePSI])

    const _onSubmit = useCallback(async () => {
      setLoading(true)

      let values = {
        plan: plan.id,
        productStoreInterval: productStoreInterval.id,
        mandatory,
        recommended,
      }

      await onSubmit(values)

      setLoading(false)
    }, [plan.id, productStoreInterval.id, mandatory, recommended, onSubmit])

    return (
      <div>
        <SpaceSeparator />
        <Autocomplete
          id="plans"
          options={plans}
          getOptionLabel={(option) => option?.chargeBeeId}
          style={{ width: 300 }}
          value={plan}
          onChange={(event, newValue) => {
            setPlan(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Plan (Charge Bee)"
              variant="outlined"
            />
          )}
        />

        <SpaceSeparator />

        <Autocomplete
          id="productStoreInterval"
          autoComplete
          options={productStoreIntervals}
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          value={productStoreInterval}
          onChange={(event, newValue) => {
            setProductStoreInterval(newValue)
          }}
          onInputChange={(event, newValue) => {
            onChangePSI(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product store inverval"
              variant="outlined"
            />
          )}
        />

        <SpaceSeparator />

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                value={mandatory}
                onChange={(event) => setMandatory(event.target.checked)}
                name="mandatory"
              />
            }
            label="Mandatory"
          />
          <FormControlLabel
            control={
              <Switch
                value={recommended}
                onChange={(event) => setRecommended(event.target.checked)}
                name="recommended"
              />
            }
            label="Recommended"
          />
        </FormGroup>

        <SpaceSeparator />
        <SpaceSeparator />

        <ButtonsWrapper>
          <Button color="primary" variant="outlined" onClick={onBack}>
            Back
          </Button>
          <Button
            disabled={loading || !plan || !productStoreInterval}
            color="primary"
            variant="contained"
            onClick={_onSubmit}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </div>
    )
  }
)

export default AddonCreate
