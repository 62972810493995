import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import { TextField, Filter, TextInput, NumberInput } from 'react-admin'
import { ListGuesser } from 'theme/components'
import { WooSyncButton } from 'components/wooSyncButton'
import { SyncProductsButton } from 'components/syncProductsButton/syncProductsButton'

const ListFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Id" source="id" />
    <TextInput label="Name" source="name" />
    <TextInput label="Domain" source="domain" />
  </Filter>
)

export const StoreList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    filter={{
      properties: [
        'id',
        'active',
        'name',
        'domain',
        'vatCode',
        'countryCode',
        'costCenter',
      ],
      'properties[fulfillment]': ['name'],
    }}
    rowClick="edit"
    bulkActionButtons={false}
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <FieldGuesser source="active" />
    <FieldGuesser source="name" />
    <FieldGuesser source="domain" />
    <FieldGuesser source="vatCode" />
    <FieldGuesser source="countryCode" />
    <FieldGuesser source="costCenter" />
    <TextField source="fulfillment.name" label="Fulfillment" />
    <WooSyncButton type="stores" />
    <SyncProductsButton />
  </ListGuesser>
)
