import React from 'react'
import { TextField, useTranslate, FunctionField } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import { DateField, ReferenceListFilterField, ResourceLinkField } from 'theme/components'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  th: {
    width: '130px',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: '0.5em',
  },
  green: {
    color: '#90C53C',
  },
  orange: {
    color: 'orange',
  },
  red: {
    color: 'red',
  },
}))

const STATUS = {
  picking: 7,
  shipped: 9,
  delivered: 13,
  picked: 15,
  returned: 17,
}

export const OrderDetailsCard = ({ basePath, record, resource }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sanitizedProps = { basePath, record, resource }

  return (
    <Paper className={classes.paper} variant="outlined" square>
      <Typography className={classes.title} variant="h6">
        {translate('resources.order.cards.order')}
      </Typography>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow key="Table-Row-id">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.order.id')}
            </TableCell>
            <TableCell align="left">
              <TextField {...sanitizedProps} source="originId" />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-displayName">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.order.displayName')}
            </TableCell>
            <TableCell align="left">
              <TextField {...sanitizedProps} source="displayName" />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-invoice">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.order.invoice')}
            </TableCell>
            <TableCell align="left">
              <TextField {...sanitizedProps} source="invoices.0.chargeBeeId" />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-customer">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.customer.field')}
            </TableCell>
            <TableCell align="left">
              <ResourceLinkField 
                {...sanitizedProps}
                label="customer.email"
                source="customer.@id"
                referencePath="customers"
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-status">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.order.status')}
            </TableCell>
            <TableCell align="left">
              <FunctionField
                addLabel={false}
                {...sanitizedProps}
                source="status.display"
                render={(record) => {
                  if (record.status.id === STATUS.delivered) {
                    return (
                      <div className={classes.green}>
                        {record.status.display}
                      </div>
                    )
                  } else if (
                    [STATUS.shipped, STATUS.picking, STATUS.picked].includes(
                      record.status.id
                    )
                  ) {
                    return (
                      <div className={classes.orange}>
                        {record.status.display}
                      </div>
                    )
                  } else if (record.status.id === STATUS.returned) {
                    return (
                      <div className={classes.red}>{record.status.display}</div>
                    )
                  } else {
                    return <div>{record.status.display}</div>
                  }
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-store">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.store.field')}
            </TableCell>
            <TableCell align="left">
              <ReferenceListFilterField
                {...sanitizedProps}
                label="resources.store.field"
                listUri="/orders"
                titlePath="store.name"
                valuePath="store.id"
                filterType="collection"
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-createdAt">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.order.createdAt')}
            </TableCell>
            <TableCell align="left">
              <DateField {...sanitizedProps} source="createdAt" short={false} />
            </TableCell>
          </TableRow>
          {record.updatedAt && (
            <TableRow key="Table-Row-updatedAt">
              <TableCell className={classes.th} component="th" scope="row">
                {translate('resources.updatedAt')}
              </TableCell>
              <TableCell align="left">
                <DateField
                  {...sanitizedProps}
                  source="updatedAt"
                  short={false}
                />
              </TableCell>
            </TableRow>
          )}
          <TableRow key="Table-Row-priority">
            <TableCell className={classes.th} component="th" scope="row">
              Priority
            </TableCell>
            <TableCell align="left">
              <FunctionField
                {...sanitizedProps}
                source="shippingPriority"
                render={(record) => (record.shippingPriority ? 'yes' : 'no')}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}
