import React from 'react'
import { FieldGuesser, ShowGuesser } from '@api-platform/admin'
import {
  TextField,
  Tab,
  ReferenceManyField,
  Pagination,
  usePermissions,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  DateInput,
  required,
  FunctionField,
  Button,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { OrderDetailsCard } from './card/OrderDetailsCard'
import { OrderDreamLogisticCard } from './card/OrderDreamLogisticCard'
import { ChargeBeeDetailsCard } from './card/ChargeBeeDetailsCard'
import { MontaDetailsCard } from './card/MontaDetailsCard'
import { ChargeBeeLinesCard } from './card/ChargeBeeLinesCard'
import { ShipDetailsCard } from './card/ShipDetailsCard'
import { DataGrid, DateField, LinkField } from 'theme/components'
import { OrderEdit } from './card/OrderEditCard'
import { hasRoles } from 'utils/user'
import ResyncButton from 'components/resyncButton'
import ResendButton from 'components/resendButton'
import {
  getNumberFromStringId,
  formatDateForAPI,
  displayLongText,
} from 'utils/string'
import useApi from 'hooks/useApi'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  buttons: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const OrderView = (props) => {
  const classes = useStyles()
  const { record } = props

  return (
    <Grid container spacing={4} className={classes.root}>
      <Grid item xs={6}>
        <OrderDetailsCard {...props} />
        <OrderDreamLogisticCard {...props} />
      </Grid>
      <Grid item xs={6}>
        <ChargeBeeDetailsCard {...props} />
        <MontaDetailsCard {...props} />
        <ShipDetailsCard
          {...props}
          type="shipping"
          title="resources.order.cards.shipping"
        />
        <div className={classes.buttons}>
          <ResyncButton resourceEndpoint="order" id={props.record.originId} />
          {Boolean(record?.countInvoices) && (
            <ResendButton resource="orders" ids={[props.record.originId]} />
          )}
        </div>
        <ChargeBeeLinesCard {...props} />
      </Grid>
    </Grid>
  )
}

const ReshipOrder = ({ record }) => {
  const { api, handleError } = useApi()
  const orderId = record.originId

  const submit = async (data) => {
    const order_line_items = []

    Object.keys(data.items).forEach((id) => {
      if (data.items[id] > 0) {
        order_line_items.push({ id, fulfillment_quantity: data.items[id] })
      }
    })

    const now = new Date()
    const timestamp = new Date(data.shippingDate).getTime()
    let inputDate = new Date(timestamp)

    if (inputDate.toDateString() === now.toDateString()) {
      if (inputDate.getHours() < 12) {
        inputDate.setHours(11, 59, 0)
      }
    } else {
      inputDate.setHours(9, 0, 0)
    }

    const formattedDate = Math.floor(inputDate.getTime() / 1000)

    const payload = {
      shipping_date: formattedDate,
      resend_reason_id: getNumberFromStringId(data.resendReasonId),
      order_line_items,
    }

    try {
      await api.reshipOrder(orderId, payload)
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <div>
      <h2>Reship the order #{orderId}</h2>

      <SimpleForm
        variant="outlined"
        save={submit}
        validate={(values) => {
          const keys = values.items ? Object.keys(values.items) : []
          let itemsTotal = keys.length
          let itemsSelected = 0
          let lastItem = ''
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i]
            if (values.items[key]) itemsSelected++
            lastItem = key
          }

          if (itemsTotal && !itemsSelected) {
            return {
              items: { [lastItem]: 'at least one item must be selected' },
            }
          }

          return {}
        }}
      >
        <ReferenceInput
          source="resendReasonId"
          reference="order-resend-reasons"
          format={(v) => (v ? v['@id'] || v : v)}
          validate={required()}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {record?.chargeBeeData?.order_line_items.map(
          ({ id, description, fulfillment_quantity }) => (
            <SelectInput
              key={id}
              defaultValue={fulfillment_quantity}
              source={`items.${id}`}
              label={displayLongText(
                description + description + description,
                35
              )}
              choices={new Array(fulfillment_quantity + 1)
                .fill(0)
                .map((_, i) => ({ id: i, name: i }))}
            />
          )
        )}
        <DateInput
          validate={required()}
          source="shippingDate"
          inputProps={{
            min: formatDateForAPI(new Date()),
          }}
          locales="nl-Nl"
          defaultValue={new Date()}
        />
      </SimpleForm>
    </div>
  )
}

const OrderTasks = () => {
  return (
    <ReferenceManyField
      addLabel={false}
      reference="tasks"
      target="orderId"
      source="originId"
      pagination={<Pagination />}
    >
      <DataGrid rowClick="show">
        <TextField source="originId" label="resources.id" />
        <TextField source="eventHandler" label="resources.task.handler" />
        {/* <ReferenceField source='statusId' reference= */}
        {/* <ReferenceListFilterField
          {...props}
          listUri="/tasks"
          titlePath="status.slug"
          valuePath="status.id"
          filterType="collection"
        /> */}
        <TextField source="status.slug" label="resources.task.status"/>
        <DateField source="createdAt" short={false} label="resources.createdAt" />
        <DateField source="startedAt" short={false} label="resources.updatedAt"/>
        <DateField source="processedAt" short={false} label="resources.task.processedAt"/>
      </DataGrid>
    </ReferenceManyField>
  )
}

export const OrderShow = (props) => {
  const { permissions } = usePermissions()
  const history = useHistory()
  const { hasAdminRole, hasOrderManagerRole } = hasRoles(permissions)

  const allowToEdit = hasAdminRole || hasOrderManagerRole

  if (!permissions) return null

  return (
    <ShowGuesser {...props}>
      <OverflowingTabbedShowLayout>
        <Tab label="resources.order.show">
          <OrderView />
        </Tab>
        {allowToEdit && (
          <Tab path="edit" label="resources.order.edit">
            <OrderEdit {...props} />
          </Tab>
        )}
        <Tab path="history" label="resources.order.history">
          <ReferenceManyField
            addLabel={false}
            reference="order-histories"
            target="order.id"
            source="id"
            pagination={<Pagination />}
            filter={{
              properties: ['id', 'createdAt'],
              'properties[status]': ['id', 'display'],
            }}
          >
            <DataGrid>
              <TextField
                source="originId"
                label="id"
                sortBy="id"
                sort={{ field: 'id', order: 'DESC' }}
              />
              <TextField
                source="status.display"
                label="resources.order.status"
              />
              <DateField source="createdAt" short={false} />
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab
          path="fulfillment-history"
          label="resources.order.fulfillmentHistory"
        >
          <ReferenceManyField
            addLabel={false}
            reference="monta-histories"
            target="order.id"
            source="id"
            pagination={<Pagination />}
            filter={{
              properties: [
                'id',
                'eventId',
                'eventOccurredAt',
                'eventCreatedAt',
              ],
              'properties[status]': ['id', 'slug'],
            }}
          >
            <DataGrid>
              <TextField
                source="originId"
                label="id"
                sortBy="id"
                sort={{ field: 'id', order: 'DESC' }}
              />
              <TextField
                source="status.slug"
                label="resources.order.fulfillmentStatus"
              />
              <FieldGuesser source="eventId" />
              <DateField
                source="eventOccurredAt"
                label="resources.order.montaOccurredAt"
              />
              <DateField
                source="eventCreatedAt"
                label="resources.order.montaCreatedAt"
              />
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab path="invoices" label="resources.order.invoices">
          <ReferenceManyField
            addLabel={false}
            reference="invoices"
            target="order.id"
            source="id"
            pagination={<Pagination />}
            filter={{
              properties: [
                'id',
                'chargeBeeId',
                'data',
                'createdAt',
                'updatedAt',
              ],
              'properties[status]': ['id', 'slug'],
            }}
          >
            <DataGrid rowClick="show">
              <TextField
                source="originId"
                label="resources.id"
                sortBy="id"
                sort={{ field: 'id', order: 'DESC' }}
              />
              <LinkField
                {...props}
                source="data.download_url"
                label="resources.invoice.download"
              />
              <DateField
                source="data.valid_till"
                label="resources.invoice.validTill"
              />
              <TextField
                source="chargeBeeId"
                label="resources.order.chargeBee"
              />
              <DateField source="createdAt" label="resources.createdAt" />
              <DateField source="updatedAt" label="resources.updatedAt" />
              <FunctionField
                label="Credit note"
                render={(record) => {
                  return (
                    <Button
                      color="primary"
                      variant="contained"
                      size="medium"
                      label="Create credit note"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        history.push(
                          `/credit-notes/create?invoiceId=${record?.originId}`
                        )
                      }}
                    />
                  )
                }}
              />
            </DataGrid>
          </ReferenceManyField>
        </Tab>
        <Tab path="reship" label="resources.order.reship">
          <ReshipOrder {...props} />
        </Tab>
        <Tab path="tasks" label="resources.order.tasks">
          <OrderTasks {...props} />
        </Tab>
      </OverflowingTabbedShowLayout>
    </ShowGuesser>
  )
}
