import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'

import { CancelReasonList } from './CancelReasonList'
import { CancelReasonCreate } from './CancelReasonCreate'
import { CancelReasonEdit } from './CancelReasonEdit'
import { ROLES } from 'utils/user'

export default {
  list: CancelReasonList,
  create: CancelReasonCreate,
  edit: CancelReasonEdit,
  icon: CancelPresentationIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_FINANCE],
}
