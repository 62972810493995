import React from 'react'
import {
  required,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  TextInput,
  maxLength,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'
import { InputGuesser } from '@api-platform/admin'
import { CreateGuesser } from 'theme/components'

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
`

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  margin: 10px 0;
  width: 100%;
  flex-wrap: wrap;
`

export const StoreCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <BooleanInput
      source="active"
      label="resources.active"
      defaultValue={false}
    />
    <InputGuesser
      source="name"
      label="resources.store.name"
      validate={[required()]}
    />
    <InputGuesser
      source="countryCode"
      label="resources.store.countryCode"
      validate={[required()]}
    />
    <InputGuesser source="domain" label="resources.domain" />
    <InputGuesser source="costCenter" label="resources.store.costCenter" />
    <ReferenceInput
      source="fulfillment"
      reference="fulfillments"
      label="Fulfillment"
      format={(v) => (v ? v['@id'] || v : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput
      source="needsProForma"
      label="resources.store.needsProForma"
      defaultValue={false}
    />
    <BooleanInput
      source="invoiceNotes"
      label="resources.store.invoiceNotes"
      defaultValue={false}
    />
    <BooleanInput source="allowSendingOrderWithoutInvoice" />
    <BooleanInput
      source="montaPriorityShipping48h"
      label="resources.store.montaPriorityShipping48h"
      defaultValue={false}
    />
    <BooleanInput
      source="allowCustomerCancellation"
      label="resources.store.allowCustomerCancellation"
      defaultValue={false}
    />
    <BooleanInput
      source="allowSubscriptionCancellationInPersonalPlan"
      label="resources.store.allowSubscriptionCancellationInPersonalPlan"
      defaultValue={false}
    />

    <BooleanInput
      source="allowCustomerPayment"
      label="resources.store.allowCustomerPayment"
      defaultValue={false}
    />
    <BooleanInput
      source="customerAllowAddClinic"
      label="resources.store.customerAllowAddClinic"
      defaultValue={false}
    />
    <InputGuesser source="allowInvoice" label="resources.store.allowInvoice" />
    <InputGuesser source="chargeBeeRestrictAddons" />
    <InputGuesser source="smtpUser" label="resources.store.smtpUser" />
    <InputGuesser source="smtpPassword" label="resources.store.smtpPassword" />
    <InputGuesser source="infoEmail" label="resources.store.infoEmail" />
    <InputGuesser source="supportPhone" />
    <InputGuesser source="vatCode" />
    <ReferenceInput
      source="country"
      reference="countries"
      validate={[required()]}
      format={(v) => (v ? v['@id'] || v : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <InputGuesser source="starterKitOrdersLimit" />
    <InputGuesser source="orderWeeklyLimit" />
    <SelectInput
      source="productFlow"
      choices={[
        { id: 'v1', name: 'v1: Midlayer flow' },
        { id: 'v2', name: 'v2: Pimcore' },
      ]}
      validate={[required()]}
    />
    <InputGuesser source="freshDeskGroup" />
    <ReferenceInput
      source="paymentFlow"
      reference="payment-flows"
      format={(v) => (v ? v['@id'] || v : v)}
      validate={[required()]}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <InputGuesser source="termsUrl" />
    <InputGuesser source="privacyPolicyUrl" />
    <SectionsWrapper>
      <Section>
        <Typography variant="h6">WooCommerce</Typography>
        <InputGuesser
          source="wcUrl"
          label="resources.store.wcUrl"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="wcKey"
          label="resources.store.wcKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="wcSecret"
          label="resources.store.wcSecret"
          variant="outlined"
          {...props}
        />
      </Section>
      <Section>
        <Typography variant="h6">ChargeBee</Typography>
        <InputGuesser
          source="chargeBeeKey"
          label="resources.store.chargeBeeKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeWebsite"
          label="resources.store.chargeBeeWebsite"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeePublishableKey"
          label="resources.store.chargeBeePublishableKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeGatewayIdAdyen"
          label="resources.store.chargeBeeGatewayIdAdyen"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeTestKey"
          label="resources.store.chargeBeeTestKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeTestWebsite"
          label="resources.store.chargeBeeTestWebsite"
          variant="outlined"
          {...props}
        />
      </Section>
      <Section>
        <Typography variant="h6">Adyen</Typography>
        <InputGuesser
          source="adyenMerchantAccount"
          variant="outlined"
          {...props}
        />
        <InputGuesser source="adyenApiKey" variant="outlined" {...props} />
        <InputGuesser source="adyenClientKey" variant="outlined" {...props} />
        <InputGuesser source="adyenEnv" variant="outlined" {...props} />
        <InputGuesser
          source="adyenLiveUrlPrefix"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="adyenWebhookHmacKey"
          variant="outlined"
          {...props}
        />
      </Section>
      <Section>
        <Typography variant="h6">Paypal</Typography>
        <TextInput
          validate={maxLength(255)}
          source="paypalUser"
          variant="outlined"
          {...props}
        />
        <TextInput
          validate={maxLength(255)}
          source="paypalPassword"
          variant="outlined"
          {...props}
        />
        <TextInput
          validate={maxLength(255)}
          source="paypalSignature"
          variant="outlined"
          {...props}
        />
      </Section>
    </SectionsWrapper>
  </CreateGuesser>
)
