import MapIcon from '@material-ui/icons/Map'
import { RegionList } from './RegionList'
import { RegionCreate } from './RegionCreate'
import { RegionEdit } from './RegionEdit'
import { ROLES } from 'utils/user'

export default {
  list: RegionList,
  create: RegionCreate,
  edit: RegionEdit,
  icon: MapIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
