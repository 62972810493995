import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'
import { usePimcoreLists } from 'contexts/pimcoreLists'
import { StartDateInput } from 'components/startDateInput'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  background: white;
  width: 300px;
`

export const AddPimcoreBundleModal = ({
  open,
  onClose,
  addItem,
  showStartDateInput,
}) => {
  const [bundle, setBundle] = useState()
  const [productsMap, setProductsMap] = useState({})
  const { bundles } = usePimcoreLists()
  const [startDate, setStartDate] = useState()

  useEffect(() => {
    if (!open) {
      setBundle({})
      setProductsMap({})
      setStartDate()
    }
  }, [open])

  return (
    <Dialog open={!!open} onClose={onClose}>
      <FormWrapper>
        <Autocomplete
          options={bundles}
          getOptionLabel={(option) => option?.name}
          value={bundle}
          onChange={(e, newBundle) => {
            setBundle(newBundle)
            setProductsMap({})
          }}
          renderInput={(params) => (
            <TextField {...params} label="Bundle" variant="outlined" />
          )}
        />

        {bundle?.products?.map((product, index) => {
          const listingsMap = {}
          const filteredListings = []
          product.listing.forEach((item) => {
            if (item.interval !== '90D') return // according to requirements bundle can have only 90d interval
            if (!item.active) return
            if (listingsMap[item.id]) return
            listingsMap[item.id] = 1

            filteredListings.push(item)
          })

          return (
            <Autocomplete
              key={index}
              options={filteredListings}
              getOptionLabel={(option) =>
                `${option?.name} - ${option?.interval}`
              }
              value={productsMap[product?.id] || null}
              onChange={(e, newListing) => {
                setProductsMap((cur) => ({
                  ...cur,
                  [product.id]: newListing,
                }))
              }}
              disabled={!filteredListings?.length}
              renderInput={(params) => (
                <TextField {...params} label="Listing" variant="outlined" />
              )}
            />
          )
        })}

        {showStartDateInput && (
          <StartDateInput startDate={startDate} setStartDate={setStartDate} />
        )}

        <Button
          onClick={() => {
            const item = {
              product: {
                id: bundle.id,
                products: Object.values(productsMap).map((product) => ({
                  id: product.id,
                  interval: product.interval,
                  quantity: product.quantity,
                })),
              },
              quantity: 1,
            }

            if (showStartDateInput) {
              item.startDate = startDate
            }

            addItem(item)

            onClose()
          }}
          color="primary"
          variant="contained"
          disabled={
            Object.keys(productsMap).length !== bundle?.products?.length
          }
        >
          Add bundle
        </Button>
      </FormWrapper>
    </Dialog>
  )
}
