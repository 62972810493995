import React from 'react'
import { SelectInput, ReferenceInput, TextInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const CancelReasonCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <TextInput source="name" />
    <TextInput source="slug" />
    <ReferenceInput
      source="parent"
      reference="cancellation-reasons"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
      filter={{ 'exists[parent]': false }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
)
