import React, { useEffect, useState } from 'react'
import useApi from 'hooks/useApi'
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import { getNumberFromStringId } from 'utils/string'

export const CouponSelect = ({ storeId, coupon, setCoupon }) => {
  const [list, setList] = useState([])
  const { api } = useApi()
  useEffect(() => {
    if (storeId) {
      api.fetchCoupons(getNumberFromStringId(storeId)).then((res) => {
        if (res.data) {
          setList(res.data)
        }
      })
    }
  }, [storeId, api])

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="coupon">Coupon</InputLabel>
      <Select
        value={coupon}
        onChange={(e) => {
          e.persist()
          setCoupon(e.target.value)
        }}
        input={<OutlinedInput name="coupon" id="coupon" label="Coupon" />}
      >
        <MenuItem key={''} value={''}>
          none
        </MenuItem>
        {list.map(({ coupon }) => (
          <MenuItem key={coupon.id} value={coupon.id}>
            {coupon.id}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
