import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'
import { ROLES } from 'utils/user'
import { PimcorePropositionFacadeList } from './PimcorePropositionFacadeList'
import { PimcorePropositionFacadeShow } from './PimcorePropositionFacadeShow'

export default {
  list: PimcorePropositionFacadeList,
  show: PimcorePropositionFacadeShow,
  icon: SettingsEthernetIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
