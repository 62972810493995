import React from 'react'
import PropTypes from 'prop-types'
import { Show, SimpleShowLayout } from 'react-admin'
import Introspecter from '@api-platform/admin/lib/Introspecter'
import BackButton from '../button/BackButton'
import styled from 'styled-components'

const ActionsRow = styled.span`
  display: flex;
  margin-top: 25px;
`

export const IntrospectedShowGuesser = ({
  children,
  fields,
  readableFields,
  writableFields,
  schema,
  schemaAnalyzer,
  showBack,
  ...props
}) => {
  showBack = showBack === undefined ? true : false
  return (
    <Show {...props}>
      <SimpleShowLayout>
        {children}
        {showBack && (
          <ActionsRow>
            <BackButton />
          </ActionsRow>
        )}
      </SimpleShowLayout>
    </Show>
  )
}

export const ShowGuesser = (props) => (
  <Introspecter component={IntrospectedShowGuesser} {...props} />
)

ShowGuesser.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  resource: PropTypes.string.isRequired,
}

ShowGuesser.defaultProps = {
  hasEdit: true,
}
