import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import { TextField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const IntervalList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    filter={{
      properties: ['id', 'name', 'period', 'unit', 'slug'],
    }}
    rowClick="show"
    bulkActionButtons={false}
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <FieldGuesser source="name" />
    <FieldGuesser source="period" />
    <FieldGuesser source="unit" />
    <FieldGuesser source="slug" />
  </ListGuesser>
)
