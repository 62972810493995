import React from 'react'
import {
  TextField,
  ReferenceManyField,
  Pagination,
  FunctionField,
} from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { formatPrice } from 'utils/string'
import { useCustomer } from 'components/pages/customer/contexts/customer'

const types = { 1: 'Authorization', 2: 'Payment', 3: 'Refund', 4: 'Chargeback' }

export const AdyenTransactionsList = (props) => {
  const { customerId } = useCustomer()

  return (
    <ReferenceManyField
      addLabel={false}
      reference="transactions"
      source="id"
      pagination={<Pagination />}
      target=""
      filter={{
        'payment.customer.id': customerId,
        properties: [
          'originId',
          'createdAt',
          'amount',
          'currency',
          'payment',
          'pspReference',
          'type',
        ],
        'properties[payment]': ['id'],
        'properties[payment][status]': ['name'],
        'properties[payment][type]': ['name'],
        'properties[payment][invoice]': ['chargeBeeId'],
        'properties[payment][paymentMethod]': ['type'],
      }}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick="show">
        <TextField source="payment.id" label="Payment ID" />
        <FunctionField source="type" render={(record) => types[record?.type]} />
        <TextField
          source="payment.invoice.chargeBeeId"
          label="Invoice chargebee ID"
        />
        <FunctionField
          label="Amount"
          source="amount"
          render={(record) => {
            const { amount, currency } = record

            return formatPrice(amount / 100, currency?.code)
          }}
        />
        <TextField source="payment.status.name" label="Status" />
        <DateField source="createdAt" short={false} label="Created at" />
        <TextField source="pspReference" label="PSP Reference" />
        <TextField source="payment.type.name" label="Payment type" />
        <TextField source="payment.paymentMethod.type" label="Payment method" />
      </DataGrid>
    </ReferenceManyField>
  )
}
