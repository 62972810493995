import WcIcon from '@material-ui/icons/Wc'
import { GenderStoreList } from './GenderStoreList'
import { GenderStoreCreate } from './GenderStoreCreate'
import { GenderStoreEdit } from './GenderStoreEdit'
import { ROLES } from 'utils/user'

export default {
  list: GenderStoreList,
  create: GenderStoreCreate,
  edit: GenderStoreEdit,
  icon: WcIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
