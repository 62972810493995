import React from 'react'
import { DateField, ShowGuesser } from 'theme/components'
import { TextField } from 'react-admin'

export const PimcoreProductShow = (props) => (
  <ShowGuesser {...props} hasEdit={false}>
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <TextField source="pimcoreId" />
    <TextField source="sku" />
    <DateField source="createdAt" short={false} />
  </ShowGuesser>
)
