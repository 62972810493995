import React from 'react'
import { HydraAdmin } from '@api-platform/admin'
import { Resource } from 'react-admin'
import { apiDocumentationParser, dataProvider } from './providers/dataProvider'
import routes from './routes'
import MuiTheme from 'theme/MuiTheme'
import { Provider as i18nProvider } from './i18n/Provider'
import Layout from './components/layout/Layout'
import { hasRoles } from 'utils/user'
import authProvider from './providers/authProvider'
import { Login } from './components/pages/user/Login'
import { BASE_API_URL } from './service/Api/routes'
import currency from './components/pages/currency'
import group from './components/pages/group'
import interval from './components/pages/interval'
import product from './components/pages/product'
import productStore from './components/pages/productStore'
import productStoreInterval from './components/pages/productStoreInterval'
import store from './components/pages/store'
import user from './components/pages/user'
import groupGalleryImage from './components/pages/group/gallery/image'
import plan from './components/pages/plan'
import addon from './components/pages/addon'
import order from './components/pages/order'
import customer from './components/pages/customer'
import event from './components/pages/events'
import task from './components/pages/task'
import fulfillmentError from './components/pages/fulfillmentError'
import montaStocks from './components/pages/montaStock'
import notification from './components/pages/notification'
import exactApi from './components/pages/exactApi'
import clinic from './components/pages/clinic'
import country from './components/pages/country'
import cancelReason from './components/pages/cancelReason'
import cluster from './components/pages/cluster'
import region from './components/pages/region'
import { Dashboard } from './components/pages/dashboard/Dashboard'
import logEntry from './components/pages/logEntry'
import shippingCountry from './components/pages/shippingCountry'
import shippingCountryStore from './components/pages/shippingCountryStore'
import customerSource from './components/pages/customerSource'
import customerSourceStore from './components/pages/customerSourceStore'
import surgeryTypeOption from './components/pages/surgeryTypeOption'
import surgeryTypeOptionStore from './components/pages/surgeryTypeOptionStore'
import gender from './components/pages/gender'
import genderStore from './components/pages/genderStore'
import cbSubscriptions from './components/pages/cbSubscriptions'
import variationProperties from './components/pages/variation-properties'
import variationPropertyValues from './components/pages/variation-property-values'
import wpProducts from './components/pages/wp-products'
import bundles from './components/pages/bundles'
import checkoutSessions from './components/pages/checkoutSessions'
import transaction from './components/pages/transaction'
import freshDeskTickets from './components/pages/freshDeskTickets'
import adyenNotification from './components/pages/adyen-notification'
import freshdeskSubjects from './components/pages/freshdeskSubjects'
import npfStock from './components/pages/npfStock'
import invoice from './components/pages/invoice'
import callReason from 'components/pages/callReason'
import postponeReason from 'components/pages/postponeReason'
import creditNote from 'components/pages/credit-note'
import chargebeePayment from 'components/pages/chargebee-payment'
import pimcoreProduct from 'components/pages/pimcoreProduct'
import pimcoreProposition from 'components/pages/pimcoreProposition'
import appointment from 'components/pages/appointment'
import orderResendReason from 'components/pages/orderResendReason'
import dreamlogistics from 'components/pages/dreamlogistics'
import customerCalls from 'components/pages/customer-calls'
import productFacades from 'components/pages/productFacade'
import pimcorePropositionFacade from 'components/pages/pimcorePropositionFacade'
import bundleStores from 'components/pages/bundle-stores'
import { ThemeProvider } from '@material-ui/core'

const resourceProps = (name, data, hasPermissions) => {
  let props = { name }

  if (hasPermissions) {
    props = {
      ...props,
      ...data,
    }
  }

  return props
}

export default () => (
  <ThemeProvider theme={MuiTheme}>
    <HydraAdmin
      apiDocumentationParser={apiDocumentationParser}
      entrypoint={BASE_API_URL}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      customRoutes={routes}
    >
      {(permissions) => {
        /* eslint-disable react/jsx-key */
        const {
          hasAdminRole,
          hasCustomerSupportRole,
          hasProductRole,
          hasWordPressRole,
          hasFinanceRole,
          hasOrderManagerRole,
          hasCountryManagerRole,
        } = hasRoles(permissions)

        return [
          <Resource name="salesforce-tracks" />,
          <Resource name="charge-bee-order-statuses" />,
          <Resource name="monta-order-statuses" />,
          <Resource name="order-statuses" />,
          <Resource name="order-histories" />,
          <Resource name="monta-histories" />,
          <Resource name="pricing-models" />,
          <Resource name="store-plans" />,
          <Resource name="payment-flows" />,
          <Resource name="email-logs" />,
          <Resource name="coupons" />,
          <Resource name="wp-images" />,
          <Resource name="bundle-products" />,
          <Resource name="wp-product-variations" />,
          <Resource name="wp-product-variation-stores" />,
          <Resource name="clinic-statuses" />,
          <Resource name="payment-statuses" />,
          <Resource name="task-statuses" />,
          <Resource name="payment-errors" />,
          <Resource name="customer-comments" />,
          <Resource
            {...resourceProps(
              'order-resend-reasons',
              orderResendReason,
              hasAdminRole
            )}
          />,
          <Resource {...resourceProps('users', user, hasAdminRole)} />,
          <Resource
            {...resourceProps(
              'groups',
              group,
              hasAdminRole || hasProductRole || hasWordPressRole
            )}
          />,
          <Resource
            {...resourceProps(
              'products',
              product,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource
            options={{ label: 'Products Stores' }}
            {...resourceProps(
              'product-stores',
              productStore,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource
            options={{ label: 'Products Stores Intervals' }}
            {...resourceProps(
              'product-store-intervals',
              productStoreInterval,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource {...resourceProps('stores', store, hasAdminRole)} />,

          <Resource
            {...resourceProps(
              'intervals',
              interval,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource {...resourceProps('currencies', currency, hasAdminRole)} />,
          <Resource
            {...resourceProps(
              'group-images',
              groupGalleryImage,
              hasAdminRole || hasWordPressRole
            )}
          />,
          <Resource
            {...resourceProps('plans', plan, hasAdminRole || hasProductRole)}
          />,
          <Resource
            {...resourceProps('addons', addon, hasAdminRole || hasProductRole)}
          />,
          <Resource
            {...resourceProps(
              'orders',
              order,
              hasAdminRole ||
                hasOrderManagerRole ||
                hasProductRole ||
                hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'invoices',
              invoice,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'customers',
              customer,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'events',
              event,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'fresh-desk-tickets',
              freshDeskTickets,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'tasks',
              task,
              hasAdminRole || hasProductRole || hasOrderManagerRole
            )}
          />,
          <Resource
            {...resourceProps(
              'fulfillment-errors',
              fulfillmentError,
              hasAdminRole
            )}
          />,
          <Resource name="fulfillments" />,
          <Resource
            {...resourceProps(
              'monta-stocks',
              montaStocks,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource
            {...resourceProps('notifications', notification, hasAdminRole)}
          />,
          <Resource
            {...resourceProps(
              'exact-online-logs',
              exactApi,
              hasAdminRole || hasFinanceRole
            )}
          />,
          <Resource
            {...resourceProps(
              'subscription-cancellations',
              cbSubscriptions,
              hasAdminRole || hasFinanceRole
            )}
          />,
          <Resource
            {...resourceProps(
              'clinics',
              clinic,
              hasAdminRole || hasCountryManagerRole
            )}
          />,
          <Resource
            {...resourceProps(
              'variation-properties',
              variationProperties,
              hasAdminRole
            )}
          />,
          <Resource
            {...resourceProps(
              'variation-property-values',
              variationPropertyValues,
              hasAdminRole
            )}
          />,
          <Resource
            {...resourceProps('wp-products', wpProducts, hasAdminRole)}
          />,
          <Resource {...resourceProps('bundles', bundles, hasAdminRole)} />,
          <Resource {...resourceProps('countries', country, hasAdminRole)} />,
          <Resource {...resourceProps('clusters', cluster, hasAdminRole)} />,
          <Resource {...resourceProps('regions', region, hasAdminRole)} />,
          <Resource
            {...resourceProps('log-entries', logEntry, hasAdminRole)}
          />,
          <Resource
            {...resourceProps(
              'shipping-countries',
              shippingCountry,
              hasAdminRole
            )}
          />,
          <Resource
            {...resourceProps(
              'cancellation-reasons',
              cancelReason,
              hasAdminRole || hasFinanceRole
            )}
          />,
          <Resource
            {...resourceProps('postpone-reasons', postponeReason, hasAdminRole)}
          />,
          <Resource
            {...resourceProps(
              'shipping-country-stores',
              shippingCountryStore,
              hasAdminRole
            )}
          />,
          <Resource
            {...resourceProps('customer-sources', customerSource, hasAdminRole)}
          />,
          <Resource
            {...resourceProps(
              'customer-source-stores',
              customerSourceStore,
              hasAdminRole
            )}
          />,
          <Resource
            {...resourceProps(
              'surgery-type-options',
              surgeryTypeOption,
              hasAdminRole
            )}
          />,
          <Resource
            {...resourceProps(
              'surgery-type-option-stores',
              surgeryTypeOptionStore,
              hasAdminRole
            )}
          />,
          <Resource {...resourceProps('genders', gender, hasAdminRole)} />,
          <Resource
            {...resourceProps(
              'fulfillment-stocks',
              dreamlogistics,
              hasAdminRole || hasOrderManagerRole
            )}
          />,
          <Resource
            {...resourceProps('gender-stores', genderStore, hasAdminRole)}
          />,

          <Resource
            {...resourceProps(
              'checkout-sessions',
              checkoutSessions,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'transactions',
              transaction,
              hasAdminRole ||
                hasCustomerSupportRole ||
                hasFinanceRole ||
                hasOrderManagerRole
            )}
          />,
          <Resource
            {...resourceProps(
              'chargebee-payments',
              chargebeePayment,
              hasAdminRole ||
                hasCustomerSupportRole ||
                hasFinanceRole ||
                hasOrderManagerRole
            )}
          />,
          <Resource
            {...resourceProps(
              'adyen-notifications',
              adyenNotification,
              hasAdminRole ||
                hasCustomerSupportRole ||
                hasOrderManagerRole ||
                hasFinanceRole
            )}
          />,
          <Resource
            {...resourceProps(
              'fresh-desk-subjects',
              freshdeskSubjects,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'npf-stocks',
              npfStock,
              hasAdminRole || hasOrderManagerRole || hasCountryManagerRole
            )}
          />,
          <Resource
            {...resourceProps('call-reasons', callReason, hasAdminRole)}
          />,
          <Resource
            {...resourceProps(
              'credit-notes',
              creditNote,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'pimcore-products',
              pimcoreProduct,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource
            {...resourceProps(
              'pimcore-propositions',
              pimcoreProposition,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource
            {...resourceProps(
              'proposition-facades',
              pimcorePropositionFacade,
              hasAdminRole || hasProductRole
            )}
          />,
          <Resource
            {...resourceProps(
              'appointments',
              appointment,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'customer-calls',
              customerCalls,
              hasAdminRole || hasCustomerSupportRole
            )}
          />,
          <Resource
            {...resourceProps(
              'proposition-product-facades',
              productFacades,
              hasAdminRole
            )}
          />,
          <Resource
            {...resourceProps('bundle-stores', bundleStores, hasAdminRole)}
          />,
        ]
        /* eslint-enable react/jsx-key */
      }}
    </HydraAdmin>
  </ThemeProvider>
)
