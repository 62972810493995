import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useCurrentSession } from '../contexts/currentSession'
import { useSubscriptions } from '../contexts/subscriptions'
import {
  convertCartItemsToSubscriptions,
  convertPsiSubscriptionsToCartItems,
  getPimcoreSubscriptions,
  getSessionSubscriptions,
} from '../subscriptions/utils'
import { AddPsiBundleModal } from 'components/addPsiBundleModal'
import { AddPsiItemModal } from 'components/addPsiItemModal'
import { useStore } from '../contexts/store'
import { Button } from 'react-admin'
import { AddPimcorePropositionModal } from 'components/addPimcorePropositionModal'
import { AddPimcoreBundleModal } from 'components/addPimcoreBundleModal'
import { PimcoreListsProvider } from 'contexts/pimcoreLists'
import { SubscriptionItem } from 'components/SubscriptionItem'
import { formatDate } from 'utils/string'
import { LinearProgress, Tooltip, Divider } from '@material-ui/core'
import { QuantityButtons } from 'components/quantityButtons'
import { useCustomer } from '../contexts/customer'
import useApi from 'hooks/useApi'

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 20px 0 -10px 0;
`

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: all 0.3s ease;
  border-radius: 8px;
  padding: 4px;
  border: 1px solid white;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const IntervalTitle = styled.h5`
  border-bottom: 2px solid black;
  padding-bottom: 8px;
`

const IntervalsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const intervalTitles = {
  '90Dv2': '90 days',
  SKT: 'Starter',
  '180D': '6 months',
  '90D': '3 months',
  SGL: 'Single',
}

export const CartStep = () => {
  const { productFlow } = useCurrentSession()
  const {
    subscriptions,
    setSubscriptions,
    coupon,
    setCoupon,
    setSubscriptionsFetching,
  } = useSubscriptions()
  const { billingAddress, personalInfo, customerMidlayerId } = useCustomer()

  const [addItemOpen, setAddItemOpen] = useState(false)
  const [addBundleOpen, setAddBundleOpen] = useState(false)
  const { storeId, store } = useStore()
  const [cartItems, setCartItems] = useState([])
  const { api, isSubmitting } = useApi()
  const [subscriptionsFetched, setSubscriptionsFetched] = useState(false)
  const [changingIndex, setChangingIndex] = useState(null)

  const currency = store?.currency?.code

  // Fetches subscription details either from checkout or Pimcore based on product flow version.
  // For initial fetch, transforms the subscriptions to ensure they're split into proper intervals and dates.
  // If subscriptions are refetching, updates subscriptions directly.
  const fetchSubscriptions = useCallback(
    async (newSubscriptions, refetching = false) => {
      if (!storeId || !newSubscriptions?.length) {
        setSubscriptions((cur) => (cur.length ? [] : cur))
        return
      }

      setSubscriptionsFetching(true)

      let response = null
      if (productFlow === 'v1') {
        response = await api.fetchCheckoutSubscriptionsInfo({
          sessionSubscriptions: getSessionSubscriptions(newSubscriptions),
          storeId,
        })
      } else {
        response = await api.getPimcoreSubscriptionsInfo({
          pimcoreSubscriptions: {
            subscriptions: getPimcoreSubscriptions(newSubscriptions),
            customerMidlayerId,
            customerPersonalInfo: {
              email: personalInfo?.email || '',
              firstName: personalInfo?.firstName || '',
              lastName: personalInfo?.lastName || '',
              cf_surgery_date: personalInfo?.surgeryDate || '',
              cf_surgery_type_v2: personalInfo?.surgeryType || '',
              cf_clinic_v2: personalInfo?.clinic || '',
              cf_surgeon_name: personalInfo?.surgeonName || '',
            },
            coupon,
            customerBillingAddress: billingAddress,
          },
          storeId,
        })
      }

      setSubscriptionsFetched(true)
      setChangingIndex(null)

      if (!response && coupon) {
        setCoupon() // no response means error. removing coupon might fix it
      }

      if (!refetching) {
        // when new item appears in cart it has no interval property, so we can not split subscriptions right away
        // but fetched subscritpions do have an interval propery for each subscription item

        // we convert subscriptions to cart items
        const newCartItems = convertPsiSubscriptionsToCartItems(
          response?.data?.subscriptions
        )

        // now we converting cart items back to subscriptions. at this point they are split into intervals and dates properly
        const properlySplitSubscriptions = convertCartItemsToSubscriptions(
          newCartItems
        )

        // with properly split subscriptions we are doing the refetch in order to get all the extra information needed
        // recursive call with second argument
        fetchSubscriptions(properlySplitSubscriptions, true)
      } else {
        // if subscriptions are refetching - we are setting that value right away
        setSubscriptions(response?.data?.subscriptions)
        setSubscriptionsFetching(false)
      }
    },
    [
      storeId,
      api,
      setSubscriptions,
      coupon,
      setCoupon,
      billingAddress,
      personalInfo,
      customerMidlayerId,
      productFlow,
      setSubscriptionsFetching,
    ]
  )

  // Adds a new item to the cart.
  // If the item has a mandatory variation, that variation is also added.
  const addItem = (newItem) => {
    setCartItems((currentItems) => {
      const items = [...currentItems]
      items.push({ ...newItem, index: currentItems.length })

      if (newItem.mandatoryVariation) {
        items.push({
          ...newItem,
          variationId: newItem.mandatoryVariation,
          mandatoryVariation: undefined,
          index: currentItems.length + 1,
          mandatory: true,
        })
      }

      fetchSubscriptions(convertCartItemsToSubscriptions(items))

      return items
    })
  }

  useEffect(() => {
    setCartItems(convertPsiSubscriptionsToCartItems(subscriptions))
  }, [subscriptions])

  // Organizes cart items based on their intervals.
  // Helps in displaying items under correct interval headers in the UI.
  const intervalsMap = useMemo(() => {
    const map = {}
    for (let i = 0; i < cartItems.length; i++) {
      let interval = cartItems[i].interval
      if ((!interval || interval === 'undefined') && cartItems[i].products) {
        interval = cartItems[i].products[0].interval
      }
      if (!map[interval]) map[interval] = []
      map[interval].push({ ...cartItems[i], index: i })
    }

    return map
  }, [cartItems])

  const intervals = useMemo(() => Object.keys(intervalsMap), [intervalsMap])

  // Handles the change in quantity of cart items.
  // If the quantity becomes zero, the item is removed from the cart.
  const handleChangeQuantity = ({ newQuantity, index }) => {
    setChangingIndex(index)
    if (newQuantity) {
      setCartItems((currentItems) => {
        const newItems = currentItems.map((item, i) => {
          if (i === index) {
            return { ...item, quantity: newQuantity }
          }
          return item
        })

        fetchSubscriptions(convertCartItemsToSubscriptions(newItems))

        return newItems
      })
    } else {
      setCartItems((currentItems) => {
        const newItems = currentItems.filter((item, i) => i !== index)

        fetchSubscriptions(convertCartItemsToSubscriptions(newItems))

        return newItems
      })
    }
  }

  useEffect(() => {
    if (subscriptionsFetched || isSubmitting) return

    fetchSubscriptions(subscriptions)
  }, [subscriptionsFetched, subscriptions, isSubmitting, fetchSubscriptions])

  useEffect(() => {
    setSubscriptionsFetched(false)
  }, [coupon, billingAddress])

  return (
    <PimcoreListsProvider store={store}>
      <IntervalsList>
        {intervals.length === 0 && (
          <span style={{ textAlign: 'center', opacity: 0.85 }}>
            The cart is empty.
            <br />
            Please add product or bundle.
          </span>
        )}
        {intervals.map((interval) => {
          // Displays cart items grouped by their intervals.
          // If an interval is 'undefined', it means the latest added item's details are being fetched. A progress bar is shown.

          const intervalItems = intervalsMap[interval]
          if (interval === 'undefined')
            return (
              <Tooltip
                title={
                  <span>
                    Fetching information for the latest item.
                    <br />
                    Click on progress bar to cancel
                  </span>
                }
                placement="top"
              >
                <div
                  style={{ padding: '20px', cursor: 'pointer' }}
                  onClick={() => {
                    setCartItems((currentItems) => {
                      const newItems = currentItems.filter(
                        (item) => item.interval && item.interval !== 'undefined'
                      )
                      fetchSubscriptions(
                        convertCartItemsToSubscriptions(newItems)
                      )

                      return newItems
                    })
                  }}
                >
                  <LinearProgress />
                </div>
              </Tooltip>
            )

          return (
            <div key={interval}>
              <IntervalTitle>
                Interval: {intervalTitles[interval]}
              </IntervalTitle>
              <List>
                {intervalItems.map((item, index) => {
                  return (
                    <>
                      <ListItem key={`item:${index}`}>
                        <Row>
                          <QuantityButtons
                            quantity={item?.quantity}
                            onIncrease={() =>
                              handleChangeQuantity({
                                newQuantity: item?.quantity + 1,
                                index: item.index,
                              })
                            }
                            increaseDisabled={
                              item?.quantity > 9 ||
                              interval === 'SKT' ||
                              isSubmitting
                            }
                            decreaseDisabled={isSubmitting}
                            onDecrease={() =>
                              handleChangeQuantity({
                                newQuantity: item?.quantity - 1,
                                index: item.index,
                              })
                            }
                            loading={item.index === changingIndex}
                          />
                          <SubscriptionItem
                            item={item}
                            currency={currency}
                            showQuantity={false}
                          />
                        </Row>
                        <Row>
                          <span
                            style={{ fontSize: '14px', marginLeft: '52px' }}
                          >
                            Start date:&nbsp;
                            <span style={{ color: '#90c449', fontWeight: 500 }}>
                              {item.startDate
                                ? formatDate(item.startDate)
                                : 'immediately'}
                            </span>
                          </span>
                        </Row>
                      </ListItem>
                      {index !== intervalItems.length - 1 && (
                        <Divider component="li" />
                      )}
                    </>
                  )
                })}
              </List>
            </div>
          )
        })}

        {/* Provides options to add products or bundles to the cart. */}
        <Buttons>
          <Button
            color="primary"
            onClick={() => setAddItemOpen(true)}
            size="small"
            label="Add product"
          />
          <Button
            color="primary"
            onClick={() => setAddBundleOpen(true)}
            size="small"
            label="Add bundle"
          />
        </Buttons>

        {/* The modal to be displayed depends on the product flow version. */}
        {productFlow === 'v1' ? (
          <>
            <AddPsiItemModal
              open={addItemOpen}
              onClose={() => setAddItemOpen(false)}
              addItem={addItem}
              storeId={storeId}
              showStartDateInput
            />
            <AddPsiBundleModal
              open={addBundleOpen}
              onClose={() => setAddBundleOpen(false)}
              addItem={addItem}
              storeId={storeId}
              showStartDateInput
            />
          </>
        ) : (
          <>
            <AddPimcorePropositionModal
              open={addItemOpen}
              onClose={() => setAddItemOpen(false)}
              addItem={addItem}
              showStartDateInput
            />
            <AddPimcoreBundleModal
              open={addBundleOpen}
              onClose={() => setAddBundleOpen(false)}
              addItem={addItem}
              showStartDateInput
            />
          </>
        )}
      </IntervalsList>
    </PimcoreListsProvider>
  )
}
