import React from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  maxLength,
} from 'react-admin'
import styled from 'styled-components'
import { CreateGuesser, ResourceCreateToolbar } from 'theme/components'
import { AutocompleteOption } from './ClinicEdit'

const MultiselectWrapper = styled.div`
  border: 1px solid gray;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px 0;
`

export const ClinicCreate = (props) => {
  return (
    <CreateGuesser
      {...props}
      variant="outlined"
      redirect={props.redirect === undefined ? 'list' : props.redirect}
      toolbar={<ResourceCreateToolbar showBack={props.showBack} />}
    >
      <TextInput source="name" validate={[required(), maxLength(99)]} />
      <TextInput source="city" validate={[maxLength(99)]} />
      <ReferenceInput
        source="salesforceTrack"
        label="SF Clinic name"
        reference="salesforce-tracks"
        filterToQuery={(searchText) => ({ clinicName: searchText })}
        format={(v) => (v ? v['@id'] || v : v)}
      >
        <AutocompleteInput
          inputText={(choice) => choice.recordData.Name}
          optionText={<AutocompleteOption />}
          resettable
          matchSuggestion={(filter, choice) =>
            choice.recordData.Name.toLowerCase().includes(filter.toLowerCase())
          }
          options={{
            suggestionsContainerProps: { style: { zIndex: 99999 } },
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Status"
        source="status"
        reference="clinic-statuses"
        format={(v) => (v ? v['@id'] || v : v)}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <MultiselectWrapper>
        <ArrayInput source="stores" label="Stores">
          <SimpleFormIterator>
            <ReferenceInput
              reference="stores"
              label=""
              format={(v) => (v ? v['@id'] || v : v)}
              filter={{ active: 1 }}
            >
              <SelectInput optionText="name" validate={required()} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </MultiselectWrapper>
      <MultiselectWrapper>
        <ArrayInput source="magentoIds" label="Magento IDs">
          <SimpleFormIterator>
            <TextInput label="" validate={[required(), maxLength(99)]} />
          </SimpleFormIterator>
        </ArrayInput>
      </MultiselectWrapper>
    </CreateGuesser>
  )
}
