import React, { useEffect, useState } from 'react'
import { InputGuesser } from '@api-platform/admin'
import {
  Toolbar,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { EditGuesser, ResourceEditToolbar } from 'theme/components'
import useApi from 'hooks/useApi'
import { AutoSelectInput } from 'theme/components/input/AutoSelectinput'

export const StoreEditTab = ({ defaultToolbar, ...props }) => {
  const { api } = useApi()
  const [agents, setAgents] = useState([])
  const [groups, setGroups] = useState([])

  const isProduction = window.location.hostname === 'admin.fitformedev.nl'

  useEffect(() => {
    if (!isProduction) return
    if (!groups.length) {
      api.getFreshdeskGroups().then(({ data }) => {
        setGroups(data.map(({ id, name }) => ({ id: String(id), name })))
      })
    }
  }, [api, groups, isProduction])

  useEffect(() => {
    if (!isProduction) return

    if (!agents.length) {
      api.getFreshdeskAgents().then(({ data }) =>
        setAgents(
          data.map(({ id, contact }) => ({
            id: String(id),
            name: contact.name,
          }))
        )
      )
    }
  }, [api, agents, isProduction])

  return (
    <EditGuesser
      {...props}
      undoable={false}
      variant="outlined"
      redirect={false}
      toolbar={
        defaultToolbar ? (
          <Toolbar />
        ) : (
          <ResourceEditToolbar showDelete={false} />
        )
      }
    >
      <InputGuesser source="active" label="resources.active" />
      <InputGuesser source="name" label="resources.store.name" />
      <InputGuesser source="countryCode" label="resources.store.countryCode" />
      <InputGuesser source="domain" label="resources.domain" />
      <InputGuesser source="costCenter" label="resources.store.costCenter" />
      <InputGuesser source="generalLedger" />
      <InputGuesser
        source="needsProForma"
        label="resources.store.needsProForma"
      />
      <InputGuesser
        source="invoiceNotes"
        label="resources.store.invoiceNotes"
      />
      <InputGuesser source="allowSendingOrderWithoutInvoice" />
      <InputGuesser
        source="montaPriorityShipping48h"
        label="resources.store.montaPriorityShipping48h"
      />

      <InputGuesser
        source="allowInvoice"
        label="resources.store.allowInvoice"
      />
      <InputGuesser source="chargeBeeRestrictAddons" />
      <InputGuesser source="smtpUser" label="resources.store.smtpUser" />
      <InputGuesser
        source="smtpPassword"
        label="resources.store.smtpPassword"
      />
      <InputGuesser source="vatCode" />
      <ReferenceInput
        source="country"
        reference="countries"
        format={(v) => (v ? v['@id'] || v : v)}
      >
        <AutoSelectInput optionText="name" />
      </ReferenceInput>
      <InputGuesser source="starterKitOrdersLimit" />
      <InputGuesser source="orderWeeklyLimit" />
      <SelectInput
        source="productFlow"
        choices={[
          { id: 'v1', name: 'v1: Midlayer flow' },
          { id: 'v2', name: 'v2: Pimcore' },
        ]}
        disabled
      />
      <InputGuesser source="pimcoreChannelId" />
      <SelectInput source="freshDeskGroup" choices={groups} resettable />
      <AutocompleteInput source="freshDeskAgent" choices={agents} resettable />
      <ReferenceInput
        source="currency"
        reference="currencies"
        format={(v) => (v ? v['@id'] || v : v)}
      >
        <AutoSelectInput optionText="code" />
      </ReferenceInput>
      <InputGuesser source="activeAntsLanguageId" />
      <InputGuesser source="activeAntsVatNumber" />
      <InputGuesser source="warehouse" />
    </EditGuesser>
  )
}
