import Icon from '@material-ui/icons/AllInclusive'
import { ROLES } from 'utils/user'
import { PimcorePropositionList } from './PimcorePropositionList'
import { PimcoreProductShow } from './PimcorePropositionShow'

export default {
  list: PimcorePropositionList,
  show: PimcoreProductShow,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
