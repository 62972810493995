import React, { useCallback, useState } from 'react'
import { Notification, defaultTheme, Login as BaseLogin } from 'react-admin'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import styled from 'styled-components'
import useApi from 'hooks/useApi'

const InputsWrapper = styled.div`
  padding: 0 1em 1em 1em;
`
const SubmitWrapper = styled.div`
  padding: 8px;
`

export const RecoverPasswordRequest = React.memo(() => {
  const { api, isSubmitting } = useApi()
  const [email, setEmail] = useState('')
  const submitEmail = useCallback(
    (e) => {
      e.preventDefault()
      const baseUrl = `${window.location.origin}/#/recover-password`
      api.requestPasswordRecovery({ email, baseUrl })
    },
    [email, api]
  )

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <BaseLogin
        // A random image that changes on every page load
        backgroundImage="https://picsum.photos/1600/900"
      >
        <form onSubmit={submitEmail}>
          <InputsWrapper>
            <div>
              <TextField
                id="email"
                label="Email"
                style={{ width: '100%' }}
                value={email}
                required
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </InputsWrapper>

          <SubmitWrapper>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isSubmitting}
            >
              reset password
            </Button>
          </SubmitWrapper>
        </form>
        <Notification />
      </BaseLogin>
    </ThemeProvider>
  )
})
