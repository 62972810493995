import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import { ROLES } from 'utils/user'

import { ClinicList } from './ClinicList'
import { ClinicEdit } from './ClinicEdit'
import { ClinicCreate } from './ClinicCreate'

export default {
  list: ClinicList,
  edit: ClinicEdit,
  create: ClinicCreate,
  icon: LocalHospitalIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_COUNTRY_MANAGER],
}
