import CustomTable from 'components/customTable'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import useApi from 'hooks/useApi'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { formatDate } from 'utils/string'

export const SelligentEmailsList = () => {
  const { customerId } = useCustomer()
  const [emails, setEmails] = useState([])
  const { api } = useApi()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    api.getSelligentEmails(customerId).then((res) => {
      const formattedEmails = res?.data?.map((item) => ({
        ...item,
        'MASTER.SENT_DT': formatDate(item['MASTER.SENT_DT']),
      }))
      setEmails(formattedEmails || [])
    })
  }, [customerId])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <CustomTable
      data={emails}
      headCells={[
        {
          rowKey: 'MASTER.SENT_DT',
          id: 'MASTER.SENT_DT',
          label: 'Mail date',
        },
        {
          rowKey: 'MASTER.CAMPAIGN_NAME',
          id: 'MASTER.CAMPAIGN_NAME',
          label: 'Campaign name',
        },
        {
          rowKey: 'MASTER.CAMPAIGN_CATEGORY',
          id: 'MASTER.CAMPAIGN_CATEGORY',
          label: 'Campaign category',
        },
        {
          rowKey: 'MASTER.MAIL_NAME',
          id: 'MASTER.MAIL_NAME',
          label: 'Mail name',
        },
      ]}
      selectable={false}
    />
  )
}
