import { isBeforeToday, parsePhoneMemoized, pluralize } from 'utils/string'

export const getAddonsFromSubscription = (subscription) => {
  if (!subscription) return []
  const addons = {}

  subscription.items.forEach((item) => {
    if (!item.quantity) return

    if (item.products) {
      item.products.forEach((product) => {
        const productQuantity = item.quantity * (product.quantity || 1)

        addons[product.chargeBeeId] = {
          id: product.chargeBeeId,
          unitPrice: Math.floor(Number(product.priceWithDiscount) * 100),
          quantity:
            (addons[product.chargeBeeId]?.quantity || 0) + productQuantity,
        }
      })
    } else {
      addons[item.chargeBeeId] = {
        id: item.chargeBeeId,
        unitPrice: Math.floor(Number(item.priceWithDiscount) * 100),
        quantity: (addons[item.chargeBeeId]?.quantity || 0) + item.quantity,
      }
    }
  })

  return Object.values(addons)
}

export const getSubscriptionsRequestBody = ({
  subscriptions,
  isInvoice,
  intervalsMap = {},
  coupon,
  shippingAddress,
  comment,
}) => {
  let invoiceHandled
  const requestBody = subscriptions
    .filter((item) => !!item?.plan)
    .map((subscription) => {
      const addons = getAddonsFromSubscription(subscription)
      if (addons.length) {
        if (!invoiceHandled && isInvoice) {
          for (let i = 0; i < subscription.items.length; i++) {
            const item = subscription.items[i]

            if (item.bundle) {
              const product = item.products.find(
                (product) => intervalsMap[product.interval]
              )

              if (product) {
                invoiceHandled = true
                addons.push({
                  id: intervalsMap[product.interval],
                  quantity: 1,
                })

                break
              }
            } else if (intervalsMap[item.interval]) {
              addons.push({ id: intervalsMap[item.interval], quantity: 1 })
              invoiceHandled = true
              break
            }
          }
        }

        let startDate = subscription.startDate

        if (isBeforeToday(new Date(startDate))) {
          startDate = null
        }

        return {
          planId: subscription.plan,
          addons,
          couponIds: coupon ? [coupon] : [],
          metaData: JSON.stringify(subscription),
          startDate: startDate ? new Date(startDate) / 1000 : null,
          cf_utm_source: localStorage.getItem('userEmail'),
          cf_utm_medium: 'cs_portal',
          shippingAddress,
        }
      }

      return null
    })
    .filter(Boolean)

  if (requestBody.length) {
    requestBody[0].comment = comment
  }

  return requestBody
}

export const getSessionSubscriptions = (subscriptions) => {
  return subscriptions?.map((subscription) => {
    const mappedItems = subscription.items.map((subscriptionItem) => ({
      bundle: subscriptionItem.bundle,
      quantity: subscriptionItem.quantity,
      variationId: subscriptionItem.variationId,
      id: subscriptionItem.id,
      products:
        subscriptionItem.products?.map((product) => ({
          mandatory: !!product.mandatory,
          variationId: product.variationId,
          productId: product.productId,
          quantity: product.quantity,
        })) || undefined,
      mandatory: subscriptionItem.mandatory,
      mandatoryVariation: subscriptionItem.mandatoryVariation,
    }))
    const validItems = mappedItems?.filter((item) =>
      item.bundle ? item.id : item.variationId
    )

    if (validItems.length !== mappedItems.length) {
      console.error('could not generate session subscription')
      return { startDate: null, items: [] }
    }
    return {
      startDate: subscription.startDate,
      items: validItems,
    }
  })
}

export const getPimcoreSubscriptions = (subscriptions = []) => {
  return subscriptions?.map((subscription) => {
    const mappedItems = subscription.items.map((subscriptionItem) => ({
      quantity: subscriptionItem.quantity,
      product: {
        id: subscriptionItem.product.id,
        interval: subscriptionItem.product.interval,
        products:
          subscriptionItem.product?.products?.map((product) => {
            const firstListing = product?.listing && product.listing[0]
            return {
              quantity: product.quantity,
              id: product.id || firstListing?.id,
              interval: product.interval || firstListing?.interval,
            }
          }) || undefined,
      },
    }))
    return {
      startDate: subscription.startDate,
      items: mappedItems,
    }
  })
}

export const convertPsiSubscriptionsToCartItems = (subscriptions) => {
  if (!subscriptions) return []
  const items = []

  for (let subscription of subscriptions) {
    for (let item of subscription.items) {
      items.push({
        ...item,
        interval: subscription.interval,
        startDate: subscription.startDate,
      })
    }
  }

  return items
}

export const convertCartItemsToSubscriptions = (cartItems) => {
  const subscriptions = []

  const intervalsMap = {}

  for (let item of cartItems) {
    if (!intervalsMap[item.interval]) intervalsMap[item.interval] = {}
    const startDate = item.startDate || null
    if (!intervalsMap[item.interval][startDate]) {
      intervalsMap[item.interval][startDate] = []
    }

    intervalsMap[item.interval][startDate].push(item)
  }

  for (const interval in intervalsMap) {
    if (!intervalsMap[interval]) continue

    for (const key in intervalsMap[interval]) {
      if (!intervalsMap[interval][key]) continue

      const startDate = key === 'null' ? null : key
      subscriptions.push({
        startDate,
        interval,
        items: intervalsMap[interval][key],
      })
    }
  }

  return subscriptions
}

const common = ['firstName', 'lastName', 'phone']
export const getCreateSubscriptionIssues = ({
  billingAddress,
  shippingAddress,
  personalInfo,
  customerMidlayerId,
  store,
}) => {
  const missing = []

  const checkFields = (obj, objName, properties) => {
    const missingForCurrentObject = []
    const invalidForCurrentObject = []

    for (let prop of properties) {
      if (!obj[prop]) {
        missingForCurrentObject.push(prop)
      } else if (prop === 'phone') {
        const parsedPhone = parsePhoneMemoized(obj[prop])

        if (!parsedPhone?.isValid()) {
          invalidForCurrentObject.push('phone')
        }
      }
    }

    if (missingForCurrentObject.length) {
      missing.push(`${objName}: ${missingForCurrentObject.join(', ')} missing`)
    }
    if (invalidForCurrentObject.length) {
      missing.push(`${objName}: ${invalidForCurrentObject.join(', ')} invalid`)
    }
  }

  if (!customerMidlayerId && !personalInfo) {
    // should not happen in real life scenario
    missing.push('both customerMidlayerId and personalInfo are missing')
  }

  if (!customerMidlayerId && personalInfo) {
    const personalFields = [...common, 'email']

    if (store.customerSurgeonEnabled && store.customerSurgeonRequired)
      personalFields.push('surgeonName')
    if (store.customerSurgeryTypeEnabled && store.customerSurgeryTypeRequired)
      personalFields.push('surgeryType')
    if (store.customerSurgeryDateEnabled && store.customerSurgeryDateRequired)
      personalFields.push('surgeryDate')
    if (store.customerClinicEnabled && store.customerClinicRequired)
      personalFields.push('clinic')

    checkFields(personalInfo, 'Personal info', personalFields)
  }

  const addressFields = [...common, 'line1', 'line2', 'city', 'zip', 'country']

  if (store.customerAddressLine3Required) addressFields.push('line3')
  if (store.customerStateRequired) addressFields.push('state')
  if (billingAddress && !customerMidlayerId) {
    checkFields(billingAddress, 'Billing address', addressFields)
  }
  checkFields(shippingAddress, 'Shipping address', addressFields)

  if (!missing.length) return null

  return `${missing.join('\n')}\n\n ${pluralize(
    missing.length,
    'issue'
  )} above must be fixed first.`
}
