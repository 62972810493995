import React, { useState } from 'react'
import { ReferenceManyField, FunctionField, TextField } from 'react-admin'
import { Switch, FormControlLabel } from '@material-ui/core'
import { DateField, DataGrid } from 'theme/components'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { LogCallModal } from 'components/logCallModal'
import styled from 'styled-components'
import { LongTextField } from 'theme/components/field/LongTextField'

export const MultilineTextField = styled(TextField)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  white-space: pre-wrap;
  max-width: 300px;
`

export const CALL_STATUSES = {
  1: 'Missed',
  2: 'Open',
  3: 'Pending',
  4: 'Resolved',
  5: 'Closed',
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const CallsList = ({ ListStats, reloadFullView, ...props }) => {
  const { customerId } = useCustomer()
  const [onlyFollowUps, setOnlyFollowUps] = useState(false)

  return (
    <>
      <Actions>
        <LogCallModal customerId={customerId} onSuccess={reloadFullView} />

        <FormControlLabel
          control={
            <Switch
              value={onlyFollowUps}
              color="primary"
              onChange={() => setOnlyFollowUps((cur) => !cur)}
            />
          }
          label="only followups"
        />
      </Actions>

      <ReferenceManyField
        addLabel={false}
        reference="customer-calls"
        target=""
        filter={{
          'customer.id': customerId,
          type: onlyFollowUps ? 1 : undefined,
          properties: ['description', 'createdAt', 'status', 'user'],
          'properties[reason]': ['name'],
          'properties[user]': ['email'],
        }}
        {...props}
      >
        <DataGrid
          showEmpty
          emptyText={
            <span style={{ fontSize: '20px' }}>no calls available</span>
          }
          rowClick="edit"
        >
          <DateField source="createdAt" label short={false} />
          <MultilineTextField source="reason.name" label="Reason" />
          <MultilineTextField source="description" label="Description" />
          <LongTextField source="user.email" label="Logged by" />
          <FunctionField
            label="Status"
            render={(record) => (
              <>
                {ListStats}
                {CALL_STATUSES[record.status]}
              </>
            )}
          />
        </DataGrid>
      </ReferenceManyField>
    </>
  )
}
