import RecentActorsIcon from '@material-ui/icons/RecentActors'

import { CustomerSourceList } from './CustomerSourceList'
import { CustomerSourceCreate } from './CustomerSourceCreate'
import { CustomerSourceEdit } from './CustomerSourceEdit'
import { ROLES } from 'utils/user'

export default {
  list: CustomerSourceList,
  create: CustomerSourceCreate,
  edit: CustomerSourceEdit,
  icon: RecentActorsIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
