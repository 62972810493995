import { useCustomer } from 'components/pages/customer/contexts/customer'
import React, { useEffect } from 'react'
import { TextField, ReferenceManyField } from 'react-admin'
import { DateField, DataGrid } from 'theme/components'

export const ShortFlowmailerEmailsList = ({
  ListStats,
  setTotal,
  ...props
}) => {
  const { customerId } = useCustomer()

  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  return (
    <ReferenceManyField
      addLabel={false}
      reference="email-logs"
      source="id"
      target=""
      filter={{
        'customer.id': customerId,
        properties: ['email', 'sendDate', 'subject'],
      }}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={() => {}}>
        {ListStats}
        <DateField source="sendDate" label="Sent on" />
        <TextField source="subject" />
      </DataGrid>
    </ReferenceManyField>
  )
}
