import React from 'react'
import PropTypes from 'prop-types'
import { ResourceToolbar } from './ResourceToolbar'

export const ResourceCreateToolbar = (props) => <ResourceToolbar {...props} />

ResourceCreateToolbar.defaultProps = {
  showDelete: false,
}

ResourceCreateToolbar.propTypes = {
  showDelete: PropTypes.bool,
}
