import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { Button, useNotify, useSaveContext } from 'react-admin'
import { getCheckoutBaseUrl } from 'utils/string'
import { useCurrentSession } from '../contexts/currentSession'
import { useStore } from '../contexts/store'
import { useForm } from 'react-final-form'
import { colors } from 'theme/MuiTheme'
import { useCustomer } from '../contexts/customer'

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

const StyledInput = styled.input`
  padding: 20px;
  margin: 10px 0;
`

const InfoBlock = styled.span`
  font-size: 15px;
  opacity: 0.9;
  border: 3px solid orange;
  border-radius: 8px;
  padding: 8px;
`

export const SendEmailDialog = () => {
  const { save, saving } = useSaveContext()
  const form = useForm()
  const { api, isSubmitting } = useApi()
  var formdata = form.getState().values
  const { session } = useCurrentSession()
  const notify = useNotify()
  const { store } = useStore()
  const { personalInfo } = useCustomer()
  const checkoutBaseUrl = getCheckoutBaseUrl(store.countryCode)

  const [confirmSendOpen, setConfirmSendOpen] = useState()

  useEffect(() => {
    window.onbeforeunload = () => {
      setConfirmSendOpen(false)
    }
  }, [])

  useEffect(() => {
    if (!session?.id) {
      setConfirmSendOpen(false)
    }
  }, [session])

  const email = useMemo(() => personalInfo?.email, [personalInfo])

  if (!session)
    return (
      <InfoBlock>
        The session is not yet created.
        <br />
        In order to send it to customer you have to first create the session.
        <br />
        (bottom right part of the page)
      </InfoBlock>
    )

  if (session?.completed || !email) return null
  const checkoutWithSession = `${checkoutBaseUrl}/${session?.originId}`

  return (
    <>
      {!!session && (
        <Button
          style={{ margin: '0' }}
          label="Send to customer"
          onClick={() =>
            save({ ...formdata }, false, {
              onSuccess: () => setConfirmSendOpen(true),
            })
          }
          size="medium"
          variant="contained"
          fullWidth
          disabled={saving || isSubmitting || confirmSendOpen}
        />
      )}
      <Dialog open={confirmSendOpen} onClose={() => setConfirmSendOpen(false)}>
        <DialogTitle>
          Are you sure you want to send a checkout session to{' '}
          <span style={{ color: colors.primary }}>{email}</span>?
        </DialogTitle>
        <DialogBody>
          <label>*link will be valid during some time</label>
          <StyledInput
            value={checkoutWithSession}
            onClick={(e) => {
              e.preventDefault()
              if (window.navigator.clipboard) {
                window.navigator.clipboard.writeText(checkoutWithSession).then(
                  () => notify('link was copied'),
                  () => notify('something went wrong')
                )
              }
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '20px',
            }}
          >
            <Button
              label="No"
              onClick={() => setConfirmSendOpen(false)}
              size="medium"
              variant="outlined"
              fullWidth
            />
            <Button
              label="Yes"
              disabled={isSubmitting}
              onClick={() => {
                api
                  .sendSessionToCustomer({
                    email: email,
                    sesssionId: session?.originId,
                    language: store.language || 'en',
                  })
                  .then(() => {
                    setConfirmSendOpen(false)
                  })
              }}
              variant="contained"
              fullWidth
            />
          </div>
        </DialogBody>
      </Dialog>
    </>
  )
}
