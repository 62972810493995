import React, { useCallback, useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import {
  useDataProvider,
  SimpleForm,
  TextInput,
  SelectInput,
  useNotify,
} from 'react-admin'
import styled from 'styled-components'
import useApi from 'hooks/useApi'

const ShippingAddressTitle = styled(DialogTitle)`
  text-align: center;
`

const FieldsHoledr = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 15px;
  width: 100%;

  .MuiFormControl-marginDense {
    width: 100% !important;
    margin-bottom: 0;
  }
`

const SimpleFormWrapper = styled(SimpleForm)`
  & .MuiCardContent-root {
    padding: 0;
  }
`

const ShippingAddressModal = ({
  open,
  storeId,
  subscription,
  setSubscription,
  onClose,
}) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { api } = useApi()
  const [countries, setCountries] = useState([])

  const fetchCountry = useCallback(async () => {
    const { data } = await dataProvider.getList('shipping-country-stores', {
      pagination: {},
      sort: {},
      filter: { itemsPerPage: 999, 'store.id': storeId },
    })

    setCountries(
      data.map((item) => {
        return { ...item, id: item?.country?.code, name: item?.name }
      })
    )
  }, [dataProvider, storeId])

  useEffect(() => {
    let mount = true

    if (mount) {
      fetchCountry()
    }

    return () => (mount = false)
  }, [fetchCountry])

  const countriesOptions = countries.map((item) => {
    return {
      id: item.id,
      name: item.name,
    }
  })

  const handleSubmit = (values) => {
    api
      .updateChargebeeSubscription(subscription.internal_id, {
        shippingAddress: values,
      })
      .then(() => {
        setSubscription((prev) => {
          return {
            ...prev,
            shipping_address: values,
          }
        })
        notify('changes saved', 'info')
        onClose()
      })
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={!!open} onClose={onClose}>
      <ShippingAddressTitle>Shipping address</ShippingAddressTitle>
      <DialogContent>
        <SimpleFormWrapper
          initialValues={{ ...subscription?.shipping_address }}
          save={handleSubmit}
        >
          <FieldsHoledr>
            <TextInput source="first_name" variant="outlined" />
            <TextInput source="last_name" variant="outlined" />
            <TextInput source="email" variant="outlined" />
            <TextInput source="phone" variant="outlined" />
            <TextInput source="line1" variant="outlined" label="Address 1" />
            <TextInput source="line2" variant="outlined" label="Address 2" />
            <TextInput source="city" variant="outlined" />
            <TextInput source="zip" variant="outlined" label="Postcode" />
            <SelectInput
              source="country"
              optionText="name"
              choices={countriesOptions}
              variant="outlined"
            />
          </FieldsHoledr>
        </SimpleFormWrapper>
      </DialogContent>
    </Dialog>
  )
}

export default ShippingAddressModal
