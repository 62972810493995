import GlobeIcon from '@material-ui/icons/Public'

import { CountryList } from './CountryList'
import { CountryCreate } from './CountryCreate'
import { CountryEdit } from './CountryEdit'
import { ROLES } from 'utils/user'

export default {
  list: CountryList,
  create: CountryCreate,
  edit: CountryEdit,
  icon: GlobeIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
