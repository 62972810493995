import React from 'react'

import {
  TextField,
  TextInput,
  Filter,
  FunctionField,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import { ListGuesser, ReferenceListFilterField } from 'theme/components'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput
      source="productStoreInterval.id"
      label="Product Store Interval Id"
    />
    <TextInput source="proposition.id" label="Proposition product Id" />
    <ReferenceArrayInput
      source="store.id"
      reference="stores"
      label="resources.store.field"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  </Filter>
)

export const PimcorePropositionFacadeList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
    rowClick="show"
    filter={{
      properties: ['id', 'chargeBeeId', 'store'],
      'properties[productStoreInterval]': ['id', 'name'],
      'properties[proposition]': ['id', 'name'],
    }}
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <FunctionField
      label="PSI Id and Name"
      render={(record) => {
        return record?.productStoreInterval
          ? `${record?.productStoreInterval?.id} - ${record?.productStoreInterval?.name}`
          : ''
      }}
    />
    <FunctionField
      label="Proposition Id and Name"
      render={(record) => {
        return record?.proposition
          ? `${record?.proposition?.id} - ${record?.proposition?.name}`
          : ''
      }}
    />
    <ReferenceListFilterField
      label="resources.store.field"
      listUri="/stores"
      titlePath="store.name"
      valuePath="store.name"
    />
  </ListGuesser>
)
