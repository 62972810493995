import { getNumberFromStringId } from 'utils/string'
import React, { createContext, useContext, useState, useEffect } from 'react'
import { useDataProvider } from 'react-admin'

const reasonsContext = createContext()

const ReasonsProvider = ({ children }) => {
  const dataProvider = useDataProvider()
  const [fetched, setFetched] = useState()
  const [postponeReasons, setPostponeReasons] = useState([])
  const [cancelReasons, setCancelReasons] = useState([])

  useEffect(() => {
    if (fetched) return
    setFetched(true)

    dataProvider
      .getList('postpone-reasons', {
        pagination: {},
        sort: {},
        filter: { properties: ['name', 'id'], itemsPerPage: 999 },
      })
      .then((res) => {
        setPostponeReasons(res?.data || [])
      })

    dataProvider
      .getList('cancellation-reasons', {
        pagination: {},
        sort: {},
        filter: { properties: ['name', 'parent'], itemsPerPage: 999 },
      })
      .then(({ data }) => {
        setCancelReasons(
          data
            .map(({ id, name, parent }) => ({
              id: getNumberFromStringId(id),
              name,
              parent: parent ? { id: parent.id, name: parent.name } : null,
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        )
      })
  }, [fetched, dataProvider])

  return (
    <reasonsContext.Provider value={{ postponeReasons, cancelReasons }}>
      {children}
    </reasonsContext.Provider>
  )
}

const useReasons = () => useContext(reasonsContext)

export { useReasons, ReasonsProvider }
