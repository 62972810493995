import React, { useState } from 'react'
import {
  useTranslate,
  TextInput,
  SimpleForm,
  useRedirect,
  required,
  RadioButtonGroupInput,
} from 'react-admin'
import { FormSpy } from 'react-final-form'

import { Redirect } from 'react-router'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import { CustomToolbar } from 'theme/components/toolbar/CustomToolbar'
import Button from '@material-ui/core/Button'
import DefaultSubMenuIcon from '@material-ui/icons/ChevronRight'
import CustomerIcon from '@material-ui/icons/AccountBox'
import { PaperCard } from 'theme/components'
import UserSession from 'service/UserSession'
import { hasRoles } from 'utils/user'
import ExactOnlineIcon from '@material-ui/icons/AccountTree'
import SearchIcon from '@material-ui/icons/Search'
import { menuItems } from 'theme/menu'
import { camelCaseToWords } from 'utils/string'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledButton = styled(Button)`
  margin-right: 10px !important;
  margin-bottom: 10px !important;
`

const BtnWrapper = styled.span`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const TextLabel = styled.span`
  margin-top: 7px;
`

export const customerLinks = [
  {
    icon: CustomerIcon,
    label: 'Single Order',
    resource: 'customers',
    defaultSearch: `filter=${JSON.stringify({ single_order: true })}`,
  },
  {
    icon: CustomerIcon,
    label: 'Recurring subscription',
    resource: 'customers',
    defaultSearch: `filter=${JSON.stringify({ recurring_subscription: true })}`,
  },
  {
    icon: CustomerIcon,
    label: 'Starter kit only',
    resource: 'customers',
    defaultSearch: `filter=${JSON.stringify({ starter_kit_only: true })}`,
  },
]

export const ButtonTile = ({ item }) => {
  const translate = useTranslate()

  return (
    <StyledButton
      key={item.resource}
      component={Link}
      to={{
        pathname: `/${item.resource}`,
        search: item.defaultSearch,
      }}
      variant="outlined"
      size="small"
    >
      <BtnWrapper>
        {item.icon ? <item.icon /> : <DefaultSubMenuIcon />}
        <TextLabel>{translate(item.label)}</TextLabel>
      </BtnWrapper>
    </StyledButton>
  )
}
export const Dashboard = () => {
  const location = useLocation()
  const { roles } = UserSession.getUser()
  const redirect = useRedirect()
  const items = menuItems(roles)
  const { hasAdminRole, hasFinanceRole } = hasRoles(roles)
  const [filter, setFilter] = useState('email')

  if (location.pathname === '/') return <Redirect to="/dashboard" />

  return (
    <PaperCard title={`Welcome, ${UserSession.getUser().name}`} square={false}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <SimpleForm
          style={{
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            borderRadius: '8px',
          }}
          resource="customers"
          submitOnEnter
          onSubmitCapture={(e) => e.preventDefault()}
          toolbar={
            <CustomToolbar
              saveButtonProps={{
                icon: <SearchIcon />,
                label: 'find customer',
              }}
            />
          }
          redirect={false}
          save={({ text, filter }) =>
            redirect(
              `/customers?filter=${JSON.stringify({
                [filter]: text.replace(/\+/g, '%2B'),
              })}`
            )
          }
          margin="none"
          initialValues={{ filter: 'email' }}
        >
          <FormSpy
            subscription={{ values: true }}
            onChange={({ values }) => setFilter(values.filter)}
          />

          <TextInput
            validate={required()}
            fullWidth
            source="text"
            label={`Search by ${camelCaseToWords(filter)}`}
          />

          <RadioButtonGroupInput
            source="filter"
            row
            fullWidth
            choices={[
              { id: 'email', name: 'Email' },
              { id: 'phone', name: 'Phone' },
              { id: 'zip', name: 'Zip' },
              { id: 'lastName', name: 'Last name' },
              { id: 'firstName', name: 'First name' },
              { id: 'all', name: 'all (🐌)' },
            ]}
          />
        </SimpleForm>

        <PaperCard title="resources.dashboard.title" square={false}>
          <Wrapper>
            {(hasAdminRole || hasFinanceRole) && (
              <ButtonTile
                item={{
                  resource: 'exact-online',
                  label: 'resources.exactOnline.title',
                  icon: ExactOnlineIcon,
                }}
              />
            )}

            {items?.map((item) => (
              <ButtonTile
                item={item}
                key={[item.resource, item.label].join('-')}
              />
            ))}
          </Wrapper>
        </PaperCard>
        <PaperCard title="Call list" square={false}>
          <Wrapper>
            {customerLinks.map((item) => (
              <ButtonTile
                item={item}
                key={[item.resource, item.label].join('-')}
              />
            ))}
          </Wrapper>
        </PaperCard>
      </div>
    </PaperCard>
  )
}
