import React, { useCallback, useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { Button, useNotify, useRedirect, useSaveContext } from 'react-admin'
import { useCurrentSession } from '../contexts/currentSession'
import { useCustomer } from '../contexts/customer'
import { useForm } from 'react-final-form'
import { useStore } from '../contexts/store'
import { colors } from 'theme/MuiTheme'
import { getCreateSubscriptionIssues } from './utils'

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

export const CreateSubscriptionPimcoreDialog = ({ totalPrice }) => {
  const {
    shippingAddress,
    customerMidlayerId,
    hasValidPaymentMethod,
    personalInfo,
  } = useCustomer()
  const { store } = useStore()
  const { session } = useCurrentSession()
  const { api, isSubmitting } = useApi()
  const form = useForm()
  const { save, saving } = useSaveContext()
  var formdata = form.getState().values
  const notify = useNotify()
  const redirect = useRedirect()
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    window.onbeforeunload = () => setConfirmOpen(false)
    return () => setConfirmOpen(false)
  }, [])

  const handleCreateSubscriptions = useCallback(() => {
    api.createPimcoreSubscription(session.originId).then(() => {
      notify('subscription created')
      redirect('/checkout-sessions')
    })
  }, [api, redirect, notify, session])

  const canCreateForExisting = customerMidlayerId && hasValidPaymentMethod
  const canCreateForNew = totalPrice === 0
  const canCreate = canCreateForExisting || canCreateForNew

  const email = useMemo(() => session?.data?.customerEmail, [session])

  if (!session || session?.completed || !canCreate) {
    return null
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        fullWidth
        label="Create subscription"
        type="button"
        onClick={() => {
          if (!canCreate) return
          const issues = getCreateSubscriptionIssues({
            shippingAddress,
            personalInfo,
            customerMidlayerId,
            store,
          })

          if (!issues) {
            save({ ...formdata }, false, {
              onSuccess: () => setConfirmOpen(true),
            })
          } else {
            notify(issues, { type: 'error', multiLine: true })
          }
        }}
        disabled={saving || isSubmitting || confirmOpen}
      />

      <Dialog open={!!confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          Are you sure you want to create a Pimcore Subscription for Customer{' '}
          <span style={{ color: colors.primary }}>{email}</span>?
        </DialogTitle>
        <DialogBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '20px',
            }}
          >
            <Button
              label="No"
              onClick={() => setConfirmOpen(false)}
              size="medium"
              variant="outlined"
              fullWidth
            />
            <Button
              label="Yes"
              disabled={isSubmitting}
              onClick={handleCreateSubscriptions}
              variant="contained"
              fullWidth
            />
          </div>
        </DialogBody>
      </Dialog>
    </>
  )
}
