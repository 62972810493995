import React, { createContext, useContext } from 'react'

const customerContext = createContext()

const CustomerProvider = ({ children, customer }) => {
  return (
    <customerContext.Provider value={customer}>
      {children}
    </customerContext.Provider>
  )
}

const useCustomer = () => useContext(customerContext)

export { useCustomer, CustomerProvider }
