import React, { useMemo } from 'react'
import { FieldGuesser } from '@api-platform/admin'
import {
  AutocompleteInput,
  TextField,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  NumberInput,
  TextInput,
  FunctionField,
  Button,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import UserSession from 'service/UserSession'
import { hasRoles } from 'utils/user'
import ResendButton from 'components/resendButton'
import { getNumberFromStringId } from 'utils/string'
import useApi from 'hooks/useApi'
import { TrackOrderButton } from './TrackOrderButton'
import styled from 'styled-components'

const CustomerButton = styled(Button)`
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 16px;
  font-size: 0.8125rem;
  text-transform: capitalize;

  .MuiButton-label span {
    padding: 0 12px;
  }
`

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput
      source="store.id"
      reference="stores"
      label="resources.store.field"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="chargeBeeStatus.id"
      reference="charge-bee-order-statuses"
      label="resources.order.chargeBeeStatus"
      perPage={500}
    >
      <SelectArrayInput optionText="slug" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="montaStatus.id"
      reference="monta-order-statuses"
      label="resources.order.fulfillmentStatus"
      perPage={500}
    >
      <SelectArrayInput optionText="slug" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="status.id"
      reference="order-statuses"
      label="resources.order.status"
      perPage={500}
    >
      <SelectArrayInput optionText="display" />
    </ReferenceArrayInput>
    <NumberInput label="Id" source="id" />
    <TextInput label="resources.order.chargeBee" source="chargeBeeId" />
    <TextInput label="resources.order.table.orderNumber" source="displayName" />
    <ReferenceInput
      source="customer.id"
      reference="customers"
      label="Customer"
      perPage={500}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
  </Filter>
)

const OrdersBulkActions = (props) => {
  const ids = props?.selectedIds.map(getNumberFromStringId)
  return <ResendButton resource="orders" ids={ids} />
}

export const OrderList = (props) => {
  const { roles } = UserSession.getUser()
  const { hasAdminRole } = useMemo(() => hasRoles(roles), [roles])
  const { api } = useApi()

  return (
    <ListGuesser
      {...props}
      filters={<ListFilter />}
      hasEdit={false}
      filter={{
        properties: [
          'id',
          'chargeBeeId',
          'description',
          'createdAt',
          'updatedAt',
          'displayName',
          'shippingPriority',
        ],
        'properties[chargeBeeStatus]': ['id', 'slug'],
        'properties[status]': ['id', 'display'],
        'properties[montaStatus]': ['id', 'slug'],
        'properties[store]': ['id', 'name'],
        'properties[customer]': ['id', 'email', 'firstName', 'lastName'],
      }}
      rowClick="show"
      bulkActionButtons={<OrdersBulkActions />}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <TextField
        source="displayName"
        label="resources.order.table.orderNumber"
      />
      <FieldGuesser source="chargeBeeId" label="resources.order.chargeBee" />
      <TextField source="status.display" label="resources.orderStatus.field" />
      <TextField
        source="chargeBeeStatus.slug"
        label="resources.chargeBeeStatus.field"
      />
      <FunctionField
        source="shippingPriority"
        label="Priority"
        render={(record) => (record.shippingPriority ? 'yes' : 'no')}
      />
      <TextField
        source="montaStatus.slug"
        label="resources.fulfillmentStatus.field"
      />
      <FunctionField
        label="Customer"
        render={(record) => {
          const url = new URL(process.env.PUBLIC_URL, window.location.href)
          const params = new URLSearchParams(url.search)
          params.set('displayedFilters', '{"all":true}')
          params.set('filter', `{"id": "${record.customer.id}"}`)
          params.set('order', 'ASC')
          params.set('page', '2')
          params.set('perPage', '20')
          url.search = params.toString()
          url.pathname = '/customers'

          return (
            <CustomerButton
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                window
                  .open(`${url.origin}/#${url.pathname}${url.search}`, '_blank')
                  .focus()
              }}
              label={`${record.customer.firstName} ${record.customer.lastName}`}
            />
          )
        }}
      />
      {hasAdminRole ? (
        <ReferenceListFilterField
          label="resources.store.field"
          listUri="/stores"
          titlePath="store.name"
          valuePath="store.name"
        />
      ) : (
        <TextField source="store.name" label="resources.store.field" />
      )}
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <FunctionField
        render={(record) => <TrackOrderButton api={api} orderId={record.id} />}
      />
    </ListGuesser>
  )
}
