import Icon from '@material-ui/icons/AllInclusive'
import { ROLES } from 'utils/user'
import { PimcoreProductList } from './PimcoreProductList'
import { PimcoreProductShow } from './PimcoreProductShow'

export default {
  list: PimcoreProductList,
  show: PimcoreProductShow,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
