import { getUrlParams } from 'utils/uri'

const CURRENCY = {
  DE: '/api/currencies/1',
  PL: '/api/currencies/2',
  CHDE: '/api/currencies/3',
  USA: '/api/currencies/4',
}

const getCurrency = (countryCode) => {
  return CURRENCY[countryCode] || CURRENCY.DE
}

const generateProductStoreIntervalData = ({ productStores, intervals }) => {
  let result = []

  productStores.forEach((productStore) => {
    intervals.forEach((interval) => {
      const qty = 1

      result = [
        ...result,
        {
          productStore: productStore['@id'],
          invoiceName: `${productStore.product.name} - ${interval.name}`,
          currency: getCurrency(productStore.store.countryCode),
          pricingModel: '/api/pricing-models/1',
          name: `${productStore.product.name} (${interval.slug}-${qty}P)`,
          interval: interval.id ? `/api/intervals/${interval.id}` : null,
          qty,
          price: '',
          shippable: true,
          enabledInPortal: true,
        },
      ]
    })
  })

  return result
}

const missingProductStoreHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  { id: 'name', numeric: false, disablePadding: false, label: 'Store' },
  {
    id: 'countryCode',
    numeric: false,
    disablePadding: false,
    label: 'Country code',
  },
  {
    id: 'skuPrefix',
    numeric: false,
    disablePadding: false,
    label: 'SKU prefix',
  },
]

const missingPsiHeadCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  {
    id: 'period',
    numeric: false,
    disablePadding: false,
    label: 'Period',
  },
  {
    id: 'unit',
    numeric: false,
    disablePadding: false,
    label: 'Unit',
  },
]

const groupHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: 'Default image',
  },
  {
    id: 'store',
    numeric: false,
    disablePadding: true,
    label: 'Store',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
]

const planHeadCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'image',
    numeric: false,
    disablePadding: true,
    label: 'Default image',
  },
  {
    id: 'store',
    numeric: false,
    disablePadding: true,
    label: 'Store',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
  },
]

const PSI_SCREENS = {
  MISSED: 'missed',
  CONFIRMATION: 'confirmation',
}

const PLAN_SCREENS = {
  ADDED_PLANS: 'addedPlans',
  ADD_PLAN: 'addPlan',
}

const GROUP_SCREENS = {
  ADDED_GROUPS: 'addedGroups',
  ADD_GROUP: 'addGroup',
}

const ADDON_SCREENS = {
  ADDED_ADDONS: 'addedAddons',
  ADD_ADDON: 'addAddon',
}

const ADDON_TYPE = {
  PLAN: 'plan',
  MAIN: 'main',
}

const reloadPage = () =>
  setTimeout(() => {
    window.location.reload()
  }, 2500)

const getInitialPsiFromUrl = () => ({
  id: getUrlParams().psiId,
  name: getUrlParams().psiName,
  productStore: {
    store: {
      name: getUrlParams().storeName,
      redirectUrl: getUrlParams().storeRedirectUrl,
    },
  },
})

const getInitialPlanFromUrl = () => ({
  id: getUrlParams().planId,
  chargeBeeId: getUrlParams().planName,
})

const getInitialGroupFromUrl = () => ({
  id: getUrlParams().groupId,
  name: getUrlParams().groupName,
})

const getInitialGroupStoreFromUrl = (productStores) =>
  getUrlParams().storeId
    ? productStores.find((ps) => ps.store['@id'] === getUrlParams().storeId)
        .store
    : productStores[0].store

const getInitialGroupNameFromUrl = (productStores) =>
  getUrlParams().storeId
    ? productStores.find((ps) => ps.store['@id'] === getUrlParams().storeId)
        .product.name
    : productStores[0].product.name

export {
  missingProductStoreHeadCells,
  missingPsiHeadCells,
  groupHeadCells,
  planHeadCells,
  PSI_SCREENS,
  PLAN_SCREENS,
  GROUP_SCREENS,
  ADDON_SCREENS,
  ADDON_TYPE,
  generateProductStoreIntervalData,
  reloadPage,
  getInitialPsiFromUrl,
  getInitialPlanFromUrl,
  getInitialGroupFromUrl,
  getInitialGroupStoreFromUrl,
  getInitialGroupNameFromUrl,
}
