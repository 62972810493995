import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, useNotify } from 'react-admin'
import { formatDateForAPI } from 'utils/string'
import useApi from 'hooks/useApi'
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from '@material-ui/core'
import { useReasons } from '../../contexts/reasons'
import { useSubscriptions } from '../../contexts/subscriptions'

const DateRow = styled.div`
  display: flex;
  gap: 16px;
`

const DatesList = styled.div`
  display: flex;
  gap: 8px;
`

const SaveButton = styled(Button)`
  span {
    padding: 0;
  }
`

const ReasonField = styled(FormControl)`
  width: 200px;
`

const DateField = styled(TextField)`
  width: 200px;
`

export const SubscriptionDates = ({ subscription }) => {
  const notify = useNotify()
  const { api } = useApi()
  const { postponeReasons } = useReasons()
  const { refetchSubscriptions } = useSubscriptions()
  const [startDate, setStartDate] = useState(null)
  const [shippingDate, setShippingDate] = useState()
  const [reason, setReason] = useState('')
  const [shippingChanged, setShippingChanged] = useState(false)
  const [startChanged, setStartChanged] = useState(false)

  useEffect(() => {
    if (subscription.current_term_start) {
      setStartDate(
        formatDateForAPI(new Date(subscription.current_term_start * 1000))
      )
    } else if (subscription?.start_date) {
      setStartDate(formatDateForAPI(new Date(subscription.start_date * 1000)))
    }

    if (subscription.next_billing_at) {
      setShippingDate(formatDateForAPI(subscription.next_billing_at * 1000))
    }
  }, [subscription])

  const handleSaveStartDate = () => {
    const minutesToAdd = 15
    const currentDate = new Date()
    const futureDate = Math.floor(
      (currentDate.getTime() + minutesToAdd * 60000) / 1000
    )
    const isImmediately =
      startDate === formatDateForAPI(new Date().toISOString())

    const changes = {
      startDate: isImmediately
        ? futureDate
        : new Date(startDate).getTime() / 1000,
    }

    if (subscription.meta_data) {
      const metaData = Object.assign(subscription.meta_data, {
        startDate: isImmediately
          ? futureDate
          : new Date(startDate).getTime() / 1000,
      })
      changes.meta_data = JSON.stringify(metaData)
    }

    api
      .updateChargebeeSubscription(subscription.internal_id, changes)
      .then(() => {
        notify(
          'Subscription start date has been updated, it might take a while for changes to apply.',
          'info'
        )
        setStartChanged(false)
        refetchSubscriptions()
      })
  }

  const handleSaveShippingDate = () => {
    api
      .updateCustomerSubscriptionNextShippingDate(
        subscription.internal_id,
        shippingDate,
        reason
      )
      .then(() => {
        notify(
          'Subscription shipping date has been updated, it might take a while for changes to apply.',
          'info'
        )
        setReason('')
        setShippingChanged(false)
        refetchSubscriptions()
      })
  }

  const currentTimestamp = new Date().getTime()
  const startDateTimestamp = new Date(startDate).getTime()

  const isFutureSubscription = startDateTimestamp > currentTimestamp
  const canEditStartDate = isFutureSubscription
  const canEditShippingDate =
    !isFutureSubscription &&
    ['in_trial', 'active', 'non_renewing'].includes(subscription.status)

  return (
    <DatesList>
      <DateRow>
        <DateField
          size="small"
          variant="outlined"
          label="Start date"
          type="date"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: { min: formatDateForAPI(new Date()) },
          }}
          value={startDate || null}
          onChange={(e) => {
            e.persist()
            setStartDate(e.target.value)
            setStartChanged(true)
          }}
          disabled={!canEditStartDate}
        />
        {startChanged && (
          <SaveButton
            color="primary"
            variant="contained"
            label="Save"
            onClick={handleSaveStartDate}
          />
        )}
      </DateRow>
      {shippingDate !== undefined && (
        <DateRow>
          <DateField
            size="small"
            variant="outlined"
            label="Next shipping date"
            type="date"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputProps: {
                min: formatDateForAPI(new Date()),
              },
            }}
            value={shippingDate || null}
            onChange={(e) => {
              e.persist()
              setShippingDate((cur) => {
                if (cur !== e.target.value) {
                  setShippingChanged(true)
                }
                return e.target.value || null
              })
            }}
            disabled={!canEditShippingDate}
          />
          {shippingChanged && (
            <>
              <ReasonField size="small" variant="outlined">
                <InputLabel>Postpone Reason</InputLabel>
                <Select
                  value={reason}
                  label="Postpone Reason"
                  onChange={(e) => setReason(e.target.value)}
                >
                  {postponeReasons?.map(({ id, name }) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </ReasonField>{' '}
              <SaveButton
                color="primary"
                variant="contained"
                label="Save"
                onClick={handleSaveShippingDate}
              />
            </>
          )}
        </DateRow>
      )}
    </DatesList>
  )
}
