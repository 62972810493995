import React from 'react'
import { DateField as RADateField } from 'react-admin'
import Tooltip from '@material-ui/core/Tooltip'
import get from 'lodash/get'
import { formatDateTime } from 'utils/string'
import PropTypes from 'prop-types'

const displayOptions = {
  full: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  short: { year: 'numeric', month: 'short', day: 'numeric' },
}

const sanitizeProps = (props) => {
  if (!props.options) {
    props.options = props.short ? displayOptions.short : displayOptions.full
  }

  delete props.short

  return props
}

export const DateField = ({ record, source, locales, options, short }) => {
  const sanitizedProps = sanitizeProps({
    source,
    record,
    options,
    locales,
    short,
  })

  if (!record) {
    return null
  }

  // User custom display options or full view
  if (options || !short) {
    return <RADateField {...sanitizedProps} />
  }

  const datetime = get(record, source)

  if (!datetime) {
    return null
  }

  let dateString = formatDateTime(datetime, locales, displayOptions.full)

  return (
    <Tooltip title={dateString}>
      <span style={{ whiteSpace: 'nowrap' }}>
        <RADateField {...sanitizedProps} />
      </span>
    </Tooltip>
  )
}

DateField.defaultProps = {
  locales: 'en',
  short: true,
  options: null,
}

DateField.propTypes = {
  short: PropTypes.bool,
}
