import React from 'react'
import styled from 'styled-components'
import { snakeCaseToWords } from 'utils/string'
import { ListItem } from '../commonComponents'
import { useRecordContext } from 'react-admin'

const billingFields = [
  'first_name',
  'last_name',
  'phone',
  'line1',
  'line2',
  'line3',
  'zip',
  'city',
  'country',
  'state',
]

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
`

export const ShortAddressesEdit = () => {
  const record = useRecordContext()

  if (!record) return '...'
  return (
    <Wrapper>
      {!!record.data?.billing_address && (
        <List>
          {billingFields.map((key) => (
            <ListItem
              key={key}
              title={snakeCaseToWords(key)}
              value={record.data.billing_address[key]}
            />
          ))}
        </List>
      )}
    </Wrapper>
  )
}
