// for multiple requests

let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

export const AUTHORIZATION_TOKEN_ERRORS = [
  'authorization.token_not_found',
  'authorization.token_expired',
]

const interceptor = (axiosInstance, refreshApiToken) => async (error) => {
  const _axios = axiosInstance
  const originalRequest = error.config

  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true

    return new Promise((resolve, reject) => {
      refreshApiToken()
        .then(async ({ data }) => {
          processQueue(null, data.token)
          resolve(_axios(originalRequest))
        })
        .catch((err) => {
          console.error(err)
          processQueue(err, null)
          reject(err)

          window.location.reload()
        })
    })
  }

  return Promise.reject(error)
}

export default interceptor
