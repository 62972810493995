import React from 'react'
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from '@api-platform/admin'
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation'
import { Redirect, Route } from 'react-router-dom'
import { immutable } from 'config/fetchHydra'
import pickBy from 'lodash/pickBy'
import { AUTHORIZATION_TOKEN_ERRORS } from 'service/Api/auth.interceptor'
import { BASE_API_URL } from 'service/Api/routes'
import { api } from 'service/Api'
import { clearAuth } from './authProvider'

const entrypoint = BASE_API_URL

const fetchHydra = (url, options = {}) => {
  // Remove from body immutable fields
  if (options.body) {
    options.body = JSON.stringify(
      pickBy(JSON.parse(options.body), (value, key) => !immutable.includes(key))
    )
  }

  return baseFetchHydra(url, {
    ...options,
    credentials: 'include',
    withCredentials: true,
  })
}

export const apiDocumentationParser = (entrypoint) =>
  parseHydraDocumentation(entrypoint, {
    credentials: 'include',
  }).then(
    // DataProvider customization
    ({ api }) => {
      return { api }
    },
    async (result) => {
      // 401 Error handling
      switch (result.status) {
        case 401:
          // eslint-disable-next-line no-case-declarations
          const responseData = await result.response.json()
          if (
            responseData &&
            responseData.origin &&
            AUTHORIZATION_TOKEN_ERRORS.includes(responseData.origin)
          ) {
            await api.refreshApiToken().catch(() => {
              clearAuth()
            })
          } else {
            clearAuth()
          }

          /* eslint-disable */
          return Promise.resolve({
            api: result.api,
            /* eslint-disable react/jsx-key */
            customRoutes: [
              <Route
                path="/"
                render={() => {
                  window.localStorage.getItem('authenticated') ? (
                    window.location.reload()
                  ) : (
                    <Redirect to="/login" />
                  )
                }}
              />,
            ],
            /* eslint-enable react/jsx-key */
          })

        default:
          return Promise.reject(result)
      }
    }
  )
const customDataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser,
  true
)

const fieldsToTrim = ['firstName', 'lastName', 'all']

const trimTrailingSpaces = (params) => {
  if (!params.filter) return
  fieldsToTrim.forEach((field) => {
    if (!params.filter[field]) return
    params.filter[field] = params.filter[field].replace(/\s+$/, '')
  })
  return params
}

export const dataProvider = {
  ...customDataProvider,
  getList: async (resource, params) => {
    const modifiedParams = trimTrailingSpaces(params)
    return customDataProvider.getList(resource, modifiedParams)
  },
}
