import React, { createContext, useContext } from 'react'

const subscriptionsContext = createContext()

const SubscriptionsProvider = ({
  children,
  subscriptions,
  setSubscriptions,
  coupon,
  setCoupon,
  subscriptionsFetching,
  setSubscriptionsFetching,
}) => {
  return (
    <subscriptionsContext.Provider
      value={{
        subscriptions,
        setSubscriptions,
        coupon,
        setCoupon,
        subscriptionsFetching,
        setSubscriptionsFetching,
      }}
    >
      {children}
    </subscriptionsContext.Provider>
  )
}

const useSubscriptions = () => useContext(subscriptionsContext)

export { useSubscriptions, SubscriptionsProvider }
