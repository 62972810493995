// used with checkout endpoints

const sourcesMap = {
  'admin.fitformedev.nl': process.env.REACT_APP_MIDLAYER_CHECKOUT_TOKEN,
  'checkout-tst.fitformedev.nl':
    process.env.REACT_APP_MIDLAYER_CHECKOUT_TOKEN_TST,
  'myffm-local.fitformedev.nl':
    process.env.REACT_APP_MIDLAYER_CHECKOUT_TOKEN_LOCAL,
}

export const token = sourcesMap[window.location.hostname] || 'test'
