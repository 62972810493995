import Icon from '@material-ui/icons/Storage'

import { ProductCreate } from './ProductCreate'
import { ProductList } from './ProductList'
import { ProductEditPage } from './ProductEdit'
import { ROLES } from 'utils/user'

export default {
  create: ProductCreate,
  list: ProductList,
  edit: ProductEditPage,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
