import React, { useEffect, useState } from 'react'
import { SelectInput } from 'react-admin'

export const AutoSelectInput = (props) => {
  const { choices, input, disabled } = props
  const [autoselected, setAutoselected] = useState(false)

  useEffect(() => {
    if (choices.length === 1 && !input.value) {
      input.onChange(choices[0].id)
      setAutoselected(true)
    }

    if (choices.length > 1) {
      setAutoselected(false)
    }
  }, [choices, input])

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      input.onChange(null)
      setAutoselected(false)
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  if (choices.length === 1 && !props.input.value) {
    props = { ...props, input: { ...props.input, value: choices[0].id } }
  }

  if (!choices.length)
    return (
      <div style={{ marginBottom: '20px' }}>
        no {input.name} options available
      </div>
    )

  if (!props.input.value) {
    props = { ...props, input: { ...props.input, value: '' } }
  }

  return <SelectInput {...props} disabled={disabled || autoselected} />
}
