import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'

import useApi from 'hooks/useApi'
import { getUrlParams, parseUrlIdsParam } from 'utils/uri'
import { GROUP_SCREENS, reloadPage } from '../utils'

export default (
  productId,
  addedProductStores,
  addedProductStoreIntervals,
  setLoading
) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const { api, handleError } = useApi()

  const [addedGroups, setAddedGroups] = useState([])
  const [screen, setScreen] = useState(
    getUrlParams().screen || GROUP_SCREENS.ADDED_GROUPS
  )

  const onAddGroupClick = useCallback(() => {
    setScreen(GROUP_SCREENS.ADD_GROUP)
  }, [])

  const onAddGroupSubmit = useCallback(
    async (values, fileData) => {
      try {
        const body = {
          ...values,
          product: productId,
        }

        const groupData = await api.createGroup(body)

        if (fileData) {
          await api.createGroupImage({ ...fileData, group: groupData['@id'] })
        }

        notify('Success', 'info')
        reloadPage()
        redirect(`/products/${encodeURIComponent(productId)}/4`)
        setLoading(true)
      } catch (error) {
        handleError(error)
      }
    },
    [api, notify, setLoading, redirect, productId, handleError]
  )

  const fetchGroups = useCallback(async () => {
    const { data } = await dataProvider.getList('groups', {
      pagination: {},
      sort: {},
      filter: {
        itemsPerPage: 999,
        'store.id': parseUrlIdsParam(
          addedProductStores.map((item) => item.store['@id'])
        ),
        'product.id': productId,
        properties: ['id', 'name', 'createdAt', 'updatedAt'],
        'properties[store]': ['id', 'name'],
        'properties[image]': ['file'],
        'properties[image][file]': ['data'],
      },
    })

    setAddedGroups(data)
  }, [dataProvider, addedProductStores, productId])

  const onBackClickGroups = useCallback(() => {
    redirect(`/products/${encodeURIComponent(productId)}/3`)

    setScreen(GROUP_SCREENS.ADDED_GROUPS)
  }, [productId, redirect])

  const addedGroupIds = useMemo(() => addedGroups.map((item) => item['@id']), [
    addedGroups,
  ])

  useEffect(() => {
    if (addedProductStores.length && addedProductStoreIntervals.length) {
      fetchGroups()
    }
  }, [addedProductStoreIntervals, addedProductStores, fetchGroups])

  return {
    screen,
    addedGroups,
    addedGroupIds,
    onAddGroupClick,
    onAddGroupSubmit,
    onBackClickGroups,
  }
}
