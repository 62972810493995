import React, { useCallback, useState, useMemo, useEffect } from 'react'
import {
  ReferenceInput,
  AutocompleteInput,
  SimpleForm,
  Button,
  required,
} from 'react-admin'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getNumberFromStringId, getProductVariationTitle } from 'utils/string'
import { AutoSelectInput } from 'theme/components/input/AutoSelectinput'
import useApi from 'hooks/useApi'
import { getApiRoute } from 'service/Api/routes'
import { getFormattedResponse } from 'utils/hydra'
import { LinearProgress, Dialog } from '@material-ui/core'
import { StartDateInput } from 'components/startDateInput'

const VariableProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid gray;
  margin: 4px;
  border-radius: 8px;
  overflow: hidden;
`

const VariableProductTitle = styled.span`
  margin: 20px 0 0 20px;
`

const Wrapper = styled.div`
  padding: 0 4px;
`

const getVariationStoreId = (id) => `/api/wp-product-variation-stores/${id}`

export const AddPsiBundleModal = ({
  open,
  onClose,
  addItem,
  storeId,
  intervalSlug,
  showStartDateInput,
  initialBundle,
  initialProducts,
}) => {
  const [bundleId, setBundleId] = useState(initialBundle)

  const [productsMap, setProductsMap] = useState({})
  const [bundle, setBundle] = useState()
  const [bundleLoading, _setBundleLoading] = useState(false)
  const [startDate, setStartDate] = useState()

  const setBundleLoading = useCallback((val) => {
    if (val) {
      _setBundleLoading(true)
    } else {
      setTimeout(() => _setBundleLoading(false), 800)
    }
  }, [])

  const { api } = useApi()

  useEffect(() => {
    if (!bundleId) {
      setBundle(null)
      return
    }
    if (bundle && bundleId === bundle['@id']) return

    setBundleLoading(true)

    api
      .get(getApiRoute('bundles'), {
        itemsPerPage: 1,
        id: bundleId,
        properties: ['id', 'name', 'interval'],
        'properties[products][product]': ['name', 'id'],
        'properties[products][quantityMin]': '*',
      })
      .then((res) => {
        const { items } = getFormattedResponse(res)
        setBundle(items[0])
        setBundleLoading(false)
      })
  }, [api, bundleId, bundle, setBundleLoading])

  const wpProductVariationStoresMap = useSelector(
    (state) => state.admin.resources['wp-product-variation-stores']?.data
  )

  const handleWpProductVariationStoreFormChange = useCallback(
    (values, bundleProductId, quantity) => {
      if (!values.wpProductVariationStore) {
        return {
          wpProductVariationStore: 'wpProductVariationStore is required',
        }
      }

      const variationId = getNumberFromStringId(values.wpProductVariationStore)

      if (open) {
        if (productsMap[bundleProductId]?.variationId !== variationId) {
          setProductsMap((current) => ({
            ...current,
            [bundleProductId]: {
              variationId,
              productId: bundleProductId,
              quantity,
            },
          }))
        }
      }
    },
    [productsMap, open]
  )

  useEffect(() => {
    if (!open) {
      setStartDate()
      setProductsMap({})
      setBundleId()
      setBundle()
    }
  }, [open])

  const handleOnClose = () => {
    setProductsMap({})
    onClose()
    setBundle(null)
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  const sortedBundleProducts = useMemo(() => {
    if (!bundle?.products) return []
    return (
      bundle?.products.sort((a, b) =>
        a.product.name.localeCompare(b.product.name)
      ) || []
    )
  }, [bundleId, bundle])
  /* eslint-enable react-hooks/exhaustive-deps */

  const initialProductsMap = useMemo(() => {
    if (!initialProducts) return {}
    const map = {}
    initialProducts.forEach(({ variationId, productId, quantity }) => {
      map[productId] = {
        variationId,
        productId,
        quantity,
      }
    })
    return map
  }, [initialProducts])

  useEffect(() => {
    // preselect initial products
    if (!bundle?.products) return
    if (!Object.keys(initialProductsMap).length) return

    const map = {}

    bundle.products.forEach((item) => {
      if (initialProductsMap[item.product.id]) {
        map[item.product.id] = initialProductsMap[item.product.id]
      }
    })

    setProductsMap(map)
  }, [bundle, initialProductsMap])

  const onSubmit = async () => {
    const formattedProducts = Object.values(productsMap)
    const products = [...formattedProducts]

    for (const product of formattedProducts) {
      const variationStoreId = getVariationStoreId(product.variationId)
      const wpProductVariationStore =
        wpProductVariationStoresMap[variationStoreId]

      if (
        wpProductVariationStore?.productVariation.mandatoryProductVariation?.id
      ) {
        const mandatoryVariationResponse = await api.get(
          getApiRoute('wp-product-variation-stores'),
          {
            itemsPerPage: 999,
            'productVariation.id':
              wpProductVariationStore.productVariation.mandatoryProductVariation
                .id,
            'store.id': storeId,
            properties: ['id'],
            'properties[productVariation][product]': ['id'],
          }
        )

        const { items } = getFormattedResponse(mandatoryVariationResponse)
        if (items.length) {
          products.push({
            mandatory: true,
            variationId: items[0].id,
            productId: items[0].productVariation.product.id,
          })
        }
      }
    }
    const bundle = {
      bundle: true,
      id: getNumberFromStringId(bundleId),
      products,
      quantity: 1,
    }
    if (showStartDateInput) {
      bundle.startDate = startDate
    }

    addItem(bundle)

    handleOnClose()
  }

  useEffect(() => {
    setBundleId(initialBundle)
  }, [initialBundle])

  return (
    <Dialog open={!!open} onClose={handleOnClose}>
      <Wrapper>
        <SimpleForm
          variant="outlined"
          toolbar={<></>}
          validate={(values) => {
            setBundleId(values.bundleId)
            return {
              wpProductStoreId: values.wpProductStoreId
                ? undefined
                : 'wp product is required',
            }
          }}
          initialValues={{ bundleId: initialBundle }}
        >
          <ReferenceInput
            reference="bundles"
            label="Bundle"
            source="bundleId"
            format={(v) => (v ? v['@id'] || v : v)}
            filter={{
              ...(storeId && { 'bundleStores.store.id': storeId }),
              ...(intervalSlug && { 'interval.slug': intervalSlug }),
              properties: ['id', 'name', 'interval'],
              'properties[products][product]': ['name', 'id'],
              'properties[products][quantityMin]': '*',
            }}
            sort={{ field: 'name', order: 'ASC' }}
            disabled={!!bundleId}
            perPage={50}
          >
            <AutocompleteInput
              options={{
                suggestionsContainerProps: { style: { zIndex: 99999 } },
              }}
              optionText="name"
            />
          </ReferenceInput>
        </SimpleForm>
        {bundleLoading ? (
          <LinearProgress style={{ margin: '0 20px' }} />
        ) : (
          sortedBundleProducts.map((bundleProduct, index) => {
            const currentVariation = productsMap[bundleProduct.product.id]
            const initialValues = {}
            if (currentVariation) {
              initialValues.wpProductVariationStore = getVariationStoreId(
                currentVariation?.variationId
              )
            }

            return (
              <VariableProductWrapper
                key={`bundle-product-${index}-${bundleProduct.product.id}`}
              >
                <VariableProductTitle>
                  {bundleProduct.product.name}
                </VariableProductTitle>

                <SimpleForm
                  toolbar={<></>}
                  variant="outlined"
                  validate={(values) =>
                    handleWpProductVariationStoreFormChange(
                      values,
                      bundleProduct.product.id,
                      bundleProduct.quantityMin
                    )
                  }
                  initialValues={initialValues}
                >
                  <ReferenceInput
                    reference="wp-product-variation-stores"
                    label="Product variation"
                    source="wpProductVariationStore"
                    format={(v) => (v ? v['@id'] || v : v)}
                    filter={{
                      ...(storeId && { 'store.id': storeId }),
                      ...(bundle.interval?.id && {
                        'productVariation.interval.id': getNumberFromStringId(
                          bundle.interval?.id
                        ),
                      }),
                      ...(bundleProduct.product.id && {
                        'productVariation.product.id': getNumberFromStringId(
                          bundleProduct.product.id
                        ),
                      }),
                      ...(intervalSlug && {
                        'productVariation.interval.slug': intervalSlug,
                      }),
                      properties: ['id'],
                      'properties[productVariation][interval]': ['name'],
                      'properties[productVariation][product]': ['name'],
                      'properties[productVariation][propertyValue]': ['name'],
                      'properties[productVariation][mandatoryProductVariation]': [
                        'id',
                      ],
                    }}
                    validate={[required()]}
                  >
                    <AutoSelectInput
                      optionText={(item) =>
                        getProductVariationTitle(item?.productVariation)
                      }
                    />
                  </ReferenceInput>
                </SimpleForm>
              </VariableProductWrapper>
            )
          })
        )}
        {showStartDateInput && (
          <div style={{ padding: '0 16px' }}>
            <StartDateInput startDate={startDate} setStartDate={setStartDate} />
          </div>
        )}
        <Button
          variant="contained"
          style={{ margin: '20px' }}
          disabled={bundle?.products?.length > Object.keys(productsMap).length}
          label="Add bundle"
          onClick={onSubmit}
        />
      </Wrapper>
    </Dialog>
  )
}
