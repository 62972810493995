import React from 'react'
import { DeleteWithConfirmButton } from 'react-admin'

export const DeleteImageButton = ({ record, basePath, resource }) => {
  if (!record) {
    return null
  }

  const url = `/groups/${encodeURIComponent(record.group['@id'])}/1`
  const sanitizedProps = { record, basePath, resource, redirect: url }

  return (
    <DeleteWithConfirmButton
      {...sanitizedProps}
      confirmTitle="resources.groupImage.delete.confirm"
    />
  )
}
