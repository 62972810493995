import React from 'react'
import {
  FunctionField,
  TextField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import { formatPrice } from 'utils/string'

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="customer.id"
      reference="customers"
      label="Customer email"
      referenceSource="email"
      filterToQuery={(searchText) => ({ email: searchText })}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <TextInput label="Chargebee Id" source="chargeBeeId" />
  </Filter>
)

export const ChargebeePaymentList = (props) => {
  return (
    <ListGuesser
      filters={<ListFilter />}
      {...props}
      hasEdit={false}
      bulkActionButtons={false}
      filter={{
        properties: [
          'id',
          'customer',
          'data',
          'chargeBeeId',
          'amount',
          'createdAt',
        ],
        'properties[data]': ['status', 'reference_number'],
        'properties[customer]': ['email', 'id'],
        'properties[invoices]': ['chargeBeeId'],
        'properties[paymentMethod]': 'slug',
      }}
    >
      <TextField source="originId" label="id" />
      <TextField source="chargeBeeId" label="Chargebee ID" />
      <FunctionField
        label="Invoices"
        source="invoices"
        render={(record) =>
          record?.invoices?.map((item) => item.chargeBeeId).join(', ')
        }
      />
      <FunctionField
        label="Amount"
        source="amount"
        render={(record) => {
          const { data } = record
          const { amount, currency_code } = data
          return formatPrice(amount / 100, currency_code)
        }}
      />
      <ReferenceListFilterField
        listUri="/customers"
        titlePath="customer.email"
        valuePath="customer.email"
        label="Customer"
      />
      <TextField source="customer.id" label="Customer id" />
      <TextField source="paymentMethod.slug" label="Payment method" />
      <TextField source="data.status" label="Payment Status" />
      <TextField source="data.reference_number" label="pspReference" />
      <DateField source="createdAt" short={false} label="Created at" />
    </ListGuesser>
  )
}
