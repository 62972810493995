import React, { useState } from 'react'
import { useNotify, ReferenceInput, SelectInput } from 'react-admin'
import { EditGuesser, ResourceCreateToolbar } from 'theme/components'
import { WPImageCard } from './WPImageCard'
import { FormSpy } from 'react-final-form'
import { getNumberFromStringId } from 'utils/string'
import { getProductVariationTitle } from 'utils/string'
import { AutoSelectInput } from 'theme/components/input/AutoSelectinput'

export const EditWPProductVariationCard = React.memo((props) => {
  const { wpProduct } = props
  const [intervalId, setIntervalId] = useState()

  const notify = useNotify()

  const onSuccess = () => {
    notify('WP product variation updated')
  }

  return (
    <EditGuesser
      {...props}
      toolbar={<ResourceCreateToolbar showBack={false} />}
      redirect={false}
      resource="wp-product-variations"
      basePath="/wp-product-variations"
      mutationMode="optimistic"
      onSuccess={onSuccess}
    >
      <FormSpy
        {...props}
        subscription={{ values: true }}
        onChange={(form) => {
          if (form?.values?.interval?.id) {
            setIntervalId(form.values.interval.id)
          } else {
            setIntervalId(getNumberFromStringId(form?.values?.interval))
          }
        }}
      />
      <ReferenceInput
        variant="outlined"
        source="interval"
        reference="intervals"
        format={(v) => (v ? v['@id'] || v : v)}
        filter={{ properties: ['id', 'name'] }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {!!wpProduct?.property?.id && (
        <ReferenceInput
          variant="outlined"
          source="propertyValue"
          reference="variation-property-values"
          filter={{
            'property.id': wpProduct.property.id,
            properties: ['id', 'name'],
          }}
          format={(v) => (v ? v['@id'] || v : v)}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}

      <ReferenceInput
        variant="outlined"
        source="mandatoryProductVariation"
        reference="wp-product-variations"
        filter={{
          'interval.id': intervalId,
          properties: ['id', 'name'],
          'properties[interval]': ['id', 'name'],
          'properties[product]': ['name'],
          'properties[propertyValue]': ['name'],
          'properties[image][file][data]': ['cloudFrontUrl'],
        }}
        format={(v) => (v ? v['@id'] || v : v)}
      >
        <AutoSelectInput optionText={getProductVariationTitle} />
      </ReferenceInput>
      <WPImageCard variationId={props.id} />
    </EditGuesser>
  )
})
