import React from 'react'
import { useInput, useTranslate } from 'react-admin'
import { DropzoneArea } from 'material-ui-dropzone'
import PropTypes from 'prop-types'
import { sanitizeFilter } from 'utils/sanitizer'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(() => styles)

const bytesIn1Megabyte = 1000000

export const FileInput = ({
  record,
  source,
  acceptedFiles,
  maxFileSize,
  ...props
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const {
    input: { onChange, ...rest },
    meta: { touched, error },
  } = useInput({ record, source, ...props })

  const onDrop = async (files) => {
    if (files.length > 0) {
      const reader = new FileReader()
      reader.fileName = files[0].name

      reader.addEventListener('load', (loadEvent) => {
        var data = loadEvent.target.result
        const base64 = data.replace(/^[^,]*,/, '')

        onChange({
          title: loadEvent.target.fileName,
          content: base64,
        })
      })

      reader.readAsDataURL(files[0], {})
    } else {
      onChange(undefined)
    }
  }

  return (
    /*eslint-disable no-extra-boolean-cast*/
    <div
      className={clsx(
        classes.normal,
        !!(touched && error) ? classes.error : null
      )}
    >
      <Typography variant="body2" className={classes.helperText}>
        {`Format${acceptedFiles.length > 1 ? 's' : ''} ${acceptedFiles.join(
          ', '
        )} ${
          acceptedFiles.length > 1 ? 'are' : 'is'
        } accepted. Max file size: ${maxFileSize / bytesIn1Megabyte}MB. `}
      </Typography>
      <DropzoneArea
        {...rest}
        {...sanitizeFilter(props, [
          'filesLimit',
          'validate',
          'showFileNamesInPreview',
        ])}
        maxFileSize={maxFileSize}
        acceptedFiles={acceptedFiles}
        onChange={(e) => onDrop(e)}
      />
      {!!(touched && error) && (
        <Typography
          color="error"
          display="block"
          variant="body2"
          className={classes.errorText}
        >
          {translate(error)}
        </Typography>
      )}
    </div>
  )
}

FileInput.defaultProps = {
  acceptedFiles: ['image/jpeg', 'image/png'],
  filesLimit: 1,
  maxFileSize: 5000000,
  showFileNamesInPreview: false,
}

FileInput.propTypes = {
  acceptedFiles: PropTypes.array,
  filesLimit: PropTypes.number,
  maxFileSize: PropTypes.number,
  showFileNamesInPreview: PropTypes.bool,
}
