import React from 'react'
import { EditGuesser, ResourceCreateToolbar } from 'theme/components'

import {
  useNotify,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  required,
  minValue,
  maxValue,
} from 'react-admin'

export const EditBundleProductCard = React.memo((props) => {
  const notify = useNotify()

  if (!props?.record) {
    return null
  }

  const onSuccess = () => {
    notify('Bundle product updated')
  }

  return (
    <EditGuesser
      variant="outlined"
      redirect={false}
      resource="bundle-products"
      basePath="/bundle-products"
      toolbar={<ResourceCreateToolbar showBack={false} />}
      onSuccess={onSuccess}
      transform={(record) => {
        const discountPercentage = Number(record.discountPercentage)
        return {
          ...record,
          discountPercentage: `${discountPercentage || 0}.00`,
        }
      }}
      {...props}
    >
      <ReferenceInput
        source="product"
        reference="wp-products"
        label="WP Product"
        filterToQuery={(searchText) => ({
          name: searchText,
          'variations.productVariationStores.store.id': props.record?.bundle?.stores?.map(
            (item) => item.id
          ),
          properties: ['name', 'id'],
        })}
        validate={[required()]}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="quantityMin" validate={[required()]} />
      <NumberInput source="quantityMax" validate={[required()]} />
      <NumberInput
        source="discountPercentage"
        validate={[required(), minValue(0), maxValue(100)]}
        min={0}
        max={100}
        transform={Number}
      />
    </EditGuesser>
  )
})
