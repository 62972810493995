import React, { useState, useEffect } from 'react'
import { ShowGuesser } from '@api-platform/admin'
import { FunctionField } from 'react-admin'
import styled from 'styled-components'
import { getNumberFromStringId, pluralize } from 'utils/string'
import { ShortCustomerSubscriptionsList } from './tiles/subscriptions/ShortCustomerSubscriptionsList'
import { CustomerEventsList } from './tiles/events/CustomerEventsList'
import { CustomerInvoicesList } from './tiles/invoices/CustomerInvoicesList'
import { AdyenTransactionsList } from './tiles/adyenTransactions/AdyenTransactionsList'
import { FlowmailerEmailsList } from './tiles/flowmailerEmails/EmailsList'
import { CustomerProvider } from './contexts/customer'
import { CustomerCreditNotesList } from './tiles/creditNotes/CustomerCreditNotesList'
import { CustomerEdit } from './tiles/editCustomerFIelds/CustomerEdit'
import { ChargebeeTransactionsList } from './tiles/chargebeeTransactions/ChargebeeTransactionsList'
import { Tile } from 'theme/components/tiles/Tile'
import { ShortCustomerEdit } from './tiles/editCustomerFIelds/ShortCustomerEdit'
import { ShortCustomerCommentsList } from './tiles/comments/ShortCustomerCommentsList'
import { ShortCustomerEventsList } from './tiles/events/ShortCustomerEventsList'
import { ShortAdyenTransactionsList } from './tiles/adyenTransactions/ShortAdyenTransactionsList'
import { ShortChargebeeTransactionsList } from './tiles/chargebeeTransactions/ShortChargebeeTransactionsList'
import { ShortCustomerInvoicesList } from './tiles/invoices/ShortCustomerInvoicesList'
import { ShortCustomerCreditNotesList } from './tiles/creditNotes/ShortCustomerCreditNotesList'
import { ShortCallsList } from './tiles/calls/ShortCallsList'
import { ShortFlowmailerEmailsList } from './tiles/flowmailerEmails/ShortEmailsList'
import { ShortAddressesEdit } from './tiles/editAddresses/ShortAddressesEdit'
import { AddressesEdit } from './tiles/editAddresses/AddressesEdit'
import { ShortCustomerOrdersList } from './tiles/orders/ShortCustomerOrdersList'
import { CustomerOrdersList } from './tiles/orders/CustomerOrdersList'
import { Status } from './tiles/status/Status'
import { CurrentPaymentMethod } from './tiles/currentPaymentMethod/CurrentPaymentMethod'
import { Actions } from './tiles/actions/Actions'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { ReasonsProvider } from './contexts/reasons'
import { SubscriptionsProvider } from './contexts/subscriptions'
import useApi from 'hooks/useApi'
import { CallsList } from './tiles/calls/CallsList'
import { LogCallModal } from 'components/logCallModal'
import { ShortSelligentEmailsList } from './tiles/selligentEmails/ShortEmailsList'
import { SelligentEmailsList } from './tiles/selligentEmails/EmailsList'

const TilesWrapper = styled.div`
  display: flex;
  width: 100%;
`

const Row = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`

export const CustomerShow = (props) => {
  const { api } = useApi()
  const customerId = getNumberFromStringId(props?.id)
  const [customer, setCustomer] = useState()
  const [personalDetailsOpen, setPersonalDetailsOpen] = useState(true)
  const [financeOpen, setFinanceOpen] = useState(false)
  const [contactsOpen, setContactsOpen] = useState(false)
  const [subscriptions, setSubscriptions] = useState([])
  const [subscriptionsFetched, setSubscriptionsFetched] = useState(false)

  useEffect(() => {
    if (subscriptionsFetched || !customerId) return
    api.getCustomerSubscriptions(customerId).then((res) => {
      setSubscriptions([]) // trigger rerender of subscription everywhere
      setTimeout(() => {
        setSubscriptions(res?.data || [])
      }, 500)
      setSubscriptionsFetched(true)
    })
  }, [subscriptionsFetched, api, customerId])

  const addressesCount =
    subscriptions?.filter(
      (item) => item.status === 'active' || item.status === 'future'
    ).length + 1

  return (
    <CustomerProvider customer={{ ...customer, customerId }}>
      <ReasonsProvider>
        <SubscriptionsProvider
          subscriptions={subscriptions}
          refetchSubscriptions={() => setSubscriptionsFetched(false)}
        >
          <TilesWrapper>
            <ShowGuesser {...props} style={{ width: '100%' }} hasEdit={false}>
              <div>
                <Accordion
                  key="personal"
                  expanded={personalDetailsOpen}
                  onChange={() => setPersonalDetailsOpen((cur) => !cur)}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="personal details"
                    id="personal"
                  >
                    <Typography
                      style={{ fontSize: '1.1rem', width: '100%' }}
                      align="center"
                      variant="h3"
                    >
                      Customer information
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Tile
                        {...props}
                        label="Customer Fields"
                        ShortView={ShortCustomerEdit}
                        FullView={CustomerEdit}
                      />
                      <Tile
                        {...props}
                        label={`${pluralize(
                          addressesCount,
                          'Address',
                          'es'
                        )} (${addressesCount})`}
                        ShortView={ShortAddressesEdit}
                        FullView={AddressesEdit}
                      />
                      <Tile
                        {...props}
                        label="Subscriptions"
                        ShortView={ShortCustomerSubscriptionsList}
                        reloadOnClick={false}
                      />
                      <Tile
                        {...props}
                        label="Orders"
                        ShortView={ShortCustomerOrdersList}
                        FullView={CustomerOrdersList}
                      />
                      <Tile {...props} label="Status" ShortView={Status} />
                      <Tile
                        {...props}
                        label="Current payment method"
                        ShortView={CurrentPaymentMethod}
                        reloadOnClick={false}
                      />
                      <Tile
                        {...props}
                        label="Actions"
                        ShortView={Actions}
                        reloadOnClick={false}
                      />
                    </Row>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  key="finance"
                  expanded={financeOpen}
                  onChange={() => setFinanceOpen((cur) => !cur)}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="finance"
                    id="finance"
                  >
                    <Typography
                      style={{ fontSize: '1.1rem', width: '100%' }}
                      align="center"
                      variant="h3"
                    >
                      Finance
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Tile
                        {...props}
                        label="Adyen Transactions"
                        ShortView={ShortAdyenTransactionsList}
                        FullView={AdyenTransactionsList}
                      />

                      <Tile
                        {...props}
                        label="CB Transactions"
                        ShortView={ShortChargebeeTransactionsList}
                        FullView={ChargebeeTransactionsList}
                      />
                      <Tile
                        {...props}
                        label="CB Invoices"
                        ShortView={ShortCustomerInvoicesList}
                        FullView={CustomerInvoicesList}
                      />

                      <Tile
                        {...props}
                        label="Credit Notes"
                        ShortView={ShortCustomerCreditNotesList}
                        FullView={CustomerCreditNotesList}
                      />
                    </Row>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  key="conacts"
                  expanded={contactsOpen}
                  onChange={() => setContactsOpen((cur) => !cur)}
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="conacts"
                    id="conacts"
                  >
                    <Typography
                      style={{ fontSize: '1.1rem', width: '100%' }}
                      align="center"
                      variant="h3"
                    >
                      Contacts
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Row>
                      <Tile
                        {...props}
                        label="Comments"
                        ShortView={ShortCustomerCommentsList}
                        reloadOnClick={false}
                      />
                      <Tile
                        {...props}
                        label="Flowmailer Emails"
                        ShortView={ShortFlowmailerEmailsList}
                        FullView={FlowmailerEmailsList}
                      />
                      <Tile
                        {...props}
                        label="Selligent Emails"
                        ShortView={ShortSelligentEmailsList}
                        FullView={SelligentEmailsList}
                      />
                      <Tile
                        {...props}
                        label="Calls"
                        ShortView={ShortCallsList}
                        FullView={CallsList}
                        reloadOnClick={false}
                        ShortViewActions={({ reloadShortView }) => (
                          <LogCallModal
                            customerId={customerId}
                            onSuccess={reloadShortView}
                          />
                        )}
                      />
                      <Tile
                        {...props}
                        label="Events"
                        ShortView={ShortCustomerEventsList}
                        FullView={CustomerEventsList}
                      />
                    </Row>
                  </AccordionDetails>
                </Accordion>
              </div>
              <FunctionField
                label=" "
                render={(record) => {
                  // renders nothing, needed here to just set customer
                  if (customer) return null
                  setCustomer(record)
                  return null
                }}
              />
            </ShowGuesser>
          </TilesWrapper>
        </SubscriptionsProvider>
      </ReasonsProvider>
    </CustomerProvider>
  )
}
