import React from 'react'

import {
  TextField,
  TextInput,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
} from 'react-admin'
import { ListGuesser, ReferenceListFilterField } from 'theme/components'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="chargeBeeId" />
    <TextInput source="name" />
    <TextInput source="pimcoreId" label="Pimcore Id" />
    <TextInput source="interval" />
    <ReferenceArrayInput
      source="store.id"
      reference="stores"
      label="resources.store.field"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
  </Filter>
)

export const PimcorePropositionList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    filters={<ListFilter />}
    filter={{
      properties: ['id', 'chargeBeeId', 'interval', 'pimcoreId', 'name'],
      'properties[store]': ['name'],
    }}
    bulkActionButtons={false}
    rowClick="show"
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="chargeBeeId" label="Chargebee id" />
    <TextField source="pimcoreId" label="Pimcore id" />
    <ReferenceListFilterField
      label="resources.store.field"
      listUri="/stores"
      titlePath="store.name"
      valuePath="store.name"
    />
    <TextField source="name" />
    <TextField source="interval" />
  </ListGuesser>
)
