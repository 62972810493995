import CustomTable from 'components/customTable'
import { formatDateTime } from 'utils/string'
import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import { useCurrentSession } from '../contexts/currentSession'

export const ErrorsStep = () => {
  const { session } = useCurrentSession()
  const dataProvider = useDataProvider()

  const [errors, setErrors] = useState([])
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    if (fetched) return

    dataProvider
      .getList('payment-errors', {
        pagination: {},
        sort: {},
        filter: {
          itemsPerPage: 999,
          'session.id': [session?.originId],
        },
      })
      .then((res) => {
        const { data } = res
        setErrors(
          data.map((item) => ({
            ...item,
            createdAt: formatDateTime(item.createdAt),
          }))
        )
        setFetched(true)
      })
  }, [dataProvider, session, fetched])

  return (
    <CustomTable
      data={errors}
      headCells={[
        { rowKey: 'createdAt', id: 'createdAt', label: 'Created at' },
        { rowKey: 'errorMessage', id: 'errorMessage', label: 'Error message' },
        {
          rowKey: 'paymentMethod',
          id: 'paymentMethod',
          label: 'Payment method',
        },
      ]}
      pageSize={10}
      selectable={false}
    />
  )
}
