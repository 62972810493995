import { useCustomer } from 'components/pages/customer/contexts/customer'
import {
  isChangeEvent,
  pretify,
  renderChanges,
} from 'components/pages/events/EventShow'
import React from 'react'
import {
  TextField,
  ReferenceManyField,
  Pagination,
  FunctionField,
} from 'react-admin'
import { DateField, DataGrid } from 'theme/components'

export const CustomerEventsList = (props) => {
  const { chargeBeeId } = useCustomer()
  return (
    <ReferenceManyField
      addLabel={false}
      reference="events"
      source="id"
      pagination={<Pagination />}
      filter={{
        customerChargeBeeId: chargeBeeId,
        properties: ['id', 'type', 'source', 'createdAt', 'user', 'data'],
      }}
      target=""
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick="show">
        <TextField source="originId" label="resources.id" sortBy="id" />
        <TextField source="type.name" label="Event" />
        <TextField source="source.name" label="Source" />
        <DateField source="createdAt" short={false} />
        <TextField source="user" label="Environment" />
        <FunctionField
          label="Data"
          render={(record) => {
            if (isChangeEvent(record.type.name.toLowerCase())) {
              return (
                <span>
                  <pre>{renderChanges(record.data)}</pre>
                </span>
              )
            }

            return (
              <div>
                <pre>{pretify(record.data, null, 2)}</pre>
              </div>
            )
          }}
        />
      </DataGrid>
    </ReferenceManyField>
  )
}
