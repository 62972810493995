import React from 'react'
import { TextField, Filter, TextInput } from 'react-admin'
import { ListGuesser } from 'theme/components'

const BundleFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
  </Filter>
)

export const BundleList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="edit"
    bulkActionButtons={false}
    filters={<BundleFilter />}
    filter={{
      properties: ['name', 'id', 'interval'],
      'properties[interval]': ['id', 'name'],
    }}
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <TextField label="interval" source="interval.name" />
  </ListGuesser>
)
