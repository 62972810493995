import React, { useState, useEffect } from 'react'
import useApi from 'hooks/useApi'
import styled from 'styled-components'
import { Switch, Button } from '@material-ui/core'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { ListItem } from '../commonComponents'
import { formatDate } from 'utils/string'
import { useRecordContext, useNotify, Confirm } from 'react-admin'

const List = styled.ul`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
  position: relative;
`

const SwitchWrapper = styled.div`
  & > .MuiSwitch-root {
    position: absolute;
    margin: -2px 0 0 -3px;
  }
`

const Expired = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ExpiredText = styled.div`
  font-size: 30pt!important;
  font-weight: bold;
  color: red;
  border-top: 5px solid red;
  border-bottom: 5px solid red;
  rotate: 45deg;
`

const isExpired = (expireDate) => {
  const expire = new Date()
  const [year, month] = expireDate.split('-')
  expire.setFullYear(year)
  expire.setMonth(month - 1)
  expire.setDate(1)
  return expire.getTime() < new Date().getTime()
}

export const CurrentPaymentMethod = () => {
  const { api, isSubmitting } = useApi()
  const { customerId } = useCustomer()
  const [paymentMethod, setPaymentMethod] = useState()
  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [fetched, setFetched] = useState(false)
  const notify = useNotify()
  const record = useRecordContext()
  const [autoCollection, setAutoCollection] = useState(record?.autoCollection)

  useEffect(() => {
    if (fetched) return
    setFetched(true)

    api.getCustomerPaymentMethod(customerId).then((response) => {
      if (response?.data) setPaymentMethod(response.data)
    })
  }, [api, customerId, fetched])

  const detailsKeys = paymentMethod?.details
    ? Object.keys(paymentMethod.details)
    : []

  return (
    <>
      <List>
        {paymentMethod?.details?.expireDate && isExpired(paymentMethod?.details?.expireDate) &&
        <Expired>
          <ExpiredText>Expired</ExpiredText>
        </Expired>
        }
        {!isSubmitting && !paymentMethod && (
          <ListItem title="Payment method" value="on invoice" />
        )}
        <ListItem title="Type" value={paymentMethod?.type} />
        <ListItem title="Gateway" value={paymentMethod?.gateway?.name} />
        <ListItem
          title="Created at"
          value={
            paymentMethod?.createdAt && formatDate(paymentMethod?.createdAt)
          }
        />
        {detailsKeys.map((key) => (
          <ListItem
            title={key}
            value={paymentMethod?.details[key]}
            key={key}
            highlightValue={key === 'expireDate'}
          />
        ))}

        <ListItem title="Payment flow" value={record?.paymentFlow?.slug} />

        {record?.paymentFlow?.slug === 'chargebee' && (
          <ListItem
            title="Auto Collection"
            value={
              <SwitchWrapper>
                <Switch
                  disabled={isSubmitting}
                  checked={autoCollection}
                  onChange={() => {
                    api
                      .updateCustomer(customerId, {
                        autoCollection: autoCollection ? 'off' : 'on',
                      })
                      .then((res) =>
                        setAutoCollection(res?.data?.auto_collection === 'on')
                      )
                  }}
                  name="autocollection"
                  color="primary"
                  size="small"
                />
              </SwitchWrapper>
            }
          />
        )}
      </List>
      {record?.paymentFlow?.slug === 'adyen' && paymentMethod && (
        <>
          <Button
            onClick={() => setConfirmOpen(true)}
            variant="outlined"
            color="primary"
          >
            Remove payment method
          </Button>
          <Confirm
            isOpen={isConfirmOpen}
            title="Remove default payment method"
            content="Are you sure you want to remove the default payment method for this customer. Without a payment method, future renewals will be charged on invoice."
            onConfirm={() =>
              api.deleteDefaultPaymentMethod(customerId).then((res) => {
                if (res) {
                  setConfirmOpen(false)
                  notify('Default payment method removed')
                }
              })
            }
            onClose={() => setConfirmOpen(false)}
          />
        </>
      )}
    </>
  )
}
