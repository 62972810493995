import React from 'react'
import { CreateGuesser, ResourceCreateToolbar } from 'theme/components'

import {
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
  useRefresh,
  useNotify,
} from 'react-admin'

export const CreateAddonCard = React.memo(({ record }) => {
  const refresh = useRefresh()
  const notify = useNotify()

  if (!record) {
    return null
  }

  const onSuccess = () => {
    notify('resources.addon.created')
    refresh()
  }

  const optionText = (option) => {
    if (!option) {
      return null
    }

    return `${option.chargeBeeId} (${option.name})`
  }

  return (
    <CreateGuesser
      variant="outlined"
      redirect={false}
      resource="addons"
      basePath="/addons"
      initialValues={{
        plan: record['@id'],
      }}
      toolbar={<ResourceCreateToolbar showBack={false} />}
      onSuccess={onSuccess}
    >
      <ReferenceInput
        source="productStoreInterval"
        reference="product-store-intervals"
        label="Product Store Interval"
        filterToQuery={(searchText) => ({
          productNameOrChargebeeId: searchText,
        })}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
        filter={{
          availablePsiForPlan: record.originId,
        }}
        resettable
      >
        <AutocompleteInput optionText={optionText} />
      </ReferenceInput>
      <BooleanInput label="Mandatory" source="mandatory" defaultValue={false} />
      <BooleanInput
        label="Recommended"
        source="recommended"
        defaultValue={false}
      />
    </CreateGuesser>
  )
})
