import React from 'react'
import { ListGuesser, ReferenceListFilterField } from 'theme/components'

export const BundleStoreList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={true}
    hasShow={false}
    filter={{
      'properties[store]': ['name'],
      'properties[bundle]': ['name'],
      'properties[upgradeTo]': ['name'],
      'properties[downgradeTo]': ['name'],
    }}
    rowClick="edit"
    bulkActionButtons={false}
  >
    <ReferenceListFilterField
      label="Store"
      listUri="/stores"
      titlePath="store.name"
      valuePath="store.name"
    />
    <ReferenceListFilterField
      label="Bundle"
      listUri="/bundles"
      titlePath="bundle.name"
      valuePath="bundle.name"
    />

    <ReferenceListFilterField
      label="Downgrade to"
      listUri="/bundles"
      titlePath="downgradeTo.name"
      valuePath="downgradeTo.name"
    />
    <ReferenceListFilterField
      label="Upgrade to"
      listUri="/bundles"
      titlePath="upgradeTo.name"
      valuePath="upgradeTo.name"
    />
  </ListGuesser>
)
