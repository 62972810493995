import React from 'react'
import { FieldGuesser, InputGuesser, ShowGuesser } from '@api-platform/admin'
import {
  ReferenceInput,
  AutocompleteInput,
  Tab,
  ReferenceManyField,
  Pagination,
  TextField,
  SelectInput,
} from 'react-admin'
import {
  DataGrid,
  EditGuesser,
  ResourceEditToolbar,
  ReferenceListFilterField,
} from 'theme/components'
import DeleteProductStoreButton from './deleteProductStoreButton'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

export const ProductStoreEdit = ({ defaultToolbar, ...props }) => (
  <EditGuesser
    {...props}
    undoable={false}
    variant="outlined"
    redirect="list"
    toolbar={
      <ResourceEditToolbar
        customDeleteButton={<DeleteProductStoreButton {...props} />}
      />
    }
  >
    <ReferenceInput
      source="store"
      reference="stores"
      label="resources.store.field"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
      filter={{ active: 1 }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="product"
      reference="products"
      label="resources.product.field"
      filterToQuery={(searchText) => ({ name: searchText })}
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <InputGuesser source="sku" />
  </EditGuesser>
)

export const ProductStoreEditPage = (props) => (
  <ShowGuesser {...props}>
    <OverflowingTabbedShowLayout>
      <Tab label="resources.productStore.edit">
        <ProductStoreEdit {...props} />
      </Tab>
      <Tab label="resources.productStore.relatedIntervals">
        <ReferenceManyField
          addLabel={false}
          reference="product-store-intervals"
          target="productStore.id"
          source="id"
          pagination={<Pagination />}
          filter={{
            properties: [
              'id',
              'price',
              'qty',
              'name',
              'description',
              'chargeBeeId',
              'invoiceName',
              'shippable',
            ],
            'properties[pricingModel]': ['id', 'slug'],
            'properties[interval]': ['id', 'name'],
            'properties[currency]': ['id', 'code'],
          }}
        >
          <DataGrid rowClick="show">
            <TextField
              source="originId"
              label="id"
              sortBy="id"
              sort={{ field: 'id', order: 'DESC' }}
            />
            <ReferenceListFilterField
              {...props}
              label="resources.interval.field"
              listUri="/intervals"
              titlePath="interval.name"
              valuePath="interval.id"
            />
            <ReferenceListFilterField
              {...props}
              label="resources.currency.field"
              listUri="/currencies"
              titlePath="currency.code"
              valuePath="currency.id"
            />
            <FieldGuesser
              source="chargeBeeId"
              label="resources.order.chargeBee"
            />
            <FieldGuesser source="name" />
            <FieldGuesser source="price" />
            <FieldGuesser source="qty" />
            <TextField
              source="pricingModel.slug"
              label="resources.productStoreInterval.pricingModel"
            />
            <FieldGuesser source="shippable" />
          </DataGrid>
        </ReferenceManyField>
      </Tab>
    </OverflowingTabbedShowLayout>
  </ShowGuesser>
)
