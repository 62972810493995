import Icon from '@material-ui/icons/Money'
import { ROLES } from 'utils/user'

import { OrderList } from './OrderList'
import { OrderShow } from './OrderShow'

export default {
  list: OrderList,
  show: OrderShow,
  icon: Icon,
  permissions: [
    ROLES.ROLE_ADMIN,
    ROLES.ROLE_ORDER_MANAGEMENT,
    ROLES.ROLE_PRODUCT,
    ROLES.ROLE_CUSTOMER_SUPPORT,
  ],
}
