import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDataProvider } from 'react-admin'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import { getSubscriptionsRequestBody } from './utils'
import { LinearProgress, Typography } from '@material-ui/core'
import { useSubscriptions } from '../contexts/subscriptions'
import { useStore } from '../contexts/store'
import { useCustomer } from '../contexts/customer'
import { SendEmailDialog } from './SendEmailDialog'
import { CreateInvoiceSubscriptionDialog } from './CreateInvoiceSubscriptionDialog'
import { CreateSubscriptionForExistingCustomerDialog } from './CreateSubscriptionForExistingCustomerDialog'
import { useCurrentSession } from '../contexts/currentSession'
import { formatDate, formatPrice } from 'utils/string'
import { colors } from 'theme/MuiTheme'
import { usePageState } from '../contexts/pageState'
import { SubscriptionItem } from 'components/SubscriptionItem'
import { CouponSelect } from 'components/couponSelect'
import { throttle } from 'lodash'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 10px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`

const TotalByDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const SubscriptionsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
`

const Subscription = styled.li`
  padding: 8px;
  position: relative;

  &:before {
    position: absolute;
    opacity: 0;
    content: ' ';
    transition: opacity 0.3s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border: 1px solid gray;
    border-radius: 8px;
  }

  &:after {
    position: absolute;
    opacity: 0;
    content: '🔧 edit';
    transition: opacity 0.3s;
    top: 8px;
    right: 8px;
  }

  &:hover {
    cursor: pointer;

    &:before {
      opacity: 0.3;
    }

    &:after {
      opacity: 1;
    }
  }
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  gap: 20px;
  padding: 0;
`

const StartingDate = styled.span`
  color: #90c449;
  font-weight: bold;
`

const Label = styled.div`
  color: ${(props) => (props.isImmediately ? colors.primary : '#000000')};
`

const ButtonHolder = styled.div`
  margin: 30px 0 0 0;
`

export const MidlayerSubscriptions = () => {
  const {
    subscriptions,
    coupon,
    setCoupon,
    subscriptionsFetching,
  } = useSubscriptions()
  const { setActiveStep } = usePageState()
  const { isInvoice, disabled } = useCurrentSession()
  const { billingAddress, customerCBId } = useCustomer()
  const { store, storeId } = useStore()
  const { api, isSubmitting } = useApi()
  const [totalPrice, _setTotalPrice] = useState()
  const [totalBySubscriptionDate, setTotalBySubscriptionDate] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(0)
  const dataProvider = useDataProvider()
  const [intervalsMap, setIntervalsMap] = useState({})
  const [invoiceCost, setInvoiceCost] = useState(0)
  const invoiceAddons = useMemo(() => new Set(Object.values(intervalsMap)), [
    intervalsMap,
  ])

  /* eslint-disable react-hooks/exhaustive-deps */
  const setTotalPrice = useCallback(
    throttle((val) => {
      _setTotalPrice((cur) => {
        if (val instanceof Function) return val(cur)
        return val
      })
    }, 10),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    dataProvider
      .getList('store-plans', {
        pagination: {},
        sort: {},
        filter: { 'store.id': storeId },
      })
      .then(({ data }) => {
        const map = {}
        data.forEach(({ interval, processingFeeAddon }) => {
          map[interval.slug] = processingFeeAddon?.chargeBeeId
        })

        setIntervalsMap(map)
      })
  }, [dataProvider, storeId])

  const estimatePrices = useCallback(
    (subs) => {
      setTotalBySubscriptionDate({})

      const formattedSubscriptions = subs?.reduce((map, curr) => {
        const date = curr.startDate || 'Immediately'

        if (!map[date]) {
          map[date] = []
        }

        map[date].push(curr)

        return map
      }, {})

      if (Object.keys(formattedSubscriptions).length > 0) {
        Object.keys(formattedSubscriptions).forEach((key) => {
          const subscriptions = formattedSubscriptions[key]

          const requestBody = getSubscriptionsRequestBody({
            subscriptions,
            isInvoice,
            intervalsMap,
            coupon,
          }).map((item) => ({
            ...item,
            billingAddress,
            subscription: { planId: item.planId },
            planId: undefined,
          }))

          api
            .checkoutEstimateTotalPrice({
              cbSubscription: requestBody,
              storeId,
              customerCBId,
            })
            .then((priceResponse) => {
              if (!priceResponse) {
                setCoupon() // no response means error. removing coupon might fix it
              }
              let amountDue = 0
              let subTotal = 0

              if (priceResponse) {
                priceResponse.data.forEach((subscriptionEstimate) => {
                  const invoiceEstimate = subscriptionEstimate.invoice_estimate
                  if (!invoiceEstimate) return
                  amountDue += Number(invoiceEstimate.amount_due)
                  subTotal += Number(invoiceEstimate.sub_total)
                  invoiceEstimate.line_items.forEach((lineItem) => {
                    if (invoiceAddons.has(lineItem.entity_id)) {
                      setInvoiceCost(lineItem.amount)
                    }
                  })
                })
              }

              setTotalBySubscriptionDate((prev) => {
                return {
                  ...prev,
                  [key]: {
                    total: amountDue,
                    discount: subTotal - amountDue,
                  },
                }
              })
            })
        })
      }
    },
    [
      api,
      billingAddress,
      coupon,
      customerCBId,
      intervalsMap,
      isInvoice,
      setCoupon,
      invoiceAddons,
      storeId,
    ]
  )

  useEffect(() => {
    estimatePrices(subscriptions)
  }, [subscriptions, estimatePrices])

  useEffect(() => {
    if (isSubmitting) {
      setTotalPrice('...')
      return
    }
    if (totalBySubscriptionDate) {
      const totalData = Object.entries(totalBySubscriptionDate).reduce(
        (map, curr) => {
          map.total = map.total + curr[1].total
          map.discount = map.discount + curr[1].discount

          return map
        },
        { total: 0, discount: 0 }
      )

      if (totalData) {
        setTotalPrice(totalData.total)
        setDiscountAmount(totalData.discount)
      }
    }
  }, [
    totalBySubscriptionDate,
    setTotalPrice,
    setDiscountAmount,
    subscriptions,
    isSubmitting,
  ])

  const getTitle = (subscription) => {
    if (subscription?.interval?.includes('SKT')) {
      return 'Starter pack'
    } else if (subscription?.interval?.includes('SGL')) {
      return 'One time order'
    }
    return 'Subscription'
  }

  const currency = store?.currency?.code

  return (
    <Wrapper disabled={disabled}>
      <SubscriptionsList>
        {subscriptions?.map((subscription, subscriptionIndex) => {
          return (
            <Subscription
              key={subscriptionIndex}
              role="button"
              onClick={() => {
                setActiveStep('cart')
              }}
            >
              <Typography variant="h6">{getTitle(subscription)}</Typography>
              <span>
                start date:{' '}
                <StartingDate>
                  {subscription.startDate
                    ? formatDate(subscription.startDate)
                    : 'immediately'}
                </StartingDate>
              </span>
              <ItemsList>
                {subscription.items?.map((item, itemIndex) => {
                  return (
                    <SubscriptionItem
                      key={`${subscriptionIndex}:${itemIndex}`}
                      item={item}
                      currency={currency}
                    />
                  )
                })}
              </ItemsList>
            </Subscription>
          )
        })}
      </SubscriptionsList>
      <CouponSelect storeId={store?.id} coupon={coupon} setCoupon={setCoupon} />
      {!!subscriptions?.length && (
        <>
          {isNaN(totalPrice) || subscriptionsFetching ? (
            <LinearProgress style={{ margin: '10px 0' }} />
          ) : (
            <>
              {totalBySubscriptionDate &&
                Object.keys(totalBySubscriptionDate).length > 1 && (
                  <div>
                    {Object.keys(totalBySubscriptionDate)
                      .sort(function (a, b) {
                        if (b === 'Immediately') {
                          return 1
                        } else {
                          return -1
                        }
                      })
                      .map((key) => {
                        const total = totalBySubscriptionDate[key]?.total

                        return (
                          <TotalByDate key={key}>
                            <Label isImmediately={key === 'Immediately'}>
                              {key === 'Immediately'
                                ? 'Immediately'
                                : new Date(key).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    day: 'numeric',
                                    month: 'short',
                                  })}
                            </Label>
                            <Price>{formatPrice(total / 100, currency)}</Price>
                          </TotalByDate>
                        )
                      })}
                  </div>
                )}
              <Footer>
                {isInvoice && (
                  <span>
                    Invoice cost:{' '}
                    <Price>{formatPrice(invoiceCost / 100, currency)}</Price>
                  </span>
                )}
                <span>
                  Total:{' '}
                  <Price>{formatPrice(totalPrice / 100, currency)}</Price>
                </span>
                {!!discountAmount && (
                  <span>
                    saved: {formatPrice(-discountAmount / 100, currency)}
                  </span>
                )}
                <ButtonHolder>
                  <CreateInvoiceSubscriptionDialog
                    intervalsMap={intervalsMap}
                    totalPrice={totalPrice}
                  />

                  <CreateSubscriptionForExistingCustomerDialog
                    totalPrice={totalPrice}
                  />
                </ButtonHolder>
              </Footer>
            </>
          )}
        </>
      )}
      <SendEmailDialog />
    </Wrapper>
  )
}
