import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import { TextField, NumberInput, Filter } from 'react-admin'
import { ListGuesser } from 'theme/components'

const ListFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Id" source="id" />
  </Filter>
)

export const CurrencyList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    filter={{
      properties: ['id', 'code'],
    }}
    rowClick="edit"
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <FieldGuesser source="code" />
  </ListGuesser>
)
