import { api } from 'service/Api'
import { getUrlParams } from 'utils/uri'

export const clearAuth = () => {
  const { status } = getUrlParams()

  if (!status && !window.location.href.includes('recover-password')) {
    localStorage.removeItem('authenticated')
    window.location.href = '/#/login'
  }
}

export default {
  login: async ({ username, password }) => {
    const { data } = await api.authorize(username, password)

    localStorage.setItem('authenticated', 'yes')
    localStorage.setItem('userName', data.user.fullName || '')
    localStorage.setItem('userEmail', data.user.email || '')
    localStorage.setItem(
      'userRoles',
      JSON.stringify(
        data.user.roles && data.user.roles.length ? data.user.roles : []
      )
    )
    localStorage.setItem('userUri', data.user['@id'] || null)

    window.dispatchEvent(new Event('login'))

    const prevUrl = localStorage.getItem('prevUrl')

    if (prevUrl) {
      setTimeout(() => (window.location.href = prevUrl), 100)
    }

    return Promise.resolve()
  },

  logout: async () => {
    localStorage.setItem('prevUrl', window.location.href)

    if (localStorage.getItem('isRefresing')) {
      localStorage.removeItem('isRefresing')
    } else {
      await api.logout()

      localStorage.removeItem('authenticated')
      localStorage.removeItem('userName')
      localStorage.removeItem('userRoles')
      localStorage.removeItem('userUri')
    }

    return Promise.resolve()
  },

  checkError: async (error) => {
    if (
      error.status === 401 ||
      (error.response && error.response.status === 401)
    ) {
      localStorage.setItem('isRefresing', 'yes')

      await api
        .refreshApiToken()
        .then(() => window.location.reload())
        .catch(() => clearAuth())

      return Promise.reject()
    }

    return Promise.resolve()
  },

  checkAuth: async () => {
    return localStorage.getItem('authenticated')
      ? Promise.resolve()
      : Promise.reject()
  },

  getPermissions: () => {
    const roles = localStorage.getItem('userRoles')
    if (window.location.href.includes('recover-password')) {
      return Promise.resolve([]) // make recover-password pages available without authentication
    }
    return roles.length === 0 ? Promise.reject() : Promise.resolve(roles)
  },
}
