import React from 'react'
import { useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import { makeStyles } from '@material-ui/core/styles'
import { formatPrice } from 'utils/string'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginTop: '3em',
  },
  th: {
    width: '130px',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: '0.5em',
  },
}))

export const OrderLinesTable = ({ lines, currencyCode }) => {
  const translate = useTranslate()

  if (!lines?.length) {
    return null
  }

  return (
    <Table aria-label="simple table" size="medium">
      <TableHead>
        <TableRow>
          <TableCell>{translate('resources.order.lines.id')}</TableCell>
          <TableCell>
            {translate('resources.order.lines.description')}
          </TableCell>
          <TableCell>{translate('resources.order.lines.type')}</TableCell>
          <TableCell>{translate('resources.order.lines.quantity')}</TableCell>
          <TableCell>{translate('resources.order.lines.amount')}</TableCell>
          <TableCell>{translate('resources.order.lines.tax')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((row) => (
          <TableRow key={`Table-Row-key-${row.id}`}>
            <TableCell align="left">{row.entity_id}</TableCell>
            <TableCell align="left">{row.description}</TableCell>
            <TableCell align="left">{row.entity_type}</TableCell>
            <TableCell align="left">
              {row.fulfillment_quantity || row.quantity}
            </TableCell>
            <TableCell align="left">
              {formatPrice(row.amount / 100, currencyCode)}
            </TableCell>
            <TableCell align="left">
              {formatPrice(row.tax_amount / 100, currencyCode)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export const ChargeBeeLinesCard = ({ record }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const data = record.chargeBeeData || null

  if (!data) {
    return null
  }

  const lines = data.order_line_items || []

  return (
    <Paper className={classes.paper} variant="outlined" square>
      <Typography className={classes.title} variant="h6">
        {translate('resources.order.cards.chargeBeeLines')}
      </Typography>
      <OrderLinesTable lines={lines} currencyCode={data.currency_code} />
    </Paper>
  )
}
