import FastfoodIcon from '@material-ui/icons/Fastfood'

import { ROLES } from 'utils/user'

import { BundleList } from './BundleList'
import { BundleEdit } from './BundleEdit'
import { BundleCreate } from './BundleCreate'

export default {
  list: BundleList,
  edit: BundleEdit,
  create: BundleCreate,
  icon: FastfoodIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
