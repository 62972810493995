import React, { useState } from 'react'
import styled from 'styled-components'
import { formatDateTime } from 'utils/string'
import CommentModal from './CommentModal'
import useApi from 'hooks/useApi'
import { useNotify, Confirm, useDelete } from 'react-admin'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined'
import { FileField } from 'components/fileField'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2px;
  width: 278px;
`

const CommentWrapper = styled.li`
  display: flex;
  flex-direction: column;
  border: 1px solid #21262c;
  padding: 8px;
  border-radius: 8px;
  gap: 4px;
  text-align: left;
`

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`

const Note = styled.span`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  white-space: pre-wrap;
  word-break: break-word;
`

const DateText = styled.span`
  flex-shrink: 0;
  scale: 0.9;
`

const Author = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  color: darkgray;
`

const Comment = ({ comment, refetchList }) => {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const { isSubmitting } = useApi()
  const id = comment?.id
  const [deleteOne] = useDelete('/customer-comments', id, comment)

  const notify = useNotify()

  const onDelete = async (event) => {
    event.preventDefault()
    try {
      await deleteOne()
    } catch (error) {
      notify('Could not delete comment', 'error')
    }
    refetchList && refetchList()
  }

  const created = new Date(comment.createdAt)
  const threeMonthsAgo = new Date()
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 4)

  const tooOldToEdit = Number(threeMonthsAgo) > Number(created)

  const authorEmail = comment?.createdBy?.email

  const isAuthor = authorEmail === localStorage.getItem('userEmail')

  const canEdit = isAuthor && !tooOldToEdit

  return (
    <CommentWrapper>
      <Row>
        <Note>{comment?.notes}</Note>
        <Actions>
          <CommentModal
            comment={comment}
            refetchList={refetchList}
            canEdit={canEdit}
          />
          {canEdit && (
            <>
              <IconButton onClick={() => setOpenConfirmDelete(true)}>
                <DeleteIcon />
              </IconButton>
              <Confirm
                isOpen={openConfirmDelete}
                loading={isSubmitting}
                title="Are you sure you want to delete this comment?"
                content={<Note>{comment?.notes}</Note>}
                onConfirm={onDelete}
                onClose={() => setOpenConfirmDelete(false)}
              />
            </>
          )}
        </Actions>
      </Row>
      <FileField file={comment?.file} />

      <Row>
        <Author>{authorEmail}</Author>
        {comment?.createdAt && (
          <DateText>{formatDateTime(new Date(comment.createdAt))}</DateText>
        )}
      </Row>
    </CommentWrapper>
  )
}

export default Comment
