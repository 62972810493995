import React, { useEffect, useState, useCallback } from 'react'
import { maxLength } from 'react-admin'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'
import { useCustomer } from '../contexts/customer'
import { debounce } from 'lodash'

const FormWrapper = styled.div`
  display: flex;
`

export const CommentStep = () => {
  const { comment, setComment } = useCustomer()
  const [value, setValue] = useState(comment)

  /* eslint-disable react-hooks/exhaustive-deps */
  const debouncedUpdate = useCallback(debounce(setComment, 500), [setComment])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    debouncedUpdate(value)
  }, [value, debouncedUpdate])

  return (
    <FormWrapper>
      <TextField
        multiline
        variant="outlined"
        label="Customer comment"
        value={value}
        rows={3}
        onChange={(e) => {
          e.persist()
          setValue(e.target.value)
        }}
        fullWidth
        validate={[maxLength(200)]}
      />
    </FormWrapper>
  )
}
