import React, { cloneElement } from 'react'
import {
  TextField,
  Filter,
  TextInput,
  useListContext,
  DateInput,
  SimpleForm,
  required,
  maxValue,
} from 'react-admin'
import { Button, Popover } from '@material-ui/core'
import { ListGuesser, DateField } from 'theme/components'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { CustomToolbar } from 'theme/components/toolbar/CustomToolbar'
import ExportIcon from '@material-ui/icons/SaveAlt'
import { exporter } from './exporter'
import styled from 'styled-components'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User" source="user" />
    <TextInput source="customerChargeBeeId" />
    <TextInput label="Entity" source="entityType.name" />
    <TextInput label="Event" source="type.name" />
  </Filter>
)

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0;
  gap: 4px;
`

const ListActions = (props) => {
  const { filters } = props
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext()

  return (
    <Toolbar>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <PopupState variant="popover" popupId="event-export-popover">
        {(popupState) => (
          <div>
            <Button {...bindTrigger(popupState)} size="small" color="primary">
              <ExportIcon style={{ marginRight: '4px' }} />
              export
            </Button>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <SimpleForm
                save={(values) => {
                  exporter(values)

                  popupState.close()
                }}
                toolbar={
                  <CustomToolbar
                    saveButtonProps={{ label: 'export', icon: <ExportIcon /> }}
                  />
                }
                variant="outlined"
                validate={(values) => {
                  const errors = {}

                  const start = Date.parse(values.startDate)
                  const end = Date.parse(values.endDate)

                  var diff = Math.floor((end - start) / 86400000)

                  if (diff > 31) {
                    errors.startDate = 'Range should not be more than 31 day'
                    errors.endDate = 'Range should not be more than 31 day'
                  }

                  return errors
                }}
              >
                <DateInput validate={required()} source="startDate" />
                <DateInput
                  validate={[
                    required(),
                    maxValue(new Date().toISOString().split('T')[0]),
                  ]}
                  source="endDate"
                />
              </SimpleForm>
            </Popover>
          </div>
        )}
      </PopupState>
    </Toolbar>
  )
}

export const EventList = (props) => (
  <ListGuesser
    actions={<ListActions />}
    filters={<ListFilter />}
    {...props}
    hasEdit={false}
    rowClick="show"
    bulkActionButtons={false}
    filter={{
      properties: ['id', 'user', 'createdAt', 'entityType'],
      'properties[store]': ['name'],
      'properties[type]': ['name'],
      'properties[source]': ['name'],
    }}
  >
    <TextField source="originId" label="resources.id" sortBy="id" />
    <TextField source="store.name" label="Store" />
    <TextField source="type.name" label="Event" />
    <TextField source="entityType.name" label="Entity" />
    <TextField source="source.name" label="Source" />
    <TextField source="user" />
    <DateField source="createdAt" />
  </ListGuesser>
)
