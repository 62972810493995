import EuroIcon from '@material-ui/icons/Subscriptions'
import { ROLES } from 'utils/user'

import { CBSubscriptionsList } from './CBSubscriptionsList'
import { CBSubscriptionsShow } from './CBSubscriptionsShow'

export default {
  list: CBSubscriptionsList,
  show: CBSubscriptionsShow,
  icon: EuroIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_FINANCE],
}
