import Icon from '@material-ui/icons/AccountBox'
import { ROLES } from 'utils/user'

import { CustomerList } from './CustomerList'
import { CustomerShow } from './CustomerShow'

export default {
  list: CustomerList,
  show: CustomerShow,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
