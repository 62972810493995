import React, { createContext, useContext, useMemo } from 'react'
import { getNumberFromStringId } from 'utils/string'

const storeContext = createContext()

const StoreProvider = ({ children, store }) => {
  const storeId = useMemo(() => {
    return store ? getNumberFromStringId(store['@id']) : null
  }, [store])
  return (
    <storeContext.Provider value={{ store, storeId }}>
      {children}
    </storeContext.Provider>
  )
}

const useStore = () => useContext(storeContext)

export { useStore, StoreProvider }
