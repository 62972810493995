import React from 'react'
import { Button } from 'react-admin'
import DeleteIcon from '@material-ui/icons/Delete'
import useApi from 'hooks/useApi'

const RemoveGroupButton = (props) => {
  const { record } = props
  const { api, isSubmitting, handleError } = useApi()

  const handleRemoveGroup = async (planId) => {
    try {
      await api.removeGroupFromPlan(planId)
      setTimeout(() => window.location.reload(), 500)
    } catch (error) {
      handleError(error)
    }
  }

  return record?.group ? (
    <Button
      style={{ marginBottom: '28px' }}
      type="button"
      variant="contained"
      color="primary"
      onClick={() => handleRemoveGroup(record.originId)}
      size="small"
      label="Remove group"
      disabled={isSubmitting}
    >
      <DeleteIcon />
    </Button>
  ) : null
}

export default RemoveGroupButton
