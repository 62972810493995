import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStore } from '../contexts/store'
import {
  Switch,
  FormControlLabel,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from '@material-ui/core'
import { useNotify } from 'react-admin'
import { useCurrentSession } from '../contexts/currentSession'
import { Subscriptions } from '../subscriptions'
import { useCustomer } from '../contexts/customer'
import { camelCaseToWords, formatDate } from 'utils/string'
import { usePageState } from '../contexts/pageState'
import useApi from 'hooks/useApi'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

const BoxesWrapper = styled.div`
  display: flex;
  gap: 20px;
`

const FieldsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`

const FieldTitle = styled.b`
  display: inline-block;

  &:first-letter {
    text-transform: capitalize;
  }
`

const StatusSelect = styled.div`
  width: 480px;
  margin-top: 20px;
`

const isDateKey = (key) => {
  key = key.toLowerCase()
  return key.indexOf('unknown') === -1 && key.indexOf('date') > -1
}

const statuses = [
  { value: 1, label: 'Pending' },
  { value: 3, label: 'Closed' },
]

export const PreviewStep = () => {
  const { api, isSubmitting, handleError } = useApi()
  const notify = useNotify()
  const { setActiveStep } = usePageState()

  const { store } = useStore()
  const { isInvoice, setIsInvoice, session, productFlow } = useCurrentSession()
  const {
    billingAddress,
    personalInfo,
    shippingAddress,
    customerMidlayerId,
  } = useCustomer()
  const [sessionStatus, setSessionStatus] = useState()

  const personalKeys = Object.keys(personalInfo)
  const billingKeys = Object.keys(billingAddress)
  const shippingKeys = Object.keys(shippingAddress)

  const details = [
    {
      step: 'personal_details',
      label: 'Personal info',
      keys: personalKeys,
      obj: personalInfo,
    },
    {
      step: 'address',
      label: 'Billing address',
      keys: billingKeys,
      obj: billingAddress,
    },
    {
      step: 'address',
      label: 'Shipping address',
      keys: shippingKeys,
      obj: shippingAddress,
    },
  ]

  const handleChangeSessionStatus = async (value) => {
    try {
      await api.updateSessionStatus(session.originId, { status: value })
      setSessionStatus(value)
      notify('session status has been updated')
    } catch (error) {
      handleError(error)
    }
  }

  useEffect(() => {
    if (session) {
      setSessionStatus(session?.status)
    }
  }, [session])

  return (
    <Wrapper>
      <BoxesWrapper>
        {details.map(({ keys, obj, label, step }) => {
          if (customerMidlayerId && label === 'Billing address') return null
          if (!keys.length) return null
          return (
            <Button
              key={label}
              onClick={() => setActiveStep(step)}
              variant="outlined"
              fullWidth
            >
              <FieldsBox>
                <Typography variant="h6">{label}</Typography>
                {keys.map(
                  (key) =>
                    !!obj[key] && (
                      <Tooltip
                        key={key}
                        title={
                          <span>
                            {camelCaseToWords(key)}:&nbsp;
                            {isDateKey(key) ? formatDate(obj[key]) : obj[key]}
                          </span>
                        }
                      >
                        <Typography
                          variant="body2"
                          display="block"
                          key={`field-${key}`}
                          style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <FieldTitle>
                            {camelCaseToWords(key)}:&nbsp;
                          </FieldTitle>
                          {isDateKey(key) ? formatDate(obj[key]) : obj[key]}
                        </Typography>
                      </Tooltip>
                    )
                )}
              </FieldsBox>
            </Button>
          )
        })}
      </BoxesWrapper>
      {!!store.allowInvoice && productFlow === 'v1' && (
        <FormControlLabel
          style={{ margin: '10px 0' }}
          control={
            <Switch
              checked={isInvoice}
              onChange={() => setIsInvoice((cur) => !cur)}
              name="Create payment on invoice"
              color="primary"
            />
          }
          label="Create payment on invoice"
        />
      )}

      <Subscriptions />

      {!!session && (
        <StatusSelect>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Session status</InputLabel>
            <Select
              disabled={isSubmitting}
              value={sessionStatus || ''}
              label="Session status"
              onChange={(e) => handleChangeSessionStatus(e.target.value)}
            >
              {statuses.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </StatusSelect>
      )}
    </Wrapper>
  )
}
