import { TabbedShowLayout } from 'react-admin'
import styled from 'styled-components'
import { colors } from 'theme/MuiTheme'

export const OverflowingTabbedShowLayout = styled(TabbedShowLayout)`
  & .MuiTabs-flexContainer {
    flex-wrap: wrap;
    margin-bottom: 12px;
    border-bottom: 1px solid darkgray;
    padding: 0 0 12px 0px;
  }

  & .MuiTabs-indicator,
  hr {
    display: none;
  }

  & .show-tab {
    border: 2px solid transparent;
  }

  & .Mui-selected {
    border: 2px solid ${colors.primary};
    border-radius: 8px;
    color: ${colors.primary};
  }
`
