import Icon from '@material-ui/icons/Timer'

import { ProductStoreIntervalCreate } from './ProductStoreIntervalCreate'
import { ProductStoreIntervalList } from './ProductStoreIntervalList'
import { ProductStoreIntervalEdit } from './ProductStoreIntervalEdit'
import { ROLES } from 'utils/user'

export default {
  create: ProductStoreIntervalCreate,
  list: ProductStoreIntervalList,
  edit: ProductStoreIntervalEdit,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN],
}
