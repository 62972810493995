import React, { useEffect } from 'react'

import { ReferenceManyField, FunctionField } from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { formatText, formatPrice } from 'utils/string'

import { useCustomer } from 'components/pages/customer/contexts/customer'

export const ShortCustomerInvoicesList = ({
  ListStats,
  setTotal,
  ...props
}) => {
  const customer = useCustomer()

  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  if (!customer?.chargeBeeId) return null

  return (
    <ReferenceManyField
      addLabel={false}
      reference="invoices"
      source="id"
      filter={{
        'customer.chargeBeeId': customer.chargeBeeId,
        properties: ['id', 'createdAt', 'data', 'paymentLink'],
      }}
      target=""
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={() => {}}>
        <FunctionField
          label="Price"
          render={(record) => {
            if (!record?.data) return '...'
            const {
              data: { amount_due, amount_paid, currency_code },
            } = record

            return (
              <>
                {ListStats}
                {formatPrice((amount_due || amount_paid) / 100, currency_code)}
              </>
            )
          }}
        />
        <FunctionField
          label="Status"
          render={(record) => {
            const {
              data: { status },
            } = record

            return formatText(status)
          }}
        />
        <DateField source="createdAt" label="Created at" />
      </DataGrid>
    </ReferenceManyField>
  )
}
