import React from 'react'
import { ShowGuesser } from '@api-platform/admin'
import { TextField, FunctionField } from 'react-admin'
import sanitizeHtml from 'sanitize-html'

export const FreshDeskTicketShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <h1>Fresh desk ticket details</h1>

      <TextField source="ticketId" label="Ticket id" />

      <FunctionField
        label="Ticket Subject"
        render={(record) => {
          const data = JSON.parse(record.data)

          return data?.freshdesk_webhook?.ticket_subject || data?.ticket_subject
        }}
      />
      <FunctionField
        label="Ticket Status"
        render={(record) => {
          const data = JSON.parse(record.data)

          return data?.freshdesk_webhook?.ticket_status || data?.ticket_status
        }}
      />
      <FunctionField
        label="Ticket Url"
        render={(record) => {
          const data = JSON.parse(record.data)
          const url = data?.freshdesk_webhook?.ticket_url || data?.ticket_url

          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          )
        }}
      />
      <FunctionField
        label="Ticket Portal Url"
        render={(record) => {
          const data = JSON.parse(record.data)
          const url =
            data?.freshdesk_webhook?.ticket_portal_url ||
            data?.ticket_portal_url
          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {url}
            </a>
          )
        }}
      />
      <FunctionField
        label="Ticket Description"
        render={(record) => {
          const data = JSON.parse(record.data)

          let sanitizedHTML = sanitizeHtml(
            data?.freshdesk_webhook?.ticket_description ||
              data?.ticket_description
          )

          return (
            <>
              {sanitizedHTML ? (
                <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
              ) : null}
            </>
          )
        }}
      />
    </ShowGuesser>
  )
}
