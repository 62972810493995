import React from 'react'
import { SelectInput, ReferenceInput, TextInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const ClusterCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <TextInput source="name" />
    <ReferenceInput
      source="region"
      reference="regions"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
)
