import Icon from '@material-ui/icons/AddCircle'
import { CreditNoteCreate } from './CreditNoteCreate'
import { CreditNoteList } from './CreditNoteList'
import { CreditNoteShow } from './CreditNodeShow'
import { ROLES } from 'utils/user'

export default {
  create: CreditNoteCreate,
  list: CreditNoteList,
  show: CreditNoteShow,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
