import React, { useState, useCallback, useEffect } from 'react'
import { Button, useDataProvider } from 'react-admin'
import ShippingAddress from './ShippingAddress'
import BillingAddress from './BillingAddress'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { useCustomer } from '../../contexts/customer'
import { useStore } from '../../contexts/store'
import { getNumberFromStringId } from 'utils/string'
import useApi from 'hooks/useApi'
import { colors } from 'theme/MuiTheme'

const ignoredAddressKeys = new Set([
  'object',
  'validation_status',
  'email',
  'company',
])

const filterUniqueAddresses = (arr) => {
  const seen = new Set()

  return arr.filter((obj) => {
    const key = JSON.stringify(
      Object.keys(obj)
        .sort()
        .filter((k) => !ignoredAddressKeys.has(k))
        .map((k) => [k, obj[k]])
    )

    if (seen.has(key)) return false

    seen.add(key)
    return true
  })
}

const AddressBox = styled.div`
  border: 1px solid;
  padding: 15px;
  border-radius: 15px;
  cursor: pointer;

  &.active {
    background-color: ${colors.primary};
    color: white;
    border-color: ${colors.primary};
  }
`

const AddressBoxHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin: 20px 0;
`

const fieldsMap = {
  first_name: 'firstName',
  last_name: 'lastName',
  phone: 'phone',
  line1: 'line1',
  line2: 'line2',
  line3: 'line3',
  zip: 'zip',
  city: 'city',
  country: 'country',
  state: 'state',
}

export const AddressStep = () => {
  const { store, storeId } = useStore()
  const { api } = useApi()
  const dataProvider = useDataProvider()
  const {
    billingAddress,
    shippingAddress,
    setShippingAddress,
    customerMidlayerId,
  } = useCustomer()
  const [selectedAddressBox, setSelectedAddressBox] = useState(null)
  const [isBillingSameAsShipping, setIsBillingSame] = useState(false)
  const [countries, setCountries] = useState(null)
  const [existingAddresses, setExistingAddresses] = useState([])
  const [customerSubscriptions, setCustomerSubscriptions] = useState([])
  const [initialValuesHandled, setInitialValuesHandled] = useState()

  useEffect(() => {
    if (initialValuesHandled) return
    setInitialValuesHandled(true)

    const keys = Object.keys(shippingAddress)

    for (let i = 0; i < keys.length; i++) {
      if (shippingAddress[keys[i]] !== billingAddress[keys[i]]) {
        setIsBillingSame(false)
        return
      }
    }

    setIsBillingSame(true)
  }, [billingAddress, shippingAddress, initialValuesHandled])

  useEffect(() => {
    if (selectedAddressBox !== null && existingAddresses[selectedAddressBox]) {
      const newShippingAddress = {}

      for (const key in fieldsMap) {
        if (!fieldsMap[key]) continue
        newShippingAddress[fieldsMap[key]] =
          existingAddresses[selectedAddressBox][key]
      }
      setShippingAddress(newShippingAddress)
    }
  }, [selectedAddressBox, existingAddresses, setShippingAddress])

  const allowedFields = {
    state: store?.customerStateEnabled,
    line3: store?.customerAddressLine3Enabled,
  }

  const fetchCounties = useCallback(async () => {
    const { data } = await dataProvider.getList('shipping-country-stores', {
      pagination: {},
      sort: {},
      filter: { itemsPerPage: 999, 'store.id': storeId },
    })

    setCountries(
      data.map((item) => ({ id: item?.country?.code, name: item?.name }))
    )
  }, [dataProvider, storeId])

  const fetchAddresses = useCallback(
    (customerMidlayerId) => {
      api
        .getCustomerSubscriptions(getNumberFromStringId(customerMidlayerId))
        .then((res) => {
          if (!res) {
            return
          }

          const addresses = res.data
            .map((item) => item.shipping_address)
            .filter(Boolean)

          if (Object.keys(billingAddress).length) {
            addresses.push(billingAddress)
          }

          const uniqueAddresses = filterUniqueAddresses(addresses)

          setCustomerSubscriptions(res.data)
          setExistingAddresses(uniqueAddresses)
        })
    },
    [api, billingAddress]
  )

  useEffect(() => {
    if (customerMidlayerId) {
      fetchAddresses(customerMidlayerId)
    }
  }, [customerMidlayerId, fetchAddresses])

  useEffect(() => {
    if (!store) return
    fetchCounties()
  }, [store, fetchCounties])

  useEffect(() => {
    if (isBillingSameAsShipping && !customerMidlayerId) {
      setShippingAddress(billingAddress)
    }
  }, [
    isBillingSameAsShipping,
    billingAddress,
    setShippingAddress,
    customerMidlayerId,
  ])

  const updateAllSubscriptionAddresses = () => {
    if (existingAddresses[selectedAddressBox]) {
      Promise.all(
        customerSubscriptions.map(async (item) => {
          await api.updateChargebeeSubscription(item.internal_id, {
            shippingAddress: {
              ...existingAddresses[selectedAddressBox],
              firstName: existingAddresses[selectedAddressBox]?.first_name,
              lastName: existingAddresses[selectedAddressBox]?.last_name,
            },
          })
        })
      ).then(() => {
        fetchAddresses(customerMidlayerId)
        setSelectedAddressBox(0)
      })
    }
  }

  if (!store) {
    return null
  }

  if (customerMidlayerId) {
    return (
      <>
        {!!existingAddresses?.length && (
          <>
            <Typography variant="body1">Existing addresses</Typography>
            <AddressBoxHolder>
              {existingAddresses.map((item, key) => {
                return (
                  <AddressBox
                    key={key}
                    onClick={() => setSelectedAddressBox(key)}
                    className={selectedAddressBox === key ? 'active' : ''}
                  >
                    {Object.keys(fieldsMap).map(
                      (field) =>
                        !!item[field] && (
                          <Typography
                            variant="body2"
                            display="block"
                            key={`field-${field}`}
                          >
                            {item[field]}
                          </Typography>
                        )
                    )}
                  </AddressBox>
                )
              })}
            </AddressBoxHolder>
            <Button
              style={{ margin: '0 0 15px 0' }}
              label="update all subscription addresses"
              onClick={updateAllSubscriptionAddresses}
              size="medium"
              variant="contained"
              fullWidth
              disabled={existingAddresses.length === 0}
            />
          </>
        )}

        <ShippingAddress countries={countries} allowedFields={allowedFields} />
      </>
    )
  }

  return (
    <>
      <BillingAddress countries={countries} allowedFields={allowedFields} />

      <FormControlLabel
        style={{ margin: '8px 0' }}
        control={
          <Checkbox
            checked={isBillingSameAsShipping}
            onChange={() => setIsBillingSame((cur) => !cur)}
          />
        }
        label="Billing same as shipping"
      />
      {!isBillingSameAsShipping && (
        <ShippingAddress countries={countries} allowedFields={allowedFields} />
      )}
    </>
  )
}
