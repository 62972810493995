import { useCustomer } from 'components/pages/customer/contexts/customer'
import React from 'react'
import {
  TextField,
  ReferenceManyField,
  Pagination,
  FunctionField,
} from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { ResendEmailButton } from './ResendButton'
import { Button } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

export const FlowmailerEmailsList = (props) => {
  const { customerId } = useCustomer()
  return (
    <ReferenceManyField
      addLabel={false}
      reference="email-logs"
      source="id"
      pagination={<Pagination />}
      target=""
      filter={{
        'customer.id': customerId,
        properties: [
          'email',
          'sendDate',
          'subject',
          'data',
          'id',
          'onlineLink',
          'status',
        ],
        'properties[data]': ['flow'],
      }}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={() => {}}>
        <TextField source="email" label="Sent to" />
        <DateField source="sendDate" short={false} label="Sent on" />
        <TextField source="subject" />
        <TextField source="data.flow.description" label="Flow" />
        <TextField source="status" label="Status" />
        <FunctionField
          label=""
          render={(record) => <ResendEmailButton record={record} />}
        />
        <FunctionField
          label=""
          render={(record) =>
            record.onlineLink && (
              <Button
                target="_blank"
                component="a"
                href={record.onlineLink}
                endIcon={<MailOutlineIcon />}
              >
                open
              </Button>
            )
          }
        />
      </DataGrid>
    </ReferenceManyField>
  )
}
