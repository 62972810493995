import React from 'react'
import { TextField } from 'react-admin'
import { DateField, ListGuesser } from 'theme/components'

export const PimcoreProductList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    filter={{
      properties: ['id', 'createdAt', 'name', 'pimcoreId', 'sku'],
    }}
    bulkActionButtons={false}
    rowClick="show"
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <TextField source="pimcoreId" />
    <TextField source="sku" />
    <DateField source="createdAt" short={false} />
  </ListGuesser>
)
