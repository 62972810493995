import React, { useEffect, useState } from 'react'
import { Button } from 'react-admin'
import useApi from 'hooks/useApi'
import { getApiRoute } from 'service/Api/routes'
import { getFormattedResponse } from 'utils/hydra'

export const UpgradeDowngrade = ({ bundleId, storeId, changeTo }) => {
  const { api, isSubmitting } = useApi()
  const [upgradeTo, setUpgradeTo] = useState()
  const [downgradeTo, setDowngradeTo] = useState()
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    if (!bundleId || !storeId || isSubmitting || fetched) return

    api
      .get(getApiRoute('bundle-stores'), {
        itemsPerPage: 1,
        'bundle.id': bundleId,
        'store.id': storeId,
        'properties[upgradeTo]': ['id'],
        'properties[downgradeTo]': ['id'],
      })
      .then((res) => {
        setFetched(true)
        const { items } = getFormattedResponse(res)
        setUpgradeTo(items[0]?.upgradeTo?.id)
        setDowngradeTo(items[0]?.downgradeTo?.id)
      })
  }, [bundleId, api, storeId, isSubmitting, fetched])

  return (
    <div>
      <Button
        disabled={!downgradeTo}
        color="primary"
        onClick={() => changeTo(`/api/bundles/${downgradeTo}`)}
        size="small"
        label="downgrade"
      />
      <Button
        disabled={!upgradeTo}
        color="primary"
        onClick={() => changeTo(`/api/bundles/${upgradeTo}`)}
        size="small"
        label="upgrade"
      />
    </div>
  )
}
