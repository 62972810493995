import React from 'react'
import {
  TextField,
  ReferenceManyField,
  Pagination,
  FunctionField,
} from 'react-admin'
import { DateField, DataGrid, ReferenceListFilterField } from 'theme/components'
import { formatPrice, formatText } from 'utils/string'
import { useCustomer } from 'components/pages/customer/contexts/customer'

export const CustomerCreditNotesList = (props) => {
  const { customerId } = useCustomer()

  return (
    <ReferenceManyField
      addLabel={false}
      reference="credit-notes"
      source="id"
      pagination={<Pagination />}
      target=""
      filter={{
        'customer.id': customerId,
        properties: [
          'id',
          'chargeBeeId',
          'invoice',
          'data',
          'status',
          'createdAt',
        ],
        'properties[invoice]': ['chargeBeeId'],
        'properties[data]': ['total', 'create_reason_code', 'currency_code'],
      }}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick="show">
        <TextField source="originId" label="id" />
        <TextField source="chargeBeeId" />
        <ReferenceListFilterField
          listUri="/invoices"
          titlePath="invoice.chargeBeeId"
          valuePath="invoice.chargeBeeId"
          label="Invoice"
        />
        <FunctionField
          label="Status"
          source="status"
          render={(record) => formatText(record.status)}
        />
        <DateField source="createdAt" />
        <FunctionField
          label="Amount"
          source="amount"
          render={(record) => {
            const {
              data: { total, currency_code },
            } = record

            return formatPrice(total / 100, currency_code)
          }}
        />
        <TextField source="data.create_reason_code" label="Reason" />
      </DataGrid>
    </ReferenceManyField>
  )
}
