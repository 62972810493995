import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'
import useApi from 'hooks/useApi'
import { PLAN_SCREENS, reloadPage } from '../utils'
import { getUrlParams, parseUrlIdsParam } from 'utils/uri'

export default (productId, addedStoresIds, addedIntervalsIds, setLoading) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const { api, handleError } = useApi()

  const [screen, setScreen] = useState(
    getUrlParams().screen || PLAN_SCREENS.ADDED_PLANS
  )
  const [addedPlans, setAddedPLans] = useState([])
  const [optionsPlans, setOptionsPlans] = useState([])
  const [relatedStoresProductPlans, setRelatedStoresProductPlans] = useState([])

  const fetchPlans = useCallback(
    async (filter = {}) => {
      const { data } = await dataProvider.getList('plans', {
        pagination: {},
        sort: {},
        filter: {
          itemsPerPage: 999,
          properties: [
            'id',
            'allowUnpaid',
            'enabledInPortal',
            'enabledInHostedPages',
            'chargeBeeId',
            'createdAt',
          ],
          'properties[productStoreInterval]': ['id', 'name'],
          'properties[group]': ['id', 'name'],
          ...filter,
        },
      })

      return data
    },
    [dataProvider]
  )

  const fetchStoresProductPlans = useCallback(async () => {
    const data = await fetchPlans({
      'productStoreInterval.productStore.store.id': parseUrlIdsParam(
        addedStoresIds
      ),
      'productStoreInterval.iterval.id': parseUrlIdsParam(addedIntervalsIds),
    })

    setRelatedStoresProductPlans(data)
  }, [addedStoresIds, addedIntervalsIds, fetchPlans])

  const fetchAddedPlans = useCallback(async () => {
    const data = await fetchPlans({
      'productStoreInterval.productStore.product.id': productId,
    })

    setAddedPLans(data)
  }, [fetchPlans, productId])

  const fetchOptionsPlans = useCallback(async () => {
    const data = await fetchPlans({
      'productStoreInterval.productStore.store.id': parseUrlIdsParam(
        addedStoresIds
      ),
    })

    setOptionsPlans(data)
  }, [addedStoresIds, fetchPlans])

  const onAddPlanSubmit = useCallback(
    async (values) => {
      try {
        await api.createPlan(values)

        notify('Success', 'info')

        reloadPage()
        redirect(`/products/${encodeURIComponent(productId)}/4`)
        setLoading(true)
      } catch (error) {
        handleError(error)
      }
    },
    [api, notify, redirect, productId, setLoading, handleError]
  )

  const onAddPlanClick = useCallback(() => {
    setScreen(PLAN_SCREENS.ADD_PLAN)
  }, [])

  const onBackClickPlans = useCallback(() => {
    redirect(`/products/${encodeURIComponent(productId)}/4`)
    setScreen(PLAN_SCREENS.ADDED_PLANS)
  }, [productId, redirect])

  const addedPlanIds = useMemo(() => addedPlans.map((item) => item['@id']), [
    addedPlans,
  ])

  useEffect(() => {
    fetchAddedPlans()
    fetchOptionsPlans()
  }, [fetchAddedPlans, fetchOptionsPlans])

  useEffect(() => {
    if (addedStoresIds.length && addedIntervalsIds.length) {
      fetchStoresProductPlans()
    }
  }, [addedStoresIds, addedIntervalsIds, fetchStoresProductPlans])

  return {
    screen,
    relatedStoresProductPlans,
    addedPlans,
    addedPlanIds,
    optionsPlans,
    onAddPlanClick,
    onAddPlanSubmit,
    onBackClickPlans,
  }
}
