import Icon from '@material-ui/icons/GroupWork'
import { GroupList } from './GroupList'
import { GroupCreate } from './GroupCreate'
import { GroupEdit } from './GroupEdit'
import { ROLES } from 'utils/user'

export default {
  list: GroupList,
  create: GroupCreate,
  edit: GroupEdit,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT, ROLES.ROLE_WORDPRESS],
}
