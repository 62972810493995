import Icon from '@material-ui/icons/Assignment'
import { ROLES } from 'utils/user'

import { FreshDeskTicketList } from './FreshDeskTicketList'
import { FreshDeskTicketShow } from './FreshDeskTicketShow'

export default {
  list: FreshDeskTicketList,
  show: FreshDeskTicketShow,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
