import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

export const exporter = (productStores) => {
  const rows = productStores.map((productStore) => ({
    id: productStore.originId,
    Store: productStore.store.name,
    Product: productStore.product.name,
    Sku: productStore.sku,
  }))

  jsonExport(
    rows,
    {
      headers: ['id', 'Store', 'Product', 'Sku'],
      rowDelimiter: ';',
    },
    (err, csv) => {
      downloadCSV(csv, `product_stores`)
    }
  )
}
