import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'

import { OrderResendReasonList } from './OrderResendReasonList'
import { OrderResendReasonCreate } from './OrderResendReasonCreate'
import { OrderResendReasonEdit } from './OrderResendReasonEdit'
import { ROLES } from 'utils/user'

export default {
  list: OrderResendReasonList,
  create: OrderResendReasonCreate,
  edit: OrderResendReasonEdit,
  icon: CancelPresentationIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_FINANCE],
}
