import React from 'react'
import { FieldGuesser, InputGuesser, ShowGuesser } from '@api-platform/admin'
import {
  ReferenceInput,
  AutocompleteInput,
  Tab,
  TextField,
  ReferenceManyField,
  List,
  BulkDeleteWithConfirmButton,
  BooleanField,
} from 'react-admin'
import {
  DataGrid,
  EditGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import { sanitizeProps } from 'utils/sanitizer'
import { CreateAddonCard } from './card/CreateAdddonCard'
import RemoveGroupButton from './removeGroupButton'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

export const PlanEdit = React.memo(({ defaultToolbar, ...props }) => {
  return (
    <ShowGuesser {...props}>
      <OverflowingTabbedShowLayout>
        <Tab label="resources.plan.edit">
          <EditGuesser
            {...props}
            undoable={false}
            variant="outlined"
            redirect="list"
          >
            <TextField
              source="productStoreInterval.productStore.product.name"
              label="Product"
            />
            <TextField
              source="productStoreInterval.interval.name"
              emptyText="Non-recurring"
              label="Interval"
            />
            <ReferenceInput
              source="group"
              reference="groups"
              label="resources.group.field"
              filterToQuery={(searchText) => ({ name: searchText })}
              format={(v) => (v && v['@id'] ? v['@id'] : v)}
              perPage={50}
            >
              <AutocompleteInput
                optionText={(option) => {
                  if (!option?.store?.name) return option?.name
                  return `${option?.name} (${option?.store?.name})`
                }}
              />
            </ReferenceInput>
            <RemoveGroupButton />
            <InputGuesser source="allowUnpaid" />
            <InputGuesser
              source="enabledInPortal"
              label="resources.plan.editPlan.enabledInPortal"
            />
            <InputGuesser
              source="enabledInHostedPages"
              label="resources.plan.editPlan.enabledInHostedPages"
            />
            <InputGuesser source="chargeBeeId" />
            <InputGuesser source="redirectUrl" />
            <ReferenceInput
              source="productStoreInterval"
              reference="product-store-intervals"
              label="Product Store Interval"
              filterToQuery={(searchText) => ({
                productNameOrChargebeeId: searchText,
              })}
              format={(v) => (v && v['@id'] ? v['@id'] : v)}
            >
              <AutocompleteInput
                optionText={(option) =>
                  option
                    ? `${option.chargeBeeId} (${option.name}) - ${option.originId}`
                    : `${option?.chargeBeeId} - ${option?.originId}`
                }
              />
            </ReferenceInput>
          </EditGuesser>
        </Tab>
        <Tab label="resources.plan.addons">
          <ReferenceManyField
            addLabel={false}
            reference="addons"
            target="plan.id"
            source="id"
            filter={{
              'plan.id': props.id,
              properties: ['id', 'mandatory', 'recommended'],
              'properties[productStoreInterval]': [
                'id',
                'name',
                'enabledInPortal',
                'chargeBeeId',
              ],
            }}
          >
            <List
              exporter={false}
              bulkActionButtons={
                <BulkDeleteWithConfirmButton
                  {...sanitizeProps(props, [
                    'hasEdit',
                    'hasList',
                    'hasShow',
                    'hasCreate',
                  ])}
                  selectedIds={[]}
                />
              }
              {...props}
            >
              <DataGrid empty="resources.plan.list.addons" rowClick="edit">
                <TextField
                  source="originId"
                  label="id"
                  sortBy="id"
                  sort={{ field: 'id', order: 'DESC' }}
                />
                <ReferenceListFilterField
                  {...props}
                  label="resources.productStoreInterval.field"
                  listUri="/plans"
                  titlePath="productStoreInterval.name"
                  valuePath="productStoreInterval.name"
                  filterType="collection"
                />
                <TextField
                  label="PSI ChargeBeeId"
                  source="productStoreInterval.chargeBeeId"
                />
                <FieldGuesser source="mandatory" />
                <FieldGuesser source="recommended" />
                <BooleanField
                  label="Enabled in portal"
                  source="productStoreInterval.enabledInPortal"
                />
              </DataGrid>
            </List>
          </ReferenceManyField>
          <CreateAddonCard {...props} />
        </Tab>
      </OverflowingTabbedShowLayout>
    </ShowGuesser>
  )
})
