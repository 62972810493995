import React, { useState, useMemo } from 'react'
import {
  useNotify,
  useRefresh,
  usePermissions,
  Confirm,
  useRecordContext,
} from 'react-admin'
import useApi from 'hooks/useApi'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { hasRoles } from 'utils/user'
import ResyncButton from 'components/resyncButton'
import PhoneIcon from '@material-ui/icons/Phone'
import { CreateAppointmentModal } from 'components/createAppointmentModal'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { colors } from 'theme/MuiTheme'
import CommentModal from '../comments/CommentModal'
import { LogCallModal } from 'components/logCallModal'

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  & button,
  a {
    height: 30px;
  }
`

const ButtonsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`

const ChargebeeButton = () => {
  const { chargeBeeId, store } = useCustomer()
  if (!store) return null
  const { chargeBeeWebsite } = store
  if (!chargeBeeId || !chargeBeeWebsite) return null

  return (
    <Button
      fullWidth
      variant="outlined"
      onClick={(e) => {
        e.preventDefault()
        window.open(
          `https://${chargeBeeWebsite}.chargebee.com/admin-console/customers/${chargeBeeId}`,
          '_blank'
        )
      }}
    >
      Chargebee profile
    </Button>
  )
}

const RequestPaymentMethodButton = ({ email, customerId }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { api, isSubmitting } = useApi()
  const notify = useNotify()

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setConfirmOpen(true)}
      >
        Request payment method
      </Button>
      <Confirm
        isOpen={confirmOpen}
        loading={isSubmitting}
        title="Request payment method for a Customer"
        content={
          <span>
            The email will be sent&nbsp;to&nbsp;
            <span style={{ color: colors.primary }}>{email}</span>
          </span>
        }
        onConfirm={() => {
          api.requestPaymentSource(customerId).then((res) => {
            if (res) {
              setConfirmOpen(false)
              notify('Payment method requested')
            }
          })
        }}
        onClose={() => setConfirmOpen(false)}
      />
    </>
  )
}

const PaymentFlowButton = () => {
  const record = useRecordContext()
  const { api, isSubmitting } = useApi()
  const refresh = useRefresh()
  const notify = useNotify()

  return (
    <Button
      fullWidth
      variant="outlined"
      onClick={() => {
        api.migrateCustomerToAdyen(record.originId).then((res) => {
          if (res) {
            notify('Customer migrated')
            refresh()
          }
        })
      }}
      disabled={isSubmitting || record?.paymentFlow?.slug !== 'chargebee'}
    >
      Migrate to Adyen
    </Button>
  )
}

const CallCustomer = () => {
  const { freshDeskId } = useCustomer()
  if (!freshDeskId) return null

  return (
    <Button
      variant="contained"
      color="primary"
      target="_blank"
      component="a"
      href={`https://fitformebv.freshdesk.com/a/contacts/${freshDeskId}`}
      endIcon={<PhoneIcon />}
    >
      Call customer
    </Button>
  )
}

export const Actions = (props) => {
  const { permissions } = usePermissions()
  const { hasAdminRole } = useMemo(() => hasRoles(permissions), [permissions])
  const { customerId, email, phone } = useCustomer()
  const refresh = useRefresh()

  return (
    <ButtonsWrapper>
      <CallCustomer />

      <ChargebeeButton />

      <RequestPaymentMethodButton
        {...props}
        email={email}
        customerId={customerId}
      />

      <PaymentFlowButton {...props} />
      <ButtonsRow>
        <CommentModal refetchList={refresh} />
        <LogCallModal customerId={customerId} onSuccess={refresh} />
        {hasAdminRole && (
          <ResyncButton
            resourceEndpoint="customer"
            id={props.id.split('/').pop()}
            fullWidth
          />
        )}
        <CreateAppointmentModal customerId={customerId} phone={phone} />
      </ButtonsRow>
    </ButtonsWrapper>
  )
}
