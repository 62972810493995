import React, { cloneElement } from 'react'
import {
  TextField,
  Filter,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  SelectArrayInput,
  DateInput,
  SelectInput,
  FunctionField,
  useListContext,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import { CreateAppointmentModal } from 'components/createAppointmentModal'
import styled from 'styled-components'

const Action = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 7px;
  align-items: center;
`

const STATUS = {
  1: 'Call scheduled',
  2: 'Completed call',
}

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="customer.id"
      reference="customers"
      label="Customer email"
      referenceSource="email"
      filterToQuery={(searchText) => ({ email: searchText })}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <ReferenceArrayInput
      source="customer.store.id"
      reference="stores"
      label="resources.store.field"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <DateInput
      label="Created (from date)"
      defaultValue={new Date()}
      source="createdAt<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Created (to date)"
      defaultValue={new Date()}
      source="createdAt<before>"
      locales="nl-Nl"
    />
    <DateInput
      label="Start (from date)"
      defaultValue={new Date()}
      source="startTime<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Start (to date)"
      defaultValue={new Date()}
      source="startTime<before>"
      locales="nl-Nl"
    />
    <SelectInput
      source="status"
      choices={[
        { id: 1, name: 'Scheduled' },
        { id: 2, name: 'Completed' },
        { id: 'default', name: 'Default' },
      ]}
    />
    <ReferenceInput
      source="reason.id"
      reference="call-reasons"
      label="resources.callReason.field"
      perPage={500}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const ListActions = (props) => {
  const { filters } = props

  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
  } = useListContext()

  return (
    <Action>
      <CreateAppointmentModal showCustomerSelect />
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </Action>
  )
}

export const AppointmentList = (props) => {
  return (
    <>
      <ListGuesser
        {...props}
        filters={<ListFilter />}
        actions={<ListActions />}
        filter={{
          properties: [
            'id',
            'durationInMin',
            'startTime',
            'reason',
            'phoneNumber',
            'callAttempts',
          ],
          'properties[reason]': ['name'],
          'properties[customer]': ['email'],
          'properties[customer][store]': ['name'],
        }}
        bulkActionButtons={false}
        rowClick="edit"
      >
        <TextField source="originId" label="id" />
        <ReferenceListFilterField
          listUri="/customers"
          titlePath="customer.email"
          valuePath="customer.email"
          label="Customer"
        />
        <FunctionField
          label="Status"
          render={(record) => {
            return record?.status ? STATUS[record?.status] : ''
          }}
        />
        <TextField source="phoneNumber" />
        <TextField source="customer.store.name" label="resources.store.field" />
        <TextField source="reason.name" label="Reason" />
        <DateField source="startTime" short={false} />
        <TextField source="durationInMin" />
        <TextField source="callAttempts" />
      </ListGuesser>
    </>
  )
}
