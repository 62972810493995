import React, { useCallback } from 'react'
import { Button } from '@material-ui/core'
import { useNotify, useUnselectAll } from 'react-admin'
import useApi from 'hooks/useApi'

const ResendButton = React.memo(({ text = 'Resend', ids, resource }) => {
  const { api, isSubmitting, handleError } = useApi()
  const notify = useNotify()
  const unselectAll = useUnselectAll()

  const onResendClick = useCallback(async () => {
    try {
      await api.resend({ resource, ids })
      notify(
        ids.length > 1 ? `Items ${ids} were resent` : `Item ${ids} was resent`
      )

      unselectAll(resource)
    } catch (error) {
      handleError(error)
    }
  }, [api, handleError, ids, resource, notify, unselectAll])

  if (!resource || !ids) {
    return null
  }

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={onResendClick}
      disabled={isSubmitting}
    >
      {text}
    </Button>
  )
})

export default ResendButton
