import React from 'react'
import { TextField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const NpfStockList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="show"
    bulkActionButtons={false}
  >
    <TextField source="originId" label="resources.id" sortBy="id" />
    <TextField source="sku" label="SKU" />
    <TextField source="description" />
    <TextField source="quantity" />
  </ListGuesser>
)
