import React, { useCallback, useEffect, useRef, useState } from 'react'
import 'react-phone-input-2/lib/material.css'
import { TextField } from '@material-ui/core'
import { zipRegexMap } from 'utils/zipRegexMap'

const map = {
  CHDE: 'CH',
  BENL: 'BE',
  BEFR: 'BE',
  CHFR: 'CH',
  INT: 'NL',
}

export const ZipInput = (props) => {
  const { value, onChange, onBlur, onFocus, country, label } = props
  const inputRef = useRef(null)
  const [mounted, setMounted] = useState(false)
  const [error, setError] = useState('')

  const handleBlur = useCallback(
    (event) => {
      const inputValue = event.target?.value?.replace(/\s/g, '')
      const regex = zipRegexMap[map[country] ? map[country] : country]
      onBlur && onBlur(event)

      if (!inputValue) return

      if (regex && regex.test(inputValue)) {
        setError()
      } else {
        setError('zip invalid')
      }
    },
    [onBlur, country]
  )

  useEffect(() => {
    if (inputRef.current && !mounted) {
      setMounted(true)
      setTimeout(() => {
        handleBlur({ target: inputRef.current }) // validate on mount
      }, 500)
    }
  }, [handleBlur, mounted])

  useEffect(() => {
    setMounted(false) // revalidate when country changes
  }, [country])

  return (
    <TextField
      {...props}
      size="small"
      variant="outlined"
      label={label || 'Zip code'}
      value={value}
      onChange={(e) => {
        setError()
        onChange(e)
      }}
      onBlur={handleBlur}
      onFocus={onFocus}
      helperText={error}
      error={error}
      inputRef={inputRef}
    />
  )
}
