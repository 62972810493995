import React, { useState, useCallback } from 'react'
import {
  SelectInput,
  SimpleForm,
  Toolbar,
  ReferenceInput,
  Button,
} from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import useApi from 'hooks/useApi'
import { getNumberFromStringId } from 'utils/string'

const ImportAddonsToolbar = ({ invalid, handleClose, handleImport }) => (
  <Toolbar>
    <Button
      onClick={handleClose}
      size="medium"
      color="secondary"
      fullWidth
      label="cancel"
    />
    <Button
      size="medium"
      color="primary"
      disabled={invalid}
      fullWidth
      component="label"
      label="choose file"
    >
      <>
        <CloudUploadIcon fontSize="small" />
        <input
          type="file"
          accept=".csv"
          onChange={handleImport}
          style={{ display: 'none' }}
        />
      </>
    </Button>
  </Toolbar>
)

const ImportAddonsButton = () => {
  const { api, isSubmitting, handleError } = useApi()
  const [isOpen, setIsOpen] = useState(false)
  const [storeId, setStoreId] = useState()
  const handleImport = useCallback(
    async (e) => {
      if (storeId) {
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        e.target.value = ''
        formData.append('store', storeId)
        try {
          await api.importAddonsForPlans(formData)
          setIsOpen(false)
        } catch (error) {
          handleError(error)
        }
      }
    },
    [api, handleError, storeId]
  )

  return (
    <>
      <Button
        color="primary"
        onClick={() => setIsOpen(true)}
        size="small"
        label="Import addons"
      >
        <CloudUploadIcon fontSize="small" />
      </Button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          {!storeId ? 'Please select the store' : 'Please choose the file'}
        </DialogTitle>
        <SimpleForm
          variant="outlined"
          saving={isSubmitting}
          redirect={false}
          toolbar={
            <ImportAddonsToolbar
              handleClose={() => setIsOpen(false)}
              handleImport={(ev) => handleImport(ev)}
            />
          }
          validate={({ store }) => {
            store && setStoreId(getNumberFromStringId(store))
            return { store: store ? undefined : 'Required' }
          }}
          submitOnEnter={false}
        >
          <ReferenceInput source="store" reference="stores" label="Store">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Dialog>
    </>
  )
}

export default ImportAddonsButton
