import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Image from 'material-ui-image'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'

export const ImageField = ({
  record,
  source,
  title,
  width,
  basePath,
  translateChoice,
  ...props
}) => {
  const src = get(record, source, source)
  const alt = get(record, title, title)

  return (
    <Box width={width}>
      <Link href={src} target="_blank">
        <Image
          src={src}
          alt={alt}
          // imageStyle={{height: 'auto'}}
          {...props}
        />
      </Link>
    </Box>
  )
}

ImageField.defaultProps = {
  width: 'inherit',
  alt: 'Image',
}

ImageField.propTypes = {
  source: PropTypes.string.isRequired,
  title: PropTypes.string,
  width: PropTypes.string,
}
