import React from 'react'
import { TextField, useTranslate, FunctionField } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginTop: '3em',
  },
  th: {
    width: '130px',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: '0.5em',
  },
  green: {
    color: '#90C53C',
  },
  orange: {
    color: 'orange',
  },
  red: {
    color: 'red',
  },
}))

const STATUS = {
  packing: 4,
  shipped: 5,
  delivered: 9,
  collected: 10,
  returned: 12,
}

export const MontaDetailsCard = ({ basePath, record, resource }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const sanitizedProps = { basePath, record, resource }

  if (
    !record.montaStatus &&
    (!record.montaData || record.montaData.length === 0)
  ) {
    return null
  }

  return (
    <Paper className={classes.paper} variant="outlined" square>
      <Typography className={classes.title} variant="h6">
        {translate('resources.order.cards.fulfillment')}
      </Typography>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow key="Table-Row-monta-id">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.order.monta')}
            </TableCell>
            <TableCell align="left">
              <TextField {...sanitizedProps} source="montaData.id" />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-monta-status">
            <TableCell className={classes.th} component="th" scope="row">
              {translate('resources.order.fulfillmentStatus')}
            </TableCell>
            <TableCell align="left">
              <FunctionField
                addLabel={false}
                {...sanitizedProps}
                source="montaStatus.slug"
                render={(record) => {
                  if (record?.montaStatus?.id === STATUS.delivered) {
                    return (
                      <div className={classes.green}>
                        {record.montaStatus.slug}
                      </div>
                    )
                  } else if (
                    [STATUS.packing, STATUS.shipped, STATUS.collected].includes(
                      record?.montaStatus?.id
                    )
                  ) {
                    return (
                      <div className={classes.orange}>
                        {record.montaStatus.slug}
                      </div>
                    )
                  } else if (record?.montaStatus?.id === STATUS.returned) {
                    return (
                      <div className={classes.red}>
                        {record.montaStatus.slug}
                      </div>
                    )
                  } else {
                    return <div>{record?.montaStatus?.slug}</div>
                  }
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  )
}
