import React from 'react'
import { FieldGuesser, ShowGuesser } from '@api-platform/admin'
import { TextField } from 'react-admin'

export const IntervalShow = (props) => (
  <ShowGuesser {...props}>
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
      addLabel={true}
    />
    <FieldGuesser source={'name'} addLabel={true} />
    <FieldGuesser source={'period'} addLabel={true} />
    <FieldGuesser source={'unit'} addLabel={true} />
  </ShowGuesser>
)
