export const getFormattedError = (body, withTitle) => {
  if (typeof body !== 'object') {
    return null
  }

  withTitle = undefined === withTitle ? true : withTitle

  let fields = ['hydra:message', 'hydra:description']

  if (withTitle) {
    fields = ['hydr:title', ...fields]
  }

  let hydraError = fields
    .filter((item) => body[item])
    .map(
      (item) =>
        body[item] +
        (body[item].substr(body[item].length - 1) === '.' ? '\n' : '. \n')
    )

  if (hydraError.length) {
    return hydraError.join('')
  } else {
    return body?.error?.message || JSON.stringify(body)
  }
}

export const getFormattedResponse = (res) => {
  const total = res?.data['hydra:totalItems'] || 0
  const items = res?.data['hydra:member'] || []
  return { total, items }
}
