import {
  ReferenceManyField,
  Pagination,
  TextField,
  ReferenceInput,
  required,
  AutocompleteInput,
} from 'react-admin'
import { FormSpy } from 'react-final-form'
import { DataGrid } from 'theme/components'
import React, { useState, useCallback } from 'react'
import { CreateOrEditForm } from 'components/createOrEditForm'
import { getNumberFromStringId } from 'utils/string'
import { debounce } from 'lodash'

const CreateOrEditIntervalPlan = React.memo((props) => {
  const [plan, setPlan] = useState()
  /* eslint-disable react-hooks/exhaustive-deps */
  const onFormChange = useCallback(
    debounce((form) => setPlan(form.values.plan), 500),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <CreateOrEditForm
      resource="store-plans"
      strictFilterWithProperties={{
        'store.id': getNumberFromStringId(props.storeProps.id),
        'interval.id': getNumberFromStringId(props.id),
        properties: ['plan', 'processingFeeAddon'],
      }}
      initialValues={{
        store: props.storeProps.id,
        interval: props.id,
      }}
      storeId={props.id}
      hiddenToolbar={false}
    >
      <FormSpy subscription={{ values: true }} onChange={onFormChange} />
      <ReferenceInput
        source="plan"
        reference="plans"
        label="resources.plan.field"
        filterToQuery={(searchText) => ({
          slug: searchText,
          'productStoreInterval.productStore.store.id': getNumberFromStringId(
            props.storeProps.id
          ),
          'productStoreInterval.interval.id': getNumberFromStringId(props.id),
          properties: ['chargeBeeId', 'id'],
        })}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
        validate={[required()]}
      >
        <AutocompleteInput optionText="chargeBeeId" />
      </ReferenceInput>

      <ReferenceInput
        source="processingFeeAddon"
        reference="product-store-intervals"
        filterToQuery={(searchText) => ({
          chargeBeeId: searchText,
        })}
        filter={{
          availablePsiForPlan: plan?.id,
          'interval.id': getNumberFromStringId(props.id),
        }}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
        disabled={!plan}
      >
        <AutocompleteInput resettable optionText="chargeBeeId" />
      </ReferenceInput>
    </CreateOrEditForm>
  )
})

export const DefaultIntervalPlansTab = (props) => (
  <ReferenceManyField
    {...props}
    hasEdit={false}
    addLabel={false}
    reference="intervals"
    pagination={<Pagination />}
    filter={{
      properties: ['id', 'name', 'period', 'slug'],
    }}
    target=""
  >
    <DataGrid expand={<CreateOrEditIntervalPlan storeProps={props} />}>
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <TextField source="name" />
      <TextField source="period" />
      <TextField source="slug" />
    </DataGrid>
  </ReferenceManyField>
)
