import CancelPresentationIcon from '@material-ui/icons/CancelPresentation'

import { PostponeReasonList } from './PostponeReasonList'
import { PostponeReasonCreate } from './PostponeReasonCreate'
import { PostponeReasonEdit } from './PostponeReasonEdit'
import { ROLES } from 'utils/user'

export default {
  list: PostponeReasonList,
  create: PostponeReasonCreate,
  edit: PostponeReasonEdit,
  icon: CancelPresentationIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
