import { createTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import { compose } from 'ramda'
import { lighten, desaturate } from 'polished'

const getDarkTone = compose(lighten(0.05), desaturate(0.01))
const getLightTone = compose(lighten(0.1), desaturate(0.01))

export const colors = { primary: '#90C53C', secondary: '#2f3337', red }

const MuiTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: getLightTone(colors.primary),
      dark: getDarkTone(colors.primary),
      main: colors.primary,
      contrastText: '#ffffff',
    },
    secondary: {
      light: getLightTone(colors.secondary),
      dark: getDarkTone(colors.secondary),
      main: colors.secondary,
      contrastText: '#ffffff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  status: {
    danger: 'orange',
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    // Forms
    MuiFormControl: {
      marginDense: {
        marginBottom: '15px',
        width: '300px',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
          backgroundColor: 'transparent',
        },
      },
      underline: {
        '& .MuiSvgIcon-root': {
          right: '-9px',
        },
      },
    },
    MuiSelect: {
      icon: {
        color: colors.primary,
      },
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiButton: {
      text: {
        color: colors.primary,
      },
    },
    // Tables
    MuiTableCell: {
      root: {
        '& a:not(.MuiButton-text)': {
          color: '#2f3337',
        },
      },
    },
    MuiTableRow: {
      head: {
        backgroundColor: 'rgba(47,95,88,0.03)',
        '& > th': {
          color: 'rgba(30,31,56,0.58)',
          fontWeight: 'bold',
          padding: '6px 8px 6px 8px',
          fontSize: '0.8rem',
        },
        '& > th:first-child': {
          padding: '6px 8px 6px 15px',
        },
      },
      hover: {
        '& > td': {
          padding: '6px 8px 6px 8px',
        },
        '& > td:first-child': {
          padding: '6px 8px 6px 15px',
        },
      },
    },
    // Resources
    MuiChip: {
      label: {
        color: '#2f3337',
      },
    },
    // Layout
    MuiPaper: {
      elevation1: {
        boxShadow:
          '2px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 1px rgba(0,0,0,0.12)',
      },
    },
  },
})

export default MuiTheme
