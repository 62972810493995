import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import { CreateGuesser } from 'theme/components'

const redirect = (basePath, id, data) =>
  `/products/${encodeURIComponent(data.id)}/1`

export const ProductCreate = (props) => (
  <CreateGuesser
    {...props}
    variant="outlined"
    redirect={redirect}
    initialValues={{ starterKit: false }}
  >
    <InputGuesser source="name" />
    <InputGuesser source="sku" />
    <InputGuesser source="pcs" />
    <InputGuesser source="weight" />
    <InputGuesser source="hsCode" />
    <InputGuesser source="starterKit" />
  </CreateGuesser>
)
