import React from 'react'
import { EditGuesser } from 'theme/components'
import {
  useNotify,
  useRefresh,
  BooleanInput,
  required,
  TextInput,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  TextField,
} from 'react-admin'
import useApi from 'hooks/useApi'
import styled from 'styled-components'
import { FileInput } from 'theme/components'
import { getApiRoute } from 'service/Api/routes'
import { getFormattedError } from 'utils/hydra'
import { FileField } from 'components/fileField'

const Images = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 20px 0;
`

const CustomToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`

const CallImages = (props) => {
  return (
    <Images>
      {props?.record?.files?.map(({ file }, index) => (
        <FileField file={file} key={`call-image-${index}`} />
      ))}
    </Images>
  )
}

export const EditCustomerCall = (props) => {
  const notify = useNotify()
  const { api } = useApi()
  const refresh = useRefresh()

  const handleSubmit = async (values) => {
    try {
      const result = await api.patch(
        `${getApiRoute('customer-calls')}/${values.originId}`,
        { ...values, type: Number(values.type) }
      )

      if (result) {
        if (values.file) {
          await api.post(getApiRoute('customer-call-files'), {
            file: values.file,
            customerCall: `/api/customer-calls/${result.data.id}`,
          })
        }
        refresh()
        notify('Call updated', 'info')
      }
    } catch (e) {
      notify(getFormattedError(e?.response?.data, false))
    }
  }

  return (
    <EditGuesser
      {...props}
      variant="outlined"
      redirect={false}
      resource="customer-calls"
      basePath={'/customer-calls'}
      toolbar={
        <CustomToolbar>
          <SaveButton
            color="primary"
            variant="contained"
            type="button"
            onSave={handleSubmit}
            label="Save"
          />
        </CustomToolbar>
      }
      transform={(record) => ({ ...record, files: undefined })}
    >
      <TextField source="user.email" label="Logged by" />
      <ReferenceInput
        source="reason"
        reference="call-reasons"
        validate={[required()]}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <TextInput
        multiline
        rows={4}
        validate={[required()]}
        source="description"
      />

      <BooleanInput source="type" label="Follow up" />
      <CallImages {...props} />
      <FileInput
        maxFileSize={990000}
        source="file"
        label="File"
        acceptedFiles={[
          '.pdf',
          '.png',
          '.jpg',
          '.jpeg',
          '.txt',
          '.doc',
          '.docx',
        ]}
      />
    </EditGuesser>
  )
}
