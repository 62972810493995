import React from 'react'
import { TextInput, required } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const PostponeReasonCreate = (props) => {
  return (
    <CreateGuesser {...props} variant="outlined" redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="slug" />
    </CreateGuesser>
  )
}
