import React, { useMemo } from 'react'
import { FieldGuesser } from '@api-platform/admin'
import {
  TextField,
  Filter,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { ProductStoreEdit } from './ProductStoreEdit'
import { ListGuesser, ReferenceListFilterField } from 'theme/components'
import { exporter } from './exporter'
import UserSession from 'service/UserSession'
import { hasRoles } from 'utils/user'

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput
      source="store.id"
      reference="stores"
      label="Store"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceInput
      source="product.id"
      reference="products"
      label="resources.product.field"
      filterToQuery={(searchText) => ({ sku: searchText })}
    >
      <AutocompleteInput optionText="sku" />
    </ReferenceInput>
    <NumberInput label="Id" source="id" />
    <TextInput label="SKU" source="sku" />
    <TextInput label="Name" source="product.name" />
    <TextInput label="Description" source="description" />
  </Filter>
)

export const ProductStoreList = (props) => {
  const { roles } = UserSession.getUser()
  const { hasAdminRole } = useMemo(() => hasRoles(roles), [roles])
  return (
    <ListGuesser
      {...props}
      filters={<ListFilter />}
      exporter={exporter}
      filter={{
        properties: ['id', 'sku', 'name', 'description'],
        'properties[product]': ['id', 'name'],
        'properties[store]': ['id', 'name'],
      }}
      rowClick="edit"
      expand={<ProductStoreEdit defaultToolbar />}
      bulkActionButtons={false}
    >
      <TextField
        source={'originId'}
        label={'id'}
        sortBy={'id'}
        sort={{ field: 'id', order: 'DESC' }}
      />
      {hasAdminRole ? (
        <ReferenceListFilterField
          {...props}
          label="resources.store.field"
          listUri="/product-stores"
          titlePath="store.name"
          valuePath="store.id"
          filterType="collection"
          addLabel={true}
        />
      ) : (
        <TextField source="store.name" label="resources.store.field" />
      )}
      <ReferenceListFilterField
        {...props}
        label="resources.product.field"
        listUri="/product-stores"
        titlePath="product.name"
        valuePath="product.name"
        filterType="collection"
        addLabel={true}
      />
      <FieldGuesser source="sku" />
    </ListGuesser>
  )
}
