import Icon from '@material-ui/icons/Euro'

import { CurrencyList } from './CurrencyList'
import { CurrencyCreate } from './CurrencyCreate'
import { CurrencyEdit } from './CurrencyEdit'
import { ROLES } from 'utils/user'

export default {
  list: CurrencyList,
  create: CurrencyCreate,
  edit: CurrencyEdit,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN],
}
