import Icon from '@material-ui/icons/Event'
import { ROLES } from 'utils/user'

import { DreamlogisticList } from './DreamlogisticList'
import { DreamlogisticShow } from './DreamlogisticShow'

export default {
  list: DreamlogisticList,
  show: DreamlogisticShow,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_ORDER_MANAGEMENT],
}
