import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import { Toolbar } from 'react-admin'
import { EditGuesser, ResourceEditToolbar } from 'theme/components'
import { useTranslate } from 'react-admin'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import { BooleanInput } from 'react-admin'

const useStyles = makeStyles(() => ({
  column: {
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    padding: '5px 10px',
  },
  table: {
    width: '500px',
    marginBottom: '20px',
  },
}))

export const MyFFMAndCheckoutTab = ({ defaultToolbar, ...props }) => {
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <EditGuesser
      {...props}
      undoable={false}
      variant="outlined"
      redirect={false}
      toolbar={
        defaultToolbar ? (
          <Toolbar />
        ) : (
          <ResourceEditToolbar showDelete={false} />
        )
      }
    >
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow key="Table-Row-heading">
            <TableCell component="th" scope="row"></TableCell>
            <TableCell component="th" scope="row">
              enabled
            </TableCell>
            <TableCell component="th" scope="row">
              required
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-1">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerAddressLine1')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput label="" helperText={false} checked disabled />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput label="" helperText={false} checked disabled />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-2">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerAddressLine2')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput label="" helperText={false} checked disabled />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerAddressLine2Required"
                helperText={false}
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-3">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerAddressLine3')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerAddressLine3Enabled"
                helperText={false}
              />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerAddressLine3Required"
                helperText={false}
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-4">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerState')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerStateEnabled"
                helperText={false}
              />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerStateRequired"
                helperText={false}
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-5">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerClinic')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerClinicEnabled"
                helperText={false}
              />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerClinicRequired"
                helperText={false}
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-6">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerSurgeryType')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerSurgeryTypeEnabled"
                helperText={false}
              />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerSurgeryTypeRequired"
                helperText={false}
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-7">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerSurgeryDate')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerSurgeryDateEnabled"
                helperText={false}
              />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerSurgeryDateRequired"
                helperText={false}
              />
            </TableCell>
          </TableRow>
          <TableRow key="Table-Row-id-8">
            <TableCell className={classes.column} component="th" scope="row">
              {translate('resources.store.customerSurgeon')}
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerSurgeonEnabled"
                helperText={false}
              />
            </TableCell>
            <TableCell className={classes.column}>
              <BooleanInput
                label=""
                source="customerSurgeonRequired"
                helperText={false}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <BooleanInput source="allowCustomerPayment" />
      <BooleanInput
        source="allowCustomerCancellation"
        label="resources.store.allowCustomerCancellation"
      />
      <BooleanInput
        source="allowSubscriptionCancellationInPersonalPlan"
        label="resources.store.allowSubscriptionCancellationInPersonalPlan"
        defaultValue={false}
      />
      <BooleanInput
        source="customerAllowAddClinic"
        label="resources.store.customerAllowAddClinic"
      />
      <BooleanInput
        source="enableContactForm"
        label="resources.store.allowContactForm"
      />
      <BooleanInput source="csMarketingCheckedByDefault" />
      <InputGuesser
        source="customerServiceFAQUrl"
        label="resources.store.customerServiceFAQUrl"
      />
      <InputGuesser
        source="customerServiceContactUrl"
        label="resources.store.customerServiceContactUrl"
      />
      <InputGuesser source="termsUrl" />
      <InputGuesser source="privacyPolicyUrl" />
      <InputGuesser source="supportPhone" />
    </EditGuesser>
  )
}
