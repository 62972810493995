import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { useListContext } from 'react-admin'

const ShortViewWrapper = styled.div`
  /* 
    Controls dimensions, scroll behavior, and font size adjustments.
  */
  &&& {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    font-size: 13px;
    overflow: hidden;
    padding: 4px 8px;
    border: 1px solid rgb(144, 197, 60);
    border-radius: 10px;

    & td {
      padding: 2px;
    }
    & span,
    p {
      font-size: 13px;
    }

    & th {
      z-index: 0;

      & span {
        font-size: 12px;
      }
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &:hover {
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
      }
    }
  }
`

const TileHeading = styled.h4`
  font-weight: 700;
  font-size: 14px;
  text-align: center;
`

const FullFiewWrapper = styled.div`
  display: flex;
  transition: all 1s ease;
  min-width: 300px;
  width: 100%;
  max-width: ${({ loading }) => (loading ? '300px' : '3000px')};
  overflow: ${({ loading }) => (loading ? 'clip' : 'auto')};
`

const InvisibleWhenLoading = styled.div`
  position: ${({ loading }) => (loading ? 'absolute' : 'relative')};
  opacity: ${({ loading }) => (loading ? 0 : 1)};
  transition: all 1s ease;
`

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  z-index: 999;
  background: white;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`

const Content = styled.div`
  flex: 1;
  margin-bottom: 1.33em;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
    }
  }
`

// Utility component that interacts with the list context and sets total count.
// It doesn't render anything visual, but provides necessary side effects.
const ShortListStats = ({ setTotal }) => {
  const { total, loading, loaded } = useListContext()

  useEffect(() => {
    setTotal(total)
  }, [total, setTotal, loaded, loading])

  return null
}

// The main Tile component that can display both a short and a full view.
// It uses the Dialog component from material-ui for the full view.
export const Tile = ({
  ShortView,
  FullView,
  label,
  reloadOnClick = true,
  ShortViewActions,
  ...rest
}) => {
  const [open, setOpen] = useState(false)
  const [showShortView, setShowShortView] = useState(true)
  const [showFullView, setShowFullView] = useState(true)
  const [total, setTotal] = useState(undefined)
  const shortViewOnly = !FullView || total === 0

  useEffect(() => {
    // Logic to toggle between short and full views when the dialog opens or closes.
    // Show loader when
    if (open) {
      setShowFullView(false)
      setTimeout(() => setShowFullView(true), 600)
    } else {
      setShowShortView(false)
      setTimeout(() => setShowShortView(true), 600)
    }
  }, [open])

  return (
    <>
      <ShortViewWrapper shortViewOnly={shortViewOnly}>
        {showShortView ? (
          <>
            <TileHeading>
              {label}
              {!isNaN(total) && ` (${total})`}
            </TileHeading>

            {ShortViewActions && (
              <div
                onClick={(e) => e.stopPropagation()}
                style={{ pointerEvents: 'auto', marginBottom: '8px' }}
              >
                {
                  <ShortViewActions
                    reloadShortView={() => {
                      setShowShortView(false)
                      setTimeout(() => setShowShortView(true), 1000)
                    }}
                  />
                }
              </div>
            )}

            <Content>
              <ShortView
                {...rest}
                ListStats={<ShortListStats setTotal={setTotal} />}
                setTotal={setTotal}
                reloadShortView={() => {
                  setShowShortView(false)
                  setTimeout(() => setShowShortView(true), 1000)
                }}
              />
            </Content>
            {!shortViewOnly || reloadOnClick ? (
              <Button
                onClick={() => {
                  if (!shortViewOnly) {
                    setOpen(true)
                  }
                  if (shortViewOnly && reloadOnClick) {
                    // reload short view
                    setShowShortView(false)
                    setTimeout(() => setShowShortView(true), 600)
                  }
                }}
                variant="outlined"
                color="primary"
                showShortView={showShortView}
                style={{ alignself: 'flex-end' }}
              >
                {reloadOnClick && shortViewOnly ? 'Reload' : 'Open'}
              </Button>
            ) : undefined}
          </>
        ) : (
          <Loader>
            <CircularProgress />
          </Loader>
        )}
      </ShortViewWrapper>
      {/* Dialog that displays the full view when the short view is clicked. */}
      {!shortViewOnly && (
        <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)}>
          <Header>
            <DialogTitle>{label}</DialogTitle>
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </IconButton>
          </Header>
          <DialogContent>
            <FullFiewWrapper loading={!showFullView}>
              {!showFullView && (
                <Loader>
                  <CircularProgress />
                </Loader>
              )}
              <InvisibleWhenLoading loading={!showFullView}>
                <FullView
                  {...rest}
                  reloadFullView={() => {
                    setShowFullView(false)
                    setTimeout(() => setShowFullView(true), 1000)
                  }}
                />
              </InvisibleWhenLoading>
            </FullFiewWrapper>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
