import React, { useCallback } from 'react'
import { useRefresh } from 'react-admin'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import useApi from 'hooks/useApi'

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: theme.spacing(1),
  },
}))

const ImportButton = React.memo(({ resource, text, accept = ['.csv'] }) => {
  const classes = useStyles()
  const { api, handleError } = useApi()
  const refresh = useRefresh()

  const onImportChange = useCallback(
    async (e) => {
      let formData = new FormData()

      formData.append('file', e.target.files[0])
      e.target.value = ''

      try {
        await api.importCsv(resource, formData)
      } catch (error) {
        handleError(error)
      }
      refresh()
    },
    [api, handleError, resource, refresh]
  )

  if (!resource) {
    return null
  }

  return (
    <Button size="small" color="primary" component="label">
      <CloudUploadIcon fontSize="small" />
      <span className={classes.label}>
        {text || `Import ${accept.join(' ')}`}
      </span>
      <input
        type="file"
        accept={accept.join(' ')}
        onChange={onImportChange}
        style={{ display: 'none' }}
      />
    </Button>
  )
})

export default ImportButton
