import { formatPrice } from 'utils/string'
import React from 'react'
import { TextField, FunctionField, Filter, SelectInput } from 'react-admin'
import { DateField, ListGuesser } from 'theme/components'

const statuses = {
  created: 'Created',
  initiated_chargebee: 'Initiated by Chargebee',
  initiated_payment_provider: 'Initiated by Adyen',
  success: 'Success',
  failed: 'Failed',
}

const ListFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      choices={Object.keys(statuses).map((id) => ({ id, name: statuses[id] }))}
    />
  </Filter>
)

export const CreditNoteList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    hasEdit={false}
    hasShow={true}
    rowClick="show"
    hasCreate={false}
    filter={{
      properties: [
        'id',
        'chargeBeeId',
        'customer',
        'invoice',
        'data',
        'status',
        'createdAt',
      ],
      'properties[customer]': ['id', 'email'],
      'properties[invoice]': ['id', 'chargeBeeId'],
      'properties[data]': ['total', 'create_reason_code', 'currency_code'],
    }}
    bulkActionButtons={false}
  >
    <TextField source="originId" label="id" />
    <TextField source="chargeBeeId" />
    <FunctionField
      label="Customer"
      render={({ customer }) => customer?.email}
    />

    <FunctionField
      label="Invoice"
      render={({ invoice }) => invoice?.chargeBeeId}
    />
    <FunctionField
      label="Status"
      source="status"
      render={(record) => statuses[record.status]}
    />
    <DateField source="createdAt" />
    <FunctionField
      label="Amount"
      source="amount"
      render={(record) => {
        const {
          data: { total, currency_code },
        } = record

        return formatPrice(total / 100, currency_code)
      }}
    />
    <TextField source="data.create_reason_code" label="Reason" />
  </ListGuesser>
)
