import React from 'react'
import { ShowGuesser } from '@api-platform/admin'
import { Tab } from 'react-admin'
import { StoreEditTab } from './storeEditTabs/StoreEditTab'
import { MyFFMAndCheckoutTab } from './storeEditTabs/MyFFMAndCheckoutTab'
import { RelatedOrdersTab } from './storeEditTabs/RelatedOrdersTab'
import { DefaultIntervalPlansTab } from './storeEditTabs/DefaultIntervalPlansTab'
import { PaymentsTab } from './storeEditTabs/PaymentsTab'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

export const StoreEditPage = (props) => (
  <ShowGuesser {...props} hasEdit={false}>
    <OverflowingTabbedShowLayout>
      <Tab label="resources.store.edit">
        <StoreEditTab {...props} />
      </Tab>
      <Tab label="resources.store.additionalFields">
        <MyFFMAndCheckoutTab {...props} />
      </Tab>
      <Tab label="Payments">
        <PaymentsTab {...props} />
      </Tab>
      <Tab label="resources.store.orders">
        <RelatedOrdersTab {...props} />
      </Tab>
      <Tab label="Default interval plans">
        <DefaultIntervalPlansTab {...props} />
      </Tab>
    </OverflowingTabbedShowLayout>
  </ShowGuesser>
)
