import PaymentsIcon from '@material-ui/icons/Payment'
import { ROLES } from 'utils/user'
import { TransactionList } from './TransactionList'
import { TransactionShow } from './TransactionShow'

export default {
  list: TransactionList,
  show: TransactionShow,
  icon: PaymentsIcon,
  permissions: [
    ROLES.ROLE_ADMIN,
    ROLES.ROLE_FINANCE,
    ROLES.ROLE_CUSTOMER_SUPPORT,
    ROLES.ROLE_ORDER_MANAGEMENT,
  ],
}
