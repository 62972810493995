import React from 'react'
import { ShowGuesser } from '@api-platform/admin'
import { TextField } from 'react-admin'

export const DreamlogisticShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <h1>Dreamlogistic details</h1>

      <TextField source="originId" label="resources.id" />
      <TextField source="sku" label="SKU" />
      <TextField source="description" />
      <TextField source="quantity" />
    </ShowGuesser>
  )
}
