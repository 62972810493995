import Icon from '@material-ui/icons/Store'

import { StoreList } from './StoreList'
import { StoreEditPage } from './StoreEdit'
import { ROLES } from 'utils/user'
import { StoreCreate } from './StoreCreate'

export default {
  create: StoreCreate,
  list: StoreList,
  edit: StoreEditPage,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN],
}
