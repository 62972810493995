import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { ROLES } from 'utils/user'

import { FulfillmentErrorList } from './FulfillmentErrorList'
import { FulfillmentErrorShow } from './FulfillmentErrorShow'

export default {
  list: FulfillmentErrorList,
  show: FulfillmentErrorShow,
  icon: ErrorOutlineIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
