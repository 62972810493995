import React, { useCallback } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useNotify } from 'react-admin'
import useApi from 'hooks/useApi'

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(2),
  },
}))

const ResyncButton = React.memo(
  ({ text = 'Resync', id, resourceEndpoint, ...props }) => {
    const { api, isSubmitting, handleError } = useApi()
    const classes = useStyles()
    const notify = useNotify()

    const onResyncClick = useCallback(async () => {
      try {
        await api.resync({ resource: resourceEndpoint, id })
        notify(`${resourceEndpoint} ${id} was resynced`)
      } catch (error) {
        handleError(error)
      }
    }, [api, handleError, id, resourceEndpoint, notify])

    if (!resourceEndpoint || !id) {
      return null
    }

    return (
      <Button
        color="primary"
        variant="contained"
        onClick={onResyncClick}
        className={classes.root}
        disabled={isSubmitting}
        {...props}
      >
        {text}
      </Button>
    )
  }
)

export default ResyncButton
