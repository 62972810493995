import React from 'react'
import {
  Toolbar,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'
import { EditGuesser } from 'theme/components'

export const BundleStoreEdit = ({ ...props }) => (
  <EditGuesser
    {...props}
    variant="outlined"
    redirect="list"
    toolbar={<Toolbar />}
  >
    <ReferenceInput
      source="store"
      reference="stores"
      filter={{ properties: ['id', 'name'] }}
      format={(v) => (v ? v['@id'] || v : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      reference="bundles"
      source="bundle"
      format={(v) => (v ? v['@id'] || v : v)}
      filter={{ properties: ['id', 'name'] }}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={50}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      reference="bundles"
      source="upgradeTo"
      format={(v) => (v ? v['@id'] || v : v)}
      filter={{ properties: ['id', 'name'] }}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={50}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      reference="bundles"
      source="downgradeTo"
      format={(v) => (v ? v['@id'] || v : v)}
      filter={{ properties: ['id', 'name'] }}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={50}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </EditGuesser>
)
