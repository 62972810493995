import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import { Toolbar } from 'react-admin'
import { EditGuesser, ResourceEditToolbar } from 'theme/components'

const redirect = (basePath, id, data) =>
  `/groups/${encodeURIComponent(data.group['@id'])}/1`

export const GroupImageEdit = ({ defaultToolbar, ...props }) => (
  <EditGuesser
    {...props}
    undoable={false}
    variant="outlined"
    redirect={redirect}
    toolbar={defaultToolbar ? <Toolbar /> : <ResourceEditToolbar />}
  >
    <InputGuesser source="main" required />
    <InputGuesser
      label="resources.groupImage.wcEnabled"
      source="wooCommerce"
      required
    />
  </EditGuesser>
)
