import React, { useState } from 'react'
import { FunctionField, Button, Tab, Link, TextField } from 'react-admin'
import { ShowGuesser } from '@api-platform/admin'
import { OrderLinesTable } from '../order/card/ChargeBeeLinesCard'
import styled from 'styled-components'

import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

const Wrapper = styled.div`
  .MuiFormControl-marginDense {
    width: 100% !important;
  }
`

export const CreditNoteShow = (props) => {
  const [showDetails, setShowDetails] = useState(false)

  return (
    <Wrapper>
      <ShowGuesser {...props}>
        <OverflowingTabbedShowLayout>
          <Tab label="Credit Note Details">
            <FunctionField
              label="Customer"
              render={({ customer }) => {
                if (!customer) return null
                const url = `/customers/${encodeURIComponent(
                  customer['@id']
                )}/show`
                return (
                  <Link to={url} target="_blank" rel="noopener noreferrer">
                    {customer.email}
                  </Link>
                )
              }}
            />

            <FunctionField
              label="Invoice"
              render={({ invoice }) => {
                if (!invoice) return null
                const url = `/invoices/${encodeURIComponent(
                  invoice['@id']
                )}/show`
                return (
                  <Link to={url} target="_blank" rel="noopener noreferrer">
                    {invoice.chargeBeeId}
                  </Link>
                )
              }}
            />

            <TextField source="status" />

            <FunctionField
              {...props}
              label="ChargeBee Id"
              render={(record) => record.chargeBeeId}
            />

            <FunctionField
              {...props}
              label=" "
              render={(record) => {
                return showDetails ? (
                  <pre>{JSON.stringify(record, null, 2)}</pre>
                ) : (
                  <Button
                    onClick={() => setShowDetails(true)}
                    label="Show more details"
                  />
                )
              }}
            />
          </Tab>
          <Tab label="Credit note items">
            <FunctionField
              label="Credit note items"
              render={(record) => {
                return <OrderLinesTable lines={record.data.line_items} />
              }}
            />
          </Tab>
        </OverflowingTabbedShowLayout>
      </ShowGuesser>
    </Wrapper>
  )
}
