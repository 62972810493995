import HealingIcon from '@material-ui/icons/Healing'
import { SurgeryTypeOptionList } from './SurgeryTypeOptionList'
import { SurgeryTypeOptionCreate } from './SurgeryTypeOptionCreate'
import { SurgeryTypeOptionEdit } from './SurgeryTypeOptionEdit'
import { ROLES } from 'utils/user'

export default {
  list: SurgeryTypeOptionList,
  create: SurgeryTypeOptionCreate,
  edit: SurgeryTypeOptionEdit,
  icon: HealingIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
