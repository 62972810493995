import React from 'react'
import { useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import { lowercaseFirstLetter } from 'utils/string'
import PropTypes from 'prop-types'

const sort = [
  'City',
  'State',
  'Street',
  'PostalCode',
  'CountryCode',
  'FirstName',
  'LastName',
  'EmailAddress',
  'PhoneNumber',
]

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginTop: '3em',
  },
  th: {
    width: '130px',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: '0.5em',
  },
}))

export const ShipDetailsCard = ({ record, type, title }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const data = record.montaData || null

  if (!data) {
    return null
  }

  let details = data[type] || null

  if (!details) {
    return null
  }

  details = Object.entries(details)
    .map((e) => ({ name: e[0], value: e[1] }))
    .filter((item) => item.value)
    .sort((item1, item2) => sort.indexOf(item1.name) - sort.indexOf(item2.name))

  return (
    <Paper className={classes.paper} variant="outlined" square>
      <Typography className={classes.title} variant="h6">
        {translate(title)}
      </Typography>
      <Table aria-label="simple table">
        <TableBody>
          {details.map((item, key) => (
            <TableRow key={`Table-Row-key-${key}`}>
              <TableCell className={classes.th} component="th" scope="row">
                {translate(
                  `resources.order.lines.${lowercaseFirstLetter(item.name)}`
                )}
              </TableCell>
              <TableCell align="left">{item.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

ShipDetailsCard.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
