import React, { useCallback, useEffect, useState } from 'react'
import {
  Login as BaseLogin,
  useLogin,
  useNotify,
  Notification,
  defaultTheme,
  useRedirect,
} from 'react-admin'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import styled from 'styled-components'
import { getUrlParams } from 'utils/uri'
import { BASE_URL } from 'service/Api/routes'
import { getFormattedError } from 'utils/hydra'

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
`
const StyledButton = styled(Button)`
  margin: 4px 0;
`

const LoginContent = React.memo(() => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const login = useLogin()
  const notify = useNotify()
  const redirect = useRedirect()

  const submit = useCallback(
    (e) => {
      e.preventDefault()

      login({ username, password }).catch((e) => {
        notify(getFormattedError(e?.response?.data, false))
      })
    },
    [login, notify, password, username]
  )

  const onSamlClick = () => {
    window.location.href = `${BASE_URL}/sso/azure?referer=https%3A%2F%2F${window.location.host}%2F%23%2Flogin`
  }

  useEffect(() => {
    const { status, state, error } = getUrlParams()

    if (status && status === 'success') {
      fetch(`${BASE_URL}/sso/azure/state/${state}`)
        .then((res) => res.json())
        .then((sessionResponse) => {
          if (sessionResponse?.user) {
            localStorage.setItem('userName', sessionResponse.user.fullName)
            localStorage.setItem('userRoles', sessionResponse.user.roles)
            localStorage.setItem('userUri', sessionResponse.user['@id'])
            localStorage.setItem('userEmail', sessionResponse.user.email)
          }
          localStorage.setItem('authenticated', 'yes')

          const prevUrl = localStorage.getItem('prevUrl')
          window.location.href = prevUrl || '/'
        })
    }

    if (error) {
      notify(decodeURIComponent(error.replace(/\+/g, '%20')))
    }
  }, [notify])

  const isProduction = window.location.hostname === 'admin.fitformedev.nl'

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      {!isProduction && (
        <form onSubmit={submit}>
          <InputsWrapper>
            <TextField
              id="username"
              label="Username"
              value={username}
              required
              onChange={(event) => setUsername(event.target.value)}
            />
            <TextField
              id="password"
              label="Password"
              type="password"
              value={password}
              required
              onChange={(event) => setPassword(event.target.value)}
            />
          </InputsWrapper>

          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
          >
            Login
          </StyledButton>

          <StyledButton
            variant="text"
            color="secondary"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              redirect('recover-password/request')
            }}
            fullWidth
          >
            reset password
          </StyledButton>
        </form>
      )}
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={onSamlClick}
        fullWidth
        style={{ marginBottom: '8px' }}
      >
        SAML LOGIN
      </StyledButton>
      <Notification />
    </ThemeProvider>
  )
})

export const Login = () => (
  <BaseLogin
    // A random image that changes on every page load
    backgroundImage="https://picsum.photos/1600/900"
  >
    <div style={{ padding: '20px' }}>
      <LoginContent />
    </div>
  </BaseLogin>
)
