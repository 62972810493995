import React, { useState, useCallback, useEffect } from 'react'
import {
  useDataProvider,
  TextInput,
  DateInput,
  SelectInput,
  Toolbar,
  useNotify,
  AutocompleteInput,
  useRefresh,
} from 'react-admin'
import useApi from 'hooks/useApi'
import { Button } from '@material-ui/core'
import { useForm, Field } from 'react-final-form'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { FieldsWrapper, TransparentEditGuesser } from '../commonComponents'

export const trialStatuses = [
  'Converted by CS',
  'Converted by Customer',
  'Converted by Customer via Website',
  'Follow up call (scheduled)',
  'In progress (new attempt)',
  'Not converted',
].map((id) => ({ id, name: id }))

export const notConvertReason = [
  'Competitor or standard multivitamin',
  'Couldn\'t reach them',
  'Customer is going to order themselves',
  'Double account',
  'Intake / Nausea',
  'Not compliant',
  'Price',
  'Taste',
  'Wants to try first',
  'Other'
].map((id) => ({ id, name: id }))

const Condition = ({when, is, children}) => (
  <Field name={when} subscription={{value: true}}>
    {({ input: {value}}) => (value === is ? children : null)}
  </Field>
)

const CustomToolbar = (props) => {
  const { api } = useApi()
  const form = useForm()
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSave = async (e) => {
    e.preventDefault() // necessary to prevent default SaveButton submit logic

    const { values } = form.getState()
    const formattedValue = {
      email: values?.data?.email,
      first_name: values?.data?.first_name,
      last_name: values?.data?.last_name,

      phone: values?.phone,
      cf_date_of_birth: values?.data?.cf_date_of_birth,
      cf_gender_v2: values?.data?.cf_gender_v2,

      cf_surgeon_name: values?.data?.cf_surgeon_name,
      cf_surgery_type_v2: values?.data?.cf_surgery_type_v2,
      cf_surgery_date: values?.data?.cf_surgery_date,

      cf_clinic_v2: values?.data?.cf_clinic_v2,
      cf_trial_status: values?.data?.cf_trial_status,
      cf_reason_trial_not_converted: values?.data?.cf_reason_trial_not_converted,

      billing_info: {
        'billing_address[first_name]':
          values?.data?.billing_address?.first_name,
        'billing_address[last_name]': values?.data?.billing_address?.last_name,
        'billing_address[phone]': values?.data?.billing_address?.phone,
        'billing_address[line1]': values?.data?.billing_address?.line1,
        'billing_address[line2]': values?.data?.billing_address?.line2,
        'billing_address[city]': values?.data?.billing_address?.city,
        'billing_address[zip]': values?.data?.billing_address?.zip,
        'billing_address[state]': values?.data?.billing_address?.state,
        'billing_address[country]': values?.data?.billing_address?.country,
      },
    }

    try {
      await api.updateCustomer(props.customerId, formattedValue)
      notify('Changes saved')
      refresh()
    } catch (e) {
      console.error(e)
    }
    return
  }

  return (
    <Toolbar {...props} style={{ backgroundColor: 'white', margin: 0 }}>
      <Button
        color="primary"
        onClick={handleSave}
        size="large"
        variant="contained"
      >
        Save
      </Button>
    </Toolbar>
  )
}

export const CustomerEdit = (props) => {
  const { api } = useApi()
  const dataProvider = useDataProvider()
  const { customerId, store } = useCustomer()

  const [genders, setGenders] = useState([])
  const [clinics, setClinics] = useState([])
  const [surgeryTypes, setSurgeryTypes] = useState([])

  const fetchData = useCallback(async () => {
    const id = store?.id
    if (id) {
      // todo fetch genders using data provider
      const genderData = (await api.fetchGenderByStoreId(id))?.data.map(
        (item) => {
          return {
            ...item,
            name: item.name,
            id: item.name,
          }
        }
      )
      const { data: clinicsData } = await dataProvider.getList('clinics', {
        pagination: {},
        sort: {},
        filter: {
          itemsPerPage: 999,
          'stores.id': [id],
          'status.id': [2], // 2 - live
        },
      })
      const { data: surgeryData } = await dataProvider.getList(
        'surgery-type-option-stores',
        {
          pagination: {},
          sort: {},
          filter: { itemsPerPage: 999, 'store.id': id },
        }
      )

      setSurgeryTypes(
        surgeryData.map((item) => {
          return {
            ...item,
            name: item.name,
            id: item.name,
          }
        })
      )
      setClinics(
        clinicsData.map((item) => {
          return {
            ...item,
            name: item.name,
            id: item.name,
          }
        })
      )
      setGenders(genderData)
    }
  }, [api, dataProvider, store])

  useEffect(() => {
    let mount = true

    if (mount) {
      fetchData()
    }

    return () => (mount = false)
  }, [fetchData])

  return (
    <TransparentEditGuesser
      {...props}
      variant="outlined"
      onSubmit={() => {}}
      toolbar={<CustomToolbar customerId={customerId} />}
    >
      <FieldsWrapper>
        <TextInput
          source="data.email"
          label="Email"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.first_name"
          label="First name"
          variant="outlined"
          fullWidth
        />
        <TextInput
          source="data.last_name"
          label="Last name"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput source="phone" variant="outlined" fullWidth size="small" />
        <DateInput
          source="data.cf_date_of_birth"
          label="Date Of Birth"
          variant="outlined"
          fullWidth
          size="small"
        />
        <SelectInput
          source="data.cf_gender_v2"
          label="Gender"
          optionText="name"
          choices={genders}
          variant="outlined"
        />

        <TextInput
          source="data.cf_surgeon_name"
          label="Surgeon Name"
          variant="outlined"
          fullWidth
          size="small"
        />
        <SelectInput
          source="data.cf_surgery_type_v2"
          label="Surgery Type"
          optionText="name"
          choices={surgeryTypes}
          variant="outlined"
        />
        <DateInput
          source="data.cf_surgery_date"
          label="Surgery Date"
          variant="outlined"
          fullWidth
          size="small"
        />

        <AutocompleteInput
          source="data.cf_clinic_v2"
          label="Clinic"
          choices={clinics}
          optionText="name"
          variant="outlined"
          options={{
            suggestionsContainerProps: { style: { zIndex: 99999 } },
          }}
        />
        <SelectInput
          source="data.cf_trial_status"
          label="Trial status"
          optionText="name"
          choices={trialStatuses}
          variant="outlined"
        />
        <Condition when="data.cf_trial_status" is="Not converted">
          <SelectInput
            source="data.cf_reason_trial_not_converted"
            label="Reason trial not converted"
            optionText="name"
            choices={notConvertReason}
            variant="outlined"
          />
        </Condition>
      </FieldsWrapper>
    </TransparentEditGuesser>
  )
}
