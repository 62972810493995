import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { LinearProgress, Typography } from '@material-ui/core'
import { useSubscriptions } from '../contexts/subscriptions'
import { useStore } from '../contexts/store'
import { SendEmailDialog } from './SendEmailDialog'
import { useCurrentSession } from '../contexts/currentSession'
import { formatDate, formatPrice } from 'utils/string'
import { CouponSelect } from 'components/couponSelect'
import { CreateSubscriptionPimcoreDialog } from './CreateSubscriptionPimcore'
import { colors } from 'theme/MuiTheme'
import { usePageState } from '../contexts/pageState'
import { SubscriptionItem } from 'components/SubscriptionItem'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 10px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
`

const TotalByDate = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const SubscriptionsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Subscription = styled.li`
  padding: 8px;
  position: relative;

  &:before {
    position: absolute;
    opacity: 0;
    content: ' ';
    transition: opacity 0.3s;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    border: 1px solid gray;
    border-radius: 8px;
  }

  &:after {
    position: absolute;
    opacity: 0;
    content: '🔧 edit';
    transition: opacity 0.3s;
    top: 8px;
    right: 8px;
  }

  &:hover {
    cursor: pointer;

    &:before {
      opacity: 0.3;
    }

    &:after {
      opacity: 1;
    }
  }
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  gap: 20px;
  padding: 0;
`

const StartingDate = styled.span`
  color: #90c449;
  font-weight: bold;
`

const Label = styled.div`
  color: ${(props) => (props.isImmediately ? colors.primary : '#000000')};
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PimcoreSubscriptions = () => {
  const {
    subscriptions,
    coupon,
    setCoupon,
    subscriptionsFetching,
  } = useSubscriptions()
  const { setActiveStep } = usePageState()
  const { disabled } = useCurrentSession()

  const { store } = useStore()
  const [totalPrice, setTotalPrice] = useState()
  const [totalBySubscriptionDate, setTotalBySubscriptionDate] = useState(null)
  const [discountAmount, setDiscountAmount] = useState(0)

  useEffect(() => {
    const formattedSubscriptions = subscriptions?.reduce((map, curr) => {
      const date = curr.startDate || 'Immediately'

      if (!map[date]) {
        map[date] = []
      }

      map[date].push(curr)

      return map
    }, {})
    setTotalBySubscriptionDate({})

    if (
      formattedSubscriptions &&
      Object.keys(formattedSubscriptions).length > 0
    ) {
      Object.keys(formattedSubscriptions).forEach((key) => {
        let amountDue = 0
        let discount = 0

        const subscriptions = formattedSubscriptions[key]

        subscriptions.forEach((subscription) => {
          amountDue += Number(subscription.total)
          discount += subscription.discounts?.reduce((a, b) => a + b, 0)
        })

        setTotalBySubscriptionDate((prev) => {
          return {
            ...prev,
            [key]: {
              total: amountDue,
              discount,
            },
          }
        })
      })
    }
  }, [subscriptions])

  useEffect(() => {
    if (totalBySubscriptionDate) {
      const totalData = Object.entries(totalBySubscriptionDate).reduce(
        (map, curr) => {
          map.total = map.total + curr[1].total
          map.discount = map.discount + curr[1].discount

          return map
        },
        { total: 0, discount: 0 }
      )

      if (totalData) {
        setTotalPrice(totalData.total)
        setDiscountAmount(totalData.discount)
      }
    }
  }, [totalBySubscriptionDate, setTotalPrice, setDiscountAmount, subscriptions])

  const getTitle = (subscription) => {
    if (subscription?.interval?.includes('SKT')) {
      return 'Starter pack'
    } else if (subscription?.interval?.includes('SGL')) {
      return 'One time order'
    }
    return 'Subscription'
  }

  const currency = store?.currency?.code

  return (
    <Wrapper disabled={disabled}>
      <SubscriptionsList>
        {subscriptions?.map((subscription, subscriptionIndex) => {
          return (
            <Subscription
              key={subscriptionIndex}
              role="button"
              onClick={() => setActiveStep('cart')}
            >
              <Typography variant="h6">{getTitle(subscription)}</Typography>
              <span>
                start date:{' '}
                <StartingDate>
                  {subscription.startDate
                    ? formatDate(subscription.startDate)
                    : 'immediately'}
                </StartingDate>
              </span>
              <ItemsList>
                {subscription.items.map((item, itemIndex) => {
                  return (
                    <SubscriptionItem
                      key={`${subscriptionIndex}:${itemIndex}`}
                      currency={currency}
                      item={item}
                    />
                  )
                })}
              </ItemsList>
            </Subscription>
          )
        })}
      </SubscriptionsList>
      <CouponSelect storeId={store?.id} coupon={coupon} setCoupon={setCoupon} />
      {!!subscriptions?.length && (
        <>
          {isNaN(totalPrice) || subscriptionsFetching ? (
            <LinearProgress style={{ margin: '10px 0' }} />
          ) : (
            <>
              {totalBySubscriptionDate &&
                Object.keys(totalBySubscriptionDate).length > 1 && (
                  <div>
                    {Object.keys(totalBySubscriptionDate)
                      .sort(function (a, b) {
                        if (b === 'Immediately') {
                          return 1
                        } else {
                          return -1
                        }
                      })
                      .map((key) => {
                        const total = totalBySubscriptionDate[key]?.total

                        return (
                          <TotalByDate key={key}>
                            <Label isImmediately={key === 'Immediately'}>
                              {key === 'Immediately'
                                ? 'Immediately'
                                : new Date(key).toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    day: 'numeric',
                                    month: 'short',
                                  })}
                            </Label>
                            <Price>{formatPrice(total / 100, currency)}</Price>
                          </TotalByDate>
                        )
                      })}
                  </div>
                )}
              <Footer>
                <span>
                  Total:{' '}
                  <Price>{formatPrice(totalPrice / 100, currency)}</Price>
                </span>
                {!!discountAmount && (
                  <span>
                    saved: {formatPrice(-discountAmount / 100, currency)}
                  </span>
                )}
              </Footer>
            </>
          )}
        </>
      )}
      <Buttons>
        <CreateSubscriptionPimcoreDialog totalPrice={totalPrice} />
        <SendEmailDialog />
      </Buttons>
    </Wrapper>
  )
}
