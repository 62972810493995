import React, { createContext, useContext } from 'react'

const customerContext = createContext()

const CustomerProvider = ({
  children,
  personalInfo,
  setPersonalInfo,
  shippingAddress,
  setShippingAddress,
  billingAddress,
  setBillingAddress,
  comment,
  setComment,
  customerMidlayerId,
  hasValidPaymentMethod,
  customerCBId,
  personalInfoChanged,
}) => {
  return (
    <customerContext.Provider
      value={{
        personalInfo,
        setPersonalInfo,
        shippingAddress,
        setShippingAddress,
        billingAddress,
        setBillingAddress,
        comment,
        setComment,
        customerMidlayerId,
        hasValidPaymentMethod,
        customerCBId,
        personalInfoChanged,
      }}
    >
      {children}
    </customerContext.Provider>
  )
}

const useCustomer = () => useContext(customerContext)

export { useCustomer, CustomerProvider }
