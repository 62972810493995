import React, { useEffect, useState, useCallback } from 'react'
import Box from '@material-ui/core/Box'
import {
  Button,
  ReferenceManyField,
  Pagination,
  TextField,
  useListContext,
  useNotify,
  useRedirect,
  useDelete,
  useRefresh,
} from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldGuesser } from '@api-platform/admin'
import { DataGrid } from 'theme/components'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  delete: {
    color: '#ff4f52',
  },
  dialog: {
    padding: '20px',
  },
  buttons: {
    display: 'flex',
    marginTop: '20px',
  },
}))

const ListPagination = ({
  closeConfirmation,
  setTotalItems,
  handleDelete,
  ...props
}) => {
  const classes = useStyles()
  const { total, perPage } = useListContext()
  useEffect(() => setTotalItems(total), [total, setTotalItems])
  return (
    <Box>
      {total > perPage && <Pagination {...props} />}
      <Box className={classes.buttons}>
        <Button
          onClick={closeConfirmation}
          size="medium"
          color="secondary"
          fullWidth
          label="cancel"
        />
        <Button
          onClick={() => handleDelete()}
          label="Delete"
          className={classes.delete}
          size="medium"
          disabled={Boolean(total)}
          fullWidth
        >
          <DeleteIcon />
        </Button>
      </Box>
    </Box>
  )
}

const DeleteProductStoreButton = (props) => {
  const { record, resource } = props
  const [deleteOne, { error }] = useDelete(resource, record.id, record)
  const refresh = useRefresh()
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const id = record.originId
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [totalItems, setTotalItems] = useState(0)

  const handleDeleteProductStore = useCallback(async () => {
    try {
      await deleteOne()
      setIsConfirmOpen(false)
      refresh()
      if (!error) {
        redirect(resource)
        notify(`Product Store ${id} was removed`)
      }
    } catch (error) {
      notify(`Error occured: ${error}`)
    }
  }, [notify, id, redirect, resource, deleteOne, error, refresh])

  return (
    <>
      <Button
        onClick={() => setIsConfirmOpen(true)}
        size="medium"
        label="Delete"
        className={classes.delete}
      >
        <DeleteIcon />
      </Button>
      <Dialog open={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
        <Box className={classes.dialog}>
          <DialogTitle>
            {totalItems === 0
              ? 'Are you sure you want to delete current Product Store?'
              : `You can't delete this Product Store because it has related PSI${
                  totalItems === 1 ? '' : 's'
                }`}
          </DialogTitle>
          <ReferenceManyField
            {...props}
            addLabel={false}
            reference="product-store-intervals"
            target="productStore.id"
            basePath="product-stores"
            source="id"
            hasEdit={false}
            pagination={
              <ListPagination
                closeConfirmation={() => setIsConfirmOpen(false)}
                setTotalItems={setTotalItems}
                handleDelete={handleDeleteProductStore}
              />
            }
          >
            <DataGrid>
              <TextField
                source="originId"
                label="id"
                sortBy="id"
                sort={{ field: 'id', order: 'DESC' }}
              />
              <FieldGuesser source="name" />
            </DataGrid>
          </ReferenceManyField>
        </Box>
      </Dialog>
    </>
  )
}

export default DeleteProductStoreButton
