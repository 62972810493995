import React from 'react'
import {
  required,
  ReferenceInput,
  SelectInput,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  maxLength,
  AutocompleteInput,
} from 'react-admin'
import styled from 'styled-components'
import { EditGuesser } from 'theme/components'

const MultiselectWrapper = styled.div`
  border: 1px solid gray;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 10px 0;
`

export const Option = styled.span`
  white-space: pre-wrap;
  overflow: hidden;
  max-width: 234px;
  width: 100%;
  border-left: 3px solid gray;
  padding-left: 16px;
  background: rgba(0, 0, 0, 0.06);
  font-weight: 500;
`

export const AutocompleteOption = (choice) => (
  <Option>{choice.record.recordData.Name}</Option>
)

export const ClinicEdit = (props) => {
  return (
    <EditGuesser
      {...props}
      variant="outlined"
      redirect="list"
      transform={(record) => ({
        ...record,
        salesforceId: undefined,
        stores: record.stores || [],
      })}
    >
      <TextInput source="name" validate={[required(), maxLength(99)]} />
      <TextInput source="city" validate={[maxLength(99)]} />
      <ReferenceInput
        source="salesforceTrack"
        label="SF Clinic name"
        reference="salesforce-tracks"
        filterToQuery={(searchText) => ({ clinicName: searchText })}
        format={(v) => (v ? v['@id'] || v : v)}
      >
        <AutocompleteInput
          inputText={(choice) => choice.recordData.Name}
          optionText={<AutocompleteOption />}
          resettable
          matchSuggestion={(filter, choice) =>
            choice.recordData.Name.toLowerCase().includes(filter.toLowerCase())
          }
        />
      </ReferenceInput>
      <ReferenceInput
        source="status"
        reference="clinic-statuses"
        format={(v) => (v ? v['@id'] || v : '')}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <MultiselectWrapper>
        <ArrayInput source="stores" label="Stores">
          <SimpleFormIterator>
            <ReferenceInput
              reference="stores"
              label=""
              format={(v) => (v ? v['@id'] || v : v)}
              filter={{ active: 1 }}
            >
              <SelectInput optionText="name" validate={required()} />
            </ReferenceInput>
          </SimpleFormIterator>
        </ArrayInput>
      </MultiselectWrapper>
      <MultiselectWrapper>
        <ArrayInput source="magentoIds" label="Magento IDs">
          <SimpleFormIterator>
            <TextInput label="" validate={[required(), maxLength(99)]} />
          </SimpleFormIterator>
        </ArrayInput>
      </MultiselectWrapper>
    </EditGuesser>
  )
}
