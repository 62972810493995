import React from 'react'
import {
  FunctionField,
  TextField,
  Filter,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import { formatPrice } from 'utils/string'

export const transactionTypes = {
  1: 'Authorization',
  2: 'Payment',
  3: 'Refund',
  4: 'Chargeback',
}

const cardNames = {
  visa: 'Visa',
  sepadirectdebit: 'SEPA',
  ideal: 'Ideal',
  mc: 'Mastercard',
  bcmc: 'Bancontact',
  paypal: 'Paypal',
  klarna: 'Klarna',
}

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      source="payment.status.id"
      reference="payment-statuses"
      label="Status"
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="payment.customer.id"
      reference="customers"
      label="Customer email"
      referenceSource="email"
      filterToQuery={(searchText) => ({ email: searchText })}
    >
      <AutocompleteInput optionText="email" />
    </ReferenceInput>
    <TextInput label="PSP Reference" source="pspReference" />
    <SelectInput
      source="type"
      choices={Object.keys(transactionTypes).map((id) => ({
        id,
        name: transactionTypes[id],
      }))}
    />
  </Filter>
)

export const TransactionList = (props) => {
  return (
    <ListGuesser
      filters={<ListFilter />}
      {...props}
      hasEdit={false}
      rowClick="show"
      bulkActionButtons={false}
      filter={{
        properties: ['id', 'pspReference', 'createdAt', 'amount', 'type'],
        'properties[payment]': ['id', 'paymentMethod'],
        'properties[currency]': ['code'],
        'properties[payment][type]': ['name'],
        'properties[payment][invoice]': ['id'],
        'properties[payment][status]': ['name'],
        'properties[payment][customer]': ['email', 'id'],
      }}
    >
      <TextField source="originId" label="id" />
      <FunctionField
        source="type"
        render={(record) => transactionTypes[record?.type]}
      />
      <TextField source="payment.id" label="Payment ID" />
      <TextField source="payment.invoice.id" label="Invoice id" />
      <FunctionField
        label="Amount"
        source="amount"
        render={(record) => {
          const { amount, currency } = record
          return formatPrice(amount / 100, currency?.code)
        }}
      />
      <ReferenceListFilterField
        listUri="/customers"
        titlePath="payment.customer.email"
        valuePath="payment.customer.email"
        label="Customer"
      />
      <TextField source="payment.customer.id" label="Customer id" />
      <TextField source="payment.status.name" label="Payment Status" />
      <TextField source="payment.type.name" label="Payment Type" />
      <TextField source="pspReference" />
      <DateField source="createdAt" short={false} label="Created at" />
      <FunctionField
        label="Payment method details"
        render={(record) => {
          if (!record?.payment?.paymentMethod?.details) return null

          const details = { ...record?.payment?.paymentMethod?.details }
          const cardType = record?.payment?.paymentMethod?.type
          const cardNumber = details.iban || details.number || ''

          return `${cardNames[cardType] || cardType} ${cardNumber}`
        }}
      />
      <TextField source="payment.paymentMethod.type" label="Payment method" />
    </ListGuesser>
  )
}
