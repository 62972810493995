import React from 'react'
import { Button } from 'react-admin'
import RefreshIcon from '@material-ui/icons/Refresh'
import useApi from 'hooks/useApi'

export const WooSyncButton = ({ record, type, buttonText }) => {
  const { api, isSubmitting } = useApi()
  const sync = async (type, record) => api.wooSync(type, record.originId)

  return (
    <Button
      label={buttonText}
      onClick={(e) => {
        e.stopPropagation()
        return sync(type, record)
      }}
      disabled={isSubmitting}
    >
      <RefreshIcon />
    </Button>
  )
}

WooSyncButton.defaultProps = {
  label: 'resources.woo.sync',
  buttonText: null,
}
