import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  TextField,
  ReferenceManyField,
  Pagination,
  FunctionField,
  useRedirect,
  Button,
} from 'react-admin'
import { DateField, DataGrid, ReferenceListFilterField } from 'theme/components'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import UserSession from 'service/UserSession'
import { hasRoles } from 'utils/user'
import useApi from 'hooks/useApi'
import { getApiRoute } from 'service/Api/routes'
import { getFormattedResponse } from 'utils/hydra'
import { TrackOrderButton } from 'components/pages/order/TrackOrderButton'
import { PostponeNextOrderButton } from './PostponeOrderButton'
import styled from 'styled-components'

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const CustomerOrdersList = (props) => {
  const customer = useCustomer()
  const { roles } = UserSession.getUser()
  const { hasAdminRole } = useMemo(() => hasRoles(roles), [roles])
  const redirect = useRedirect()
  const [invoices, setInvoices] = useState([])
  const { api } = useApi()
  const history = useHistory()

  useEffect(() => {
    api
      .get(getApiRoute('invoices'), {
        itemsPerPage: 999,
        'customer.chargeBeeId': customer.chargeBeeId,
        properties: ['id', 'order'],
      })
      .then((res) => {
        const { items } = getFormattedResponse(res)
        setInvoices(items)
      })
  }, [customer, api])

  if (!customer?.id) return null

  return (
    <ReferenceManyField
      addLabel={false}
      reference="orders"
      source="id"
      pagination={<Pagination />}
      filter={{
        'customer.id': customer.id,
        properties: [
          'id',
          'createdAt',
          'updatedAt',
          'displayName',
          'shippingPriority',
          'subscriptionId',
        ],
        'properties[chargeBeeStatus]': ['id', 'slug'],
        'properties[status]': ['id', 'display'],
        'properties[montaStatus]': ['id', 'slug'],
        'properties[store]': ['id', 'name'],
        'properties[customer]': ['id', 'email'],
      }}
      target=""
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available">
        <FunctionField
          source="displayName"
          label="resources.order.table.orderNumber"
          render={(record) => (
            <Button
              onClick={() =>
                redirect(`/orders/${encodeURIComponent(record.id)}/show`)
              }
              label={record.displayName}
            />
          )}
        />
        <TextField
          source="chargeBeeStatus.slug"
          label="resources.chargeBeeStatus.field"
        />
        <TextField
          source="status.display"
          label="resources.orderStatus.field"
        />
        <TextField
          source="montaStatus.slug"
          label="resources.fulfillmentStatus.field"
        />

        <FunctionField
          label="History"
          render={(record) => (
            <Button
              onClick={() => {
                redirect(
                  `/orders/${encodeURIComponent(record.id)}/show/history`
                )
              }}
              label="Order history"
            />
          )}
        />

        <FunctionField
          label="Fulfillment History"
          render={(record) => (
            <Button
              onClick={() => {
                redirect(
                  `/orders/${encodeURIComponent(
                    record.id
                  )}/show/fulfillment-history`
                )
              }}
              label="Fulfillment history"
            />
          )}
        />

        <FunctionField
          label="Reship"
          render={(record) => (
            <Button
              onClick={() => {
                redirect(`/orders/${encodeURIComponent(record.id)}/show/reship`)
              }}
              label="Reship"
            />
          )}
        />

        <FunctionField
          source="shippingPriority"
          label="Priority"
          render={(record) => (record.shippingPriority ? 'yes' : 'no')}
        />

        {hasAdminRole ? (
          <ReferenceListFilterField
            label="resources.store.field"
            listUri="/stores"
            titlePath="store.name"
            valuePath="store.name"
          />
        ) : (
          <TextField source="store.name" label="resources.store.field" />
        )}
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <FunctionField
          label=""
          render={(record) => {
            const invoiceId = invoices.find(
              (item) => item.order?.id === record.originId
            )?.id

            if (!invoiceId) return null

            return (
              <Button
                color="primary"
                variant="contained"
                size="medium"
                label="Create credit note"
                onClick={() =>
                  history.push(`/credit-notes/create?invoiceId=${invoiceId}`)
                }
              />
            )
          }}
        />
        <FunctionField
          render={(record) => (
            <Buttons>
              <TrackOrderButton api={api} orderId={record.id} />
              <PostponeNextOrderButton
                api={api}
                subscriptionId={record.subscriptionId}
              />
            </Buttons>
          )}
        />
      </DataGrid>
    </ReferenceManyField>
  )
}
