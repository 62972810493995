import React, { useState } from 'react'
import { useNotify, Button } from 'react-admin'
import RefreshIcon from '@material-ui/icons/Refresh'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import useApi from 'hooks/useApi'

export const SyncProductsButton = ({ record }) => {
  const { api, isSubmitting, handleError } = useApi()
  const notify = useNotify()
  const sync = async (record, env) => {
    try {
      await api.syncProducts({ id: record.originId, env })
      notify(
        `Task for syncing products for store ${record.name}, environment ${env} was created`
      )
    } catch (error) {
      handleError(error)
    }
  }
  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const closeMenu = () => setAnchorEl(null)

  return (
    <div>
      <Button disabled={isSubmitting} onClick={openMenu}>
        <RefreshIcon />
      </Button>
      <Menu
        id="product-sync-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            sync(record, 'prod')
            closeMenu()
          }}
        >
          Prod
        </MenuItem>
        <MenuItem
          onClick={(event) => {
            event.stopPropagation()
            sync(record, 'test')
            closeMenu()
          }}
        >
          Test
        </MenuItem>
      </Menu>
    </div>
  )
}

SyncProductsButton.defaultProps = {
  label: 'resources.product.sync',
}
