import React from 'react'
import { Route } from 'react-router-dom'
import GroupImageCreate from './components/pages/group/gallery/image/GroupImageCreate'
import { Dashboard } from './components/pages/dashboard/Dashboard'
import { ExactOnline } from './components/pages/exactOnline/ExactOnline'
import { RecoverPasswordRequest } from './components/pages/user/RecoverPasswordRequest'
import { RecoverPassword } from './components/pages/user/RecoverPassword'

export default [
  // Add your custom routes here
  <Route
    exact
    key="account-deposit-create"
    path="/groups/:id/gallery/add"
    component={GroupImageCreate}
  />,
  <Route exact key="dashboard" path="/dashboard" component={Dashboard} />,
  <Route
    exact
    key="exactOnline"
    path="/exact-online"
    component={ExactOnline}
  />,
  <Route
    exact
    key="recover-password-request"
    path="/recover-password/request"
    component={RecoverPasswordRequest}
    noLayout
  />,
  <Route
    key="recover-password"
    exact
    path="/recover-password/:token"
    component={RecoverPassword}
    noLayout
  />,
]
