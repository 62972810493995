import React from 'react'
import { Link, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { displayLongText } from 'utils/string'

export const LinkField = ({ record, source, text, limitText }) => {
  const translate = useTranslate()

  if (!record) {
    return
  }

  const to = get(record, source)

  if (!to) {
    return null
  }

  const inner = text ? translate(text) : limitText ? displayLongText(to) : to

  return (
    <Link
      to={{
        pathname: to,
      }}
      onClick={(event) => event.stopPropagation()}
      target="_blank"
      rel="noopener noreferrer"
    >
      {inner}
    </Link>
  )
}

LinkField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  text: PropTypes.string,
  limitText: PropTypes.bool,
}

LinkField.defaultProps = {
  addLabel: true,
  limitText: true,
}
