import { throttle } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import Api from 'service/Api'

export default (headers, withCredentials = true) => {
  const [isSubmitting, _setIsSubmitting] = useState(false)

  /* eslint-disable react-hooks/exhaustive-deps */
  const setIsSubmitting = useCallback(
    throttle((val) => {
      _setIsSubmitting((cur) => {
        if (val instanceof Function) return val(cur)
        return val
      })
    }, 300),
    []
  )
  /* eslint-enable react-hooks/exhaustive-deps */

  const notify = useNotify()
  const translate = useTranslate()
  const api = useMemo(
    () => new Api(notify, translate, setIsSubmitting, withCredentials, headers),
    [headers, notify, translate, setIsSubmitting, withCredentials]
  )

  // Not sure that we need to share this method in callback..
  const handleError = useCallback(
    (error) => notify(api.handleErrorMessage(error)),
    [notify, api]
  )

  return { api, isSubmitting, handleError }
}
