import React from 'react'
import { TextField, FunctionField } from 'react-admin'
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { DateField, ListGuesser } from 'theme/components'

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
`

const ChangeItem = styled.li`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListOfChanges = styled.p`
  width: 100%;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const LogEntryList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick={() => {}}
    bulkActionButtons={false}
  >
    <DateField source="loggedAt" />
    <TextField source="user.username" label="Username" />
    <TextField source="objectClass" />
    <TextField source="action" />
    <TextField source="objectId" />
    <FunctionField
      label="Changes"
      render={(record) => {
        if (record.action === 'remove') return '-'

        const keys = record.data ? Object.keys(record.data) : []
        return (
          <Tooltip
            title={
              <StyledList>
                {keys.map((key) =>
                  record?.data[key] ? (
                    <ChangeItem key={key}>
                      <b>{key}</b>
                      {`: from ${JSON.stringify(
                        record.data[key].old
                      )} to ${JSON.stringify(record.data[key].new)}`}
                    </ChangeItem>
                  ) : null
                )}
              </StyledList>
            }
          >
            <ListOfChanges>{keys.join(', ')}</ListOfChanges>
          </Tooltip>
        )
      }}
    />
  </ListGuesser>
)
