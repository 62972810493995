import React from 'react'
import {
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  TextField,
  DeleteButton,
  Toolbar,
  SaveButton,
  useRefresh,
} from 'react-admin'
import { EditGuesser } from 'theme/components'
import { LogCallModal } from 'components/logCallModal'
import styled from 'styled-components'

const LogCallWrapper = styled.div`
  display: flex;
  gap: 12px;
  & > div > .MuiButtonBase-root {
    width: initial !important;
  }
`

const LogCall = ({ record }) => {
  const refresh = useRefresh()

  return (
    <LogCallWrapper>
      <LogCallModal
        customerId={record.customer?.id}
        appointmentId={record.originId}
        initialReason={(record.reason && record.reason['@id']) || record.reason}
        onSuccess={refresh}
      />
      <LogCallModal
        customerId={record.customer?.id}
        appointmentId={record.originId}
        initialReason={(record.reason && record.reason['@id']) || record.reason}
        onSuccess={refresh}
        couldNotReach
      />
    </LogCallWrapper>
  )
}

export const AppointmentEdit = (props) => {
  return (
    <EditGuesser
      {...props}
      undoable={false}
      variant="outlined"
      redirect="list"
      toolbar={
        <Toolbar>
          <DeleteButton label="cancel" style={{ marginLeft: 'auto' }} />
          <SaveButton style={{ marginLeft: '12px' }} />
        </Toolbar>
      }
      transform={(record) => ({
        ...record,
        customer: record.customer ? record.customer['@id'] : undefined,
      })}
    >
      <TextField source="callAttempts" />
      <DateTimeInput source="startTime" validate={required()} />
      <TextInput source="phoneNumber" validate={required()} />
      <ReferenceInput
        source="reason"
        reference="call-reasons"
        filterToQuery={(searchText) => ({ name: searchText })}
        validate={required()}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <LogCall />
    </EditGuesser>
  )
}
