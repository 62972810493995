import React from 'react'
import { TextField, FunctionField } from 'react-admin'
import { ListGuesser, DateField } from 'theme/components'

export const FreshDeskTicketList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="show"
    bulkActionButtons={false}
    filter={{
      properties: ['id', 'createdAt', 'ticketId'],
    }}
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField
      source="ticketId"
      label="Ticket Id"
      sortBy="ticketId"
      sort={{ field: 'ticketId', order: 'DESC' }}
    />
    <FunctionField
      label="Ticket Subject"
      render={(record) => {
        const data = JSON.parse(record.data)

        return data?.freshdesk_webhook?.ticket_subject || data?.ticket_subject
      }}
    />
    <FunctionField
      label="Ticket Status"
      render={(record) => {
        const data = JSON.parse(record.data)

        return data?.freshdesk_webhook?.ticket_status || data?.ticket_status
      }}
    />
    <DateField source="createdAt" />
  </ListGuesser>
)
