import { downloadCSV } from 'react-admin'
import { api } from 'service/Api'

export const exporter = async (psi) => {
  const ids = psi.map((item) => item.originId)

  const data = await api.exportCsv('product-store-intervals', {
    ids,
  })

  downloadCSV(data, `product_store_intervals`)
}
