import React, { useState } from 'react'
import { CreateGuesser, ResourceCreateToolbar } from 'theme/components'
import { FormSpy } from 'react-final-form'
import {
  useRefresh,
  useNotify,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin'
import { getProductVariationTitle } from 'utils/string'
import { getNumberFromStringId } from 'utils/string'
import { AutoSelectInput } from 'theme/components/input/AutoSelectinput'

export const CreateWPProductVariationCard = React.memo((props) => {
  const { record } = props
  const [intervalId, setIntervalId] = useState()
  const refresh = useRefresh()
  const notify = useNotify()

  if (!record) {
    return null
  }

  const onSuccess = () => {
    notify('resources.variation.valueCreated')
    refresh()
  }

  return (
    <CreateGuesser
      variant="outlined"
      redirect={false}
      resource="wp-product-variations"
      basePath="/wp-product-variations"
      initialValues={{ product: record['@id'] }}
      toolbar={<ResourceCreateToolbar showBack={false} />}
      onSuccess={onSuccess}
    >
      <FormSpy
        {...props}
        subscription={{ values: true }}
        onChange={(form) => {
          if (form?.values?.interval?.id) {
            setIntervalId(form.values.interval.id)
          } else {
            setIntervalId(getNumberFromStringId(form?.values?.interval))
          }
        }}
      />
      <ReferenceInput
        variant="outlined"
        source="interval"
        reference="intervals"
        format={(v) => (v ? v['@id'] || v : v)}
        validate={[required()]}
        filter={{ properties: ['id', 'name'] }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      {!!record?.property?.id && (
        <ReferenceInput
          variant="outlined"
          source="propertyValue"
          reference="variation-property-values"
          filter={{
            'property.id': record.property.id,
            properties: ['id', 'name'],
          }}
          format={(v) => (v ? v['@id'] || v : v)}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      {intervalId && (
        <ReferenceInput
          variant="outlined"
          source="mandatoryProductVariation"
          reference="wp-product-variations"
          filter={{
            'interval.id': intervalId,
            properties: ['id', 'name'],
            'properties[interval]': ['id', 'name'],
            'properties[product]': ['name'],
            'properties[propertyValue]': ['name'],
          }}
          format={(v) => (v ? v['@id'] || v : v)}
        >
          <AutoSelectInput optionText={getProductVariationTitle} />
        </ReferenceInput>
      )}
      <span>*image can be added only on the edit screen</span>
    </CreateGuesser>
  )
})
