import React from 'react'
import styled from 'styled-components'
import { LinearProgress } from '@material-ui/core'

const QuantityButton = styled.button`
  color: white;
  background-color: #90c449;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const QuantityButtons = ({
  quantity,
  onIncrease,
  onDecrease,
  increaseDisabled = false,
  decreaseDisabled = false,
  loading = false,
}) => {
  return (
    <Wrapper>
      <b>{quantity || 0}</b>
      <div style={{ display: 'flex', gap: '1px' }}>
        <QuantityButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onDecrease()
          }}
          disabled={decreaseDisabled || loading}
        >
          -
        </QuantityButton>
        <QuantityButton
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            onIncrease()
          }}
          disabled={increaseDisabled || loading}
        >
          +
        </QuantityButton>
      </div>
      {loading && (
        <LinearProgress
          style={{ width: '100%', bottom: '-10px', position: 'absolute' }}
        />
      )}
    </Wrapper>
  )
}
