import React, { cloneElement } from 'react'
import {
  TextField,
  TextInput,
  Filter,
  SelectInput,
  DateInput,
  usePermissions,
  ReferenceInput,
  BooleanInput,
  FunctionField,
  useListContext,
  CreateButton,
  ExportButton,
} from 'react-admin'
import {
  ListGuesser,
  DateField,
  ReferenceListFilterField,
} from 'theme/components'
import { formatDateForAPI } from 'utils/string'
import { ROLES } from 'utils/user'
import { Button } from '@material-ui/core'
import useApi from 'hooks/useApi'
import { exporter } from './exporter'
import styled from 'styled-components'

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0;
  gap: 4px;
`

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="Id" />
    <TextInput source="customerEmail" label="Customer email" />
    <TextInput source="utm_source" label="UTM source" />
    <TextInput source="utm_medium" label="UTM medium" />
    <TextInput source="search" label="Customer first and last name" />
    <SelectInput
      source="status"
      choices={[
        { id: 1, name: 'Pending' },
        { id: 2, name: ' Completed' },
        { id: 3, name: ' Closed' },
        { id: 'default', name: 'Default' },
      ]}
    />
    <DateInput
      label="Completed At"
      defaultValue={formatDateForAPI(new Date())}
      source="completedAt"
      locales="nl-Nl"
    />
    <DateInput
      label="Viewed At"
      defaultValue={formatDateForAPI(new Date())}
      source="viewedAt"
      locales="nl-Nl"
    />
    <DateInput
      label="Created At"
      defaultValue={formatDateForAPI(new Date())}
      source="createdAt"
      locales="nl-Nl"
    />
    <ReferenceInput
      source="store.id"
      reference="stores"
      label="resources.store.field"
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput source="hasError" />
  </Filter>
)

const ListActions = (props) => {
  const { filters, maxResults } = props

  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
    total,
  } = useListContext()

  return (
    <Toolbar>
      <ExportButton
        label="Export CSV"
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <CreateButton basePath={basePath} />
    </Toolbar>
  )
}

export const CheckoutSessionList = (props) => {
  const { permissions } = usePermissions()
  const { api, isSubmitting } = useApi()

  return (
    <ListGuesser
      {...props}
      filters={<ListFilter />}
      actions={<ListActions />}
      hasEdit={false}
      rowClick="edit"
      bulkActionButtons={false}
      exporter={exporter}
      filter={{
        properties: [
          'id',
          'data',
          'createdAt',
          'viewedAt',
          'completedAt',
          'customerEmail',
        ],
        'properties[store]': ['name'],
      }}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <TextField source={'originId'} label={'id'} />
      <TextField source="data.utm_source" label="utm_source" />
      <TextField source="data.utm_medium" label="utm_medium" />
      <TextField source="data.customerEmail" label="Customer email" />
      {permissions?.includes(ROLES.ROLE_ADMIN) ? (
        <ReferenceListFilterField
          label="resources.store.field"
          listUri="/stores"
          titlePath="store.name"
          valuePath="store.name"
        />
      ) : (
        <TextField source="store.name" label="resources.store.field" />
      )}
      <DateField source="createdAt" />
      <DateField source="viewedAt" label="Viewed At" />
      <FunctionField
        label="Completed At"
        render={(record) => {
          if (record?.completedAt) {
            return <DateField record={record} source="completedAt" />
          }
          return (
            <Button
              size="small"
              fullWidth
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (isSubmitting) return
                api.resendSessionToCustomer({ sesssionId: record.originId })
              }}
              color="primary"
              variant="outlined"
            >
              resend
            </Button>
          )
        }}
      />
    </ListGuesser>
  )
}
