import React, { useCallback, useMemo, useState } from 'react'
import {
  Button,
  TextField,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DropzoneArea } from 'material-ui-dropzone'

import { convertFileToBase64 } from 'utils/base64'
import {
  getInitialGroupStoreFromUrl,
  getInitialGroupNameFromUrl,
} from '../utils'

const SpaceSeparator = styled.div`
  height: 15px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`

const GroupCreate = React.memo(
  ({ productStores, onBackClick, onSubmit, productId }) => {
    const [main, setMain] = useState(false)
    const [wooCommerce, setWooCommerce] = useState(false)

    const [loading, setLoading] = useState(false)

    const [store, setStore] = useState(
      getInitialGroupStoreFromUrl(productStores)
    )
    const [name, setName] = useState(getInitialGroupNameFromUrl(productStores))
    const [image, setImage] = useState(null)

    const options = useMemo(() => productStores.map((item) => item.store), [
      productStores,
    ])

    const _onSubmit = useCallback(async () => {
      setLoading(true)

      let fileData = null
      let values = {
        store: store['@id'],
        product: productId,
        name,
      }

      if (image) {
        let content = await convertFileToBase64({ rawFile: image })

        var blob = new Blob([content], {
          type: image.type,
        })

        fileData = {
          main,
          wooCommerce,
          file: {
            title: image.name,
            src: URL.createObjectURL(blob),
            content: content.replace(/^data:image.+;base64,/, ''),
            rawFile: {
              path: image.path,
            },
          },
        }
      }

      onSubmit(values, fileData)
    }, [store, main, wooCommerce, image, name, onSubmit, productId])

    return (
      <div>
        <SpaceSeparator />
        <Autocomplete
          id="store"
          options={options}
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          value={store}
          onChange={(event, newValue) => {
            setStore(newValue)
          }}
          renderInput={(params) => (
            <TextField {...params} label="Store" variant="outlined" />
          )}
        />

        <SpaceSeparator />

        <TextField
          id="name"
          label="Name"
          style={{ width: 300 }}
          variant="outlined"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <SpaceSeparator />

        <FormLabel>Image</FormLabel>

        <DropzoneArea
          onChange={(files) => setImage(files[0])}
          acceptedFiles={['image/jpeg', 'image/png']}
          filesLimit={1}
          maxFileSize={500000}
        />

        {image && (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  value={main}
                  onChange={(event) => setMain(event.target.checked)}
                  name="main"
                />
              }
              label="Main"
            />
            <FormControlLabel
              control={
                <Switch
                  value={wooCommerce}
                  onChange={(event) => setWooCommerce(event.target.checked)}
                  name="wooCommerce"
                />
              }
              label="Woo Commerce"
            />
          </FormGroup>
        )}

        <ButtonsWrapper>
          <Button color="primary" variant="outlined" onClick={onBackClick}>
            Back
          </Button>

          <Button
            disabled={loading || !name || !store}
            color="primary"
            variant="contained"
            onClick={_onSubmit}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </div>
    )
  }
)

export default GroupCreate
