import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import { ResourceEditToolbar } from 'theme/components/toolbar/ResourceEditToolbar'
import { EditGuesser } from 'theme/components'

export const CurrencyEdit = (props) => (
  <EditGuesser
    {...props}
    undoable={false}
    variant="outlined"
    redirect="list"
    toolbar={<ResourceEditToolbar showDelete={false} />}
  >
    <InputGuesser source="code" />
  </EditGuesser>
)
