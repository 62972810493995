import React from 'react'
import { TextInput } from 'react-admin'
import { EditGuesser } from 'theme/components'

export const FreshdeskSubjectEdit = (props) => (
  <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
    <TextInput source="name" />
    <TextInput source="translationKey" />
  </EditGuesser>
)
