import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { GroupImageEdit } from './image/GroupImageEdit'
import React from 'react'
import {
  Datagrid,
  BooleanField,
  EditButton,
  Loading,
  useTranslate,
} from 'react-admin'
import { DeleteImageButton } from './action/DeleteImageButton'
import { ImageField } from 'theme/components'

export const GalleryGrid = ({ ids, loaded, data, ...props }) => {
  const trans = useTranslate()

  if (loaded === false) {
    return <Loading />
  }

  if (ids.length === 0) {
    return (
      <Box textAlign="center" m={1}>
        <Typography variant="h4" paragraph>
          {trans('resources.groupImage.list.empty')}
        </Typography>
        <Typography variant="body1">
          {trans('resources.groupImage.list.emptyHelp')}
        </Typography>
      </Box>
    )
  }

  return (
    <Datagrid
      expand={<GroupImageEdit defaultToolbar />}
      {...{ ...props, ids, data, loaded }}
    >
      <ImageField
        label="resources.groupImage.preview"
        source="url"
        title="name"
        width="80px"
      />
      <BooleanField source="main" />
      <BooleanField
        label="resources.groupImage.wcEnabled"
        source="wooCommerce"
      />
      <EditButton />
      <DeleteImageButton {...props} />
    </Datagrid>
  )
}
