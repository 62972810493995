import CategoryIcon from '@material-ui/icons/Category'

import { ROLES } from 'utils/user'

import { VariationList } from './VariationPropertyList'
import { VariationEdit } from './VariationPropertyEdit'
import { VariationCreate } from './VariationPropertyCreate'

export default {
  list: VariationList,
  edit: VariationEdit,
  create: VariationCreate,
  icon: CategoryIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
