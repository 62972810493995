import React, { useState, useCallback } from 'react'
import {
  SelectInput,
  SimpleForm,
  Toolbar,
  ReferenceInput,
  Button,
  useRefresh,
} from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import useApi from 'hooks/useApi'
import { useSelector } from 'react-redux'

const ImportSubscriptionCancellationsToolbar = ({
  invalid,
  handleClose,
  handleImport,
}) => (
  <Toolbar>
    <Button
      onClick={handleClose}
      size="medium"
      color="secondary"
      fullWidth
      label="cancel"
    />
    <Button
      size="medium"
      color="primary"
      disabled={invalid}
      fullWidth
      component="label"
      label="choose file"
    >
      <>
        <CloudUploadIcon fontSize="small" />
        <input
          type="file"
          accept=".csv"
          onChange={handleImport}
          style={{ display: 'none' }}
        />
      </>
    </Button>
  </Toolbar>
)

const ImportSubscriptionCancellationButton = () => {
  const refresh = useRefresh()

  const { api, isSubmitting, handleError } = useApi()
  const [isOpen, setIsOpen] = useState(false)
  const [cancelationReason, setCancelationReason] = useState()
  const [cancelationSubreason, setCancelationSubreason] = useState()
  const reasonsMap = useSelector(
    (state) => state.admin.resources['cancellation-reasons']?.data
  )

  const handleImport = useCallback(
    async (e) => {
      if (cancelationReason && cancelationSubreason && reasonsMap) {
        const reasonText = reasonsMap[cancelationReason]?.name
        const subReasonText = reasonsMap[cancelationSubreason]?.name
        let formData = new FormData()
        formData.append('file', e.target.files[0])
        e.target.value = ''
        formData.append('reason', reasonText || cancelationReason)
        formData.append('subReason', subReasonText || cancelationSubreason)
        try {
          await api.importSubscriptionCancellations(formData)
        } catch (error) {
          handleError(error)
        } finally {
          refresh()
          setIsOpen(false)
        }
      }
    },
    [
      api,
      handleError,
      cancelationReason,
      cancelationSubreason,
      reasonsMap,
      refresh,
    ]
  )

  let title = 'Select file'

  if (!cancelationReason) {
    title = 'Select reason'
  } else if (!cancelationSubreason) {
    title = 'Select sub-reason'
  }

  return (
    <>
      <Button
        color="primary"
        onClick={() => setIsOpen(true)}
        size="small"
        label="Import subscription cancellations"
      >
        <CloudUploadIcon fontSize="small" />
      </Button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{title}</DialogTitle>
        <SimpleForm
          variant="outlined"
          saving={isSubmitting}
          redirect={false}
          toolbar={
            <ImportSubscriptionCancellationsToolbar
              handleClose={() => setIsOpen(false)}
              handleImport={(ev) => handleImport(ev)}
            />
          }
          validate={({ reason, subreason }) => {
            setCancelationReason(reason)
            setCancelationSubreason(subreason)
            const errors = {}

            if (!reason) {
              errors.reason = 'Required'
            }
            if (!subreason) {
              errors.subreason = 'Required'
            }

            return errors
          }}
          submitOnEnter={false}
        >
          <ReferenceInput
            source="reason"
            reference="cancellation-reasons"
            filter={{ 'exists[parent]': false }}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <ReferenceInput
            source="subreason"
            reference="cancellation-reasons"
            filter={{ 'parent.id': cancelationReason }}
            disabled={!cancelationReason}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </Dialog>
    </>
  )
}

export default ImportSubscriptionCancellationButton
