import React from 'react'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  AutocompleteInput,
  maxLength,
} from 'react-admin'
import { EditGuesser } from 'theme/components'

export const ShippingCountryStoreEdit = (props) => (
  <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
    <ReferenceInput
      source="country"
      reference="shipping-countries"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
      filterToQuery={(searchText) => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      source="store"
      reference="stores"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput
      source="name"
      label="Translated country name"
      validate={maxLength(99)}
    />
    <BooleanInput source="enabledInPortal" />
  </EditGuesser>
)
