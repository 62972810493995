import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import {
  Toolbar,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin'
import { EditGuesser, ResourceEditToolbar } from 'theme/components'

export const ProductStoreIntervalEdit = ({ defaultToolbar, ...props }) => (
  <EditGuesser
    {...props}
    undoable={false}
    variant="outlined"
    redirect="list"
    toolbar={
      defaultToolbar ? <Toolbar /> : <ResourceEditToolbar showDelete={false} />
    }
  >
    <ReferenceInput
      source="productStore"
      reference="product-stores"
      label="resources.productStore.field"
      filterToQuery={(searchText) => ({ sku: searchText })}
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <AutocompleteInput
        optionText={(option) => {
          if (!option) return option?.sku
          return `${option.sku} (${option?.store?.name})`
        }}
      />
    </ReferenceInput>
    <ReferenceInput
      source="interval"
      reference="intervals"
      label="resources.interval.field"
      filterToQuery={(searchText) => ({ name: searchText })}
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>
    <ReferenceInput
      source="currency"
      reference="currencies"
      label="resources.currency.field"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="code" />
    </ReferenceInput>
    <ReferenceInput
      source="pricingModel"
      reference="pricing-models"
      label="resources.productStoreInterval.pricingModel"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="slug" />
    </ReferenceInput>
    <InputGuesser source="chargeBeeId" label="resources.order.chargeBee" />
    <InputGuesser source="name" inputProps={{ maxLength: 50 }} />
    <InputGuesser source="invoiceName" inputProps={{ maxLength: 100 }} />
    <InputGuesser source="invoiceNotes" inputProps={{ maxLength: 2000 }} />
    <InputGuesser source="price" />
    <InputGuesser source="qty" />
    <InputGuesser source="shippable" />
    <InputGuesser
      source="enabledInPortal"
      label="resources.productStoreInterval.enabledInPortal"
      defaultValue={false}
    />
    <InputGuesser source="description" />
  </EditGuesser>
)
