import React from 'react'
import {
  Datagrid,
  useTranslate,
  Loading,
  ShowButton,
  EditButton,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { sanitizeProps } from 'utils/sanitizer'

const useStyles = makeStyles({
  message: {
    textAlign: 'center',
    opacity: 0.85,
    margin: '0 1em',
  },
  toolbar: {
    textAlign: 'center',
    marginTop: '2em',
  },
  loading: {
    '& > div': {
      paddingBottom: '3em',
    },
  },
})

export const DataGrid = ({
  children,
  hasList,
  loaded,
  ids,
  emptyText,
  showEmpty,
  ...props
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  if (!loaded) {
    return <Loading className={classes.loading} />
  }

  if (ids.length === 0 && showEmpty) {
    return (
      <span className={classes.message}>
        <Typography variant="h4" paragraph>
          {translate(emptyText)}
        </Typography>
      </span>
    )
  }

  return (
    <Datagrid {...sanitizeProps(props, ['hasShow', 'hasEdit'])}>
      {children}
      {!props.rowClick && props.hasShow && <ShowButton />}
      {!props.rowClick && props.hasEdit && <EditButton />}
    </Datagrid>
  )
}

DataGrid.defaultProps = {
  emptyText: 'resources.emptyList',
  hasShow: false,
}

DataGrid.propTypes = {
  emptyText: PropTypes.string,
}
