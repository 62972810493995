import React, { useState } from 'react'
import useApi from 'hooks/useApi'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import MailIcon from '@material-ui/icons/MailOutline'
import styled from 'styled-components'
import {
  TextField,
  Button,
  ReferenceManyField,
  Pagination,
  FunctionField,
  useRecordContext,
} from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { DateField, DataGrid } from 'theme/components'
import { getNumberFromStringId, formatText, formatPrice } from 'utils/string'
import PaymentModal from './PaymentModal'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { getApiRoute } from 'service/Api/routes'

const PayButton = styled(Button)`
  span {
    padding: 0;
  }
`

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`

const SendInfoiceDialog = ({ invoiceId }) => {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const { api, isSubmitting } = useApi()

  return (
    <>
      <button
        style={{
          background: 'none',
          border: 'none',
          cursor: isSubmitting ? 'not-allowed' : 'pointer',
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()

          setConfirmOpen(true)
        }}
      >
        <MailIcon />
      </button>
      <Dialog open={!!confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          Are you sure you want to send this invoice to customer?
        </DialogTitle>
        <DialogBody>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              gap: '20px',
            }}
          >
            <Button
              label="No"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setConfirmOpen(false)
              }}
              size="medium"
              variant="outlined"
              fullWidth
            />
            <Button
              label="Yes"
              disabled={isSubmitting}
              onClick={async (e) => {
                e.preventDefault()
                e.stopPropagation()
                await api.sendInvoiceToCustomer(invoiceId)
                setConfirmOpen(false)
              }}
              variant="contained"
              fullWidth
            />
          </div>
        </DialogBody>
      </Dialog>
    </>
  )
}

export const CustomerInvoicesList = (props) => {
  const record = useRecordContext()

  const customer = useCustomer()
  const [openPaymentModal, setOpenPaymentModal] = useState(false)
  const [invoiceId, setInvoiceId] = useState(null)
  const [paymentLink, setPaymentLink] = useState(null)

  if (!customer?.chargeBeeId) return null

  return (
    <>
      <ReferenceManyField
        addLabel={false}
        reference="invoices"
        source="id"
        pagination={<Pagination />}
        filter={{
          'customer.chargeBeeId': customer.chargeBeeId,
          properties: ['id', 'chargeBeeId', 'createdAt', 'data', 'paymentLink'],
        }}
        target=""
        {...props}
      >
        <DataGrid showEmpty emptyText="No options available" rowClick="show">
          <TextField source="chargeBeeId" label="Invoice number" />
          <FunctionField
            label="Price"
            render={(record) => {
              if (!record?.data) return null
              const {
                data: { amount_due, amount_paid, currency_code },
              } = record

              return formatPrice(
                (amount_due || amount_paid) / 100,
                currency_code
              )
            }}
          />
          <FunctionField
            label="Status"
            render={(record) => {
              const {
                data: { status },
              } = record

              return formatText(status)
            }}
          />
          <FunctionField
            label="Credit note status"
            render={(record) => {
              const creditNotes = record.data.issued_credit_notes
              const newRecord = {
                ...record,
                creditNotes:
                  creditNotes.length > 0
                    ? formatText(creditNotes[creditNotes.length - 1]?.cn_status)
                    : null,
              }

              return <TextField record={newRecord} source="creditNotes" />
            }}
          />
          <DateField source="createdAt" label="Created at" short={false} />
          <FunctionField
            render={(record) => {
              const {
                data: { status },
                paymentLink,
                id,
              } = record

              return (
                status !== 'paid' && (
                  <PayButton
                    color="primary"
                    variant="contained"
                    label={
                      paymentLink ? 'Resend payment link' : 'New payment link'
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      setInvoiceId(getNumberFromStringId(id))
                      setPaymentLink(paymentLink)
                      return setOpenPaymentModal(true)
                    }}
                  />
                )
              )
            }}
          />
          <FunctionField
            label="Download"
            render={(record) => {
              return (
                <button
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    window.open(
                      getApiRoute(
                        `customer-support/invoice/${record.originId}/pdf`
                      ),
                      '_blank'
                    )
                  }}
                >
                  <DownloadIcon />
                </button>
              )
            }}
          />
          <FunctionField
            label="Send"
            render={(record) => {
              return <SendInfoiceDialog invoiceId={record.originId} />
            }}
          />
        </DataGrid>
      </ReferenceManyField>

      {openPaymentModal && (
        <PaymentModal
          open={openPaymentModal}
          onClose={() => setOpenPaymentModal(false)}
          invoiceId={invoiceId}
          paymentLink={paymentLink}
          record={record}
        />
      )}
    </>
  )
}
