import { downloadCSV } from 'react-admin'
import { api } from 'service/Api'

export const exporter = async (plans) => {
  const ids = plans.map((item) => item.originId)

  const data = await api.exportCsv('plans', {
    ids,
  })

  downloadCSV(data, `plans`)
}
