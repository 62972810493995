import React from 'react'
import { TextField, FunctionField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const CancelReasonList = (props) => (
  <ListGuesser
    {...props}
    filter={{ properties: ['name', 'slug', 'parent', 'data'] }}
    rowClick="edit"
  >
    <TextField source="name" />
    <TextField source="slug" />
    <TextField source="parent.name" label="resource.cancelReason.parent" />
    <FunctionField
      label="Version"
      render={(record) => {
        const history = record?.data?.history
        const newRecord = {
          ...record,
          version: history ? history[history.length - 1]?.version : null,
        }

        return <TextField record={newRecord} source="version" />
      }}
    />
  </ListGuesser>
)
