import React from 'react'
import { ShowGuesser } from 'theme/components'
import CustomTable from 'components/customTable'
import styled from 'styled-components'
import { getAllResult } from './helper'

const Status = styled.span`
  color: ${(props) => (props.isError ? 'orange' : 'green')};
`

const Table = ({ record }) => {
  const data = getAllResult(record).reduce((prev, current) => {
    if (current?.type === 'error') {
      prev = [
        ...prev,
        {
          ...current,
          status: <Status isError>{current.message}</Status>,
        },
      ]
    } else if (current?.type === 'success') {
      prev = [
        ...prev,
        { ...current, status: <Status>Success</Status>, subscription: null },
      ]
    }

    return prev
  }, [])

  return (
    <CustomTable
      data={data}
      headCells={[
        {
          rowKey: 'store',
          id: 'store',
          label: 'Store',
        },
        {
          rowKey: 'subscription',
          id: 'subscription',
          label: 'Subscription id',
        },
        {
          rowKey: 'status',
          id: 'status',
          label: 'Status',
        },
      ]}
      pageSize={10}
      selectable={false}
    />
  )
}

export const CBSubscriptionsShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <h1>Uploaded File Details</h1>
      <Table {...props} />
    </ShowGuesser>
  )
}
