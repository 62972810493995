import HealingIcon from '@material-ui/icons/Healing'
import { SurgeryTypeOptionStoreList } from './SurgeryTypeOptionStoreList'
import { SurgeryTypeOptionStoreCreate } from './SurgeryTypeOptionStoreCreate'
import { SurgeryTypeOptionStoreEdit } from './SurgeryTypeOptionStoreEdit'
import { ROLES } from 'utils/user'

export default {
  list: SurgeryTypeOptionStoreList,
  create: SurgeryTypeOptionStoreCreate,
  edit: SurgeryTypeOptionStoreEdit,
  icon: HealingIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
