import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate,
  MenuItemLink,
  Responsive,
  usePermissions,
} from 'react-admin'
import { useLocation, Link } from 'react-router-dom'

import SubMenu from './SubMenu'
import PropTypes from 'prop-types'
import DefaultIcon from '@material-ui/icons/ViewList'
import DefaultSubMenuIcon from '@material-ui/icons/ChevronRight'
import { menuItems, menuStates } from '../../menu'
import dashboard from 'components/pages/dashboard'
import exactOnline from 'components/pages/exactOnline'
import { hasRoles, parseRoles, roleOptions } from 'utils/user'
import Logo from 'components/layout/Logo'
import styled from 'styled-components'
import UserSession from 'service/UserSession'

const HeadingLink = styled(Link)`
  padding: 32px 10px 12px 10px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);

  & img {
    width: 180px;
    height: 38px;
  }
`

const DashboardLinkWrapper = styled.span`
  transition: all 0.3s;
  display: flex;
  flex-direction: column;

  & > img {
    height: ${({ open }) => (open ? '38px' : '18px')};
    width: ${({ open }) => (open ? '180px' : '38px')};
  }
`

const StyledMenuItemLink = styled(MenuItemLink)`
  transition: all ease 250ms;

  &[aria-current] {
    background-color: #90c53c;
    color: white;

    & > div {
      color: white;
    }
  }
`

const Role = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Menu = React.memo(({ onMenuClick, dense, logout }) => {
  const { permissions } = usePermissions()
  const { hasAdminRole, hasFinanceRole } = React.useMemo(
    () => hasRoles(permissions),
    [permissions]
  )

  const { roles } = UserSession.getUser()

  const location = useLocation()
  const items = React.useMemo(() => menuItems(permissions), [permissions])
  const translate = useTranslate()
  const open = useSelector((state) => state.admin.ui.sidebarOpen)

  const [state, setState] = useState(menuStates(items))

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    const menuItem = items.find(
      (_item) => `/${_item.resource}` === location.pathname
    )

    if (location.pathname && menuItem && !state[menuItem.stateName]) {
      handleToggle(menuItem.stateName)
    }
  }, [items, location])
  /* eslint-enable react-hooks/exhaustive-deps */

  const rolesSet = new Set(parseRoles(roles))
  const roleLabel = roleOptions.find((role) => rolesSet.has(role.id))?.name

  return (
    <div>
      <HeadingLink to="/dashboard">
        <DashboardLinkWrapper open={open}>
          <Logo isSmallIcon={!open} />
          {open && <Role>{roleLabel}</Role>}
        </DashboardLinkWrapper>
      </HeadingLink>

      <StyledMenuItemLink
        key="menuItemDashboard"
        to="/dashboard"
        primaryText={translate('resources.dashboard.menu')}
        onClick={onMenuClick}
        leftIcon={dashboard.icon ? <dashboard.icon /> : <DefaultIcon />}
      />
      {(hasAdminRole || hasFinanceRole) && (
        <StyledMenuItemLink
          key="menuItemExactOnline"
          to="/exact-online"
          primaryText={translate('resources.exactOnline.menu')}
          onClick={onMenuClick}
          leftIcon={exactOnline.icon ? <exactOnline.icon /> : <DefaultIcon />}
        />
      )}
      {items.map((item) =>
        item.hasChildren ? (
          <SubMenu
            key={`menuItem${item.resource}`}
            handleToggle={() => handleToggle(item.stateName)}
            isOpen={state[item.stateName]}
            sidebarIsOpen={open}
            name={item.label}
            icon={item.icon ? <item.icon /> : <DefaultSubMenuIcon />}
            dense={dense}
          >
            {(!item.deny || (item.deny && item.deny === false)) && (
              <StyledMenuItemLink
                key={`menuItem${item.resource}`}
                to={{
                  pathname: `/${item.resource}`,
                  search: item.defaultSearch,
                }}
                primaryText={translate(item.label)}
                leftIcon={item.icon ? <item.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                dense={dense}
                sidebarIsOpen={open}
              />
            )}

            {item.subItems.map((subItem) => (
              <StyledMenuItemLink
                key={`menuSubItem${subItem.resource}`}
                to={{
                  pathname: `/${subItem.resource}`,
                  search: subItem.defaultSearch,
                }}
                primaryText={translate(subItem.label)}
                onClick={onMenuClick}
                leftIcon={subItem.icon ? <subItem.icon /> : <DefaultIcon />}
              />
            ))}
          </SubMenu>
        ) : (
          <StyledMenuItemLink
            key={`menuItem${item.resource}`}
            to={{
              pathname: `/${item.resource}`,
              search: item.defaultSearch,
            }}
            primaryText={translate(item.label)}
            leftIcon={item.icon ? <item.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            dense={dense}
            sidebarIsOpen={open}
          />
        )
      )}
      <Responsive small={logout} medium={null} />
    </div>
  )
})

Menu.propTypes = {
  dense: PropTypes.bool,
  onMenuClick: PropTypes.func,
}

export default Menu
