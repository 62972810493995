import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { ROLES } from 'utils/user'

import { ExactApiList } from './ExactApiList'

export default {
  list: ExactApiList,
  icon: SwapHorizIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_FINANCE],
}
