import user from 'components/pages/user'
import group from 'components/pages/group'
import store from 'components/pages/store'
import currency from 'components/pages/currency'
import product from 'components/pages/product'
import productStore from 'components/pages/productStore'
import productStoreInterval from 'components/pages/productStoreInterval'
import interval from 'components/pages/interval'
import plan from 'components/pages/plan'
import addon from 'components/pages/addon'
import order from 'components/pages/order'
import orderResendReason from 'components/pages/orderResendReason'
import customer from 'components/pages/customer'
import events from 'components/pages/events'
import freshDeskTickets from 'components/pages/freshDeskTickets'
import npfStock from 'components/pages/npfStock'
import freshdeskSubjects from 'components/pages/freshdeskSubjects'
import task from 'components/pages/task'
import error from 'components/pages/fulfillmentError'
import montaStock from 'components/pages/montaStock'
import notification from 'components/pages/notification'
import exactApi from 'components/pages/exactApi'
import clinic from 'components/pages/clinic'
import country from 'components/pages/country'
import cancelReason from 'components/pages/cancelReason'
import cluster from 'components/pages/cluster'
import region from 'components/pages/region'
import logEntry from 'components/pages/logEntry'
import shippingCountry from 'components/pages/shippingCountry'
import shippingCountryStore from 'components/pages/shippingCountryStore'
import customerSource from 'components/pages/customerSource'
import customerSourceStore from 'components/pages/customerSourceStore'
import surgeryTypeOption from 'components/pages/surgeryTypeOption'
import surgeryTypeOptionStore from 'components/pages/surgeryTypeOptionStore'
import gender from 'components/pages/gender'
import cbSubscriptions from 'components/pages/cbSubscriptions'
import variations from 'components/pages/variation-properties'
import wpProducts from 'components/pages/wp-products'
import bundles from 'components/pages/bundles'
import checkoutSessions from 'components/pages/checkoutSessions'
import transaction from 'components/pages/transaction'
import adyenNotification from 'components/pages/adyen-notification'
import invoice from 'components/pages/invoice'
import callReason from 'components/pages/callReason'
import creditNote from 'components/pages/credit-note'
import chargebeePayment from 'components/pages/chargebee-payment'
import pimcoreProduct from 'components/pages/pimcoreProduct'
import pimcoreProposition from 'components/pages/pimcoreProposition'
import appointment from 'components/pages/appointment'
import postponeReason from 'components/pages/postponeReason'
import dreamlogistics from 'components/pages/dreamlogistics'
import productFacade from 'components/pages/productFacade'
import pimcorePropositionFacade from 'components/pages/pimcorePropositionFacade'
import bundleStores from 'components/pages/bundle-stores'

export default [
  { resource: 'users', label: 'resources.user.menu', ...user },
  {
    resource: 'stores',
    label: 'resources.store.menu',
    ...store,
    subItems: [
      { resource: 'currencies', label: 'resources.currency.menu', ...currency },
      { resource: 'intervals', label: 'resources.interval.menu', ...interval },
    ],
  },
  {
    resource: 'groups',
    label: 'resources.group.menu',
    ...group,
    deny: true,
    subItems: [
      { resource: 'groups', label: 'resources.group.subMenu', ...group },
    ],
  },
  {
    resource: 'products',
    label: 'resources.product.menu',
    ...product,
    subItems: [
      {
        resource: 'product-stores',
        label: 'resources.productStore.menu',
        ...productStore,
      },
    ],
  },
  {
    resource: 'product-store-intervals',
    label: 'resources.chargeBeeProduct.menu',
    deny: true,
    ...product,
    subItems: [
      {
        resource: 'product-store-intervals',
        label: 'resources.productStoreInterval.menu',
        ...productStoreInterval,
      },
      { resource: 'plans', label: 'resources.plan.menu', ...plan },
      { resource: 'addons', label: 'resources.addon.menu', ...addon },
    ],
  },
  {
    resource: 'orders',
    label: 'resources.order.menu',
    ...order,
  },
  {
    resource: 'customers',
    label: 'resources.customer.menu',
    defaultSearch: `filter=${JSON.stringify({
      email: '',
      phone: '',
      zip: '',
      lastName: '',
      firstName: '',
    })}`,

    ...customer,
  },
  {
    resource: 'appointments',
    defaultSearch: `filter=${JSON.stringify({ status: 'default' })}`,
    label: 'resources.appointment.menu',
    ...appointment,
  },
  {
    resource: 'events',
    label: 'resources.events.menu',
    ...events,
  },
  {
    resource: 'fresh-desk-tickets',
    label: 'resources.freshdesk.menu',
    deny: true,
    ...freshDeskTickets,
    subItems: [
      {
        resource: 'fresh-desk-tickets',
        label: 'resources.freshDeskTickets.menu',
        ...freshDeskTickets,
      },
      {
        resource: 'fresh-desk-subjects',
        label: 'resources.freshdeskSubjects.menu',
        ...freshdeskSubjects,
      },
    ],
  },
  { resource: 'tasks', label: 'resources.task.menu', ...task },
  {
    label: 'resources.logistics.menu',
    deny: true,
    ...npfStock,
    subItems: [
      {
        resource: 'npf-stocks',
        label: 'resources.npfStock.menu',
        ...npfStock,
      },
      {
        resource: 'fulfillment-stocks',
        label: 'resources.dreamlogistics.menu',
        ...dreamlogistics,
      },
      {
        resource: 'fulfillment-errors',
        label: 'resources.error.menu',
        ...error,
      },
      {
        resource: 'monta-stocks',
        label: 'resources.montaStocks.menu',
        ...montaStock,
      },
    ],
  },
  {
    resource: 'exact-online-logs',
    label: 'resources.finance.menu',
    ...exactApi,
    deny: true,
    subItems: [
      {
        resource: 'exact-online-logs',
        label: 'resources.exactApi.menu',
        ...exactApi,
      },
      {
        resource: 'subscription-cancellations',
        label: 'resources.cbSubscriptions.menu',
        ...cbSubscriptions,
      },
      {
        resource: 'invoices',
        label: 'resources.invoice.menu',
        ...invoice,
      },
      {
        resource: 'credit-notes',
        label: 'resources.creditNote.menu',
        ...creditNote,
      },
      {
        resource: 'transactions',
        label: 'resources.adyenTransaction.menu',
        ...transaction,
      },
      {
        resource: 'chargebee-payments',
        label: 'resources.chargebeeTransaction.menu',
        ...chargebeePayment,
      },
      {
        resource: 'adyen-notifications',
        label: 'resources.adyenNotifications.menu',
        ...adyenNotification,
      },
    ],
  },
  {
    resource: 'notifications',
    label: 'resources.notification.menu',
    ...notification,
  },
  {
    resource: 'clinics',
    label: 'resources.clinic.menu',
    ...clinic,
  },

  {
    resource: 'wp-products',
    label: 'resources.wpProduct.menu',
    ...wpProducts,
    subItems: [
      {
        resource: 'variation-properties',
        label: 'resources.variation.menu',
        ...variations,
      },
      { resource: 'bundles', label: 'resources.bundle.menu', ...bundles },
      {
        resource: 'bundle-stores',
        label: 'resources.bundleStore.menu',
        ...bundleStores,
      },
    ],
  },
  {
    resource: 'checkout-sessions',
    defaultSearch: `filter=${JSON.stringify({ status: 'default' })}`,
    label: 'resources.checkoutSession.menu',
    ...checkoutSessions,
  },
  {
    resource: 'countries',
    label: 'resources.country.menu',
    ...country,
    subItems: [
      { resource: 'clusters', label: 'resources.cluster.menu', ...cluster },
      { resource: 'regions', label: 'resources.region.menu', ...region },
    ],
  },
  {
    resource: 'cancellation-reasons',
    label: 'resources.reason.menu',
    ...cancelReason,
    deny: true,
    subItems: [
      {
        resource: 'cancellation-reasons',
        label: 'resources.cancelReason.menu',
        ...cancelReason,
      },
      {
        resource: 'call-reasons',
        label: 'resources.callReason.menu',
        ...callReason,
      },
      {
        resource: 'order-resend-reasons',
        label: 'resources.order.orderResendReasons',
        ...orderResendReason,
      },
      {
        resource: 'postpone-reasons',
        label: 'resources.postponeReason.menu',
        ...postponeReason,
      },
    ],
  },
  {
    resource: 'log-entries',
    label: 'resources.logEntry.menu',
    ...logEntry,
  },
  {
    resource: 'shipping-countries',
    label: 'resources.shippingCountry.menu',
    ...shippingCountry,
    deny: true,
    subItems: [
      {
        resource: 'shipping-countries',
        label: 'resources.shippingCountry.subMenu',
        ...shippingCountry,
      },
      {
        resource: 'shipping-country-stores',
        label: 'resources.shippingCountryStore.menu',
        ...shippingCountryStore,
      },
      {
        resource: 'customer-sources',
        label: 'resources.customerSource.menu',
        ...customerSource,
      },
      {
        resource: 'customer-source-stores',
        label: 'resources.customerSourceStore.menu',
        ...customerSourceStore,
      },
      {
        resource: 'surgery-type-options',
        label: 'resources.surgeryTypeOption.menu',
        ...surgeryTypeOption,
      },
      {
        resource: 'surgery-type-option-stores',
        label: 'resources.surgeryTypeOptionStore.menu',
        ...surgeryTypeOptionStore,
      },
      {
        resource: 'genders',
        label: 'resources.gender.menu',
        ...gender,
      },
      {
        resource: 'gender-stores',
        label: 'resources.genderStore.menu',
        ...gender,
      },
    ],
  },

  {
    resource: 'pimcore-products',
    label: 'resources.pimcore.menu',
    ...pimcoreProduct,
    deny: true,
    subItems: [
      {
        resource: 'pimcore-products',
        label: 'resources.pimcoreProduct.menu',
        ...pimcoreProduct,
      },
      {
        resource: 'pimcore-propositions',
        label: 'resources.pimcoreProposition.menu',
        ...pimcoreProposition,
      },
    ],
  },

  {
    resource: 'proposition-product-facades',
    label: 'resources.facade.menu',
    ...pimcorePropositionFacade,
    deny: true,
    subItems: [
      {
        resource: 'proposition-facades',
        label: 'resources.pimcorePropositionFacade.menu',
        ...pimcorePropositionFacade,
      },
      {
        resource: 'proposition-product-facades',
        label: 'resources.productFacade.menu',
        ...productFacade,
      },
    ],
  },
]
