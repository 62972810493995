import React, { useState, useMemo, useEffect } from 'react'
import { ReferenceInput, SimpleForm } from 'react-admin'
import { useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import { getNumberFromStringId } from 'utils/string'
import { CustomToolbar } from 'theme/components/toolbar/CustomToolbar'
import { AutoSelectInput } from 'theme/components/input/AutoSelectinput'
import { getProductVariationTitle } from 'utils/string'
import useApi from 'hooks/useApi'
import { getApiRoute } from 'service/Api/routes'
import { getFormattedResponse } from 'utils/hydra'
import { usePrevious } from 'hooks/usePrevious'
import { StartDateInput } from 'components/startDateInput'

export const AddPsiItemModal = ({
  open,
  onClose,
  addItem,
  storeId,
  intervalSlug,
  showStartDateInput,
}) => {
  const { api } = useApi()
  const [wpProductId, setWpProductId] = useState()
  const [variationStoreId, setVariationStoreId] = useState()
  const prevWpProduct = usePrevious(wpProductId)
  const [showVariationSelect, setShowVariationSelect] = useState()
  const wpProductVariationStoresMap = useSelector(
    (state) => state.admin.resources['wp-product-variation-stores']?.data
  )
  const [startDate, setStartDate] = useState()

  const wpProductVariationStore = useMemo(
    () => wpProductVariationStoresMap[variationStoreId],
    [wpProductVariationStoresMap, variationStoreId]
  )

  useEffect(() => {
    if (wpProductId !== prevWpProduct) {
      // hide variations for a while to do the proper clean up
      setShowVariationSelect(false)
      setVariationStoreId()
      setTimeout(() => setShowVariationSelect(true), 200)
    }
  }, [wpProductId, prevWpProduct])

  const handleClose = () => {
    setVariationStoreId()
    onClose && onClose()
  }

  const onSubmit = async (values) => {
    const item = {
      bundle: false,
      quantity: 1,
      variationId: getNumberFromStringId(
        values.wpProductVariationStore || variationStoreId
      ),
    }

    if (showStartDateInput) {
      item.startDate = startDate
    }

    let mandatoryVariation = null

    if (
      wpProductVariationStore?.productVariation?.mandatoryProductVariation?.id
    ) {
      const mandatoryVariationResponse = await api.get(
        getApiRoute('wp-product-variation-stores'),
        {
          itemsPerPage: 999,
          'productVariation.id':
            wpProductVariationStore.productVariation.mandatoryProductVariation
              .id,
          'store.id': storeId,
          properties: ['id'],
        }
      )

      const { items } = getFormattedResponse(mandatoryVariationResponse)
      if (items.length) {
        item.mandatoryVariation = items[0].id
      }
    }

    if (mandatoryVariation) {
      item.mandatoryVariation = mandatoryVariation.variationId
    }

    addItem(item)

    setTimeout(() => handleClose(), 150)
  }

  useEffect(() => {
    if (!open) {
      setStartDate()
      setWpProductId()
      setVariationStoreId()
    }
  }, [open])

  return (
    <Dialog open={!!open} onClose={handleClose}>
      <SimpleForm
        variant="outlined"
        validate={(values) => {
          setWpProductId(values.wpProduct)
          setVariationStoreId(
            values.wpProductVariationStore || variationStoreId
          )

          return {
            wpProduct: values.wpProduct ? undefined : 'wp product is required',
          }
        }}
        save={onSubmit}
        toolbar={
          <CustomToolbar
            saveButtonProps={{
              disabled: !wpProductId || !variationStoreId,
              fullWidth: true,
            }}
          />
        }
      >
        <ReferenceInput
          reference="wp-products"
          label="WP product"
          source="wpProduct"
          format={(v) => (v ? v['@id'] || v : v)}
          filter={{
            ...(storeId && {
              'variations.productVariationStores.store.id': storeId,
            }),
            // 'variations.interval.slug[0]': 'SGL',
            ...(intervalSlug && { 'variations.interval.slug': intervalSlug }),
            bundleOnly: false,
            properties: ['name', 'id'],
          }}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={50}
        >
          <AutoSelectInput
            options={{
              suggestionsContainerProps: { style: { zIndex: 99999 } },
            }}
            optionText="name"
          />
        </ReferenceInput>

        {!!wpProductId && showVariationSelect && (
          <ReferenceInput
            reference="wp-product-variation-stores"
            label="Product variation"
            source="wpProductVariationStore"
            format={(v) => (v ? v['@id'] || v : v)}
            filter={{
              ...(storeId && { 'store.id': storeId }),
              ...(wpProductId && {
                'productVariation.product.id': getNumberFromStringId(
                  wpProductId
                ),
              }),
              // 'productVariation.interval.slug[0]': 'SGL',
              ...(intervalSlug && {'productVariation.interval.slug': intervalSlug,}),
              properties: ['id'],
              'properties[productVariation][interval]': ['name', 'slug'],
              'properties[productVariation][product]': ['name'],
              'properties[productVariation][propertyValue]': ['name'],
              'properties[productVariation][mandatoryProductVariation]': ['id'],
            }}
          >
            <AutoSelectInput
              optionText={(item) =>
                getProductVariationTitle(item?.productVariation)
              }
            />
          </ReferenceInput>
        )}

        {showStartDateInput && (
          <StartDateInput startDate={startDate} setStartDate={setStartDate} />
        )}
      </SimpleForm>
    </Dialog>
  )
}
