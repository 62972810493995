import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  Checkbox,
  Button,
  Input,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`

const Wrapper = styled.div`
  margin-top: 20px;
`

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const createData = (
  index,
  interval,
  productStore,
  currency,
  price,
  pricingModel,
  name,
  invoiceName,
  qty
) => {
  return {
    index,
    productStore,
    currency,
    price,
    pricingModel,
    name,
    invoiceName,
    interval,
    qty,
  }
}

const Row = React.memo((props) => {
  const {
    row,
    handleChange,
    index,
    currencySelectOptions,
    intervalSelectOptions,
    pricingModelSelectOptions,
    addedProductStores,
    isSelected,
    onSelectClick,
  } = props
  const classes = useRowStyles()
  const handleSelectChange = useCallback(
    (event) => {
      handleChange({
        ...event,
        target: {
          ...event.target,
          dataset: {
            id: index,
          },
        },
      })
    },
    [index, handleChange]
  )

  const productStore = useMemo(
    () => addedProductStores.find((ps) => ps.id === row.productStore),
    [addedProductStores, row]
  )

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected(row.index)}
            onChange={(e) => onSelectClick(e, row.index)}
          />
        </TableCell>
        <TableCell align="left">
          {row.interval ? (
            <TextField
              select
              disabled
              inputProps={{
                readOnly: true,
              }}
              value={row.interval}
              onChange={handleSelectChange}
              name="interval"
            >
              {intervalSelectOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ) : (
            <Input value="Non-recurring " name="interval" disabled />
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          <Input
            disabled
            value={productStore.store.name}
            name="store"
            inputProps={{
              readOnly: true,
              'aria-label': 'Product store',
              'data-id': index,
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <Input
            disabled
            value={productStore.sku}
            name="productStore"
            inputProps={{
              'aria-label': 'Product store',
              'data-id': index,
            }}
          />
        </TableCell>
        <TableCell align="left">
          <TextField
            select
            value={row.currency}
            onChange={handleSelectChange}
            name="currency"
          >
            {currencySelectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell align="left" width={60}>
          <Input
            required
            value={row.price}
            name="price"
            onChange={handleChange}
            type="number"
            inputProps={{
              'aria-label': 'Price',
              'data-id': index,
              required: true,
            }}
          />
        </TableCell>
        <TableCell align="left">
          <TextField
            select
            value={row.pricingModel}
            onChange={handleSelectChange}
            name="pricingModel"
          >
            {pricingModelSelectOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </TableCell>
        <TableCell align="left">
          <Input
            required
            value={row.name}
            name="name"
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Name', 'data-id': index }}
          />
        </TableCell>
        <TableCell align="left">
          <Input
            required
            value={row.invoiceName}
            name="invoiceName"
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Invoice name', 'data-id': index }}
          />
        </TableCell>
        <TableCell align="left" width={30}>
          <Input
            required
            value={row.qty}
            name="qty"
            type="number"
            onChange={handleChange}
            inputProps={{ 'aria-label': 'Qty', 'data-id': index, min: 1 }}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
})

const PsiConfirmationTable = React.memo(
  ({
    onBackClick,
    onSubmitClick,
    selectedProductStores = [],
    data,
    currencySelectOptions,
    intervalSelectOptions,
    pricingModelSelectOptions,
    addedProductStores,
  }) => {
    const [dataTable, setDataTable] = useState(
      data.map((item, i) => ({ ...item, index: i }))
    )
    const [selectedRows, setSelectedRows] = useState([])

    const handleChange = useCallback(
      (event) => {
        const _dataTable = [...dataTable]

        _dataTable[event.target.dataset.id][event.target.name] =
          event.target.value

        setDataTable(_dataTable)
      },
      [setDataTable, dataTable]
    )

    const rows = useMemo(
      () =>
        dataTable.map((item) =>
          createData(
            item.index,
            item.interval,
            item.productStore,
            item.currency,
            item.price,
            item.pricingModel,
            item.name,
            item.invoiceName,
            item.qty
          )
        ),
      [dataTable]
    )

    const onSelectAllClick = useCallback(() => {
      if (selectedRows.length !== rows.length) {
        return setSelectedRows(rows.map((row) => row.index))
      }

      return setSelectedRows([])
    }, [rows, selectedRows.length])

    const isSelected = useCallback((index) => selectedRows.includes(index), [
      selectedRows,
    ])

    const onSelectClick = useCallback(
      (event, index) => {
        const selectedIndex = selectedRows.indexOf(index)
        let newSelected = []

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selectedRows, index)
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selectedRows.slice(1))
        } else if (selectedIndex === selectedRows.length - 1) {
          newSelected = newSelected.concat(selectedRows.slice(0, -1))
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selectedRows.slice(0, selectedIndex),
            selectedRows.slice(selectedIndex + 1)
          )
        }

        setSelectedRows(newSelected)
      },
      [selectedRows]
    )

    useEffect(() => {
      if (!selectedProductStores.length) {
        onBackClick()
      }
    }, [selectedProductStores, onBackClick])

    const onAddClick = useCallback(() => {
      const filteredDataTable = dataTable.filter((dataTableItem) =>
        selectedRows.includes(dataTableItem.index)
      )

      onSubmitClick(filteredDataTable)
    }, [onSubmitClick, dataTable, selectedRows])

    return (
      <Wrapper>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table" size="small">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < rows.length
                    }
                    checked={
                      rows.length > 0 && selectedRows.length === rows.length
                    }
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all desserts' }}
                  />
                </TableCell>
                <TableCell align="left">Interval</TableCell>
                <TableCell>Store</TableCell>
                <TableCell>Product store SKU</TableCell>
                <TableCell align="left">Currency</TableCell>
                <TableCell align="left">Price</TableCell>
                <TableCell align="left">Pricing Model</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Invoice name</TableCell>
                <TableCell align="left">Qty</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <Row
                  key={i}
                  index={i}
                  row={row}
                  handleChange={handleChange}
                  currencySelectOptions={currencySelectOptions}
                  intervalSelectOptions={intervalSelectOptions}
                  pricingModelSelectOptions={pricingModelSelectOptions}
                  addedProductStores={addedProductStores}
                  isSelected={isSelected}
                  onSelectClick={onSelectClick}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ButtonsWrapper>
          <Button color="primary" variant="outlined" onClick={onBackClick}>
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onAddClick}
            disabled={!selectedRows.length}
          >
            Add
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    )
  }
)

export default PsiConfirmationTable
