import EventNoteIcon from '@material-ui/icons/EventNote'
import { ROLES } from 'utils/user'

import { LogEntryList } from './logEntryList'

export default {
  list: LogEntryList,
  icon: EventNoteIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
