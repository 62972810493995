import React from 'react'
import {
  TextField,
  FunctionField,
  Filter,
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import { ListGuesser } from 'theme/components'
import { exporter } from './exporter'

const ClinicFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="City" source="city" />
    <ReferenceArrayInput
      source="stores.id"
      reference="stores"
      label="Stores"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceInput
      source="status.id"
      label="Status"
      reference="clinic-statuses"
      format={(v) => (v ? v['@id'] || v : '')}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <BooleanInput label="SalesForce" source="exists[salesforceId]" />
  </Filter>
)

export const ClinicList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="edit"
    bulkActionButtons={false}
    filters={<ClinicFilter />}
    filter={{
      properties: ['city', 'name', 'stores', 'magentoIds', 'id'],
      'properties[salesforceTrack]': ['recordData'],
      'properties[status]': ['name'],
    }}
    exporter={exporter}
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <TextField source="city" />
    <FunctionField
      label="Stores"
      render={(record) => record.stores.map((store) => store.name).join(', ')}
    />
    <TextField
      label="Salesforce Track"
      source="salesforceTrack.recordData.Name"
    />
    <TextField label="Status" source="status.name" />
    <FunctionField
      label="Magento IDs"
      render={(record) => record.magentoIds?.join(', ')}
    />
  </ListGuesser>
)
