import React from 'react'
import { SelectInput, ReferenceInput, TextInput } from 'react-admin'
import { EditGuesser } from 'theme/components'

export const ClusterEdit = (props) => (
  <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
    <TextInput source="name" />
    <ReferenceInput
      source="region"
      reference="regions"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </EditGuesser>
)
