import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { EditGuesser } from 'theme/components'
import { ShowGuesser } from '@api-platform/admin'
import { colors } from 'theme/MuiTheme'
import { IconButton, Typography } from '@material-ui/core'
import CopyIcon from '@material-ui/icons/FileCopy'
import {useNotify} from 'react-admin'

export const FieldsWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 15px;
  width: 100% !important;
`

const transparentGuesserStyles = css`
  & > div,
  & > div > div,
  & > div > div > div {
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: none;
  }

  h4 {
    margin-top: 0;
  }

  .MuiFormControl-marginDense {
    width: 100% !important;
    margin-bottom: 0;
  }
`

export const TransparentShowGuesser = styled(ShowGuesser)`
  ${transparentGuesserStyles}
`

export const TransparentEditGuesser = styled(EditGuesser)`
  ${transparentGuesserStyles}
`

const totalWidth = 280
const titleWidth = 120

const ListItemWrapper = styled.li`
  display: flex;
  gap: 8px;
  height: 20px;
  justify-content: center;
  font-weight: ${({ urgent }) => (urgent ? 'bold' : 'initial')};
  overflow: visible;
  position: relative;
`

const halfStyles = css`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  word-break: keep-all;
  white-space: nowrap;
`

const ListItemTitle = styled.span`
  text-align: left;
  margin-left: auto;
  color: #4d5966;
  text-transform: capitalize;
  color: ${({ urgent }) => (urgent ? colors.red[700] : 'initial')};
  max-width: ${titleWidth}px;
  ${halfStyles}
`
const ListItemValue = styled.span`
  text-align: left;
  margin-right: auto;
  color: #21262c;
  font-weight: ${({ highlightValue }) => (highlightValue ? 'bold' : 'initial')};
  max-width: ${totalWidth - titleWidth}px;
  ${halfStyles}
`

export const ListItem = ({ title, value, key, urgent, highlightValue }) => {
  const [hover, setHover] = useState(false)
  const notify = useNotify();

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    notify('Value copied to clipboard');
  }

  if (value === undefined || value === null) return null
  return (
    <ListItemWrapper
      urgent={urgent}
      key={key}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ListItemTitle urgent={urgent}>
        <Typography>{title}</Typography>
      </ListItemTitle>
      <ListItemValue highlightValue={highlightValue}>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <IconButton
          size="small"
          onClick={() => copyToClipboard(value)}
          style={{ display: hover ? undefined : 'none' }}
        >
          <CopyIcon style={{height: '15px'}} />
        </IconButton>
        <Typography onClick={() => copyToClipboard(value)}>{value}</Typography>
        </div>
      </ListItemValue>
    </ListItemWrapper>
  )
}
