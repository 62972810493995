import Icon from '@material-ui/icons/Store'
import { ProductStoreCreate } from './ProductStoreCreate'
import { ProductStoreList } from './ProductStoreList'
import { ProductStoreEditPage } from './ProductStoreEdit'
import { ROLES } from 'utils/user'

export default {
  create: ProductStoreCreate,
  list: ProductStoreList,
  edit: ProductStoreEditPage,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN],
}
