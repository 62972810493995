import React, { useEffect } from 'react'
import { TextField, ReferenceManyField, FunctionField } from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { formatPrice } from 'utils/string'
import { useCustomer } from 'components/pages/customer/contexts/customer'

export const ShortChargebeeTransactionsList = ({
  ListStats,
  setTotal,
  ...props
}) => {
  const { customerId } = useCustomer()

  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  return (
    <ReferenceManyField
      addLabel={false}
      reference="chargebee-payments"
      source="id"
      target=""
      filter={{
        'customer.id': customerId,
        properties: ['createdAt', 'amount', 'currencyCode', 'type', 'status'],
      }}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={() => {}}>
        {ListStats}
        <TextField source="status" />
        <FunctionField
          label="Amount"
          source="amount"
          render={(record) => {
            const { amount, currencyCode } = record

            return formatPrice(amount / 100, currencyCode)
          }}
        />
        <DateField source="createdAt" label="Created at" />
      </DataGrid>
    </ReferenceManyField>
  )
}
