import React, { cloneElement } from 'react'
import {
  TextField,
  Filter,
  TextInput,
  CreateButton,
  TopToolbar,
  useListContext,
  Button,
  BooleanField,
  BooleanInput,
} from 'react-admin'
import RefreshIcon from '@material-ui/icons/Refresh'
import { ListGuesser } from 'theme/components'
import useApi from 'hooks/useApi'

const WPProductFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
    <BooleanInput source="internal" />
  </Filter>
)

const ListActions = (props) => {
  const {
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    basePath,
  } = useListContext()
  const { api, isSubmitting } = useApi()

  return (
    <TopToolbar {...props} style={{ display: 'flex', gap: '12px' }}>
      {props.filters &&
        cloneElement(props.filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <Button
        disabled={isSubmitting}
        onClick={() => api.wooSyncWPProducts()}
        label="sync WP"
      >
        <RefreshIcon />
      </Button>
    </TopToolbar>
  )
}

export const WPProductList = (props) => (
  <ListGuesser
    {...props}
    hasEdit={false}
    rowClick="edit"
    bulkActionButtons={false}
    filters={<WPProductFilter />}
    filter={{
      properties: ['name', 'id', 'bundleOnly'],
    }}
    actions={<ListActions />}
  >
    <TextField
      source={'originId'}
      label={'id'}
      sortBy={'id'}
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <BooleanField source="bundleOnly" />
  </ListGuesser>
)
