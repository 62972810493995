import React from 'react'
import { useSelector } from 'react-redux'
import {
  required,
  TextInput,
  ReferenceManyField,
  List,
  TextField,
  Tab,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin'
import {
  EditGuesser,
  DataGrid,
  ShowGuesser,
  ResourceEditToolbar,
} from 'theme/components'
import { EditWPProductVariationStoresCard } from './card/EditWPProductVariationStoresCard'
import { CreateWPProductVariationCard } from './card/CreateWPProductVariationCard'
import { EditWPProductVariationCard } from './card/EditWPProductVariationCard'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Title = styled(Typography)`
  margin: 50px 0 20px 0;
`

export const WPProductEdit = (props) => {
  const wpProduct = useSelector(
    (state) => state.admin.resources['wp-products']?.data[props.id]
  )

  return (
    <ShowGuesser {...props} hasEdit={false}>
      <OverflowingTabbedShowLayout>
        <Tab label="Edit WP product">
          <EditGuesser
            {...props}
            variant="outlined"
            redirect="list"
            toolbar={
              <ResourceEditToolbar showBack={false} showDelete={false} />
            }
          >
            <TextInput
              source="name"
              label="Name of WP product"
              validate={[required()]}
            />
            <FormDataConsumer>
              {({ formData }) =>
                formData.property ? (
                  <ReferenceInput
                    variant="outlined"
                    source="property"
                    reference="variation-properties"
                    format={(v) => (v ? v['@id'] || v : v)}
                    sort={{ field: 'name', order: 'ASC' }}
                    disabled
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                ) : null
              }
            </FormDataConsumer>
            <BooleanInput source="bundleOnly" />
            <BooleanInput source="internal" />
          </EditGuesser>
          <Title variant="h5">WP Product variations</Title>
          <ReferenceManyField
            addLabel={false}
            target=""
            reference="wp-product-variations"
            filter={{
              'product.id': props.id,
              properties: ['id', 'name'],
              'properties[interval]': ['id', 'name'],
              'properties[product]': ['name'],
              'properties[propertyValue]': ['name'],
            }}
          >
            <List
              {...props}
              exporter={false}
              bulkActionButtons={false}
              actions={false}
            >
              <DataGrid
                rowClick={() => {}}
                expand={<EditWPProductVariationCard wpProduct={wpProduct} />}
              >
                <TextField label="Interval" source="interval.name" />
                <TextField label="Property value" source="propertyValue.name" />
              </DataGrid>
            </List>
          </ReferenceManyField>
          <CreateWPProductVariationCard {...props} />
        </Tab>
        <Tab label="Stores">
          <ReferenceManyField
            target=""
            addLabel={false}
            reference="stores"
            filter={{ properties: ['id', 'name'] }}
          >
            <List
              exporter={false}
              {...props}
              bulkActionButtons={false}
              actions={false}
            >
              <DataGrid
                expand={
                  <EditWPProductVariationStoresCard wpProduct={wpProduct} />
                }
              >
                <TextField label="Store name" source="name" />
              </DataGrid>
            </List>
          </ReferenceManyField>
        </Tab>
      </OverflowingTabbedShowLayout>
    </ShowGuesser>
  )
}
