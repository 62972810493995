import React, { useEffect } from 'react'
import { TextField, ReferenceManyField, useNotify } from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { useCustomer } from 'components/pages/customer/contexts/customer'

export const ShortCustomerOrdersList = ({ ListStats, setTotal, ...props }) => {
  const customer = useCustomer()
  const notify = useNotify()

  const handleRowClick = (id, path, record) => {
    navigator.clipboard.writeText(record.displayName);
    notify("Order number copied");
  }

  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  if (!customer?.id) return null

  return (
    <ReferenceManyField
      addLabel={false}
      reference="orders"
      source="id"
      filter={{
        'customer.id': customer.id,
        properties: ['id', 'createdAt', 'displayName'],
        'properties[status]': ['id', 'display'],
      }}
      target=""
      {...props}
      hasEdit={false}
      hasShow={false}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={handleRowClick}>
        {ListStats}
        <TextField source="displayName" label="Order id" />

        <TextField
          source="status.display"
          label="resources.orderStatus.field"
        />
        <DateField source="createdAt" />
      </DataGrid>
    </ReferenceManyField>
  )
}
