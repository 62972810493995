import React from 'react'
import { TextInput, maxLength } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const GenderCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <TextInput source="name" validate={maxLength(99)} />
    <TextInput source="slug" validate={maxLength(99)} />
  </CreateGuesser>
)
