import React, { createContext, useContext } from 'react'

const subscriptionsContext = createContext()

const SubscriptionsProvider = ({
  children,
  subscriptions,
  refetchSubscriptions,
}) => {
  return (
    <subscriptionsContext.Provider
      value={{ subscriptions, refetchSubscriptions }}
    >
      {children}
    </subscriptionsContext.Provider>
  )
}

const useSubscriptions = () => useContext(subscriptionsContext)

export { useSubscriptions, SubscriptionsProvider }
