import React from 'react'
import { TextField, BooleanField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const GenderStoreList = (props) => (
  <ListGuesser
    {...props}
    filter={{
      properties: ['id', 'name', 'enabledInPortal'],
      'properties[store]': ['name'],
      'properties[gender]': ['name'],
    }}
    rowClick="edit"
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="gender.name" label="Gender" />
    <TextField source="name" label="Translated gender" />
    <TextField source="store.name" label="Store" />
    <BooleanField source="enabledInPortal" />
  </ListGuesser>
)
