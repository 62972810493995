import React from 'react'
import { InputGuesser } from '@api-platform/admin'
import {
  Toolbar,
  SelectInput,
  ReferenceInput,
  TextInput,
  maxLength,
  BooleanInput,
} from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { EditGuesser, ResourceEditToolbar } from 'theme/components'
import { Section, SectionsWrapper } from '../StoreCreate'

export const PaymentsTab = ({ defaultToolbar, ...props }) => (
  <EditGuesser
    {...props}
    undoable={false}
    variant="outlined"
    redirect={false}
    toolbar={
      defaultToolbar ? <Toolbar /> : <ResourceEditToolbar showDelete={false} />
    }
  >
    <ReferenceInput
      source="paymentFlow"
      reference="payment-flows"
      format={(v) => (v ? v['@id'] || v : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SectionsWrapper>
      <Section>
        <Typography variant="h6">WooCommerce</Typography>
        <InputGuesser
          source="wcUrl"
          label="resources.store.wcUrl"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="wcKey"
          label="resources.store.wcKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="wcSecret"
          label="resources.store.wcSecret"
          variant="outlined"
          {...props}
        />
      </Section>

      <Section>
        <Typography variant="h6">ChargeBee</Typography>
        <InputGuesser
          source="chargeBeeKey"
          label="resources.store.chargeBeeKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeWebsite"
          label="resources.store.chargeBeeWebsite"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeePublishableKey"
          label="resources.store.chargeBeePublishableKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeGatewayIdAdyen"
          label="resources.store.chargeBeeGatewayIdAdyen"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeSyncedAt"
          variant="outlined"
          disabled
          {...props}
        />
        <InputGuesser
          source="chargeBeeTestKey"
          label="resources.store.chargeBeeTestKey"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeTestWebsite"
          label="resources.store.chargeBeeTestWebsite"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="chargeBeeTestSyncedAt"
          variant="outlined"
          disabled
          {...props}
        />
      </Section>

      <Section>
        <Typography variant="h6">Adyen</Typography>
        <InputGuesser
          source="adyenMerchantAccount"
          variant="outlined"
          {...props}
        />
        <InputGuesser source="adyenApiKey" variant="outlined" {...props} />
        <InputGuesser source="adyenClientKey" variant="outlined" {...props} />
        <InputGuesser source="adyenEnv" variant="outlined" {...props} />
        <InputGuesser
          source="adyenLiveUrlPrefix"
          variant="outlined"
          {...props}
        />
        <InputGuesser
          source="adyenWebhookHmacKey"
          variant="outlined"
          {...props}
        />
      </Section>
      <Section style={{ display: 'flex', gap: '12px' }}>
        <Typography variant="h6">Paypal</Typography>
        <Section>
          <Typography variant="h6">Chargebee</Typography>
          <TextInput
            validate={maxLength(255)}
            source="paypalUser"
            variant="outlined"
            {...props}
          />
          <TextInput
            validate={maxLength(255)}
            source="paypalPassword"
            variant="outlined"
            {...props}
          />
          <TextInput
            validate={maxLength(255)}
            source="paypalSignature"
            variant="outlined"
            {...props}
          />
        </Section>
        <Section>
          <Typography variant="h6">Adyen</Typography>
          <BooleanInput source="paypalEnabled" variant="outlined" {...props} />
          <TextInput
            validate={maxLength(255)}
            source="paypalOAuthClient"
            variant="outlined"
            {...props}
          />
          <TextInput
            validate={maxLength(255)}
            source="paypalOAuthSecret"
            variant="outlined"
            {...props}
          />
        </Section>
      </Section>
    </SectionsWrapper>
  </EditGuesser>
)
