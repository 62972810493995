import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'
import { formatDateTime } from 'utils/string'

export const exporter = (products) => {
  const rows = products.map((product) => ({
    id: product.originId,
    Name: product.name,
    Sku: product.sku,
    Pcs: product.pcs,
    Weight: product.weight,
    'Hs code': product.hsCode,
    'Created at': formatDateTime(product.createdAt),
    'Updated At': formatDateTime(product.updatedAt),
  }))

  jsonExport(
    rows,
    {
      headers: [
        'id',
        'Name',
        'Sku',
        'Pcs',
        'Weight',
        'Hs code',
        'Created at',
        'Updated At',
      ],
      rowDelimiter: ';',
    },
    (err, csv) => {
      downloadCSV(csv, `products`)
    }
  )
}
