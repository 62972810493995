import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import { RolesField } from './common/RolesField'
import { TextField, Filter, TextInput, DateInput } from 'react-admin'
import { DateField, ListGuesser } from 'theme/components'
import { Chip } from '@material-ui/core'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <TextInput label="First Name" source="firstName" />
    <TextInput label="Last Name" source="lastName" />
    <DateInput
      label="Created (from date)"
      defaultValue={new Date()}
      source="createdAt<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Created (to date)"
      defaultValue={new Date()}
      source="createdAt<before>"
      locales="nl-Nl"
    />
    <Chip label="Customers" source="hasRole" defaultValue={'ROLE_CUSTOMER'} />
    <Chip
      label="Admins"
      source="hasAnyRoleExcept"
      defaultValue={'ROLE_CUSTOMER'}
    />
  </Filter>
)

export const UserList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    filter={{
      properties: [
        'id',
        'email',
        'fullName',
        'roles',
        'createdAt',
        'lastSuccessLoginAt',
      ],
    }}
    rowClick="edit"
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <FieldGuesser source="email" />
    <FieldGuesser source="fullName" />
    <RolesField source="roles" />
    <DateField source="lastSuccessLoginAt" label="Last login" />
    <DateField source="createdAt" />
  </ListGuesser>
)
