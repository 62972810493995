import React, { useState } from 'react'
import { Dialog, DialogTitle, Button, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import {
  useNotify,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  TextInput,
  BooleanInput,
} from 'react-admin'
import useApi from 'hooks/useApi'
import { getFormattedError } from 'utils/hydra'
import { FileInput } from 'theme/components'
import { getNumberFromStringId } from 'utils/string'
import { getApiRoute } from 'service/Api/routes'

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`

export const LogCallModal = ({
  customerId,
  appointmentId,
  initialReason,
  onSuccess,
  couldNotReach,
  removeFromList,
}) => {
  const notify = useNotify()
  const { api } = useApi()
  const [open, setOpen] = useState(false)

  const handleSubmit = async (values) => {
    const body = {
      description: values.description,
      reason_id: getNumberFromStringId(values.callReason),
      appointmentId,
      removeFromList,
      priority: 3, // not used anywhere at the moment, was implemented by backend for no reason (but required)
      status: values.couldNotReach ? 1 : 2, // 1: missed, 2: open
      type: values.needFollowUp ? 1 : 0, // 1: followup, 2: no followup
    }

    try {
      const result = await api.logCustomerCall(customerId, body)

      if (result) {
        if (values.file) {
          await api.post(getApiRoute('customer-call-files'), {
            file: values.file,
            customerCall: `/api/customer-calls/${result.data.id}`,
          })
        }
      }

      notify('call logged', 'info')
      if (onSuccess) onSuccess()
      setOpen(false)
    } catch (e) {
      notify(getFormattedError(e?.response?.data, false))
    }
  }

  if (!customerId) return null

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        size="small"
        onClick={() => setOpen(true)}
        color="primary"
        variant="contained"
        fullWidth
        style={{ whiteSpace: 'nowrap' }}
      >
        {couldNotReach ? 'log missed call' : 'log call'}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Header>
          <DialogTitle>
            {couldNotReach ? 'log missed call' : 'log call'}
          </DialogTitle>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Header>

        <SimpleForm
          variant="outlined"
          save={handleSubmit}
          margin="none"
          initialValues={{ callReason: initialReason, couldNotReach }}
        >
          <ReferenceInput
            reference="call-reasons"
            source="callReason"
            format={(v) => (v ? v['@id'] || v : v)}
            filter={{
              properties: ['name', 'slug', 'id'],
            }}
            validate={[required()]}
            fullWidth
          >
            <SelectInput
              fullWidth
              options={{
                suggestionsContainerProps: { style: { zIndex: 99999 } },
              }}
            />
          </ReferenceInput>

          <TextInput
            fullWidth
            multiline
            rows={4}
            validate={[required()]}
            source="description"
          />
          <BooleanInput source="needFollowUp" />
          <BooleanInput source="couldNotReach" />

          <FileInput
            maxFileSize={990000}
            source="file"
            label="File"
            acceptedFiles={[
              '.pdf',
              '.png',
              '.jpg',
              '.jpeg',
              '.txt',
              '.doc',
              '.docx',
            ]}
          />
        </SimpleForm>
      </Dialog>
    </div>
  )
}
