import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { Button, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import styled from 'styled-components'
import { usePimcoreLists } from 'contexts/pimcoreLists'
import { StartDateInput } from 'components/startDateInput'

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  background: white;
  width: 300px;
`

export const AddPimcorePropositionModal = ({
  open,
  onClose,
  addItem,
  intervalSlug,
  showStartDateInput,
}) => {
  const [proposition, setProposition] = useState()
  const [listing, setListing] = useState()
  const { propositions } = usePimcoreLists()
  const [startDate, setStartDate] = useState()

  const filteredListings =
    proposition?.listing?.filter((item) =>
      intervalSlug ? item.interval === intervalSlug : true
      && item.active
    )?.sort((a,b) => `${a?.name} - ${a?.interval}`.localeCompare(`${b?.name} - ${b?.interval}`)) || []

  const filteredPropositions =
    propositions?.filter(p =>
      p.active && p.listing?.find(l => 
        intervalSlug ? l.interval === intervalSlug : true && l.active
      )
    )?.sort((a,b) => a.name?.localeCompare(b.name)) || []


  useEffect(() => {
    if (!open) {
      setProposition(undefined)
      setListing(undefined)
      setStartDate()
    }
  }, [open])

  return (
    <Dialog open={!!open} onClose={onClose}>
      <FormWrapper>
        <Autocomplete
          options={filteredPropositions}
          getOptionLabel={(option) => option?.name}
          value={proposition}
          onChange={(e, newProposition) => {
            setProposition(newProposition)
            setListing()
          }}
          renderInput={(params) => (
            <TextField {...params} label="Proposition" variant="outlined" />
          )}
        />

        <Autocomplete
          options={filteredListings}
          getOptionLabel={(option) => `${option?.name} - ${option?.interval}`}
          value={listing || null}
          onChange={(e, newListing) => setListing(newListing)}
          disabled={!filteredListings?.length}
          renderInput={(params) => (
            <TextField {...params} label="Listing" variant="outlined" />
          )}
        />

        {showStartDateInput && (
          <StartDateInput startDate={startDate} setStartDate={setStartDate} />
        )}

        <Button
          onClick={() => {
            const item = {
              product: {
                id: listing.id,
                interval: listing.interval,
              },
              quantity: 1,
            }
            if (showStartDateInput) {
              item.startDate = startDate
            }

            addItem(item)
            onClose()
          }}
          color="primary"
          variant="contained"
          disabled={!listing}
        >
          Add proposition
        </Button>
      </FormWrapper>
    </Dialog>
  )
}
