import React from 'react'
import { ShowGuesser, ReferenceListFilterField } from 'theme/components'
import { TextField, FunctionField, Button, useNotify } from 'react-admin'
import useApi from 'hooks/useApi'

export const PimcoreProductShow = (props) => {
  const { api, isSubmitting, handleError } = useApi()
  const notify = useNotify()

  const handleSync = async (pimcoreId) => {
    try {
      await api.syncPimcorePropositionsChargeeBee(pimcoreId)
      notify('You have synced successfully')
    } catch (error) {
      handleError(error)
    }
  }

  return (
    <ShowGuesser {...props} hasEdit={false}>
      <TextField source="originId" />
      <TextField source="name" />
      <TextField source="chargeBeeId" label="Chargebee id" />
      <TextField source="pimcoreId" label="Pimcore id" />
      <ReferenceListFilterField
        label="resources.store.field"
        listUri="/stores"
        titlePath="store.name"
        valuePath="store.name"
      />

      <TextField source="interval" />
      <FunctionField
        label="Proposition Products"
        source="products"
        render={(record) => {
          const { products } = record
          return products
            ?.map((propositionProduct) => propositionProduct.product.name)
            ?.join(', ')
        }}
      />
      <FunctionField
        addLabel={false}
        render={(record) => {
          if (!record) {
            return null
          }

          return (
            <Button
              label="Sync Chargebee"
              color="primary"
              variant="contained"
              disabled={isSubmitting}
              onClick={() => handleSync(record.originId)}
            />
          )
        }}
      />
    </ShowGuesser>
  )
}
