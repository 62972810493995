import React from 'react'
import {
  FunctionField,
  TextField,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'
import { formatPrice, formatText } from 'utils/string'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { getApiRoute } from 'service/Api/routes'

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Invoice chargeBee ID" source="chargeBeeId" />
    <TextInput label="Email" source="customer.email" />
    <ReferenceInput
      source="customer.store.id"
      reference="stores"
      label="resources.store.field"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <SelectInput
      label="Status"
      source="chargeBeeStatus"
      choices={[
        { id: 'paid', name: 'Paid' },
        { id: 'posted', name: 'Posted' },
        { id: 'payment_due', name: 'Payment Due' },
        { id: 'not_paid', name: 'Not Paid' },
        { id: 'voided', name: 'Voided' },
        { id: 'pending', name: 'Pending' },
      ]}
    />
  </Filter>
)

export const InvoiceList = (props) => {
  return (
    <ListGuesser
      filters={<ListFilter />}
      {...props}
      hasEdit={false}
      rowClick="show"
      bulkActionButtons={false}
      filter={{
        properties: ['data', 'id', 'chargeBeeId', 'paidAt', 'createdAt'],
        'properties[data]': [
          'paid_at',
          'status',
          'customer_id',
          'currency_code',
          'issued_credit_notes',
        ],
        'properties[customer]': ['chargeBeeId', 'email'],
        'properties[customer][store]': ['id', 'name'],
      }}
    >
      <TextField source="chargeBeeId" label="Chargebee Invoice id" />
      <FunctionField
        label="Amount"
        source="amount"
        render={(record) => {
          const {
            data: { total, currency_code },
          } = record

          return formatPrice(total / 100, currency_code)
        }}
      />
      <FunctionField
        label="Email"
        render={(record) => {
          if (!record?.customer) return null
          const modifiedRecord = {
            ...record,
            identifier: record.customer.email || record.customer.chargeBeeId,
            chargeBeeId: record.customer.chargeBeeId,
          }

          return (
            <ReferenceListFilterField
              record={modifiedRecord}
              listUri="/customers"
              titlePath="identifier"
              valuePath="chargeBeeId"
              label="Email"
            />
          )
        }}
      />
      <FunctionField
        label="Store"
        render={(record) => {
          if (!record?.customer?.store) return null
          const modifiedRecord = {
            ...record,
            id: record.customer.store.id,
            name: record.customer.store.name,
          }
          return (
            <ReferenceListFilterField
              record={modifiedRecord}
              listUri="/stores"
              titlePath="name"
              valuePath="id"
              label="Store"
            />
          )
        }}
      />
      <FunctionField
        label="Paid on"
        source="paidAt"
        render={(record) => {
          const modifiedRecord = {
            ...record,
            paidAt: record.data.paid_at * 1000,
          }

          return modifiedRecord?.paidAt ? (
            <DateField record={modifiedRecord} source="paidAt" short={false} />
          ) : (
            ''
          )
        }}
      />
      <TextField source="data.status" label="Status" />
      <FunctionField
        label="Credit note status"
        render={(record) => {
          const creditNotes = record.data.issued_credit_notes
          const newRecord = {
            ...record,
            creditNotes:
              creditNotes?.length > 0
                ? formatText(creditNotes[creditNotes.length - 1]?.cn_status)
                : null,
          }

          return <TextField record={newRecord} source="creditNotes" />
        }}
      />
      <FunctionField
        label="Created At"
        source="createdAt"
        render={(record) => {
          const modifiedRecord = {
            ...record,
            date: record.data.date * 1000,
          }

          return modifiedRecord?.date ? (
            <DateField record={modifiedRecord} source="date" short={false} />
          ) : (
            ''
          )
        }}
      />
      <FunctionField
        label="Download"
        render={(record) => {
          return (
            <button
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                window.open(
                  getApiRoute(
                    `customer-support/invoice/${record.originId}/pdf`
                  ),
                  '_blank'
                )
              }}
            >
              <DownloadIcon />
            </button>
          )
        }}
      />
    </ListGuesser>
  )
}
