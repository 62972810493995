import React from 'react'
import { required, maxLength, TextInput, NumberInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const MontaStockCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <TextInput source="sku" label="Product SKU" validate={required()} />
    <TextInput
      source="barcodePrimary"
      label="Primary barcode"
      validate={maxLength(255)}
    />
    <TextInput
      source="barcodeSecondary"
      label="Secondary barcode"
      validate={maxLength(255)}
    />
    <TextInput
      source="description"
      label="Product Description"
      validate={maxLength(255)}
    />
    <NumberInput source="weight" />
    <NumberInput source="length" />
    <NumberInput source="width" />
    <NumberInput source="height" />
    <TextInput source="customField" validate={maxLength(255)} />
    <TextInput source="supplier" validate={maxLength(255)} />
    <TextInput
      source="supplierCode"
      label="Supplier product code"
      validate={maxLength(255)}
    />
    <NumberInput source="purchasePrice" />
    <NumberInput source="sellingPrice" />
    <NumberInput source="weightInGrams" />
    <TextInput
      source="hsCode"
      label="HS tariff code"
      validate={maxLength(255)}
    />
    <TextInput
      source="country"
      label="Country or origin"
      validate={maxLength(255)}
    />
  </CreateGuesser>
)
