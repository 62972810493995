import React from 'react'
import {
  SelectInput,
  ReferenceInput,
  required,
  AutocompleteInput,
} from 'react-admin'
import { InputGuesser } from '@api-platform/admin'
import { CreateGuesser } from 'theme/components'

export const GroupCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <ReferenceInput
      source="store"
      reference="stores"
      label="Store"
      filter={{ active: 1 }}
    >
      <SelectInput optionText="name" validate={required()} />
    </ReferenceInput>
    <ReferenceInput
      source="product"
      reference="products"
      label="Product"
      filter={{ active: 1 }}
    >
      <AutocompleteInput optionText="name" resettable />
    </ReferenceInput>
    <InputGuesser source={'name'} validate={required()} />
  </CreateGuesser>
)
