import React from 'react'
import {
  required,
  TextInput,
  ReferenceManyField,
  DeleteWithConfirmButton,
  List,
  TextField,
  FunctionField,
  useRefresh,
} from 'react-admin'

import {
  EditGuesser,
  DataGrid,
  ShowGuesser,
  ResourceEditToolbar,
} from 'theme/components'
import { CreateVariationPropertyValueCard } from './card/CreateVariationPropertyValueCard'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Title = styled(Typography)`
  margin: 50px 0 20px 0;
`

export const VariationEdit = (props) => {
  const refresh = useRefresh()

  return (
    <ShowGuesser {...props} hasEdit={false}>
      <EditGuesser
        {...props}
        variant="outlined"
        redirect="list"
        toolbar={<ResourceEditToolbar showBack={false} showDelete={false} />}
      >
        <TextInput
          source="name"
          label="Name of property"
          validate={[required()]}
        />
        <TextInput source="nameWc" label="Woocommerce Name" />
      </EditGuesser>
      <Title variant="h5">Variation property values</Title>
      <ReferenceManyField
        addLabel={false}
        target=""
        reference="variation-property-values"
        filter={{
          'property.id': props.id,
          properties: ['id', 'name', 'nameWc', 'visibleToCustomer'],
        }}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <List
          exporter={false}
          {...props}
          bulkActionButtons={false}
          actions={false}
        >
          <DataGrid rowClick="edit">
            <TextField label="Property value" source="name" />
            <TextField label="Woocommerce Name" source="nameWc" />
            <FunctionField
              label="Visible to customer"
              render={(record) => {
                return record?.visibleToCustomer ? 'Yes' : 'No'
              }}
            />
            <DeleteWithConfirmButton
              redirect={false}
              confirmTitle="Are you sure you want to delete this property value?"
              confirmContent=""
              onSuccess={refresh}
            />
          </DataGrid>
        </List>
      </ReferenceManyField>
      <CreateVariationPropertyValueCard {...props} />
    </ShowGuesser>
  )
}
