import React from 'react'
import {
  TextInput,
  email,
  ArrayInput,
  SimpleFormIterator,
  Tab,
} from 'react-admin'
import { ShowGuesser } from '@api-platform/admin'
import { ResourceEditToolbar } from 'theme/components/toolbar/ResourceEditToolbar'
import { EditGuesser } from 'theme/components'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

export const NotificationEdit = (props) => (
  <ShowGuesser {...props}>
    <OverflowingTabbedShowLayout>
      <Tab label="resources.notification.editReceivers">
        <EditGuesser
          {...props}
          redirect="list"
          toolbar={<ResourceEditToolbar showDelete={false} />}
        >
          <ArrayInput source="receivers" label="">
            <SimpleFormIterator>
              <TextInput validate={email()} label="" />
            </SimpleFormIterator>
          </ArrayInput>
        </EditGuesser>
      </Tab>
      <Tab label="resources.notification.editDescription">
        <EditGuesser
          {...props}
          redirect="list"
          toolbar={<ResourceEditToolbar showDelete={false} />}
        >
          <TextInput source="description" label="" margin="normal" />
        </EditGuesser>
      </Tab>
    </OverflowingTabbedShowLayout>
  </ShowGuesser>
)
