import React from 'react'
import { TextInput, required } from 'react-admin'
import { EditGuesser } from 'theme/components'

export const PostponeReasonEdit = (props) => {
  return (
    <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
      <TextInput source="name" validate={required()} />
      <TextInput source="slug" />
    </EditGuesser>
  )
}
