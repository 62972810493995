import React from 'react'
import styled from 'styled-components'
import { snakeCaseToWords } from 'utils/string'
import { ListItem } from '../commonComponents'
import { useRecordContext } from 'react-admin'

const personalFields = [
  'id',
  'first_name',
  'last_name',
  'phone',
  'email',
  'cf_surgery_date',
  'cf_surgery_type_v2',
  'cf_clinic_v2',
  'cf_date_of_birth',
  'cf_surgeon_name',
]
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const List = styled.ul`
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
`

export const ShortCustomerEdit = () => {
  const record = useRecordContext()

  if (!record) return '...'

  return (
    <Wrapper>
      {!!record.data && (
        <List>
          {personalFields.map((key) => (
            <ListItem
              key={key}
              title={snakeCaseToWords(key.replace(/(^cf_|v2$)/g, ''))}
              value={record.data[key]}
            />
          ))}
        </List>
      )}
    </Wrapper>
  )
}
