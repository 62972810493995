import React from 'react'
import styled from 'styled-components'
import { useCurrentSession } from '../contexts/currentSession'
import { MidlayerSubscriptions } from './midlayerSubscriptions'
import { PimcoreSubscriptions } from './pimcoreSubscriptions'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 10px;
  width: 500px;
`

export const Subscriptions = () => {
  const { productFlow } = useCurrentSession()

  return (
    <Wrapper>
      {productFlow === 'v1' ? (
        <MidlayerSubscriptions />
      ) : (
        <PimcoreSubscriptions />
      )}
    </Wrapper>
  )
}
