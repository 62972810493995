import React, { useState, useEffect, useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core'
import { useNotify } from 'react-admin'
import styled from 'styled-components'

import { getFormattedError } from 'utils/hydra'
import { formatDateTime } from 'utils/string'
import useApi from 'hooks/useApi'

const Title = styled(DialogTitle)`
  h2 {
    font-size: 14px;
  }
`

const CopyHolder = styled.div`
  display: flex;
`

const Input = styled.input`
  padding: 10px;
  font-size: 14px;
  background-color: #d9d9d9;
  border: 1px solid #d9d9d9;
  border-radius: 0;
  flex: 1;
`

const CopyButton = styled(Button)`
  background-color: #b7b7b7;
  border: 1px solid #b7b7b7;
  color: #000000;
  border-radius: 0;
  padding: 0 45px;
  width: 70px;
  text-transform: capitalize;
`

const DateText = styled.div`
  font-size: 14px;
  margin-bottom: 15px;
`

const PaymentModal = ({ open, record, invoiceId, paymentLink, onClose }) => {
  const { api } = useApi()
  const notify = useNotify()
  const [dataPaymentLink, setDataPaymentLink] = useState('')

  const generatePaymentUrl = useCallback(async () => {
    try {
      const link = (await api.generateInvoiceLink(invoiceId)).data
      setDataPaymentLink(link)
    } catch (e) {
      notify(getFormattedError(e?.response?.data, false))
    }
  }, [api, invoiceId, notify])

  const handleSubmit = useCallback(async () => {
    try {
      await api.sendPaymentLink(invoiceId)
    } catch (e) {
      notify(getFormattedError(e?.response?.data, false))
    } finally {
      onClose()
    }
  }, [api, invoiceId, notify, onClose])

  const copyTextToClipboard = (e, value) => {
    e.preventDefault()
    if (window.navigator.clipboard) {
      window.navigator.clipboard.writeText(value).then(
        () => notify('link was copied'),
        () => notify('something went wrong')
      )
    }
  }

  useEffect(() => {
    let mount = true

    if (mount) {
      generatePaymentUrl()
    }

    return () => {
      mount = false
    }
  }, [generatePaymentUrl])

  return (
    <Dialog fullWidth maxWidth="xs" open={!!open} onClose={onClose}>
      <Title>
        Are you sure you want to send a payment link to <b>{record.email}</b>
      </Title>
      <DialogContent>
        <DateText>
          Link created on{' '}
          {formatDateTime(dataPaymentLink?.paymentLinkGeneratedAt)}
        </DateText>
        <CopyHolder>
          <Input
            readonly
            disabled
            type="text"
            value={dataPaymentLink?.paymentLink}
          />
          <CopyButton
            onClick={(e) =>
              copyTextToClipboard(e, dataPaymentLink?.paymentLink)
            }
          >
            Copy
          </CopyButton>
        </CopyHolder>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleSubmit()} color="primary">
          {paymentLink ? 'Resend link' : 'Send link'}
        </Button>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PaymentModal
