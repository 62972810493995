import React, { useState, useCallback, useEffect, useMemo } from 'react'
import {
  useDataProvider,
  TextInput,
  SelectInput,
  Toolbar,
  useNotify,
  useRefresh,
} from 'react-admin'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import { Button } from '@material-ui/core'
import { useForm } from 'react-final-form'
import { formatDateTime } from 'utils/string'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { FieldsWrapper, TransparentEditGuesser } from '../commonComponents'
import { useSubscriptions } from 'components/pages/customer/contexts/subscriptions'

const Title = styled.h4`
  font-size: 16px;
`
const SelectSubscriptionInput = styled(SelectInput)`
  .MuiFormHelperText-root {
    display: none;
  }
`

const CustomToolbar = (props) => {
  const { api } = useApi()
  const form = useForm()
  const notify = useNotify()
  const refresh = useRefresh()
  const { refetchSubscriptions } = useSubscriptions()

  const handleClick = async (e) => {
    e.preventDefault() // necessary to prevent default SaveButton submit logic

    const { values } = form.getState()
    const formattedValue = {
      email: values?.data?.email,
      first_name: values?.data?.first_name,
      last_name: values?.data?.last_name,

      phone: values?.phone,
      cf_date_of_birth: values?.data?.cf_date_of_birth,
      cf_gender_v2: values?.data?.cf_gender_v2,

      cf_surgeon_name: values?.data?.cf_surgeon_name,
      cf_surgery_type_v2: values?.data?.cf_surgery_type_v2,
      cf_surgery_date: values?.data?.cf_surgery_date,

      cf_clinic_v2: values?.data?.cf_clinic_v2,
      cf_trial_status: values?.data?.cf_trial_status,

      billing_info: {
        'billing_address[first_name]':
          values?.data?.billing_address?.first_name,
        'billing_address[last_name]': values?.data?.billing_address?.last_name,
        'billing_address[phone]': values?.data?.billing_address?.phone,
        'billing_address[line1]': values?.data?.billing_address?.line1,
        'billing_address[line2]': values?.data?.billing_address?.line2,
        'billing_address[line3]': values?.data?.billing_address?.line3,
        'billing_address[zip]': values?.data?.billing_address?.zip,
        'billing_address[city]': values?.data?.billing_address?.city,
        'billing_address[state]': values?.data?.billing_address?.state,
        'billing_address[country]': values?.data?.billing_address?.country,
      },
    }

    const formattedShippingAddressData = {
      shippingAddress: {
        last_name: values?.shipping_address?.last_name,
        first_name: values?.shipping_address?.first_name,
        phone: values?.shipping_address?.phone,
        line1: values?.shipping_address?.line1,
        line2: values?.shipping_address?.line2,
        line3: values?.shipping_address?.line3,
        zip: values?.shipping_address?.zip,
        city: values?.shipping_address?.city,
        state: values?.shipping_address?.state,
        country: values?.shipping_address?.country,
      },
    }

    try {
      await api.updateCustomer(props.customerId, formattedValue)

      if (values?.subscriptionId) {
        await api.updateChargebeeSubscription(
          values.subscriptionId,
          formattedShippingAddressData
        )
      }

      notify('Changes saved')
      refetchSubscriptions()
      refresh()
    } catch (e) {
      console.error(e)
    }
    return
  }

  return (
    <Toolbar {...props} style={{ backgroundColor: 'white', margin: 0 }}>
      <Button
        color="primary"
        onClick={handleClick}
        size="large"
        variant="contained"
      >
        Save
      </Button>
    </Toolbar>
  )
}

export const AddressesEdit = (props) => {
  const dataProvider = useDataProvider()
  const { customerId, store } = useCustomer()
  const { subscriptions } = useSubscriptions()

  const [countries, setCountries] = useState([])
  const [selectedSubscription, setSelectedSubscription] = useState()

  const fetchData = useCallback(async () => {
    const id = store?.id
    if (id) {
      const { data } = await dataProvider.getList('shipping-country-stores', {
        pagination: {},
        sort: {},
        filter: { itemsPerPage: 999, 'store.id': id },
      })

      setCountries(
        data.map((item) => {
          return { ...item, id: item?.country?.code, name: item?.name }
        })
      )
    }
  }, [dataProvider, store])

  useEffect(() => {
    let mount = true

    if (mount) {
      fetchData()
    }

    return () => (mount = false)
  }, [fetchData])

  const subscriptionOptions = useMemo(() => {
    return subscriptions
      .filter((item) => item.status === 'active' || item.status === 'future')
      .map((item) => {
        return {
          id: item.internal_id,
          name: `${item.plan_name} (${formatDateTime(item.created_at * 1000)})`,
        }
      })
  }, [subscriptions])

  useEffect(() => {
    if (subscriptionOptions.length && !selectedSubscription) {
      setSelectedSubscription(subscriptionOptions[0].id)
    }
  }, [subscriptionOptions, selectedSubscription])

  const subscription = subscriptions.find(
    (item) => item.internal_id === selectedSubscription
  )

  return (
    <TransparentEditGuesser
      {...props}
      variant="outlined"
      onSubmit={() => {}}
      initialValues={{
        ...props.record,
        shipping_address: { ...(subscription?.shipping_address || {}) },
        subscriptionId: selectedSubscription,
      }}
      toolbar={<CustomToolbar customerId={customerId} />}
    >
      <Title>Billing Address</Title>
      <FieldsWrapper>
        <TextInput
          source="data.billing_address.first_name"
          label="First Name"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.last_name"
          label="Last Name"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.email"
          label="Email"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.phone"
          label="Phone"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.line1"
          label="Address line 1"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.line2"
          label="Address line 2"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.line3"
          label="Address line 3"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.zip"
          label="Postcode"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.city"
          label="City"
          variant="outlined"
          fullWidth
          size="small"
        />
        <TextInput
          source="data.billing_address.state"
          label="State"
          variant="outlined"
          fullWidth
          size="small"
        />

        <SelectInput
          source="data.billing_address.country"
          label="Country"
          choices={countries}
          optionText="name"
          variant="outlined"
        />
      </FieldsWrapper>
      {subscriptionOptions.length ? (
        <>
          <Title>Shipping Address</Title>
          <SelectSubscriptionInput
            label="Subscriptions"
            optionText="name"
            source="subscriptionId"
            variant="outlined"
            onChange={(e) => setSelectedSubscription(e.target.value)}
            choices={subscriptionOptions}
            fullWidth
          />
        </>
      ) : null}

      {subscriptionOptions.length ? (
        <FieldsWrapper>
          <TextInput
            source="shipping_address.first_name"
            label="First Name"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.last_name"
            label="Last Name"
            variant="outlined"
            fullWidth
            size="small"
          />

          <TextInput
            source="shipping_address.email"
            label="Email"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.phone"
            label="Phone"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.line1"
            label="Address line 1"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.line2"
            label="Address line 2"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.line3"
            label="Address line 3"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.zip"
            label="Postcode"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.city"
            label="City"
            variant="outlined"
            fullWidth
            size="small"
          />
          <TextInput
            source="shipping_address.state"
            label="State"
            variant="outlined"
            fullWidth
            size="small"
          />

          <SelectInput
            source="shipping_address.country"
            label="Country"
            choices={countries}
            optionText="name"
            variant="outlined"
          />
        </FieldsWrapper>
      ) : null}
    </TransparentEditGuesser>
  )
}
