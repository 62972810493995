import PaymentsIcon from '@material-ui/icons/Receipt'
import { ROLES } from 'utils/user'
import { ChargebeePaymentList } from './ChargebeePaymentList'

export default {
  list: ChargebeePaymentList,
  icon: PaymentsIcon,
  permissions: [
    ROLES.ROLE_ADMIN,
    ROLES.ROLE_FINANCE,
    ROLES.ROLE_CUSTOMER_SUPPORT,
    ROLES.ROLE_ORDER_MANAGEMENT,
  ],
}
