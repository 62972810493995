import React from 'react'
import { required, TextInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const VariationCreate = (props) => {
  return (
    <CreateGuesser {...props} variant="outlined" redirect="list">
      <TextInput
        source="name"
        label="Name of property"
        validate={[required()]}
      />
      <TextInput source="nameWc" label="Woocommerce Name" />
    </CreateGuesser>
  )
}
