import React from 'react'
import { TextField, FunctionField, Button, useRedirect } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const ProductFacadeList = (props) => {
  const redirect = useRedirect()

  return (
    <ListGuesser
      {...props}
      filter={{
        'properties[productStoreInterval]': ['id', 'name'],
        'properties[propositionProduct][proposition]': ['id', 'name'],
        'properties[propositionProduct][product]': ['id', 'name'],
      }}
      rowClick="edit"
    >
      <FunctionField
        label="PSI id"
        source="productStoreInterval.id"
        render={(record) => {
          if (!record?.productStoreInterval?.id) return null
          return (
            <Button
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const id = `/api/product-store-intervals/${record.productStoreInterval.id}`
                const url = `/product-store-intervals/${encodeURIComponent(id)}`
                redirect(url)
              }}
              label={record.productStoreInterval.id}
            />
          )
        }}
      />
      <TextField label="PSI name" source="productStoreInterval.name" />

      <FunctionField
        label="Proposition id"
        source="propositionProduct.proposition.id"
        render={(record) => {
          if (!record?.propositionProduct?.proposition?.id) return null
          return (
            <Button
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const id = `/api/pimcore-propositions/${record.propositionProduct.proposition.id}`
                const url = `/pimcore-products/${encodeURIComponent(id)}/show`
                redirect(url)
              }}
              label={record.propositionProduct.proposition.id}
            />
          )
        }}
      />
      <TextField
        label="Proposition Name"
        source="propositionProduct.proposition.name"
      />
      <FunctionField
        label="Warehouse product id"
        source="propositionProduct.product.id"
        render={(record) => {
          if (!record?.propositionProduct?.product.id) return null
          return (
            <Button
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                const id = `/api/pimcore-products/${record.propositionProduct.product.id}`
                const url = `/pimcore-products/${encodeURIComponent(id)}/show`
                redirect(url)
              }}
              label={record.propositionProduct.product.id}
            />
          )
        }}
      />
      <TextField
        label="Warehouse product name"
        source="propositionProduct.product.name"
      />
    </ListGuesser>
  )
}
