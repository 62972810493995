import React from 'react'
import { TextField } from 'react-admin'
import { ListGuesser } from 'theme/components'

export const FreshdeskSubjectList = (props) => (
  <ListGuesser
    {...props}
    filter={{ properties: ['id', 'translationKey', 'name'] }}
    rowClick="edit"
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="name" />
    <TextField source="translationKey" />
  </ListGuesser>
)
