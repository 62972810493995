import React, { useState } from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import styled from 'styled-components'
import { Dialog } from '@material-ui/core'
import { startAndEnd } from 'utils/string'

const PreviewImage = styled.div`
  width: 100px;
  height: 100px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 8px;
`

const DownloadLink = styled.a`
  width: 100px;
  height: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  word-break: break-all;
  gap: 8px;
`

const Image = ({ src }) => {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <PreviewImage
        role="button"
        onClick={() => setModalOpen(true)}
        src={src}
      />
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="xl"
      >
        <img src={src} alt="file" />
      </Dialog>
    </>
  )
}

export const FileField = ({ file }) => {
  if (!file) return null
  if (file.data.mime.indexOf('image') !== -1) {
    return <Image src={file.data.cloudFrontUrl} />
  }

  return (
    <DownloadLink href={file.data.cloudFrontUrl} download={file.data.name}>
      <AttachFileIcon />
      {startAndEnd(file.data.name)}
    </DownloadLink>
  )
}
