import React from 'react'
import { SelectInput, ReferenceInput, TextInput } from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const CountryCreate = (props) => (
  <CreateGuesser {...props} variant="outlined" redirect="list">
    <TextInput source="name" />
    <TextInput source="code" />
    <ReferenceInput
      source="cluster"
      reference="clusters"
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </CreateGuesser>
)
