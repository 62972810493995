import React, { useMemo, useState, useEffect } from 'react'
import { Button, useNotify } from 'react-admin'

import styled from 'styled-components'

import { formatDateForAPI } from 'utils/string'

import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from '@material-ui/core'

import { useSubscriptions } from 'components/pages/customer/contexts/subscriptions'
import { useReasons } from 'components/pages/customer/contexts/reasons'

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const PostponeNextOrderButton = ({ api, subscriptionId }) => {
  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const { postponeReasons } = useReasons()

  const [shippingDate, setShippingDate] = useState()
  const [reason, setReason] = useState('')
  const [shippingChanged, setShippingChanged] = useState(false)

  const { subscriptions, refetchSubscriptions } = useSubscriptions()

  const subscription = useMemo(() => {
    return subscriptions?.find((item) => item?.internal_id === subscriptionId)
  }, [subscriptions, subscriptionId])

  useEffect(() => {
    if (subscription?.next_billing_at) {
      setShippingDate(formatDateForAPI(subscription.next_billing_at * 1000))
    }
  }, [subscription])

  const handleSaveShippingDate = () => {
    api
      .updateCustomerSubscriptionNextShippingDate(
        subscription.internal_id,
        shippingDate,
        reason
      )
      .then(() => {
        notify('Subscription shipping date has been updated', 'info')
        setReason('')
        setShippingChanged(false)
        setOpen(false)
        refetchSubscriptions()
      })
  }

  return (
    <>
      <Button
        disabled={shippingDate === undefined}
        variant="outlined"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setOpen(true)
        }}
        label="postpone next order"
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <DialogTitle>Select next shipping date</DialogTitle>

          <Column>
            <TextField
              size="small"
              variant="outlined"
              label="Next shipping date"
              type="date"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                inputProps: { min: formatDateForAPI(new Date()) },
              }}
              value={shippingDate || null}
              onChange={(e) => {
                e.persist()
                setShippingDate((cur) => {
                  if (cur !== e.target.value) {
                    setShippingChanged(true)
                  }
                  return e.target.value
                })
              }}
            />
            {shippingChanged && (
              <FormControl size="small" variant="outlined">
                <InputLabel>Postpone Reason</InputLabel>
                <Select
                  value={reason}
                  label="Postpone Reason"
                  onChange={(e) => setReason(e.target.value)}
                >
                  {postponeReasons?.map(({ id, name }) => (
                    <MenuItem key={id} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Column>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              label="Save"
              fullWidth
              onClick={handleSaveShippingDate}
              disabled={!reason}
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
