import React from 'react'
import { TextField, FunctionField } from 'react-admin'
import styled from 'styled-components'
import { ListGuesser, DateField } from 'theme/components'
import ImportSubscriptionCancellationButton from './importSubscriptionCancellationButton'
import { getAllResult } from './helper'

const Wrapper = styled.div`
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;

  .MuiToolbar-gutters {
    min-height: auto;
    padding: 0;
  }

  .list-page {
    width: 100%;
    flex: 1;
  }
`

const STATUS = {
  1: 'Pending',
  2: 'Processing',
  3: 'Done',
  4: 'Failed',
}

export const CBSubscriptionsList = (props) => {
  return (
    <Wrapper>
      <ImportSubscriptionCancellationButton />
      <ListGuesser
        {...props}
        hasEdit={false}
        rowClick="show"
        bulkActionButtons={false}
      >
        <FunctionField
          label="Status"
          render={(record) => {
            const { status } = record

            return STATUS[Number(status)]
          }}
        />
        <TextField source="fileName" label="File Name" />
        <FunctionField
          label="Number of Canceled Subscriptions"
          render={(record) => {
            const subscriptionsCancelled = getAllResult(record).reduce(
              (prev, current) => {
                return (prev = prev + (current?.subscriptionsCancelled || 0))
              },
              0
            )

            return subscriptionsCancelled
          }}
        />
        <DateField source="startedAt" />
      </ListGuesser>
    </Wrapper>
  )
}
