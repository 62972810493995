import Icon from '@material-ui/icons/AddCircle'
import { AddonCreate } from './AddonCreate'
import { AddonList } from './AddonList'
import { AddonEdit } from './AddonEdit'
import { ROLES } from 'utils/user'

export default {
  create: AddonCreate,
  list: AddonList,
  edit: AddonEdit,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
