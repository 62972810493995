import React from 'react'
import {
  required,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { CreateGuesser } from 'theme/components'

export const BundleCreate = (props) => {
  return (
    <CreateGuesser {...props} variant="outlined" redirect="list">
      <TextInput source="name" validate={[required()]} />
      <ReferenceArrayInput
        source="stores"
        reference="stores"
        label="Store"
        perPage={500}
        filter={{ active: 1 }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceInput
        source="interval"
        reference="intervals"
        label="resources.interval.field"
        filterToQuery={(searchText) => ({ name: searchText })}
        format={(v) => (v && v['@id'] ? v['@id'] : v)}
      >
        <AutocompleteInput optionText="name" resettable />
      </ReferenceInput>
    </CreateGuesser>
  )
}
