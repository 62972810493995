import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Helmet } from 'react-helmet'

const deployed = new Set([
  'admin.fitformedev.nl',
  'admin-sta.fitformedev.nl',
  'admin-tst.fitformedev.nl',
])

const configureSentryUser = () => {
  Sentry.configureScope((scope) => {
    if (localStorage.getItem('userEmail')) {
      scope.setUser({
        id: localStorage.getItem('userEmail'),
        username: localStorage.getItem('userEmail'),
      })
    } else {
      scope.setUser(null)
    }
  })
}

if (deployed.has(window.location.hostname)) {
  Sentry.init({
    dsn:
      'https://a1433c1a01b24e96a0ca91fadab40072@o927224.ingest.sentry.io/6567801',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0, // 1.0 - capture 100% of transactions.
    environment: window.location.hostname,
  })

  configureSentryUser()

  window.addEventListener('login', () => {
    configureSentryUser()
  })
}

const isProduction = window.location.hostname === 'admin.fitformedev.nl'

ReactDOM.render(
  <>
    {isProduction && (
      <Helmet>
        <script type="text/javascript">
          {(function (c, l, a, r, i, t, y) {
            c[a] =
              c[a] ||
              function () {
                const arr = (c[a].q = c[a].q || [])
                arr.push(arguments)
              }
            t = l.createElement(r)
            t.async = 1
            t.src = 'https://www.clarity.ms/tag/' + i
            y = l.getElementsByTagName(r)[0]
            y.parentNode.insertBefore(t, y)

            window.clarity('set', 'userId', localStorage.getItem('userEmail'))
          })(window, document, 'clarity', 'script', 'hkpj30vz8u')}
        </script>
      </Helmet>
    )}
    <App />
  </>,
  document.getElementById('root')
)

serviceWorker.unregister()
