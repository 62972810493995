import React from 'react'
import { TextInput, maxLength } from 'react-admin'
import { EditGuesser } from 'theme/components'

export const GenderEdit = (props) => (
  <EditGuesser {...props} undoable={false} variant="outlined" redirect="list">
    <TextInput source="name" validate={maxLength(99)} />
    <TextInput source="slug" validate={maxLength(99)} />
  </EditGuesser>
)
