import React, { useEffect, useState } from 'react'
import { ShowGuesser } from '@api-platform/admin'
import { TextField, FunctionField, Button,
  useNotify, } from 'react-admin'
import { DateField } from 'theme/components'

import useApi from 'hooks/useApi'

const ReprocessButton = (props) => {
  const { record } = props
  const { api, isSubmitting } = useApi()
  const notify = useNotify()
  const [clickedAt, setClickedAt] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const reprocess = () => {
    setClickedAt(Date.now())
    api.reprocessAdyenNotification(record.originId).then(() => {
      notify('Reprocessing scheduled')
    })
  }

  useEffect(() => {
    const diffSeconds = (Date.now() - clickedAt) / 1000
    console.log('lo')
    let allowReprocess = true
    if (!record.error) {
      allowReprocess = false
    }
    if (isSubmitting) {
      allowReprocess = false
    }
    if (diffSeconds < 180) {
      allowReprocess = false
    }
    if (!record.processed) {
      allowReprocess = false
    }
    setDisabled(!allowReprocess)
  }, [clickedAt, isSubmitting, record])

  return (
    <Button
      style={{ margin: '12px 0' }}
      color="primary"
      variant="outlined"
      size="medium"
      onClick={reprocess}
      disabled={disabled}
      label="Reprocess notification"
    />
  )
}

export const AdyenNotificationShow = (props) => {
  return (
    <ShowGuesser {...props}>
      <TextField source="originId" label="id" />
      <DateField source="createdAt" short={false} />
      <DateField source="processedAt" short={false} />
      <TextField source="payment.id" label="payment id" />
      <TextField source="error" />
      <FunctionField
        label="Data"
        render={(record) => {
          return (
            <div>
              <pre>{JSON.stringify(record.data, null, 2)}</pre>
            </div>
          )
        }}
      />
      <ReprocessButton />
    </ShowGuesser>
  )
}
