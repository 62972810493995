import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import {
  getAddonsFromSubscription,
  getPimcoreSubscriptions,
} from 'components/pages/checkoutSessions/checkoutSessionInput/subscriptions/utils'
import { formatDate } from 'utils/string'
import { EditPimcoreSubscriptionModal } from './EditPimcoreSubscriptionModal'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { useRecordContext } from 'react-admin'
import { SubscriptionItem } from 'components/SubscriptionItem'
import { useSubscriptions } from 'components/pages/customer/contexts/subscriptions'

const SubscriptionBody = styled.li`
  padding: 8px;
  border: 1px solid gray;
  border-radius: 8px;
  margin: 8px 0;
  cursor: ${({ cancelled }) => (cancelled ? 'not-allowed' : 'pointer')};
  position: relative;
  opacity: ${({ cancelled }) => (cancelled ? 0.5 : 1)};
  pointer-events: ${({ cancelled }) => (cancelled ? 'none' : 'initial')};
  text-align: left;
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  gap: 4px;
  padding: 0;
`

const getStatusColor = (status) => {
  if (status === 'active') return '#90C53C'
  if (status === 'future') return '#FFA500'
  return '#ff0000'
}

const Status = styled.span`
  color: ${({ status }) => getStatusColor(status)};
  text-transform: capitalize;
`

const ReasonTitle = styled.h4`
  margin: 0;
`

export const PimcoreSubscriptionItem = ({
  subscription: initialSubscription,
  index: subscriptionIndex,
}) => {
  const { store } = useCustomer()
  const storeId = store?.id
  const { api } = useApi()
  const [edit, setEdit] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [subscription, setSubscription] = useState(initialSubscription)
  const [metaData, setMetaData] = useState(initialSubscription.meta_data)
  const [subscriptionInfo, setSubscriptionInfo] = useState({})
  const record = useRecordContext()
  const { refetchSubscriptions } = useSubscriptions()

  const billingAddress = record?.data?.billing_address

  useEffect(() => {
    if (metaData) {
      setSubscription((cur) => ({
        ...cur,
        addons: getAddonsFromSubscription(metaData),
        metaData: metaData,
      }))
    }
  }, [metaData])

  const fetchSubscription = useCallback(() => {
    if (!storeId) return

    return api
      .getPimcoreSubscriptionsInfo({
        pimcoreSubscriptions: {
          subscriptions: getPimcoreSubscriptions([metaData]),
          customerPersonalInfo: {},
          customerBillingAddress: billingAddress,
        },
        storeId,
      })
      .then((subscriptionsResponse) => {
        setFetched(true)

        setSubscriptionInfo(subscriptionsResponse?.data?.subscriptions[0])
      })
  }, [storeId, api, billingAddress, metaData])

  useEffect(() => {
    if (fetched) return
    fetchSubscription()
  }, [fetched, fetchSubscription])

  const refetchSubscription = useCallback(() => {
    return new Promise((resolve, reject) => {
      const res = fetchSubscription()
      if (res) {
        res.then(resolve).catch(reject)
      } else {
        resolve()
      }
    })
  }, [fetchSubscription])

  const currency = store?.currency?.code

  return (
    <>
      <SubscriptionBody
        role="button"
        onClick={!edit ? () => setEdit(true) : undefined}
        cancelled={subscription.status === 'cancelled'}
      >
        <h4
          style={{
            margin: 0,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Subscription {subscriptionIndex + 1} (pimcore)
          <Status status={subscription.status}>{subscription.status}</Status>
        </h4>
        {subscription.status === 'cancelled' ? (
          <div>
            <ReasonTitle>Cancel Reason</ReasonTitle>
            {subscription?.cf_cancel_reason_1 ? (
              <ul>
                <li>{subscription.cf_cancel_reason_1}</li>
                <li>{subscription.cf_cancel_reason_2}</li>
              </ul>
            ) : (
              <p>No Reason Selected</p>
            )}
          </div>
        ) : null}
        {subscription.next_billing_at && (
          <span>
            next shipping at: {formatDate(subscription.next_billing_at * 1000)}
          </span>
        )}

        <ItemsList>
          {subscriptionInfo?.items?.map((item, itemIndex) => {
            return (
              <SubscriptionItem
                item={item}
                currency={currency}
                key={`${subscriptionIndex}:${itemIndex}`}
              />
            )
          })}
        </ItemsList>
      </SubscriptionBody>
      <EditPimcoreSubscriptionModal
        open={edit}
        onClose={() => {
          refetchSubscriptions()
          setEdit(false)
          setTimeout(() => setFetched(false), 200)
        }}
        metaData={metaData}
        setMetaData={setMetaData}
        subscription={subscription}
        setSubscription={setSubscription}
        refetchSubscription={refetchSubscription}
        subscriptionInfo={subscriptionInfo}
      />
    </>
  )
}
