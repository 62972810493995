import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  Button,
  useRefresh,
  useNotify,
  useUnselectAll,
  SelectInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  required,
  ReferenceInput,
} from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import useApi from 'hooks/useApi'
import { getNumberFromStringId } from 'utils/string'

const MainCopyButton = styled(Button)`
  margin-right: 20px;
`

const ToolbarCopyButton = styled(SaveButton)`
  margin-left: 20px;
`

const CopyGroupsToolbar = ({ invalid, handleClose, ...props }) => (
  <Toolbar {...props}>
    <Button
      label="Cancel"
      onClick={handleClose}
      size="medium"
      color="secondary"
      fullWidth
    />
    <ToolbarCopyButton
      label="Copy"
      icon={<FileCopyIcon />}
      redirect={false}
      submitOnEnter={false}
      variant="outlined"
      fullWidth
      disabled={invalid}
    />
  </Toolbar>
)

const CopyGroupsButton = (props) => {
  const { selectedIds } = props
  const { api, isSubmitting, handleError } = useApi()
  const [isOpen, setIsOpen] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const unselectAll = useUnselectAll()

  const handleCopy = useCallback(
    async ({ store }) => {
      try {
        const storeId = getNumberFromStringId(store)
        const groupIds = selectedIds.map(getNumberFromStringId)
        await api.copyGroups({ storeId, groupIds })
        refresh()
        notify(
          `Group${
            groupIds.length > 1 ? 's' : ''
          } ${groupIds} copied to Store ${storeId}`
        )
        unselectAll('groups')
        setIsOpen(false)
      } catch (error) {
        handleError(error)
      }
    },
    [api, handleError, selectedIds, notify, refresh, unselectAll]
  )

  return (
    <>
      <MainCopyButton
        color="primary"
        onClick={() => setIsOpen(true)}
        size="small"
        label="Copy"
        variant="contained"
      >
        <FileCopyIcon />
      </MainCopyButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Please select the store</DialogTitle>
        <SimpleForm
          variant="outlined"
          save={handleCopy}
          saving={isSubmitting}
          redirect={false}
          toolbar={<CopyGroupsToolbar handleClose={() => setIsOpen(false)} />}
          validate={({ store }) => (store ? {} : { store: 'required' })}
        >
          <ReferenceInput source="store" reference="stores" label="Store">
            <SelectInput optionText="name" validation={required()} />
          </ReferenceInput>
        </SimpleForm>
      </Dialog>
    </>
  )
}

export default CopyGroupsButton
