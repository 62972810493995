import React from 'react'
import { Button } from 'react-admin'
import { getNumberFromStringId } from 'utils/string'
import { getApiRoute } from 'service/Api/routes'

export const TrackOrderButton = ({ api, orderId }) => {
  return (
    <Button
      variant="outlined"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()

        api
          .get(getApiRoute(`orders/${getNumberFromStringId(orderId)}/tracking`))
          .then((res) => {
            if (res?.data?.url) {
              setTimeout(() => window.open(res?.data?.url, '_blank').focus()) // setTimeout is here to handle safari issue https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
            }
          })
      }}
      label="track"
    />
  )
}
