import React, { useEffect } from 'react'
import { TextField, ReferenceManyField, FunctionField } from 'react-admin'
import { DateField, DataGrid } from 'theme/components'
import { formatPrice, formatText } from 'utils/string'
import { useCustomer } from 'components/pages/customer/contexts/customer'

export const ShortCustomerCreditNotesList = ({
  ListStats,
  setTotal,
  ...props
}) => {
  const { customerId } = useCustomer()

  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  if (!customerId) return null

  return (
    <ReferenceManyField
      reference="credit-notes"
      source="id"
      filter={{
        'customer.id': customerId,
        properties: ['id', 'data', 'status', 'createdAt'],
        'properties[data]': ['total', 'create_reason_code', 'currency_code'],
      }}
      perPage={10}
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={() => {}}>
        {ListStats}
        <FunctionField
          label="Status"
          source="status"
          render={(record) => formatText(record.status)}
        />
        <DateField source="createdAt" />
        <FunctionField
          label="Amount"
          source="amount"
          render={(record) => {
            const {
              data: { total, currency_code },
            } = record

            return formatPrice(total / 100, currency_code)
          }}
        />
        <TextField source="data.create_reason_code" label="Reason" />
      </DataGrid>
    </ReferenceManyField>
  )
}
