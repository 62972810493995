import React from 'react'
import {
  Toolbar,
  ReferenceInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin'
import { ResourceEditToolbar } from 'theme/components/toolbar/ResourceEditToolbar'
import { EditGuesser } from 'theme/components'

export const AddonEdit = ({ defaultToolbar, ...props }) => (
  <EditGuesser
    {...props}
    variant="outlined"
    redirect="list"
    toolbar={
      defaultToolbar ? <Toolbar /> : <ResourceEditToolbar showDelete={false} />
    }
  >
    <ReferenceInput
      source="plan"
      reference="plans"
      label="resources.plan.field"
      filterToQuery={(searchText) => ({ slug: searchText })}
      format={(v) => (v && v['@id'] ? v['@id'] : v)}
    >
      <AutocompleteInput optionText="chargeBeeId" />
    </ReferenceInput>
    <BooleanInput source="mandatory" />
    <BooleanInput source="recommended" />
  </EditGuesser>
)
