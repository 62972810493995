import Icon from '@material-ui/icons/Event'
import { ROLES } from 'utils/user'

import { EventShow } from './EventShow'
import { EventList } from './EventList'

export default {
  show: EventShow,
  list: EventList,
  icon: Icon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
