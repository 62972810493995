import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { ROLES } from 'utils/user'
import { AdyenNotificationList } from './AdyenNotificationList'
import { AdyenNotificationShow } from './AdyenNotificationShow'

export default {
  list: AdyenNotificationList,
  show: AdyenNotificationShow,
  icon: NotificationsNoneIcon,
  permissions: [
    ROLES.ROLE_ADMIN,
    ROLES.ROLE_FINANCE,
    ROLES.ROLE_CUSTOMER_SUPPORT,
  ],
}
