import React from 'react'
import {
  required,
  TextInput,
  ReferenceManyField,
  DeleteWithConfirmButton,
  List,
  TextField,
  useRefresh,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import {
  EditGuesser,
  DataGrid,
  ShowGuesser,
  ResourceEditToolbar,
} from 'theme/components'
import { CreateBundleProductCard } from './card/CreateBundleProductCard'
import { EditBundleProductCard } from './card/EditBundleProductCard'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

const Title = styled(Typography)`
  margin: 50px 0 20px 0;
`

export const BundleEdit = (props) => {
  const refresh = useRefresh()

  return (
    <ShowGuesser {...props} hasEdit={false}>
      <EditGuesser
        {...props}
        variant="outlined"
        redirect="list"
        toolbar={<ResourceEditToolbar showBack={false} showDelete={false} />}
        transform={(record) => ({
          ...record,
          interval: record.interval['@id'] || record.interval,
          stores: record?.stores?.map((item) => item['@id'] || item) || [],
          products: undefined,
        })}
      >
        <TextInput
          source="name"
          label="Name of property"
          validate={[required()]}
        />
        <ReferenceArrayInput
          source="stores"
          reference="stores"
          label="Store"
          perPage={500}
          filter={{ active: 1 }}
          format={(items) => {
            return items ? items.map((v) => (v && v['@id'] ? v['@id'] : v)) : []
          }}
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          source="interval"
          reference="intervals"
          label="resources.interval.field"
          filterToQuery={(searchText) => ({ name: searchText })}
          format={(v) => (v && v['@id'] ? v['@id'] : v)}
        >
          <AutocompleteInput optionText="name" resettable />
        </ReferenceInput>
        <ReferenceInput
          reference="bundles"
          source="upgradeTo"
          format={(v) => (v ? v['@id'] || v : v)}
          filter={{ properties: ['id', 'name'] }}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={50}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="bundles"
          source="downgradeTo"
          format={(v) => (v ? v['@id'] || v : v)}
          filter={{ properties: ['id', 'name'] }}
          sort={{ field: 'name', order: 'ASC' }}
          perPage={50}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </EditGuesser>
      <Title variant="h5">Products in bundle</Title>
      <ReferenceManyField
        addLabel={false}
        target=""
        reference="bundle-products"
        filter={{
          'bundle.id': props.id,
          properties: [
            'product',
            'quantityMin',
            'quantityMax',
            'discountPercentage',
          ],
        }}
      >
        <List
          {...props}
          exporter={false}
          bulkActionButtons={false}
          actions={false}
        >
          <DataGrid
            expand={<EditBundleProductCard bundleProps={{ ...props }} />}
          >
            <TextField label="Product" source="product.name" />
            <TextField label="Min Quantity" source="quantityMin" />
            <TextField label="Max Quantity" source="quantityMax" />
            <TextField label="Discount" source="discountPercentage" />
            <DeleteWithConfirmButton
              redirect={false}
              confirmTitle="Are you sure you want to delete this bundle product?"
              confirmContent=""
              onSuccess={refresh}
            />
          </DataGrid>
        </List>
      </ReferenceManyField>
      <Title variant="h5">Add product to bundle</Title>
      <CreateBundleProductCard {...props} />
    </ShowGuesser>
  )
}
