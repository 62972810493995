import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'

import { required, useDataProvider } from 'react-admin'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import {
  CreateGuesser,
  FileInput,
  ResourceCreateToolbar,
} from 'theme/components'
import { colors } from 'theme/MuiTheme'

const ImageWrapper = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  margin-bottom: 12px;

  & > img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    z-index: 1;
    position: absolute;
  }

  &:after {
    transition: opacity 0.2s ease-in-out;
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: white;
    opacity: 0;
    z-index: 1;
  }

  &:hover {
    &:after {
      opacity: 0.5;
    }
    & > div {
      z-index: 2;
    }
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 4px;
  align-items: center;
  justify-content: center;
`

export const WPImageCard = React.memo(({ variationId }) => {
  const dataProvider = useDataProvider()
  const [popupOpen, setPopupOpen] = useState(false)
  const wpProductVariation = useSelector(
    (state) => state.admin.resources['wp-product-variations']
  ).data[variationId]

  return (
    <div>
      {wpProductVariation?.image ? (
        <ImageWrapper>
          <img
            alt="preview"
            src={wpProductVariation.image.file.data.cloudFrontUrl}
          />

          <ButtonsWrapper>
            <Button onClick={() => setPopupOpen(true)}>
              <AddPhotoAlternateIcon style={{ color: colors.primary }} />
            </Button>
            <Button
              onClick={() => {
                dataProvider.update('wp-product-variations', {
                  id: wpProductVariation.id,
                  data: { ...wpProductVariation, image: null },
                  previousData: { ...wpProductVariation },
                })
              }}
            >
              <DeleteOutlineIcon style={{ color: '#ff4f52' }} />
            </Button>
          </ButtonsWrapper>
        </ImageWrapper>
      ) : (
        <Button
          onClick={() => setPopupOpen(true)}
          style={{ color: colors.primary }}
        >
          <AddPhotoAlternateIcon /> add image
        </Button>
      )}
      <Dialog open={popupOpen} onClose={() => setPopupOpen(false)}>
        <CreateGuesser
          resource="wp-images"
          basePath=""
          variant="outlined"
          redirect={false}
          onSuccess={(res) => {
            if (res?.data) {
              dataProvider
                .update('wp-product-variations', {
                  id: wpProductVariation.id,
                  data: { ...wpProductVariation, image: res.data.id },
                  previousData: { ...wpProductVariation },
                })
                .then(() => setPopupOpen(false))
            }
          }}
          toolbar={<ResourceCreateToolbar showBack={false} />}
        >
          <FileInput source="file" label="File" validate={[required()]} />
        </CreateGuesser>
      </Dialog>
    </div>
  )
})
