import React from 'react'
import {
  Paper,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { formatPrice } from 'utils/string'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1em',
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    marginTop: '3em',
  },
  th: {
    width: '130px',
    fontWeight: 'bold',
  },
  title: {
    marginBottom: '0.5em',
  },
}))

export const OrderDreamLogisticCard = ({ record }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const data = record.chargeBeeData || null

  if (!data || record?.orderItems?.length === 0) {
    return null
  }

  return (
    <Paper className={classes.paper} variant="outlined" square>
      <Typography className={classes.title} variant="h6">
        {translate('resources.order.cards.dreamlogistic')}
      </Typography>

      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{translate('resources.order.lines.id')}</TableCell>
            <TableCell>{translate('resources.order.lines.sku')}</TableCell>
            <TableCell>{translate('resources.order.lines.quantity')}</TableCell>
            <TableCell>
              {translate('resources.order.lines.unitPrice')}
            </TableCell>
            <TableCell>{translate('resources.order.lines.amount')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {record?.orderItems?.map((item, key) => {
            return (
              <TableRow key={key}>
                <TableCell align="left">{item.id}</TableCell>
                <TableCell align="left">{item.sku}</TableCell>
                <TableCell align="left">{item.qty}</TableCell>
                <TableCell align="left">
                  {formatPrice(item.unitPrice, data.currency_code)}
                </TableCell>
                <TableCell align="left">
                  {formatPrice(item.price, data.currency_code)}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Paper>
  )
}
