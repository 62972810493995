import WcIcon from '@material-ui/icons/Wc'
import { GenderList } from './GenderList'
import { GenderCreate } from './GenderCreate'
import { GenderEdit } from './GenderEdit'
import { ROLES } from 'utils/user'

export default {
  list: GenderList,
  create: GenderCreate,
  edit: GenderEdit,
  icon: WcIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
