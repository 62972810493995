import React, { useEffect, useState, useCallback } from 'react'
import { Edit, useDataProvider, SimpleForm, Create } from 'react-admin'
import { CustomToolbar } from 'theme/components/toolbar/CustomToolbar'

export const getSaveButtonClass = (storeId) =>
  `store-${String(storeId).replace(/[^0-9.]/g, '')}-save`

const loadingStyles = {
  opacity: 0.3,
  pointerEvents: 'none',
}

export const CreateOrEditForm = ({
  resource,
  strictFilterWithProperties,
  initialValues,
  storeId,
  hiddenToolbar = true,
  children, // inputs
}) => {
  const [value, setValue] = useState()
  const dataProvider = useDataProvider()
  const [loading, setLoading] = useState(false)
  const [fetched, setFetched] = useState(false)

  const tryToGetExistingValue = useCallback(async () => {
    if (loading || fetched) {
      return
    }
    setLoading(true)
    const response = await dataProvider.getList(resource, {
      pagination: {},
      sort: {},
      filter: strictFilterWithProperties,
    })
    setLoading(false)
    setFetched(true)
    if (response) {
      setValue(response.data[0])
    }
  }, [
    dataProvider,
    strictFilterWithProperties,
    resource,
    setValue,
    loading,
    fetched,
  ])

  useEffect(() => {
    if (loading || fetched) return

    tryToGetExistingValue()
  }, [tryToGetExistingValue, loading, fetched])

  if (!strictFilterWithProperties.properties) {
    return (
      <span>
        strictFilterWithProperties <b>must include properties</b>
      </span>
    )
  }

  return !value || loading ? (
    <Create
      style={loading ? loadingStyles : {}}
      redirect={false}
      resource={resource}
      basePath={`/${resource}`}
      onSuccess={(res) => res?.data && setValue(res.data)}
    >
      <SimpleForm
        redirect={false}
        variant="outlined"
        mutationMode="optimistic"
        toolbar={
          <CustomToolbar
            hidden={hiddenToolbar}
            saveButtonClass={getSaveButtonClass(storeId)}
          />
        }
        initialValues={initialValues}
      >
        {children}
      </SimpleForm>
    </Create>
  ) : (
    <Edit
      redirect={false}
      resource={resource}
      basePath={`/${resource}`}
      mutationMode="optimistic"
      onSuccess={() => {}}
      id={value['@id']}
    >
      <SimpleForm
        redirect={false}
        variant="outlined"
        mutationMode="optimistic"
        toolbar={
          <CustomToolbar
            hidden={hiddenToolbar}
            saveButtonClass={getSaveButtonClass(storeId)}
          />
        }
        initialValues={initialValues}
      >
        {children}
      </SimpleForm>
    </Edit>
  )
}
