import GlobeIcon from '@material-ui/icons/Public'

import { ShippingCountryList } from './ShippingCountryList'
import { ShippingCountryCreate } from './ShippingCountryCreate'
import { ShippingCountryEdit } from './ShippingCountryEdit'
import { ROLES } from 'utils/user'

export default {
  list: ShippingCountryList,
  create: ShippingCountryCreate,
  edit: ShippingCountryEdit,
  icon: GlobeIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
