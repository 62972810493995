import React from 'react'
import { BulkDeleteWithConfirmButton } from 'react-admin'
import {
  TextField,
  Filter,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ListButton,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
  ImageField,
} from 'theme/components'
import CopyGroupsButton from 'components/copyGroupsButton'
import { ROLES } from 'utils/user'
import { WooSyncButton } from 'components/wooSyncButton'

export const GalleryButton = ({ record, ...props }) => (
  <ListButton
    {...props}
    basePath={`/groups/${encodeURIComponent(record.id)}/1`}
  />
)

const GroupBulkActionButtons = (props) => (
  <>
    <CopyGroupsButton {...props} />
    <BulkDeleteWithConfirmButton {...props} />
  </>
)

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput
      source="store.id"
      reference="stores"
      label="Store"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceInput
      source="product.id"
      reference="products"
      label="resources.product.field"
      filterToQuery={(searchText) => ({ sku: searchText })}
    >
      <AutocompleteInput optionText="sku" />
    </ReferenceInput>
    <NumberInput label="Id" source="id" />
    <TextInput label="SKU" source="product.sku" />
    <TextInput label="Name" source="name" />
  </Filter>
)

export const GroupList = ({ classes, ...props }) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <ListGuesser
      {...props}
      filters={<ListFilter permissions={permissions} />}
      filter={{
        properties: ['id', 'name', 'createdAt', 'updatedAt'],
        'properties[store]': ['id', 'name'],
        'properties[image]': ['file'],
        'properties[image][file]': ['data'],
        'properties[product]': ['sku'],
      }}
      rowClick="edit"
      bulkActionButtons={<GroupBulkActionButtons />}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <ImageField
        label="resources.group.image"
        source="image.url"
        title="image.name"
        aspectRatio={1}
      />
      <TextField source="name" label="Name" />
      <TextField source="product.sku" label="SKU" />
      {permissions.includes(ROLES.ROLE_ADMIN) ? (
        <ReferenceListFilterField
          label="resources.store.field"
          listUri="/stores"
          titlePath="store.name"
          valuePath="store.name"
        />
      ) : (
        <TextField source="store.name" label="resources.store.field" />
      )}

      <GalleryButton label="resources.group.gallery" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
      <WooSyncButton type="groups" />
    </ListGuesser>
  )
}
