import React, { useCallback, useEffect, useState } from 'react'
import {
  FormControlLabel,
  Switch,
  Button,
  TextField,
  FormGroup,
} from '@material-ui/core'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getInitialPsiFromUrl, getInitialGroupFromUrl } from '../utils'

const SpaceSeparator = styled.div`
  height: 15px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`

const PlanCreate = React.memo(
  ({ groups, productStoreIntervals, onBackClick, onSubmit }) => {
    const [loading, setLoading] = useState(false)
    const [redirectUrl, setRedirectUrl] = useState('')
    const [group, setGroup] = useState(getInitialGroupFromUrl())
    const [productStoreInterval, setProductStoreInterval] = useState(
      getInitialPsiFromUrl()
    )
    const [allowUnpaid, setAllowUnpaid] = useState(false)
    const [enabledInHostedPages, setEnabledInHostedPages] = useState(true)
    const [enabledInPortal, setEnabledInPortal] = useState(true)

    const _onSubmit = useCallback(async () => {
      setLoading(true)

      let values = {
        group: group.id,
        productStoreInterval: productStoreInterval.id,
        allowUnpaid,
        redirectUrl,
        enabledInHostedPages,
        enabledInPortal,
        chargeBeeId: '',
      }

      await onSubmit(values)

      setLoading(false)
    }, [
      group,
      productStoreInterval.id,
      allowUnpaid,
      redirectUrl,
      enabledInHostedPages,
      enabledInPortal,
      onSubmit,
    ])

    useEffect(() => {
      const _redirectUrl =
        (productStoreInterval &&
          productStoreInterval.productStore &&
          productStoreInterval.productStore.store.redirectUrl) ||
        ''

      setRedirectUrl(_redirectUrl)
    }, [productStoreInterval])

    return (
      <div>
        <SpaceSeparator />
        <Autocomplete
          id="groups"
          options={groups}
          getOptionLabel={(option) => option?.name}
          style={{ width: 300 }}
          value={group}
          onChange={(event, newValue) => {
            setGroup(newValue)
          }}
          renderInput={(params) => (
            <TextField {...params} label="Group" variant="outlined" />
          )}
        />

        <SpaceSeparator />

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                value={allowUnpaid}
                onChange={(event) => setAllowUnpaid(event.target.checked)}
                name="allowUnpaid"
              />
            }
            label="Allow unpaid"
          />
          <FormControlLabel
            control={
              <Switch
                value={enabledInHostedPages}
                checked={enabledInHostedPages}
                onChange={(event) =>
                  setEnabledInHostedPages(event.target.checked)
                }
                name="enabledInHostedPages"
              />
            }
            label="Enabled in hosted pages"
          />
          <FormControlLabel
            control={
              <Switch
                value={enabledInPortal}
                checked={enabledInPortal}
                onChange={(event) => setEnabledInPortal(event.target.checked)}
                name="enabledInPortal"
              />
            }
            label="Enabled in portal"
          />
        </FormGroup>

        <SpaceSeparator />

        <TextField
          id="redirectUrl"
          label="Redirect url"
          style={{ width: 300 }}
          variant="outlined"
          value={redirectUrl}
          onChange={(event) => setRedirectUrl(event.target.value)}
        />

        <SpaceSeparator />

        <Autocomplete
          id="productStoreInterval"
          options={productStoreIntervals}
          getOptionLabel={(option) =>
            option?.productStore?.store?.name
              ? `${option?.productStore?.store?.name}, ${option?.name}`
              : option?.name
          }
          style={{ width: 300 }}
          value={productStoreInterval}
          onChange={(event, newValue) => {
            setProductStoreInterval(newValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Product store inverval"
              variant="outlined"
            />
          )}
        />

        <SpaceSeparator />
        <SpaceSeparator />

        <ButtonsWrapper>
          <Button color="primary" variant="outlined" onClick={onBackClick}>
            Back
          </Button>
          <Button
            disabled={loading || !group || !productStoreInterval}
            color="primary"
            variant="contained"
            onClick={_onSubmit}
          >
            Save
          </Button>
        </ButtonsWrapper>
      </div>
    )
  }
)

export default PlanCreate
