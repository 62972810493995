import React, { useState } from 'react'
import {
  useRefresh,
  SimpleForm,
  Toolbar,
  Button,
  useUnselectAll,
} from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import TuneIcon from '@material-ui/icons/Tune'
import SaveIcon from '@material-ui/icons/Save'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import useApi from 'hooks/useApi'
import { getNumberFromStringId } from 'utils/string'

const BulkUpdatePlansToolbar = ({ isLoading, handleClose, handleSave }) => (
  <Toolbar>
    <Button
      onClick={handleClose}
      size="medium"
      color="secondary"
      fullWidth
      label="cancel"
    />
    <Button
      size="medium"
      color="primary"
      disabled={isLoading}
      fullWidth
      label="save"
      onClick={handleSave}
    >
      <SaveIcon fontSize="small" />
    </Button>
  </Toolbar>
)

const BulkUpdatePlansButton = ({ selectedIds }) => {
  const { api, isSubmitting } = useApi()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()
  const [isOpen, setIsOpen] = useState(false)
  const [allowUnpaid, setAllowUnpaid] = useState(true)
  const [enabledInPortal, setEnabledInPortal] = useState(true)
  const [enabledInHostedPages, setEnabledInHostedPages] = useState(true)
  const ids = selectedIds.map((id) => getNumberFromStringId(id))
  const handleSave = () => {
    api
      .bulkUpdatePlans({
        ids,
        allowUnpaid,
        enabledInPortal,
        enabledInHostedPages,
      })
      .then(() => {
        setIsOpen(false)
        unselectAll('plans')
        refresh()
      })
  }

  return (
    <>
      <Button
        color="primary"
        onClick={() => setIsOpen(true)}
        size="small"
        label="Bulk update"
      >
        <TuneIcon fontSize="small" />
      </Button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          Click save to apply settings to selected plans
        </DialogTitle>
        <SimpleForm
          variant="outlined"
          saving={isSubmitting}
          redirect={false}
          toolbar={
            <BulkUpdatePlansToolbar
              handleSave={handleSave}
              handleClose={() => setIsOpen(false)}
              isLoading={isSubmitting}
            />
          }
        >
          <FormControlLabel
            control={
              <Switch
                checked={allowUnpaid}
                onChange={() => setAllowUnpaid(!allowUnpaid)}
                name="Allow Unpaid"
                color="primary"
              />
            }
            label="Allow Unpaid"
          />
          <FormControlLabel
            control={
              <Switch
                checked={enabledInPortal}
                onChange={() => setEnabledInPortal(!enabledInPortal)}
                name="Enabled In Portal"
                color="primary"
              />
            }
            label="Enabled In Portal"
          />
          <FormControlLabel
            control={
              <Switch
                checked={enabledInHostedPages}
                onChange={() =>
                  setEnabledInHostedPages(!setEnabledInHostedPages)
                }
                name="Enabled In Hosted Pages"
                color="primary"
              />
            }
            label="Enabled In Hosted Pages"
          />
        </SimpleForm>
      </Dialog>
    </>
  )
}

export default BulkUpdatePlansButton
