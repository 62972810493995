import { useCallback, useEffect, useState } from 'react'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'
import useApi from 'hooks/useApi'
import { reloadPage } from '../utils'

export default (productId, setLoading) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const { api, handleError } = useApi()

  const [missingProductStores, setMissingProductStores] = useState([])

  const [addedProductStores, setAddedProductStores] = useState([])
  const [addedStoresIds, setAddedStoresIds] = useState([])

  const fetchMissingProductStores = useCallback(async () => {
    const data = await api.fetchMissingProductStores(productId)

    setMissingProductStores(data['hydra:member'])
  }, [productId, setMissingProductStores, api])

  const syncProductStores = useCallback(async () => {
    try {
      const { data } = await dataProvider.getManyReference('product-stores', {
        target: 'product.id',
        id: productId,
        pagination: {},
        sort: {},
        filter: {
          itemsPerPage: 999,
          properties: ['id', 'sku', 'name', 'description'],
          'properties[store]': ['id', 'name', 'countryCode'],
          'properties[product]': ['id', 'name'],
        },
      })

      setAddedProductStores(data)
    } catch (error) {
      console.error(error)
    }
  }, [dataProvider, productId])

  const onAddProductStoresClick = useCallback(
    async (selectedProductStores, record) => {
      try {
        await Promise.all(
          selectedProductStores.map((item) =>
            api.createProductStore({
              store: `/api/stores/${item.id}`,
              product: record.id,
              sku: `${record.sku}${item.countryCode}`,
            })
          )
        )

        notify('Success', 'info')

        reloadPage()

        await syncProductStores()

        redirect(`/products/${encodeURIComponent(record.id)}/2`)
        setLoading(true)
      } catch (error) {
        handleError(error)
      }
    },
    [notify, syncProductStores, setLoading, redirect, api, handleError]
  )

  useEffect(() => {
    fetchMissingProductStores()
  }, [fetchMissingProductStores])

  useEffect(() => {
    if (productId) {
      syncProductStores()
    }
  }, [productId, syncProductStores])

  useEffect(() => {
    if (addedProductStores.length) {
      setAddedStoresIds(addedProductStores.map((ps) => ps.store['@id']))
    }
  }, [addedProductStores])

  return {
    addedProductStores,
    addedStoresIds,
    onAddProductStoresClick,
    missingProductStores,
  }
}
