import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import { ROLES } from 'utils/user'

import { MontaStockList } from './MontaStockList'
import { MontaStockEdit } from './MontaStockEdit'
import { MontaStockCreate } from './MontaStockCreate'

export default {
  list: MontaStockList,
  edit: MontaStockEdit,
  create: MontaStockCreate,
  icon: LocalShippingIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_PRODUCT],
}
