import React, { useCallback, useState } from 'react'
import {
  Notification,
  defaultTheme,
  Login as BaseLogin,
  useRedirect,
} from 'react-admin'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme } from '@material-ui/core/styles'
import { TextField, Button } from '@material-ui/core'
import styled from 'styled-components'
import useApi from 'hooks/useApi'

const InputsWrapper = styled.div`
  padding: 0 1em 1em 1em;
`
const SubmitWrapper = styled.div`
  padding: 8px;
`

export const RecoverPassword = React.memo((props) => {
  const { api, isSubmitting } = useApi()
  const redirect = useRedirect()
  const [newPassword, setNewPassword] = useState('')

  const submitNewPassword = useCallback(
    (e) => {
      e.preventDefault()
      api
        .setNewPassword({
          password: newPassword,
          token: props.match.params.token,
        })
        .then((response) => {
          if (response) {
            redirect('/login')
          }
        })
    },
    [newPassword, api, redirect, props]
  )

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <BaseLogin
        // A random image that changes on every page load
        backgroundImage="https://picsum.photos/1600/900"
      >
        <form onSubmit={submitNewPassword}>
          <InputsWrapper>
            <div>
              <TextField
                id="password"
                type="password"
                label="New Password"
                style={{ width: '100%' }}
                value={newPassword}
                required
                onChange={(event) => setNewPassword(event.target.value)}
              />
            </div>
          </InputsWrapper>
          <SubmitWrapper>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isSubmitting}
            >
              set new password
            </Button>
          </SubmitWrapper>
        </form>

        <Notification />
      </BaseLogin>
    </ThemeProvider>
  )
})
