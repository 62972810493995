import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import { getAddonsFromSubscription } from 'components/pages/checkoutSessions/checkoutSessionInput/subscriptions/utils'
import { getSessionSubscriptions } from 'components/pages/checkoutSessions/checkoutSessionInput/subscriptions/utils'
import { formatDate, formatPrice } from 'utils/string'
import { EditSubscriptionModal } from './EditSubscriptionModal'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { SubscriptionItem } from 'components/SubscriptionItem'
import { useSubscriptions } from 'components/pages/customer/contexts/subscriptions'

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const SubscriptionBody = styled.li`
  padding: 8px;
  border: 1px solid gray;
  border-radius: 8px;
  margin: 8px 0;
  cursor: ${({ cancelled }) => (cancelled ? 'not-allowed' : 'pointer')};
  position: relative;
  opacity: ${({ cancelled }) => (cancelled ? 0.5 : 1)};
  pointer-events: ${({ cancelled }) => (cancelled ? 'none' : 'initial')};
  text-align: left;
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  gap: 4px;
  padding: 0;
`

const Item = styled.li`
  display: flex;
  list-style: none;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  opacity: ${({ mandatory }) => (mandatory ? 0.53 : 1)};
  pointer-events: ${({ mandatory }) => (mandatory ? 'none' : 'inherit')};
  margin: 4px;
`

const ItemName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 auto 0 4px;
  flex: 1;
`

const ItemDescription = styled.span`
  font-weight: 600;
`

const ItemHeader = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
`

const getStatusColor = (status) => {
  if (status === 'active') return '#90C53C'
  if (status === 'future') return '#FFA500'
  return '#ff0000'
}

const ReasonTitle = styled.h4`
  margin: 0;
`

const Status = styled.span`
  color: ${({ status }) => getStatusColor(status)};
  text-transform: capitalize;
`

export const MidlayerSubscriptionItem = ({
  subscription: initialSubscription,
  index: subscriptionIndex,
}) => {
  const { store } = useCustomer()
  const storeId = store?.id
  const { api } = useApi()
  const [edit, setEdit] = useState(false)
  const [fetched, setFetched] = useState(false)
  const [subscription, setSubscription] = useState(initialSubscription)
  const [metaData, setMetaData] = useState(initialSubscription.meta_data)
  const { refetchSubscriptions } = useSubscriptions()

  useEffect(() => {
    if (metaData) {
      setSubscription((cur) => ({
        ...cur,
        addons: getAddonsFromSubscription(metaData),
        metaData: metaData,
      }))
    }
  }, [metaData])

  const fetchSubscription = useCallback(() => {
    if (!storeId || !metaData?.items?.length) {
      return
    }

    return api
      .fetchCheckoutSubscriptionsInfo({
        sessionSubscriptions: getSessionSubscriptions([
          { items: metaData.items },
        ]),
        storeId,
      })
      .then((subscriptionsResponse) => {
        if (subscriptionsResponse?.data?.subscriptions?.length) {
          const { items } = subscriptionsResponse.data.subscriptions[0]
          setMetaData((cur) => ({ ...cur, items }))
        }
        setFetched(true)
      })
  }, [storeId, api, metaData])

  useEffect(() => {
    if (fetched) return
    fetchSubscription()
  }, [fetched, fetchSubscription])

  const refetchSubscription = useCallback(() => {
    return new Promise((resolve, reject) => {
      const res = fetchSubscription()
      if (res) {
        res.then(resolve).catch(reject)
      } else {
        resolve()
      }
    })
  }, [fetchSubscription])

  return (
    <>
      <SubscriptionBody
        role="button"
        onClick={!edit ? () => setEdit(true) : undefined}
        cancelled={subscription.status === 'cancelled'}
      >
        <h4
          style={{
            margin: 0,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Subscription {subscriptionIndex + 1}
          <Status status={subscription.status}>{subscription.status}</Status>
        </h4>

        {subscription.status === 'cancelled' ? (
          <div>
            <ReasonTitle>Cancel Reason</ReasonTitle>
            {subscription?.cf_cancel_reason_1 ? (
              <ul>
                <li>{subscription.cf_cancel_reason_1}</li>
                <li>{subscription.cf_cancel_reason_2}</li>
              </ul>
            ) : (
              <p>No Reason Selected</p>
            )}
          </div>
        ) : null}

        {subscription.next_billing_at && (
          <span>
            next shipping at: {formatDate(subscription.next_billing_at * 1000)}
          </span>
        )}

        {subscription.metaData ? (
          <>
            <ItemsList>
              {subscription.metaData.items?.map((item, itemIndex) => {
                return (
                  <SubscriptionItem
                    key={`${subscriptionIndex}:${itemIndex}`}
                    item={item}
                    currency={item.currency}
                  />
                )
              })}
            </ItemsList>
          </>
        ) : (
          <ItemsList>
            {!!subscription.addons &&
              Object.values(subscription.addons).map((addon) => (
                <Item key={addon.id}>
                  <ItemHeader>
                    {addon?.quantity} X
                    <ItemName>
                      <ItemDescription>{addon.name}</ItemDescription>
                    </ItemName>
                    <Price>
                      {formatPrice(
                        addon.unit_price / 100,
                        subscription.currency_code ||
                          subscription.base_currency_code
                      )}
                    </Price>
                  </ItemHeader>
                </Item>
              ))}
          </ItemsList>
        )}
      </SubscriptionBody>
      <EditSubscriptionModal
        open={edit}
        onClose={() => {
          refetchSubscriptions()
          setEdit(false)
          setTimeout(() => setFetched(false), 200)
        }}
        metaData={metaData}
        setMetaData={setMetaData}
        subscription={subscription}
        setSubscription={setSubscription}
        refetchSubscription={refetchSubscription}
      />
    </>
  )
}
