import React from 'react'
import { formatPrice } from 'utils/string'
import styled from 'styled-components'

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const Item = styled.li`
  display: flex;
  list-style: none;
  font-size: 14px;
  line-height: 19px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin: 4px;
  padding-right: 30px;
  width: 100%;
`
const ItemName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 auto 0 8px;
  flex: 1;
  font-size: 14px;
  opacity: ${({ mandatory }) => (mandatory ? 0.3 : 1)};
`

const ItemDescription = styled.span`
  font-weight: 600;
  display: flex;
  gap: 8px;
`

const ItemHeader = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  gap: 4px;
`

const BundleProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 0 0 4px;
  margin-top: 10px;
`

export const SubscriptionItem = ({ item, currency, showQuantity = true }) => {
  let itemPrice = item.priceWithDiscount || 0

  if (item.product) {
    // pimcore
    itemPrice = item.product.price / 100 || 0
    if (item.product.products) {
      item.product.products.forEach(({ listing, quantity, bundleDiscount }) => {
        if (!listing) return
        const bundleItemPrice = Number(listing[0]?.price)
        const bundleItemQuantity = quantity || 1
        let total = bundleItemPrice * bundleItemQuantity
        if (bundleDiscount) {
          const onePercent = total / 100
          total -= onePercent * bundleDiscount
        }
        itemPrice += total
      })

      itemPrice = itemPrice / 100 || 0
    }
  } else {
    // psi
    itemPrice = item.priceWithDiscount || 0

    if (item.products) {
      item.products.forEach(({ priceWithDiscount, quantity }) => {
        const bundleItemPrice = Number(priceWithDiscount)
        const bundleItemQuantity = quantity || 1
        const total = bundleItemPrice * bundleItemQuantity
        itemPrice += total
      })
    }
  }

  const products = item.products || item.product?.products
  const name = item.name || item.product?.name
  const interval = item.interval
  const titleParts = [name, interval].filter(Boolean)

  return (
    <Item mandatory={item.mandatory}>
      <ItemHeader>
        {showQuantity && <span>{item?.quantity || 0} ✕ </span>}

        <ItemName>
          <ItemDescription>{titleParts.join(' - ')}</ItemDescription>
          <span>{item.productName}</span>
        </ItemName>
        <Price>{formatPrice(itemPrice, currency)}</Price>
      </ItemHeader>
      {products && (
        <BundleProductsWrapper>
          {products.map((product, productIndex) => {
            const productName =
              product.productName ||
              (product?.listing && product.listing[0]?.name)
            return (
              <ItemName
                key={`product${productIndex}`}
                mandatory={product.mandatory}
              >
                <ItemDescription>
                  <span>{product?.quantity || 1} ✕ </span> {productName}
                </ItemDescription>
                <span>{product.name}</span>
              </ItemName>
            )
          })}
        </BundleProductsWrapper>
      )}
    </Item>
  )
}
