import { useCallback, useState } from 'react'
import { useNotify, useRedirect } from 'react-admin'
import useApi from 'hooks/useApi'
import { getUrlParams } from 'utils/uri'
import { ADDON_SCREENS, ADDON_TYPE, reloadPage } from '../utils'

export default (productId, setLoading) => {
  const notify = useNotify()
  const { api, handleError } = useApi()
  const redirect = useRedirect()

  const [screen, setScreen] = useState(
    getUrlParams().screen || ADDON_SCREENS.ADDED_ADDONS
  )

  const [type, setType] = useState(ADDON_TYPE.PLAN)

  const onAddAddonClick = useCallback((type) => {
    setScreen(ADDON_SCREENS.ADD_ADDON)
    setType(type)
  }, [])

  const onBackClickAddon = useCallback(() => {
    redirect(`/products/${encodeURIComponent(productId)}/5`)
    setScreen(ADDON_SCREENS.ADDED_ADDONS)
  }, [productId, redirect])

  const onAddAddonSubmit = useCallback(
    async (values) => {
      try {
        await api.createAddon(values)

        notify('Success', 'info')
        reloadPage()
        redirect(`/products/${encodeURIComponent(productId)}/5`)
        setLoading(true)
      } catch (error) {
        handleError(error)
      }
    },
    [api, handleError, notify, productId, redirect, setLoading]
  )

  return {
    screen,
    type,
    onAddAddonClick,
    onAddAddonSubmit,
    onBackClickAddon,
  }
}
