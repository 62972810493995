import React from 'react'
import { ShowGuesser, InputGuesser, FieldGuesser } from '@api-platform/admin'
import {
  Tab,
  ReferenceManyField,
  Pagination,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  Toolbar,
  TextField,
  usePermissions,
  required,
} from 'react-admin'
import { AddImageButton } from './gallery/action/AddImageButton'
import { GalleryGrid } from './gallery/GalleryGrid'
import BackButton from 'theme/components/button/BackButton'
import { withStyles } from '@material-ui/core/styles'
import {
  ReferenceListFilterField,
  DateField,
  DataGrid,
  EditGuesser,
} from 'theme/components'
import { ROLES } from 'utils/user'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'

const styles = {
  toolbar: {
    justifyContent: 'space-between',
  },
}

const ActionsToolbar = withStyles(styles)(({ classes, ...props }) => (
  <Toolbar className={classes.toolbar} {...props}>
    <BackButton />
    <AddImageButton />
  </Toolbar>
))

export const GroupEdit = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <ShowGuesser {...props}>
      <OverflowingTabbedShowLayout>
        <Tab label="resources.group.edit">
          <EditGuesser
            {...props}
            undoable={false}
            variant="outlined"
            redirect="list"
          >
            <ReferenceInput
              source="store"
              reference="stores"
              label="Store"
              format={(v) => v['@id'] || v}
              filter={{ active: 1 }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              source="product"
              reference="products"
              label="Product"
              format={(v) => (v ? v['@id'] || v : '')}
              filter={{ active: 1 }}
            >
              <AutocompleteInput optionText="name" resettable />
            </ReferenceInput>
            <InputGuesser source="name" validate={required()} />
          </EditGuesser>
        </Tab>
        <Tab label="resources.group.gallery">
          <ReferenceManyField
            addLabel={false}
            reference="group-images"
            resource="gallery"
            target="group.id"
            pagination={<Pagination />}
            filter={{
              properties: ['id', 'wooCommerce', 'main', 'url', 'name'],
              'properties[file]': ['data'],
              'properties[group]': ['id'],
            }}
          >
            <GalleryGrid />
          </ReferenceManyField>
          <ActionsToolbar />
        </Tab>
        <Tab label="resources.group.plans">
          <ReferenceManyField
            addLabel={false}
            reference="plans"
            target="group.id"
            source="id"
            pagination={<Pagination />}
            filter={{
              properties: ['id', 'allowUnpaid', 'createdAt'],
              'properties[productStoreInterval]': ['id', 'name'],
            }}
          >
            <DataGrid
              empty="resources.group.list.plans"
              rowClick={
                permissions.includes(ROLES.ROLE_WORDPRESS) ? null : 'edit'
              }
            >
              <TextField
                source="originId"
                label="id"
                sortBy="id"
                sort={{ field: 'id', order: 'DESC' }}
              />

              {!permissions.includes(ROLES.ROLE_WORDPRESS) && (
                <ReferenceListFilterField
                  label="resources.productStoreInterval.field"
                  listUri="/product-store-intervals"
                  titlePath="productStoreInterval.name"
                  valuePath="productStoreInterval.name"
                />
              )}

              {permissions.includes(ROLES.ROLE_WORDPRESS) && (
                <TextField
                  source="productStoreInterval.name"
                  label="resources.productStoreInterval.field"
                />
              )}

              <FieldGuesser source="allowUnpaid" />
              <DateField source="createdAt" />
            </DataGrid>
          </ReferenceManyField>
        </Tab>
      </OverflowingTabbedShowLayout>
    </ShowGuesser>
  )
}
