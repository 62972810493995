import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, useNotify } from 'react-admin'
import useApi from 'hooks/useApi'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { SubscriptionDates } from '../SubscriptionsDates'
import CancelSubscriptionModal from '../CancelSubscriptionModal'
import ShippingAddressModal from '../ShippingAddressModal'
import { formatPrice } from 'utils/string'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { AddPimcorePropositionModal } from 'components/addPimcorePropositionModal'
import { AddPimcoreBundleModal } from 'components/addPimcoreBundleModal'
import { ListingForm } from './ListingForm'
import { QuantityButtons } from 'components/quantityButtons'
import { CouponSelect } from 'components/couponSelect'

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const SubscriptionBody = styled.div`
  padding: 8px;
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  gap: 20px;
  padding: 0;
`

const Item = styled.li`
  display: flex;
  list-style: none;
  font-size: 14px;
  line-height: 19px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin: 4px;
`

const ItemName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 auto 0 16px;
  flex: 1;
`

const ItemDescription = styled.span`
  font-weight: 600;
`

const ItemHeader = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  gap: 20px;
`

const BundleProductsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 16px;
  margin-top: 10px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const DetailRow = styled.div`
  display: flex;
  gap: 12px;
  margin: 0 0 16px 0;

  & > div {
    flex: 1;
  }
`

const FutureBlock = styled.div`
  background: #f6fbf4;
  border-radius: 8px;
  border: 1px solid #e3f2c1;
  padding: 12px;
  display: inline-flex;
  flex-direction: column;
  margin: 8px 0;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`

export const EditPimcoreSubscriptionModal = ({
  onClose,
  open,
  metaData,
  setMetaData,
  subscription,
  setSubscription,
  refetchSubscription,
  subscriptionInfo,
}) => {
  const { store } = useCustomer()
  const chargeBeeWebsite = store?.chargeBeeWebsite
  const storeId = store?.id
  const notify = useNotify()
  const { api, isSubmitting } = useApi()
  const [addItemOpen, setAddItemOpen] = useState()
  const [addBundleOpen, setAddBundleOpen] = useState()
  const [oneTimeItemOpen, setOneTimeItemOpen] = useState()
  const [cancelReasonOpen, setCancelReasonOpen] = useState()
  const [subscriptionChanged, setSubscriptionChanged] = useState(false)
  const [shippingAddressOpen, setShippingAddressOpen] = useState(false)
  const [invoiceEstimate, setInvoiceEstimate] = useState()
  const [coupon, setCoupon] = useState(subscription.coupon)
  const [changingIndex, setChangingIndex] = useState(null)
  const [shouldRefetch, setShouldRefetch] = useState(false)

  useEffect(() => {
    if (shouldRefetch) {
      refetchSubscription().then(() => setChangingIndex(null))
      setShouldRefetch(false)
    }
  }, [metaData, shouldRefetch, refetchSubscription])

  useEffect(() => {
    if (!isSubmitting) setChangingIndex(null)
  }, [isSubmitting])

  useEffect(() => {
    if (open && !invoiceEstimate) {
      api.getSubscriptionEstimateInfo(subscription.internal_id).then((res) => {
        setInvoiceEstimate(res?.data?.invoice_estimate)
      })
    }
  }, [open, api, subscription, invoiceEstimate])

  const addItem = (newItem) => {
    setMetaData((cur) => {
      let items = cur.items || []

      items.push(newItem)

      return { ...cur, items }
    })
    setSubscriptionChanged(true)
    setShouldRefetch(true)
  }

  const addOneTimeItem = (newItem) => {
    api
      .addOneTimeAddonToSubscription(subscription.internal_id, newItem)
      .then(() => {
        notify('Product added to next delivery', 'info')
        onClose()
      })
  }

  const handleChangeQuantity = (newQuantity, itemIndex) => {
    setChangingIndex(itemIndex)
    setSubscriptionChanged(true)
    setMetaData((cur) => {
      let items = []
      if (newQuantity) {
        items = cur.items?.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        )
        return { ...cur, items }
      } else {
        // remove item if newQuantity is 0
        items = cur.items?.filter((_, index) => index !== itemIndex)
      }

      return { ...cur, items }
    })

    setShouldRefetch(true)
  }

  const saveChanges = useCallback(() => {
    api
      .updatePimcoreSubscription(subscription.internal_id, {
        items: metaData.items,
        coupons: coupon ? [coupon] : [],
      })
      .then(() => {
        notify('changes saved', 'info')
        onClose()
      })
    setSubscriptionChanged(false)
  }, [subscription, api, notify, onClose, metaData, coupon])

  // let bundlesCount = 0
  let interval = ''

  if (subscription?.metaData?.items) {
    for (let item of subscription.metaData.items) {
      if (item.product.products) {
        // bundlesCount++

        for (let bundleProduct of item.product.products) {
          interval = bundleProduct.interval
        }
      } else {
        interval = item.product.interval
      }
    }
  }

  const currency = store?.currency?.code

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <Header>
        <DialogTitle>Edit pimcore subscription</DialogTitle>
        <IconButton aria-label="close" size="small" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Header>

      <DialogContent>
        <SubscriptionBody>
          <SubscriptionDates subscription={subscription} />

          <h5>Plan name: {subscription.plan_name}</h5>
          {subscription.metaData ? (
            <>
              <ItemsList>
                {subscriptionInfo?.items?.map((item, itemIndex) => {
                  let itemPrice = item.product.price / 100 || 0
                  if (item.product.products) {
                    item.product.products.forEach(
                      ({ listing, quantity, bundleDiscount }) => {
                        if (!listing) return
                        const bundleItemPrice = Number(listing[0]?.price)
                        const bundleItemQuantity = quantity || 1
                        let total = bundleItemPrice * bundleItemQuantity
                        if (bundleDiscount) {
                          const onePercent = total / 100
                          total -= onePercent * bundleDiscount
                        }
                        itemPrice += total
                      }
                    )

                    itemPrice = itemPrice / 100 || 0
                  }

                  return (
                    <Item key={`${subscription.internal_id}:${itemIndex}`}>
                      <ItemHeader>
                        <QuantityButtons
                          quantity={item?.quantity}
                          onIncrease={() =>
                            handleChangeQuantity(item?.quantity + 1, itemIndex)
                          }
                          onDecrease={() =>
                            handleChangeQuantity(item?.quantity - 1, itemIndex)
                          }
                          increaseDisabled={
                            item?.quantity > 9 ||
                            isSubmitting ||
                            item.product.interval === 'SKT'
                          }
                          decreaseDisabled={
                            isSubmitting ||
                            (subscriptionInfo?.items.length === 1 &&
                              item?.quantity === 1)
                          }
                          loading={itemIndex === changingIndex}
                        />

                        {!item.product.products ? (
                          <ListingForm
                            listingId={item.product.id}
                            itemIndex={itemIndex}
                            interval={item.product.interval}
                            setMetaData={(newMetaData) => {
                              setMetaData(newMetaData)
                              setSubscriptionChanged(true)
                              setShouldRefetch(true)
                            }}
                          />
                        ) : (
                          <ItemName>
                            <ItemDescription>
                              {item.product.name}
                            </ItemDescription>
                          </ItemName>
                        )}
                        <Price>{formatPrice(itemPrice, currency)}</Price>
                      </ItemHeader>
                      {item.product.products && (
                        <BundleProductsWrapper>
                          {item.product.products.map(
                            (product, productIndex) => {
                              return (
                                <Row key={product.chargeBeeId}>
                                  <span>{product.quantity || 1} X</span>
                                  <ListingForm
                                    listingId={product.listing[0].id}
                                    itemIndex={itemIndex}
                                    productIndex={productIndex}
                                    interval={product.listing[0].interval}
                                    bundleId={item.product.id}
                                    setMetaData={(newMetaData) => {
                                      setMetaData(newMetaData)
                                      setSubscriptionChanged(true)
                                      setShouldRefetch(true)
                                    }}
                                  />
                                </Row>
                              )
                            }
                          )}
                        </BundleProductsWrapper>
                      )}
                    </Item>
                  )
                })}
              </ItemsList>

              <div style={{ width: '350px', margin: '8px 0' }}>
                <CouponSelect
                  storeId={store?.id}
                  coupon={coupon}
                  setCoupon={(newCoupon) => {
                    setCoupon(newCoupon)
                    setSubscriptionChanged(true)
                  }}
                />
              </div>

              {invoiceEstimate && (
                <FutureBlock>
                  <h3 style={{ margin: '0 0 12px 0' }}>
                    Future invoice details
                  </h3>
                  <DetailRow>
                    <div>
                      <b>Plan amount: </b>
                      {!!invoiceEstimate.total && (
                        <Price>
                          {formatPrice(
                            invoiceEstimate.total / 100,
                            subscription.currency_code ||
                              subscription.base_currency_code
                          )}
                        </Price>
                      )}
                    </div>
                  </DetailRow>
                  <DetailRow>
                    Plan name:{' '}
                    {
                      invoiceEstimate.line_items.find(
                        (item) => item.entity_type === 'plan'
                      )?.description
                    }
                  </DetailRow>
                  <DetailRow>
                    <div>
                      <b>Future addons:</b>
                      {invoiceEstimate.line_items
                        .filter((item) => item.entity_type !== 'plan')
                        .map((item, key) => {
                          return (
                            <Item key={`future:${key}`}>
                              <BundleProductsWrapper>
                                <ItemName key={`product${key}`}>
                                  <ItemDescription>
                                    {item.quantity} x {item.description} -{' '}
                                    {formatPrice(
                                      item.unit_amount / 100,
                                      subscription.currency_code ||
                                        subscription.base_currency_code
                                    )}
                                  </ItemDescription>
                                </ItemName>
                              </BundleProductsWrapper>
                            </Item>
                          )
                        })}
                    </div>
                  </DetailRow>
                </FutureBlock>
              )}
            </>
          ) : (
            <ItemsList>
              {!!subscription.addons &&
                Object.values(subscription.addons).map((addon) => (
                  <Item key={addon.id}>
                    <ItemHeader>
                      {addon?.quantity} X
                      <ItemName>
                        <ItemDescription>{addon.name}</ItemDescription>
                      </ItemName>
                      <Price>
                        {formatPrice(
                          addon.unit_price / 100,
                          subscription.currency_code ||
                            subscription.base_currency_code
                        )}
                      </Price>
                    </ItemHeader>
                  </Item>
                ))}
            </ItemsList>
          )}

          <Row>
            {subscription.metaData && (
              <>
                <Button
                  color="primary"
                  onClick={() => setAddItemOpen(true)}
                  size="small"
                  label="Add product"
                />
                <Button
                  color="primary"
                  onClick={() => setAddBundleOpen(true)}
                  size="small"
                  label="Add bundle"
                  disabled={interval !== '90D'}
                />

                <Button
                  variant="outlined"
                  onClick={saveChanges}
                  size="small"
                  label="Save changes"
                  disabled={
                    !subscriptionChanged ||
                    !subscriptionInfo?.items ||
                    subscriptionInfo?.items.length === 0
                  }
                />

                <Button
                  color="primary"
                  onClick={() => setOneTimeItemOpen(true)}
                  size="small"
                  label="Add one-time product"
                />

                <Button
                  color="primary"
                  onClick={() => setShippingAddressOpen(true)}
                  size="small"
                  label="Edit address"
                />
              </>
            )}
            {!!(subscription.id && chargeBeeWebsite) && (
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  window.open(
                    `https://${chargeBeeWebsite}.chargebee.com/admin-console/subscriptions/${subscription.id}`,
                    '_blank'
                  )
                }}
                label="Edit in Chargebee"
              />
            )}
            {subscription.status !== 'cancelled' && (
              <Button
                color="primary"
                label="Cancel Subscription"
                onClick={() => setCancelReasonOpen(true)}
              />
            )}
          </Row>

          <AddPimcorePropositionModal
            open={addItemOpen}
            onClose={() => setAddItemOpen(false)}
            addItem={addItem}
            intervalSlug={interval}
          />
          <AddPimcorePropositionModal
            open={oneTimeItemOpen}
            onClose={() => setOneTimeItemOpen(false)}
            addItem={addOneTimeItem}
            intervalSlug="SGL"
          />
          <AddPimcoreBundleModal
            open={addBundleOpen}
            onClose={() => setAddBundleOpen(false)}
            addItem={addItem}
          />
          <CancelSubscriptionModal
            subscriptionId={subscription.internal_id}
            open={cancelReasonOpen}
            onClose={() => setCancelReasonOpen(false)}
            onEndEdit={onClose}
          />
          <ShippingAddressModal
            open={shippingAddressOpen}
            storeId={storeId}
            subscription={subscription}
            setSubscription={setSubscription}
            onClose={() => setShippingAddressOpen(false)}
          />
        </SubscriptionBody>
      </DialogContent>
    </Dialog>
  )
}
