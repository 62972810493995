export default {
  resources: {
    // Base
    back: 'Back',
    empty: 'N/A',
    id: 'ID',
    emptyList: 'No items available',
    updatedAt: 'Updated At',
    createdAt: 'Updated At',
    active: 'Active',
    domain: 'Domain',

    // pages
    dashboard: {
      menu: 'Dashboard',
      title: 'Dashboard',
    },

    montaStatus: {
      field: 'Monta status',
    },

    exactOnline: {
      menu: 'Exact Online',
      title: 'Exact Online',
      login: 'Login',
      reLogin: 'Re-Login',
      lastLogin: 'Last Login:',
    },

    // Resources
    user: {
      created: 'User has been created successfully',
      updated: 'User has been updated successfully',
      menu: 'Users',
    },
    group: {
      created: 'Group has been created successfully',
      updated: 'Group has been updated successfully',
      store: 'Store',
      image: 'Default image',
      description: 'Description',
      gallery: 'Gallery',
      edit: 'Edit Group',
      menu: 'WordPress',
      subMenu: 'Products',
      field: 'Group',
      plans: 'Related Plans',
      list: {
        plans: 'Select Group has no related Plans',
      },
    },
    groupImage: {
      wcEnabled: 'Enabled in Wordpress',
      preview: 'Preview',
      created: 'Image has been created successfully',
      add: 'Add New Image',
      back: 'Back to the gallery',
      list: {
        empty: 'No images available',
        emptyHelp: 'Click on the button below to add a new image',
      },
      file: {
        helper: 'Image jpeg/png, max upload size is 5000kb',
      },
      delete: {
        confirm: 'Delete group image.',
      },
    },
    currency: {
      created: 'Currency has been created successfully',
      updated: 'Currency has been updated successfully',
      menu: 'Currencies',
      field: 'Currency',
    },
    product: {
      menu: 'Products',
      field: 'Product',
      edit: 'Edit Product',
      updated: 'Product has been updated successfully',
      productStores: 'Related Product Stores',
      relatedProductStoresIntervals: 'Related Product Stores',
      productStoresIntervals: 'Product Stores Intervals',
      groups: 'Wordpress products',
      plans: 'Plans',
      addons: 'Addons',
      list: {
        productStore: 'Select Product has no related Product Stores',
      },
      sync: 'Products Sync',
    },
    chargeBeeProduct: {
      menu: 'Chargebee Products',
    },
    productStore: {
      menu: 'Products Stores',
      field: 'Product Store',
      fieldSku: 'Product Sku',
      edit: 'Edit Product Store',
      updated: 'Product Store has been updated successfully',
      intervals: 'Related Product Intervals',
      relatedIntervals: 'Related Product Store Intervals',
      list: {
        productStoreInterval:
          'Select Product Store has no related Product Intervals',
      },
    },
    productStoreInterval: {
      menu: 'Product Store Intervals',
      field: 'Product Store Interval',
      pricingModel: 'Pricing Model',
      empty: 'No',
      edit: 'Edit Product Interval',
      updated: 'Product Interval has been updated successfully',
      enabledInPortal: 'Displayed in Customer Portal',
    },
    store: {
      menu: 'Stores',
      field: 'Store',
      wcUrl: 'WooCommerce Url',
      wcKey: 'WooCommerce Key',
      wcSecret: 'WooCommerce Secret',
      chargeBeeKey: 'ChargeBee Key',
      chargeBeePublishableKey: 'ChargeBee Publishable Key',
      chargeBeeGatewayIdAdyen: 'ChargeBee Gateway Id Adyen',
      chargeBeeWebsite: 'ChargeBee Website',
      chargeBeeTestKey: 'ChargeBee Test Key',
      chargeBeeTestWebsite: 'ChargeBee Test Website',
      name: 'Name',
      updated: 'Store has been updated successfully',
      edit: 'Edit Store',
      orders: 'Related Orders',
      countryCode: 'Country Code',
      costCenter: 'Cost Center',
      warehouse: 'Warehouse',
      needsProForma: 'ProForma',
      montaPriorityShipping48h: 'Monta Priority 48h',
      allowCustomerCancellation: 'Allow cancel subscription [Subscriptions]',
      allowSubscriptionCancellationInPersonalPlan:
        'Allow cancel subscription [Personal plan]',
      allowCustomerPayment: 'Allow customer payment',
      invoiceNotes: 'Invoice Notes',
      smtpUser: 'SMTP User',
      smtpPassword: 'SMTP Password',
      infoEmail: 'Info Email',
      additionalFields: 'my FitForMe & checkout',
      customerAddressLine1: 'Address line 1',
      customerAddressLine2: 'Address line 2',
      customerAddressLine3: 'Address line 3',
      customerState: 'State',
      customerClinic: 'Clinic',
      customerSurgeryType: 'Surgery type',
      customerSurgeryDate: 'Surgery date',
      customerSurgeon: 'Surgeon',
      customerServiceFAQUrl: 'FAQ URL',
      customerServiceContactUrl: 'Contact URL',
      customerAllowAddClinic: 'Allow add clinic',
      allowInvoice: 'Allow creating invoice from session',
      allowContactForm: 'Allow contact form',
    },
    interval: {
      menu: 'Configuration',
      field: 'Interval',
    },
    adyenTransaction: {
      menu: 'Adyen Transactions',
    },
    chargebeeTransaction: {
      menu: 'Chargebee Transactions',
    },
    plan: {
      menu: 'Plans',
      field: 'Plan',
      updated: 'Plan has been updated successfully',
      edit: 'Edit Plan',
      addons: 'Related Addons',
      list: {
        addons: 'Select Plans has no related Addons',
        enabledInPortal: 'Customer Portal',
        enabledInHostedPages: 'Checkout',
      },
      editPlan: {
        enabledInPortal: 'Displayed in Customer Portal',
        enabledInHostedPages: 'Checkout using drop-in script',
      },
    },
    addon: {
      menu: 'Addons',
      field: 'Addon',
      created: 'Addon was created successfully',
      plan: {
        label: 'Plan (Charge Bee)',
      },
      updated: 'Addon has been updated successfully',
    },
    order: {
      menu: 'Orders',
      field: 'Order',
      chargeBee: 'ChargeBee ID',
      chargeBeeStatus: 'ChargeBee Status',
      fulfillmentStatus: 'Fulfillment Status',
      monta: 'WebShop order ID',
      status: 'Status',
      show: 'View Order',
      edit: 'Edit Order',
      history: 'Order History',
      fulfillmentHistory: 'Fulfillment History',
      montaOccurredAt: 'Event Occurred At',
      montaCreatedAt: 'Event Created At',
      id: 'Order ID',
      createdAt: 'Placed At',
      invoices: 'Invoices',
      reship: 'Reship',
      tasks: 'Tasks',
      orderResendReasons: 'Order Resend Reasons',
      invoice: 'invoice',
      displayName: 'Display Name',
      updated: 'Great! Order was updated successfully.',
      cards: {
        order: 'Order Details',
        chargeBee: 'ChargeBee Details',
        fulfillment: 'Fulfillment Details',
        chargeBeeLines: 'ChargeBee Order Lines',
        fulfillmentLines: 'Fulfillment Order Lines',
        shipping: 'Shipping Details',
        billing: 'Billing Details',
        dreamlogistic: 'Fulfillment Order Lines',
      },
      lines: {
        id: 'ID',
        description: 'Description',
        type: 'Type',
        quantity: 'Quantity',
        sku: 'SKU',
        city: 'City',
        street: 'Street',
        state: 'State',
        postalCode: 'Zip Code',
        countryCode: 'Country Code',
        firstName: 'First Name',
        lastName: 'LastName',
        emailAddress: 'Email',
        phoneNumber: 'Phone',
        amount: 'Amount',
        unitPrice: 'Unit price',
        tax: 'tax',
        houseNumber: 'House Number',
        houseNumberAddition: 'House Number Addition',
      },
      table: {
        orderNumber: 'Order number',
      },
    },
    customer: {
      menu: 'Customers',
      field: 'Customer',
      searchByAll: 'Name/Email/Phone/Zip (🐌)',
    },
    events: {
      menu: 'Events',
      field: 'Events',
    },
    freshDeskTickets: {
      menu: 'Freshdesk Tickets',
      field: 'Freshdesk Tickets',
    },
    npfStock: {
      menu: 'NPF stock data',
      field: 'NPF stock data',
    },
    logistics: {
      menu: 'Logistics',
    },
    dreamlogistics: {
      menu: 'DL stock data',
    },
    freshdeskSubjects: {
      menu: 'Freshdesk Subjects',
    },
    freshdesk: {
      menu: 'Freshdesk',
    },
    subscription: {
      menu: 'Subscriptions',
      field: 'Subscription',
    },
    orderStatus: {
      field: 'Order status',
    },
    fulfillmentStatus: {
      field: 'Fulfillment status',
    },
    chargeBeeStatus: {
      field: 'ChargeBee status',
    },
    invoice: {
      download: 'Download Link',
      validTill: 'Link Valid Till',
      menu: 'Invoices',
    },
    task: {
      handler: 'Handler',
      status: 'Status',
      input: 'Input',
      output: 'Output',
      menu: 'Tasks',
      processedAt: 'Processed At'
    },
    error: {
      menu: 'Fulfillment Errors',
    },
    montaStocks: {
      menu: 'Supply Chain',
    },
    finance: {
      menu: 'Finance',
    },
    exactApi: {
      menu: 'Exact API',
    },
    cbSubscriptions: {
      menu: 'CB Subscriptions',
    },
    notification: {
      menu: 'Notification',
      editDescription: 'Edit description',
      editReceivers: 'Edit receivers',
    },
    clinic: {
      menu: 'Hospitals',
    },
    variation: {
      menu: 'Variations',
      valueCreated: 'New variation property value created',
    },
    wpProduct: {
      menu: 'WP Product v2',
    },
    bundle: {
      menu: 'Bundles',
    },
    country: {
      menu: 'Countries',
    },
    cluster: {
      menu: 'Clusters',
    },
    region: {
      menu: 'Regions',
    },
    cancelReason: {
      menu: 'Cancel reasons',
      label: 'Cancel reason',
      parent: 'Parent',
    },
    reason: {
      menu: 'Reasons',
    },
    postponeReason: {
      menu: 'Postpone Reasons',
    },
    logEntry: {
      menu: 'Log Entries',
    },
    shippingCountry: {
      menu: 'Checkout & MyFFM',
      subMenu: 'Shipping countries',
    },
    shippingCountryStore: {
      menu: 'Shipping country stores',
    },
    customerSource: {
      menu: 'Customer sources',
    },
    customerSourceStore: {
      menu: 'Customer source stores',
    },
    surgeryTypeOption: {
      menu: 'Surgery type',
    },
    surgeryTypeOptionStore: {
      menu: 'Surgery type stores',
    },
    gender: {
      menu: 'Genders',
    },
    genderStore: {
      menu: 'Gender stores',
    },
    checkoutSession: {
      menu: 'Checkout Sessions',
    },
    woo: {
      scheduled:
        'Awesome! Document has been scheduled for a sync successfully.',
      sync: 'Woo Sync',
    },
    adyenNotifications: {
      menu: 'Adyen Notifications',
    },
    callReason: {
      menu: 'Call Reasons',
      field: 'Call Reason',
    },
    creditNote: {
      menu: 'Credit Notes',
    },
    pimcore: {
      menu: 'Pimcore',
    },
    pimcoreProduct: {
      menu: 'Warehouse Products',
    },
    pimcoreProposition: {
      menu: 'Propositions',
    },
    pimcorePropositionFacade: {
      menu: 'Proposition Interfaces',
    },
    appointment: {
      menu: 'Appointments',
    },
    productFacade: {
      menu: 'Product Interfaces',
    },
    facade: {
      menu: 'Pimcore Interfaces',
    },
    bundleStore: {
      menu: 'Bundle up./down.',
    },
  },
}
