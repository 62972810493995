import React from 'react'
import { ShowGuesser, ReferenceListFilterField } from 'theme/components'
import { TextField } from 'react-admin'

export const PimcorePropositionFacadeShow = (props) => {
  return (
    <ShowGuesser {...props} hasEdit={false}>
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />

      <TextField source="productStoreInterval.id" label="PSI id" />
      <TextField source="productStoreInterval.name" label="PSI name" />
      <TextField source="proposition.id" label="Proposition id" />
      <TextField source="proposition.name" label="Proposition name" />
      <TextField
        source="proposition.chargeBeeId"
        label="Proposition ChargeBeeId"
      />

      <ReferenceListFilterField
        label="resources.store.field"
        listUri="/stores"
        titlePath="store.name"
        valuePath="store.name"
      />
    </ShowGuesser>
  )
}
