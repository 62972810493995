import Icon from '@material-ui/icons/AssignmentTurnedIn'
import { ROLES } from 'utils/user'

import { TaskList } from './TaskList'
import { TaskShow } from './TaskShow'

export default {
  list: TaskList,
  show: TaskShow,
  icon: Icon,
  permissions: [
    ROLES.ROLE_ADMIN,
    ROLES.ROLE_PRODUCT,
    ROLES.ROLE_ORDER_MANAGEMENT,
  ],
}
