import AccountTreeIcon from '@material-ui/icons/AccountTree'

import { ClusterList } from './ClusterList'
import { ClusterCreate } from './ClusterCreate'
import { ClusterEdit } from './ClusterEdit'
import { ROLES } from 'utils/user'

export default {
  list: ClusterList,
  create: ClusterCreate,
  edit: ClusterEdit,
  icon: AccountTreeIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
