import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, useNotify } from 'react-admin'
import useApi from 'hooks/useApi'
import {
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import { SubscriptionDates } from '../SubscriptionsDates'
import CancelSubscriptionModal from '../CancelSubscriptionModal'
import ShippingAddressModal from '../ShippingAddressModal'
import { formatPrice } from 'utils/string'
import { VariationForm } from './VariationForm'
import { AddPsiItemModal } from 'components/addPsiItemModal'
import { AddPsiBundleModal } from 'components/addPsiBundleModal'
import { useCustomer } from 'components/pages/customer/contexts/customer'
import { QuantityButtons } from 'components/quantityButtons'
import { UpgradeDowngrade } from './UpgradeDowngrade'
import { CouponSelect } from 'components/couponSelect'

const Price = styled.span`
  font-weight: 600;
  margin-left: auto;
`

const SubscriptionBody = styled.div`
  padding: 8px;
`

const ItemsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 10px 0 10px 0;
  gap: 20px;
  padding: 0;
`

const Item = styled.li`
  display: flex;
  list-style: none;
  font-size: 14px;
  line-height: 19px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  opacity: ${({ mandatory }) => (mandatory ? 0.53 : 1)};
  pointer-events: ${({ mandatory }) => (mandatory ? 'none' : 'inherit')};
  margin: 4px;
`

const ItemName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0 auto 0 16px;
  flex: 1;
`

const ItemDescription = styled.span`
  font-weight: 600;
`

const ItemHeader = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
`

const BundleProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 0 0 16px;
  margin-top: 10px;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Mandatory = styled.span`
  padding: 4px;
  background: gray;
  border-radius: 8px;
  color: white;
`

const DetailRow = styled.div`
  display: flex;
  gap: 12px;
  margin: 0 0 16px 0;

  & > div {
    flex: 1;
  }
`

const FutureBlock = styled.div`
  background: #f6fbf4;
  border-radius: 8px;
  border: 1px solid #e3f2c1;
  padding: 12px;
  display: inline-flex;
  flex-direction: column;
  margin: 8px 0;
`

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
`

export const EditSubscriptionModal = ({
  onClose,
  open,
  metaData,
  setMetaData,
  subscription,
  setSubscription,
  refetchSubscription,
}) => {
  const { store } = useCustomer()
  const storeId = store?.id
  const chargeBeeWebsite = store?.chargeBeeWebsite
  const notify = useNotify()
  const { api, isSubmitting } = useApi()
  const [addItemOpen, setAddItemOpen] = useState()
  const [addBundleOpen, setAddBundleOpen] = useState()
  const [cancelReasonOpen, setCancelReasonOpen] = useState()
  const [subscriptionChanged, setSubscriptionChanged] = useState(false)
  const [shippingAddressOpen, setShippingAddressOpen] = useState(false)
  const [invoiceEstimate, setInvoiceEstimate] = useState()
  const [initialBundle, setInitialBundle] = useState()
  const [initialProducts, setInitialProducts] = useState([])
  const [bundleToBeReplaced, setBundleToBeReplaced] = useState()
  const [coupon, setCoupon] = useState(subscription.coupon)
  const [changingIndex, setChangingIndex] = useState(null)
  const [shouldRefetch, setShouldRefetch] = useState(false)

  useEffect(() => {
    if (shouldRefetch) {
      refetchSubscription().then(() => setChangingIndex(null))
      setShouldRefetch(false)
    }
  }, [metaData, shouldRefetch, refetchSubscription])

  useEffect(() => {
    if (open && !invoiceEstimate) {
      api.getSubscriptionEstimateInfo(subscription.internal_id).then((res) => {
        setInvoiceEstimate(res?.data?.invoice_estimate)
      })
    }
  }, [open, api, subscription, invoiceEstimate])

  const addItem = (newItem, toBeReplaced) => {
    setMetaData((cur) => {
      let items = [...cur.items] || []
      if (toBeReplaced) {
        items = items.map((item) => {
          if (item.id === toBeReplaced) {
            return { ...newItem, quantity: item.quantity }
          }

          return item
        })
      } else {
        items.push(newItem)
      }

      if (newItem.mandatoryVariation) {
        items.push({
          ...newItem,
          variationId: newItem.mandatoryVariation,
          mandatoryVariation: undefined,
          mandatory: true,
        })
      }

      return { ...cur, items }
    })
    setSubscriptionChanged(true)
    setShouldRefetch(true)
  }

  useEffect(() => {
    if (!isSubmitting) setChangingIndex(null)
  }, [isSubmitting])

  const handleChangeQuantity = (newQuantity, itemIndex) => {
    setChangingIndex(itemIndex)
    setSubscriptionChanged(true)
    setMetaData((cur) => {
      let items = []
      if (newQuantity) {
        items = cur.items?.map((item, index) =>
          index === itemIndex ? { ...item, quantity: newQuantity } : item
        )
        return { ...cur, items }
      } else {
        // remove item if newQuantity is 0
        items = cur.items?.filter((_, index) => index !== itemIndex)
      }

      return { ...cur, items }
    })

    setShouldRefetch(true)
  }

  const saveChanges = useCallback(() => {
    api
      .updateChargebeeSubscription(subscription.internal_id, {
        addons: subscription.addons,
        metaData: JSON.stringify(subscription.metaData),
        replaceAddonList: true,
        changeOption: 'end_of_term',
        coupons: coupon ? [coupon] : [],
      })
      .then(() => {
        notify('changes saved', 'info')
        onClose()
      })
    setSubscriptionChanged(false)
  }, [subscription, api, notify, onClose, coupon])

  let pricingModel = subscription.pricing_model

  if (!subscription.pricing_model) {
    const perUnitProducts = subscription.metaData?.products?.filter(
      (product) => product.pricingModel === 'per_unit'
    )

    if (perUnitProducts?.length === subscription.products?.length) {
      pricingModel = 'per_unit'
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <Header>
        <DialogTitle>Edit subscription</DialogTitle>
        <IconButton aria-label="close" size="small" onClick={() => onClose()}>
          <CloseIcon />
        </IconButton>
      </Header>

      <DialogContent>
        <SubscriptionBody>
          <SubscriptionDates subscription={subscription} />

          <h5>Plan name: {subscription.plan_name}</h5>
          {subscription.metaData ? (
            <>
              <ItemsList>
                {subscription.metaData.items?.map((item, itemIndex) => {
                  let currency = item.currency
                  let itemPrice = item.priceWithDiscount
                  if (item.products) {
                    currency = item.products[0]?.currency
                    itemPrice = item.products.reduce(
                      (total, currentProduct) =>
                        total + Number(currentProduct.priceWithDiscount),
                      0
                    )
                  }

                  return (
                    <Item key={`${subscription.internal_id}:${itemIndex}`}>
                      <ItemHeader>
                        {pricingModel === 'per_unit' ? (
                          <QuantityButtons
                            onIncrease={() =>
                              handleChangeQuantity(
                                item?.quantity + 1,
                                itemIndex
                              )
                            }
                            onDecrease={() =>
                              handleChangeQuantity(
                                item?.quantity - 1,
                                itemIndex
                              )
                            }
                            increaseDisabled={
                              item?.quantity > 9 ||
                              isSubmitting ||
                              item?.interval === 'SKT'
                            }
                            decreaseDisabled={isSubmitting}
                            quantity={item?.quantity}
                            loading={itemIndex === changingIndex}
                          />
                        ) : (
                          <FormControlLabel
                            style={{ marginRight: '10px' }}
                            control={
                              <Checkbox
                                checked={!!item?.quantity}
                                onChange={() =>
                                  handleChangeQuantity(
                                    item?.quantity ? 0 : 1,
                                    itemIndex
                                  )
                                }
                              />
                            }
                          />
                        )}

                        {!item.products ? (
                          <VariationForm
                            variationId={item.variationId}
                            itemIndex={itemIndex}
                            setMetaData={(newMetaData) => {
                              setMetaData(newMetaData)
                              setSubscriptionChanged(true)
                              setShouldRefetch(true)
                            }}
                            storeId={storeId}
                            intervalSlug={subscription.metaData?.interval}
                          />
                        ) : (
                          <ItemName>
                            <div>
                              <ItemDescription>{item.name} </ItemDescription>
                              {item.mandatory && (
                                <Mandatory>mandatory</Mandatory>
                              )}
                            </div>
                            <span>{item.productName}</span>
                          </ItemName>
                        )}
                        <Price>{formatPrice(itemPrice, currency)}</Price>
                      </ItemHeader>
                      {item.products && (
                        <>
                          <BundleProductsWrapper>
                            {item.products.map((product, productIndex) => {
                              return (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                  }}
                                  key={product.chargeBeeId}
                                >
                                  <VariationForm
                                    variationId={product.variationId}
                                    itemIndex={itemIndex}
                                    productIndex={productIndex}
                                    setMetaData={(newMetaData) => {
                                      setMetaData(newMetaData)
                                      setSubscriptionChanged(true)
                                      setShouldRefetch(true)
                                    }}
                                    storeId={storeId}
                                    intervalSlug={
                                      subscription.metaData?.interval
                                    }
                                  />
                                  {product.mandatory ? (
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setSubscriptionChanged(true)
                                        setMetaData((cur) => {
                                          let items = cur.items.map(
                                            (item, i) => {
                                              if (i === itemIndex) {
                                                item.products = item.products?.filter(
                                                  (_, j) => j !== productIndex
                                                )
                                              }

                                              return { ...item }
                                            }
                                          )
                                          return { ...cur, items }
                                        })

                                        setShouldRefetch(true)
                                      }}
                                    >
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                  ) : null}
                                </div>
                              )
                            })}
                          </BundleProductsWrapper>
                          <UpgradeDowngrade
                            storeId={storeId}
                            bundleId={item.id}
                            changeTo={(bundleId) => {
                              setInitialBundle(bundleId)
                              setInitialProducts(item.products)
                              setBundleToBeReplaced(item.id)
                              setAddBundleOpen(true)
                            }}
                          />
                        </>
                      )}
                    </Item>
                  )
                })}
              </ItemsList>

              <div style={{ width: '350px', margin: '8px 0' }}>
                <CouponSelect
                  storeId={store?.id}
                  coupon={coupon}
                  setCoupon={(newCoupon) => {
                    setCoupon(newCoupon)
                    setSubscriptionChanged(true)
                  }}
                />
              </div>

              {invoiceEstimate && (
                <FutureBlock>
                  <h3 style={{ margin: '0 0 12px 0' }}>
                    Future invoice details
                  </h3>
                  <DetailRow>
                    <div>
                      <b>Plan amount: </b>
                      {!!invoiceEstimate.total && (
                        <Price>
                          {formatPrice(
                            invoiceEstimate.total / 100,
                            subscription.currency_code ||
                              subscription.base_currency_code
                          )}
                        </Price>
                      )}
                    </div>
                  </DetailRow>
                  <DetailRow>
                    Plan name:{' '}
                    {
                      invoiceEstimate.line_items.find(
                        (item) => item.entity_type === 'plan'
                      )?.description
                    }
                  </DetailRow>
                  <DetailRow>
                    <div>
                      <b>Future addons:</b>
                      {invoiceEstimate.line_items
                        .filter((item) => item.entity_type !== 'plan')
                        .map((item, key) => {
                          return (
                            <Item key={`future:${key}`}>
                              <BundleProductsWrapper>
                                <ItemName key={`product${key}`}>
                                  <ItemDescription>
                                    {item.quantity} x {item.description} -{' '}
                                    {formatPrice(
                                      item.unit_amount / 100,
                                      subscription.currency_code ||
                                        subscription.base_currency_code
                                    )}
                                  </ItemDescription>
                                </ItemName>
                              </BundleProductsWrapper>
                            </Item>
                          )
                        })}
                    </div>
                  </DetailRow>
                </FutureBlock>
              )}
            </>
          ) : (
            <ItemsList>
              {!!subscription.addons &&
                Object.values(subscription.addons).map((addon) => (
                  <Item key={addon.id}>
                    <ItemHeader>
                      {addon?.quantity} X
                      <ItemName>
                        <ItemDescription>{addon.name}</ItemDescription>
                      </ItemName>
                      <Price>
                        {formatPrice(
                          addon.unit_price / 100,
                          subscription.currency_code ||
                            subscription.base_currency_code
                        )}
                      </Price>
                    </ItemHeader>
                  </Item>
                ))}
            </ItemsList>
          )}

          <Buttons>
            {subscription.metaData && (
              <>
                <Button
                  color="primary"
                  onClick={() => setAddItemOpen(true)}
                  size="small"
                  label="Add product"
                />
                <Button
                  color="primary"
                  onClick={() => setAddBundleOpen(true)}
                  size="small"
                  label="Add bundle"
                />

                <Button
                  variant="outlined"
                  onClick={saveChanges}
                  size="small"
                  label="Save changes"
                  disabled={
                    !subscriptionChanged ||
                    subscription.metaData.items.length === 0
                  }
                />

                <Button
                  color="primary"
                  onClick={() => setShippingAddressOpen(true)}
                  size="small"
                  label="Edit address"
                />
              </>
            )}
            {!!(subscription.id && chargeBeeWebsite) && (
              <Button
                color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  window.open(
                    `https://${chargeBeeWebsite}.chargebee.com/admin-console/subscriptions/${subscription.id}`,
                    '_blank'
                  )
                }}
                label="Edit in Chargebee"
              />
            )}
            {subscription.status !== 'cancelled' && (
              <Button
                color="primary"
                label="Cancel Subscription"
                onClick={() => setCancelReasonOpen(true)}
              />
            )}
          </Buttons>

          <AddPsiItemModal
            open={addItemOpen}
            onClose={() => setAddItemOpen(false)}
            addItem={addItem}
            storeId={storeId}
            intervalSlug={subscription.metaData?.interval}
            subscription={subscription}
          />
          <AddPsiBundleModal
            open={addBundleOpen}
            storeId={storeId}
            intervalSlug={subscription.metaData?.interval}
            onClose={() => {
              setAddBundleOpen(false)
              setInitialBundle(null)
              setInitialProducts(null)
            }}
            addItem={(item) => {
              if (bundleToBeReplaced) {
                addItem(item, bundleToBeReplaced)
                setInitialBundle(null)
                setBundleToBeReplaced(null)
              } else {
                addItem(item)
              }
            }}
            initialBundle={initialBundle}
            initialProducts={initialProducts}
          />
          <CancelSubscriptionModal
            subscriptionId={subscription.internal_id}
            open={cancelReasonOpen}
            onClose={() => setCancelReasonOpen(false)}
            onEndEdit={onClose}
          />
          <ShippingAddressModal
            open={shippingAddressOpen}
            storeId={storeId}
            subscription={subscription}
            setSubscription={setSubscription}
            onClose={() => setShippingAddressOpen(false)}
          />
        </SubscriptionBody>
      </DialogContent>
    </Dialog>
  )
}
