import React, { useState } from 'react'
import {
  required,
  TextInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
} from 'react-admin'
import { CreateGuesser } from 'theme/components'
import { FormControlLabel, Switch } from '@material-ui/core'

export const WPProductCreate = (props) => {
  const [isVariable, setIsVariable] = useState(true)

  return (
    <CreateGuesser {...props} variant="outlined" redirect="list">
      <FormControlLabel
        control={
          <Switch
            checked={isVariable}
            onChange={() => setIsVariable((val) => !val)}
            name="isVariable"
            color="primary"
          />
        }
        label="Variable Product"
      />

      <TextInput
        source="name"
        label="Name of WP product"
        validate={[required()]}
      />
      {isVariable ? (
        <ReferenceInput
          source="property"
          reference="variation-properties"
          format={(v) => (v ? v['@id'] || v : v)}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      ) : null}
      <BooleanInput source="bundleOnly" />
      <BooleanInput source="internal" />
    </CreateGuesser>
  )
}
