import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  Button,
  useRefresh,
  useNotify,
  SelectInput,
  SimpleForm,
  Toolbar,
  SaveButton,
  required,
  ReferenceInput,
} from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import useApi from 'hooks/useApi'
import { getNumberFromStringId } from 'utils/string'

const ToolbarCopyButton = styled(SaveButton)`
  margin-left: 20px;
`

const CopyPlanToolbar = ({ invalid, handleClose, ...props }) => (
  <Toolbar {...props}>
    <Button
      label="Cancel"
      onClick={handleClose}
      size="medium"
      color="secondary"
      fullWidth
    />
    <ToolbarCopyButton
      label="Copy"
      icon={<FileCopyIcon />}
      redirect={false}
      submitOnEnter={false}
      variant="outlined"
      fullWidth
      disabled={invalid}
    />
  </Toolbar>
)

const CopyPlanButton = (props) => {
  const { record } = props
  const { api, isSubmitting, handleError } = useApi()
  const [isOpen, setIsOpen] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()

  const handleCopy = useCallback(
    async ({ productStoreInterval }) => {
      try {
        const productStoreIntervalId = getNumberFromStringId(
          productStoreInterval
        )

        const new_plan = await api.clonePlan(record.originId, {
          productStoreInterval: {
            id: productStoreIntervalId,
          },
        })

        if (new_plan) {
          refresh()
          notify(`New plan created`)
        }

        setIsOpen(false)
      } catch (error) {
        handleError(error)
      }
    },
    [api, handleError, record, notify, refresh]
  )

  return (
    <>
      <Button
        color="primary"
        onClick={() => setIsOpen(true)}
        size="small"
        label="Copy"
        variant="contained"
      >
        <FileCopyIcon />
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Please select product store interval</DialogTitle>
        <SimpleForm
          variant="outlined"
          save={handleCopy}
          saving={isSubmitting}
          redirect={false}
          toolbar={<CopyPlanToolbar handleClose={() => setIsOpen(false)} />}
          validate={({ productStoreInterval }) =>
            productStoreInterval ? {} : { productStoreInterval: 'required' }
          }
        >
          <ReferenceInput
            source="productStoreInterval"
            reference="product-store-intervals"
            label="Product store interval"
          >
            <SelectInput optionText="name" validation={required()} />
          </ReferenceInput>
        </SimpleForm>
      </Dialog>
    </>
  )
}

export default CopyPlanButton
