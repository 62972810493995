import React, { useCallback, useEffect, useState } from 'react'
import {
  FunctionField,
  Button,
  useNotify,
  TextField,
  Tab,
  ReferenceManyField,
  Pagination,
  Confirm,
} from 'react-admin'
import { Link } from 'react-router-dom'
import { ReferenceListFilterField, DateField } from 'theme/components'
import { formatPrice } from 'utils/string'
import { ShowGuesser } from '@api-platform/admin'
import { OrderLinesTable } from '../order/card/ChargeBeeLinesCard'
import styled from 'styled-components'
import useApi from 'hooks/useApi'
import { getNumberFromStringId } from 'utils/string'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'
import { DataGrid } from 'theme/components'
import { transactionTypes } from 'components/pages/transaction/TransactionList'
import { formatDate } from 'utils/string'

const Wrapper = styled.div`
  .MuiFormControl-marginDense {
    width: 100% !important;
  }
`

const CreditNoteIssued = styled.div`
  color: #ff0000;
  border: 2px solid #ff0000;
  border-radius: 7px;
  padding: 3px 10px;
  margin-bottom: 15px;
  display: inline-block;
`

const CreateCreditNoteButton = ({ record }) => (
  <Button
    style={{ margin: '12px 0' }}
    color="primary"
    variant="contained"
    size="medium"
    component={Link}
    to={{
      pathname: '/credit-notes/create',
      search: `?invoiceId=${record?.originId}`,
    }}
    label="Create credit note"
  />
)

const CollectNowButton = ({ record }) => {
  const { api, isSubmitting } = useApi()
  const notify = useNotify()
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)

  const statusSet = new Set(['not_paid', 'payment_due', 'posted'])

  const handleConfirm = () => {
    api.collectInvoiceNow(record.originId).then(() => {
      notify('Invoice collection requested')
      localStorage.setItem(
        `collect_invoice_${record.originId}`,
        formatDate(new Date())
      )
      setConfirmDialogOpen(false)
    })
  }

  const collectInvoiceStorage = localStorage.getItem(
    `collect_invoice_${record.originId}`
  )
  const isClickedToday = collectInvoiceStorage
    ? collectInvoiceStorage === formatDate(new Date())
    : false

  if (!statusSet.has(record.chargeBeeStatus)) return null

  return (
    <>
      <Button
        style={{ margin: '12px 0' }}
        color="primary"
        variant="outlined"
        size="medium"
        onClick={() => (!isClickedToday ? setConfirmDialogOpen(true) : null)}
        disabled={isSubmitting || !record?.originId || isClickedToday}
        label="Collect invoice now"
      />
      <Confirm
        isOpen={confirmDialogOpen}
        loading={isSubmitting}
        title="You will now collect a payment"
        content="By clicking confirm you will charge the customer the open amount
        immediately. Only press confirm if that is what you want to do.
        Press cancel if you would like to return to the invoice."
        onConfirm={() => handleConfirm()}
        onClose={() => setConfirmDialogOpen(false)}
      />
    </>
  )
}

export const InvoiceShow = (props) => {
  const { api, handleError } = useApi()
  const [showDetails, setShowDetails] = useState(false)
  const [refund, setRefund] = useState([])
  const [invoice, setInvoice] = useState()

  const fetchRefund = useCallback(async () => {
    try {
      const data = (await api.getAmountRefund(getNumberFromStringId(props.id)))
        .data
      setRefund(data)
    } catch (error) {
      handleError(error)
    }
  }, [api, handleError, props.id])

  useEffect(() => {
    let mount = true

    if (mount) {
      fetchRefund()
    }

    return () => (mount = false)
  }, [fetchRefund])

  return (
    <Wrapper>
      <ShowGuesser {...props}>
        <FunctionField
          label=" "
          render={(record) => {
            if (invoice) return null
            setInvoice(record)
            return null
          }}
        />
        <OverflowingTabbedShowLayout>
          <Tab label="Invoice Details">
            <FunctionField
              {...props}
              label="Invoice ID"
              render={(record) => record.chargeBeeId}
            />
            <FunctionField
              {...props}
              label="Status"
              render={(record) => record.chargeBeeStatus}
            />

            <ReferenceListFilterField
              {...props}
              label="Order"
              listUri="/orders"
              titlePath="order.displayName"
              valuePath="order.id"
            />

            <FunctionField
              {...props}
              label="Invoice date"
              render={(record) => (
                <DateField record={record} source="createdAt" />
              )}
            />

            <FunctionField
              {...props}
              label="Amount due"
              render={(record) =>
                formatPrice(
                  record.data.amount_due / 100,
                  record.data.currency_code
                )
              }
            />
            <FunctionField
              {...props}
              label="Amount paid"
              render={(record) =>
                formatPrice(
                  record.data.amount_paid / 100,
                  record.data.currency_code
                )
              }
            />
            <FunctionField
              {...props}
              label="Amount adjusted"
              render={(record) =>
                formatPrice(
                  record.data.amount_adjusted / 100,
                  record.data.currency_code
                )
              }
            />
            <FunctionField
              {...props}
              addLabel={false}
              render={(record) => {
                const creditIssuedValue = record?.data?.issued_credit_notes?.[0]

                return creditIssuedValue ? (
                  <CreditNoteIssued>
                    Credits issued{' '}
                    {formatPrice(
                      (creditIssuedValue?.cn_total || 0) / 100,
                      record.data.currency_code
                    )}
                  </CreditNoteIssued>
                ) : null
              }}
            />
            <FunctionField
              {...props}
              label="Amount refunded"
              render={(record) => {
                const currency = Object.keys(refund)?.[0]

                return formatPrice(
                  (refund?.[currency] || 0) / 100,
                  currency || record.data.currency_code
                )
              }}
            />

            <CreateCreditNoteButton />
            <CollectNowButton />
          </Tab>
          <Tab label="Invoice items">
            <FunctionField
              label="Invoice items"
              render={(record) => {
                return <OrderLinesTable lines={record.data.line_items} />
              }}
            />
            <FunctionField
              {...props}
              label=" "
              render={(record) => {
                return showDetails ? (
                  <pre>{JSON.stringify(record.data, null, 2)}</pre>
                ) : (
                  <Button
                    onClick={() => setShowDetails(true)}
                    label="Show more details"
                  />
                )
              }}
            />
          </Tab>
          <Tab label="resources.adyenNotifications.menu">
            <ReferenceManyField
              addLabel={false}
              reference="adyen-notifications"
              source="id"
              pagination={<Pagination />}
              filter={{
                'payment.invoice.id': getNumberFromStringId(invoice?.id),
                properties: ['id', 'createdAt', 'processedAt', 'error'],
                'properties[payment]': ['id'],
              }}
              target=""
            >
              <DataGrid rowClick="show">
                <TextField source="originId" label="id" />
                <DateField source="createdAt" />
                <DateField source="processedAt" />
                <TextField source="payment.id" label="payment id" />
                <TextField source="error" />
              </DataGrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="Related transactions">
            <ReferenceManyField
              addLabel={false}
              reference="transactions"
              source="id"
              pagination={<Pagination />}
              filter={{
                'payment.invoice.id': getNumberFromStringId(invoice?.id),
                properties: [
                  'id',
                  'pspReference',
                  'createdAt',
                  'amount',
                  'type',
                ],
                'properties[payment]': ['id', 'paymentMethod'],
                'properties[currency]': ['code'],
                'properties[payment][type]': ['name'],
                'properties[payment][invoice]': ['id'],
                'properties[payment][status]': ['name'],
                'properties[payment][customer]': ['email', 'id'],
              }}
              target=""
            >
              <DataGrid rowClick="show">
                <TextField source="originId" label="id" />
                <FunctionField
                  source="type"
                  render={(record) => transactionTypes[record?.type]}
                />
                <TextField source="payment.id" label="Payment ID" />
                <TextField source="payment.invoice.id" label="Invoice id" />
                <FunctionField
                  label="Amount"
                  source="amount"
                  render={(record) => {
                    const { amount, currency } = record
                    return formatPrice(amount / 100, currency?.code)
                  }}
                />
                <ReferenceListFilterField
                  listUri="/customers"
                  titlePath="payment.customer.email"
                  valuePath="payment.customer.email"
                  label="Customer"
                />
                <TextField source="payment.customer.id" label="Customer id" />
                <TextField
                  source="payment.status.name"
                  label="Payment Status"
                />
                <TextField source="payment.type.name" label="Payment Type" />
                <TextField source="pspReference" />
                <DateField
                  source="createdAt"
                  short={false}
                  label="Created at"
                />
              </DataGrid>
            </ReferenceManyField>
          </Tab>
        </OverflowingTabbedShowLayout>
      </ShowGuesser>
    </Wrapper>
  )
}
