import Icon from '@material-ui/icons/Storage'
import { ROLES } from 'utils/user'

import { NpfStockShow } from './NpfStockShow'
import { NpfStockList } from './NpfStockList'

export default {
  show: NpfStockShow,
  list: NpfStockList,
  icon: Icon,
  permissions: [
    ROLES.ROLE_ADMIN,
    ROLES.ROLE_COUNTRY_MANAGER,
    ROLES.ROLE_ORDER_MANAGEMENT,
  ],
}
