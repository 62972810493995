import React, { createContext, useContext, useState } from 'react'

const currentSessionContext = createContext()

const CurrentSessionProvider = ({
  children,
  session,
  productFlow,
  disabled,
}) => {
  const [isInvoice, setIsInvoice] = useState(false)
  return (
    <currentSessionContext.Provider
      value={{ session, isInvoice, setIsInvoice, productFlow, disabled }}
    >
      {children}
    </currentSessionContext.Provider>
  )
}

const useCurrentSession = () => useContext(currentSessionContext)

export { useCurrentSession, CurrentSessionProvider }
