import React from 'react'
import { List, Pagination, BulkDeleteWithConfirmButton } from 'react-admin'
import PropTypes from 'prop-types'
import FilterGuesser from '@api-platform/admin/lib/FilterGuesser'
import { DataGrid } from '..'
import { sanitizeProps } from 'utils/sanitizer'

const ListPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100]} {...props} />
)
const BulkActionButtons = (props) => (
  <>
    <BulkDeleteWithConfirmButton {...props} />
  </>
)

export const ListGuesser = ({
  hasShow,
  hasEdit,
  rowClick,
  exporter,
  bulkActionButtons,
  showEmpty,
  ...props
}) => {
  return (
    <List
      pagination={<ListPagination />}
      bulkActionButtons={
        bulkActionButtons === false
          ? false
          : bulkActionButtons || <BulkActionButtons />
      }
      {...{ exporter, ...props }}
    >
      <DataGrid
        {...{
          ...sanitizeProps(props, ['syncWithLocation']),
          hasShow,
          hasEdit,
          rowClick,
          showEmpty,
        }}
      />
    </List>
  )
}

ListGuesser.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  filters: PropTypes.element,
  perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sort: PropTypes.object,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  showEmpty: PropTypes.bool,
}

ListGuesser.defaultProps = {
  filters: <FilterGuesser />,
  perPage: 50,
  sort: { field: 'id', order: 'DESC' },
  hasEdit: true,
  hasShow: true,
  exporter: false,
  showEmpty: true,
}
