import React from 'react'
import { FieldGuesser } from '@api-platform/admin'
import {
  TextField,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  TextInput,
  BooleanField,
} from 'react-admin'
import {
  DateField,
  ListGuesser,
  ReferenceListFilterField,
} from 'theme/components'

const ListFilter = (props) => (
  <Filter {...props}>
    <ReferenceArrayInput
      source="status.id"
      reference="task-statuses"
      label="resources.task.status"
      perPage={500}
    >
      <SelectArrayInput optionText="slug" />
    </ReferenceArrayInput>
    <NumberInput label="Id" source="id" />
    <NumberInput label="Order Id" source="orderId" />
    <TextInput label="resources.task.handler" source="eventHandler" />
  </Filter>
)

export const TaskList = (props) => (
  <ListGuesser
    {...props}
    filters={<ListFilter />}
    hasEdit={false}
    filter={{
      properties: [
        'id',
        'eventHandler',
        'createdAt',
        'startedAt',
        'processedAt',
        'serial',
        'orderId',
      ],
      'properties[status]': ['id', 'slug'],
    }}
    rowClick="show"
    bulkActionButtons={false}
  >
    <TextField
      source="originId"
      label="id"
      sortBy="id"
      sort={{ field: 'id', order: 'DESC' }}
    />
    <TextField source="orderId" />
    <FieldGuesser source="eventHandler" label="resources.task.handler" />
    <ReferenceListFilterField
      {...props}
      listUri="/tasks"
      titlePath="status.slug"
      valuePath="status.id"
      filterType="collection"
    />
    <BooleanField source="serial" />
    <DateField source="createdAt" short={false} />
    <DateField source="startedAt" short={false} />
    <DateField source="processedAt" />
  </ListGuesser>
)
