import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg'

import { CallReasonList } from './CallReasonList'
import { CallReasonCreate } from './CallReasonCreate'
import { CallReasonEdit } from './CallReasonEdit'
import { ROLES } from 'utils/user'

export default {
  list: CallReasonList,
  create: CallReasonCreate,
  edit: CallReasonEdit,
  icon: PermPhoneMsgIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
