import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined'
import { AppointmentList } from './AppointmentList'
import { AppointmentEdit } from './AppointmentEdit'
import { ROLES } from 'utils/user'

export default {
  list: AppointmentList,
  edit: AppointmentEdit,
  icon: CalendarIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
