import React from 'react'
import {
  TextField,
  Filter,
  BooleanInput,
  DateInput,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
} from 'react-admin'
import ResendButton from 'components/resendButton'
import { DateField, ListGuesser } from 'theme/components'
import { getNumberFromStringId } from 'utils/string'
import { LongTextField } from 'theme/components/field/LongTextField'

const BulkActions = (props) => {
  const ids = props?.selectedIds.map(getNumberFromStringId)
  return <ResendButton resource="exact-online-logs" ids={ids} />
}

const ListFilter = (props) => (
  <Filter {...props}>
    <BooleanInput label="Has error" source="exists<error>" />
    <DateInput
      label="Created (from date)"
      defaultValue={new Date()}
      source="createdAt<after>"
      locales="nl-Nl"
    />
    <DateInput
      label="Created (to date)"
      defaultValue={new Date()}
      source="createdAt<before>"
      locales="nl-Nl"
    />
    <ReferenceArrayInput
      source="store.id"
      reference="stores"
      label="Store"
      perPage={500}
      filter={{ active: 1 }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <TextInput label="Invoice ID" source="refNumber" />
    <TextInput source="objectType" label="Type" />
  </Filter>
)

export const ExactApiList = (props) => (
  <ListGuesser
    {...props}
    rowClick={false}
    bulkActionButtons={<BulkActions />}
    filters={<ListFilter />}
    filter={{
      properties: ['refNumber', 'createdAt', 'error', 'objectType'],
      'properties[store]': ['name'],
    }}
  >
    <TextField source="refNumber" label="Invoice number" />
    <TextField source="store.name" label="Store" />
    <DateField source="createdAt" />
    <LongTextField source="error" />
    <TextField source="objectType" label="Type" />
  </ListGuesser>
)
