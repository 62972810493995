import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { FieldGuesser, InputGuesser, ShowGuesser } from '@api-platform/admin'
import {
  Toolbar,
  Tab,
  ReferenceManyField,
  Pagination,
  TextField,
  ShowButton,
  EditButton,
  Loading,
  Filter,
  TextInput,
  List,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  SelectArrayInput,
  NumberInput,
  BooleanField,
} from 'react-admin'
import { OverflowingTabbedShowLayout } from 'theme/components/tabs/OverflowingTabbedShowLayout'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import {
  missingProductStoreHeadCells,
  missingPsiHeadCells,
  PSI_SCREENS,
  PLAN_SCREENS,
  GROUP_SCREENS,
  ADDON_SCREENS,
  ADDON_TYPE,
} from './utils'

import {
  DataGrid,
  EditGuesser,
  ImageField,
  ResourceEditToolbar,
  ReferenceListFilterField,
} from 'theme/components'
import AddDataTable from './AddDataTable'
import PsiConfirmationTable from './PsiConfirmationTable'
import PlanCreate from './PlanCreate'
import GroupCreate from './GroupCreate'
import AddonCreate from './AddonCreate'
import useProductStoreInterval from './hooks/useProductStoreInterval'
import useProductStore from './hooks/useProductStore'
import useGroups from './hooks/useGroups'
import usePlans from './hooks/usePlans'
import useAddons from './hooks/useAddons'
import {
  CreateFromListAddonBtn,
  CreateFromListPlanBtn,
  CreateFromPlanListAddongBtn,
  CreateFromListGroupBtn,
  CreatePlanFromListGroupBtn,
} from './CreateFromListBtn'
import { parseUrlIdsParam } from 'utils/uri'
import UserSession from 'service/UserSession'
import { hasRoles } from 'utils/user'

const TableSeparator = styled.div`
  height: 50px;
`

const StyledTypography = styled(Typography)`
  display: block;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 20px;
`

const useStyles = makeStyles((theme) => ({
  tab: {
    padding: theme.spacing(1),
    fontSize: 13,
    minWidth: 100,
  },
}))

export const ProductEdit = React.memo(({ defaultToolbar, ...props }) => (
  <EditGuesser
    {...props}
    undoable={false}
    variant="outlined"
    redirect="list"
    toolbar={
      defaultToolbar ? <Toolbar /> : <ResourceEditToolbar showDelete={false} />
    }
  >
    <InputGuesser source="name" />
    <InputGuesser source="sku" />
    <InputGuesser source="pcs" />
    <InputGuesser source="weight" />
    <InputGuesser source="hsCode" />
    <InputGuesser source="starterKit" />
  </EditGuesser>
))

const PSIFilters = React.memo((props) => (
  <Filter {...props}>
    <ReferenceInput
      source="productStore.id"
      reference="product-stores"
      label="resources.productStore.field"
      filter={{
        'product.id': props.productId,
      }}
      filterToQuery={(searchText) => ({
        sku: searchText,
      })}
    >
      <AutocompleteInput optionText="sku" resettable />
    </ReferenceInput>
    <ReferenceArrayInput
      source="productStore.store.id"
      reference="stores"
      label="Store"
      perPage={500}
      filter={{
        id: props.storeIds,
        active: 1,
      }}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="currency.id"
      reference="currencies"
      label="Currency"
      perPage={500}
    >
      <SelectArrayInput optionText="code" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      source="interval.id"
      reference="intervals"
      label="Interval"
      perPage={500}
    >
      <SelectArrayInput optionText="name" />
    </ReferenceArrayInput>
    <NumberInput label="Id" source="id" />
    <TextInput label="Price" source="price" />
    <TextInput label="Quantity" source="qty" />
    <TextInput label="Name" source="name" />
    <TextInput label="ChargeBee ID" source="chargeBeeId" />
  </Filter>
))

export const ProductEditPage = React.memo((props) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const ps = useProductStore(props.id, setLoading)
  const psi = useProductStoreInterval(
    props.id,
    ps.addedProductStores,
    setLoading
  )
  const addons = useAddons(props.id, setLoading)
  const groups = useGroups(
    props.id,
    ps.addedProductStores,
    psi.addedProductStoreIntervals,
    setLoading
  )
  const plans = usePlans(
    props.id,
    ps.addedStoresIds,
    psi.addedIntervalsIds,
    setLoading
  )

  const addAddonPlans = useMemo(
    () =>
      addons.type === ADDON_TYPE.PLAN
        ? plans.optionsPlans
        : plans.relatedStoresProductPlans,
    [addons.type, plans.optionsPlans, plans.relatedStoresProductPlans]
  )

  const addAddonPSI = useMemo(
    () =>
      addons.type === ADDON_TYPE.MAIN
        ? psi.addedProductStoreIntervals
        : psi.optionsProductStoreIntervals,
    [
      addons.type,
      psi.addedProductStoreIntervals,
      psi.optionsProductStoreIntervals,
    ]
  )

  const renderAddedProductStores = useCallback(() => {
    const { roles } = UserSession.getUser()
    const { hasAdminRole } = hasRoles(roles)
    return (
      <Tab
        label="resources.product.relatedProductStoresIntervals"
        className={classes.tab}
      >
        <StyledTypography display="inline" variant="h6">
          Added product stores
        </StyledTypography>

        <ReferenceManyField
          addLabel={false}
          reference="product-stores"
          target="product.id"
          source="id"
          pagination={<Pagination />}
          filter={{
            properties: ['id', 'sku', 'name', 'description'],
            'properties[store]': ['id', 'name'],
          }}
        >
          <DataGrid
            {...props}
            empty="resources.product.list.productStore"
            rowClick="edit"
          >
            <TextField
              source="originId"
              label="id"
              sortBy="id"
              sort={{ field: 'id', order: 'DESC' }}
            />
            {hasAdminRole ? (
              <ReferenceListFilterField
                {...props}
                label="resources.store.field"
                listUri="/product-stores"
                titlePath="store.name"
                valuePath="store.id"
                filterType="collection"
              />
            ) : (
              <TextField source="store.name" label="resources.store.field" />
            )}
            <FieldGuesser source="sku" />
          </DataGrid>
        </ReferenceManyField>

        <TableSeparator />

        {ps.missingProductStores.length && (
          <AddDataTable
            data={ps.missingProductStores}
            headCells={missingProductStoreHeadCells}
            onSubmitClick={ps.onAddProductStoresClick}
            title="Create product stores"
            selectable
          />
        )}
      </Tab>
    )
  }, [props, ps.missingProductStores, ps.onAddProductStoresClick, classes.tab])

  const renderPSI = useCallback(() => {
    const { roles } = UserSession.getUser()
    const { hasAdminRole } = hasRoles(roles)
    const intervalsData = [
      ...psi.intervals,
      {
        name: 'Non-recurring',
        period: null,
        unit: null,
      },
    ]

    if (loading) {
      return (
        <Tab
          label="resources.product.productStoresIntervals"
          className={classes.tab}
        >
          <Loading />
        </Tab>
      )
    }

    return psi.screen === PSI_SCREENS.MISSED &&
      Boolean(ps.addedProductStores.length) ? (
      <Tab
        label="resources.product.productStoresIntervals"
        className={classes.tab}
      >
        <ReferenceManyField
          addLabel={false}
          reference="product-store-intervals"
          target="productStore.product.id"
          source="id"
          exporter={false}
          filter={{
            'productStore.product.id': props.id,
            properties: [
              'id',
              'price',
              'qty',
              'name',
              'description',
              'chargeBeeId',
              'invoiceName',
              'shippable',
            ],
            'properties[pricingModel]': ['id', 'slug'],
            'properties[interval]': ['id', 'name'],
            'properties[productStore]': ['id', 'sku', 'store', 'product'],
            'properties[currency]': ['id', 'code'],
          }}
        >
          <List
            {...props}
            filters={
              <PSIFilters
                productId={props.id}
                storeIds={parseUrlIdsParam(ps.addedStoresIds)}
              />
            }
            bulkActionButtons={false}
          >
            <DataGrid>
              <TextField
                source="originId"
                label="id"
                sortBy="id"
                sort={{ field: 'id', order: 'DESC' }}
              />
              {hasAdminRole ? (
                <ReferenceListFilterField
                  {...props}
                  label="resources.store.field"
                  listUri="/stores"
                  titlePath="productStore.store.name"
                  valuePath="productStore.store.name"
                />
              ) : (
                <TextField
                  source="productStore.store.name"
                  label="resources.store.field"
                />
              )}
              <ReferenceListFilterField
                {...props}
                label="resources.productStore.field"
                listUri="/product-stores"
                titlePath="productStore.sku"
                valuePath="productStore.sku"
              />
              <ReferenceListFilterField
                {...props}
                label="resources.interval.field"
                listUri="/intervals"
                titlePath="interval.name"
                valuePath="interval.id"
              />
              <ReferenceListFilterField
                {...props}
                label="resources.currency.field"
                listUri="/currencies"
                titlePath="currency.code"
                valuePath="currency.id"
              />
              <FieldGuesser
                source="chargeBeeId"
                label="resources.order.chargeBee"
              />
              <FieldGuesser source="name" />
              <FieldGuesser source="price" />
              <FieldGuesser source="qty" />
              <TextField
                source="pricingModel.slug"
                label="resources.productStoreInterval.pricingModel"
              />
              <FieldGuesser source="shippable" />
              <ShowButton label="" />
              {Boolean(groups.addedGroups.length) && (
                <CreateFromListPlanBtn
                  setLoading={setLoading}
                  productId={props.id}
                  group={
                    groups.addedGroups.length === 1
                      ? {
                          id: groups.addedGroups[0]['@id'],
                          name: groups.addedGroups[0].name,
                        }
                      : null
                  }
                />
              )}

              {Boolean(addAddonPlans.length) && (
                <CreateFromListAddonBtn
                  setLoading={setLoading}
                  productId={props.id}
                  plan={
                    plans.addedPlans.length === 1
                      ? {
                          id: plans.addedPlans[0]['@id'],
                          name: plans.addedPlans[0].chargeBeeId,
                        }
                      : null
                  }
                />
              )}
              <CreateFromListGroupBtn
                productId={props.id}
                setLoading={setLoading}
              />
            </DataGrid>
          </List>
        </ReferenceManyField>

        <TableSeparator />

        {psi.intervals.length && (
          <AddDataTable
            data={intervalsData}
            headCells={missingPsiHeadCells}
            onSubmitClick={psi.onSubmitPsiClick}
            title="Create product store intervals"
            submitText="Continue"
            setSelectedData={psi.setSelectedProductStores}
            selectedData={psi.selectedProductStores}
            pageSize={10}
            selectable
          />
        )}
      </Tab>
    ) : (
      Boolean(psi.confirmationData.length) && (
        <Tab
          label="resources.product.productStoresIntervals"
          className={classes.tab}
        >
          <PsiConfirmationTable
            data={psi.confirmationData}
            onBackClick={psi.onBackClickPSI}
            onSubmitClick={psi.onAddProductStoreIntervalsClick}
            selectedProductStores={psi.selectedProductStores}
            currencySelectOptions={psi.currencySelectOptions}
            intervalSelectOptions={psi.intervalSelectOptions}
            pricingModelSelectOptions={psi.pricingModelSelectOptions}
            addedProductStores={ps.addedProductStores}
          />
        </Tab>
      )
    )
  }, [
    psi.intervals,
    psi.screen,
    psi.onSubmitPsiClick,
    psi.setSelectedProductStores,
    psi.selectedProductStores,
    psi.confirmationData,
    psi.onBackClickPSI,
    psi.onAddProductStoreIntervalsClick,
    psi.currencySelectOptions,
    psi.intervalSelectOptions,
    psi.pricingModelSelectOptions,
    loading,
    ps.addedProductStores,
    ps.addedStoresIds,
    classes.tab,
    props,
    groups.addedGroups,
    addAddonPlans.length,
    plans.addedPlans,
  ])

  const renderGroups = useCallback(() => {
    const { roles } = UserSession.getUser()
    const { hasAdminRole } = hasRoles(roles)

    if (loading) {
      return (
        <Tab label="resources.product.groups" className={classes.tab}>
          <Loading />
        </Tab>
      )
    }

    return groups.screen === GROUP_SCREENS.ADDED_GROUPS &&
      Boolean(psi.addedProductStoreIntervals.length) ? (
      <Tab label="resources.product.groups" className={classes.tab}>
        <StyledTypography display="inline" variant="h6">
          Added groups
        </StyledTypography>

        <ReferenceManyField
          addLabel={false}
          reference="groups"
          target="productStoreInterval.productStore.product.id"
          source="id"
          pagination={<Pagination />}
          filter={{
            properties: ['id', 'name', 'createdAt', 'updatedAt'],
            'store.id': parseUrlIdsParam(
              ps.addedProductStores.map((item) => item.store['@id'])
            ),
            'product.id': props.id,
            'properties[store]': ['id', 'name'],
            'properties[image]': ['file'],
            'properties[image][file]': ['data'],
          }}
        >
          <DataGrid>
            <TextField
              source="originId"
              label="id"
              sortBy="id"
              sort={{ field: 'id', order: 'DESC' }}
            />
            <ImageField
              label="resources.group.image"
              source="image.url"
              title="image.name"
              aspectRatio={1}
              width={75}
            />
            <TextField source="name" label="Name" />
            {hasAdminRole ? (
              <ReferenceListFilterField
                {...props}
                label="resources.store.field"
                listUri="/stores"
                titlePath="store.name"
                valuePath="store.name"
              />
            ) : (
              <TextField source="store.name" label="resources.store.field" />
            )}
            <EditButton label="" />
            <CreatePlanFromListGroupBtn
              productId={props.id}
              setLoading={setLoading}
            />
          </DataGrid>
        </ReferenceManyField>

        <Button
          color="primary"
          variant="contained"
          onClick={groups.onAddGroupClick}
        >
          Create wordpress product
        </Button>

        <TableSeparator />
      </Tab>
    ) : (
      Boolean(psi.addedProductStoreIntervals.length) &&
        Boolean(ps.addedProductStores.length) && (
          <Tab label="resources.product.groups" className={classes.tab}>
            <GroupCreate
              productId={props.id}
              productStores={ps.addedProductStores}
              onSubmit={groups.onAddGroupSubmit}
              onBackClick={groups.onBackClickGroups}
            />
          </Tab>
        )
    )
  }, [
    loading,
    groups.screen,
    groups.onAddGroupClick,
    groups.onAddGroupSubmit,
    groups.onBackClickGroups,
    psi.addedProductStoreIntervals.length,
    classes.tab,
    ps.addedProductStores,
    props,
  ])

  const renderPlans = useCallback(() => {
    if (loading) {
      return (
        <Tab label="resources.product.plans" className={classes.tab}>
          <Loading />
        </Tab>
      )
    }

    return plans.screen === PLAN_SCREENS.ADDED_PLANS &&
      Boolean(groups.addedGroups.length) ? (
      <Tab label="resources.product.plans" className={classes.tab}>
        <StyledTypography display="inline" variant="h6">
          Added plans
        </StyledTypography>
        <ReferenceManyField
          addLabel={false}
          reference="plans"
          target="productStoreInterval.productStore.product.id"
          source="id"
          pagination={<Pagination />}
          filter={{
            properties: [
              'id',
              'allowUnpaid',
              'enabledInPortal',
              'enabledInHostedPages',
              'chargeBeeId',
              'createdAt',
            ],
            'properties[productStoreInterval]': ['id', 'name'],
            'properties[group]': ['id', 'name'],
          }}
        >
          <DataGrid>
            <TextField
              source="originId"
              label="id"
              sortBy="id"
              sort={{ field: 'id', order: 'DESC' }}
            />
            <FieldGuesser source="allowUnpaid" />
            <FieldGuesser source="enabledInPortal" />
            <BooleanField source="enabledInHostedPages" />
            <FieldGuesser source="chargeBeeId" />
            <ReferenceListFilterField
              {...props}
              label="resources.productStoreInterval.field"
              listUri="/product-store-intervals"
              titlePath="productStoreInterval.name"
              valuePath="productStoreInterval.name"
              filterType="collection"
            />
            <ReferenceListFilterField
              {...props}
              label="resources.group.field"
              listUri="/groups"
              titlePath="group.name"
              valuePath="group.name"
              filterType="collection"
            />
            <EditButton label="" />
            <CreateFromPlanListAddongBtn
              productId={props.id}
              setLoading={setLoading}
            />
          </DataGrid>
        </ReferenceManyField>
        <Button
          color="primary"
          variant="contained"
          onClick={plans.onAddPlanClick}
        >
          Create plan
        </Button>
      </Tab>
    ) : (
      Boolean(groups.addedGroups.length) && (
        <Tab label="resources.product.plans" className={classes.tab}>
          <PlanCreate
            groups={groups.addedGroups}
            productStoreIntervals={psi.addedProductStoreIntervals}
            onSubmit={plans.onAddPlanSubmit}
            onBackClick={plans.onBackClickPlans}
          />
        </Tab>
      )
    )
  }, [
    loading,
    plans.screen,
    plans.onAddPlanClick,
    plans.onAddPlanSubmit,
    plans.onBackClickPlans,
    groups.addedGroups,
    classes.tab,
    props,
    psi.addedProductStoreIntervals,
  ])

  const renderAddons = useCallback(() => {
    if (loading) {
      return (
        <Tab label="resources.product.addons" className={classes.tab}>
          <Loading />
        </Tab>
      )
    }

    return addons.screen === ADDON_SCREENS.ADDED_ADDONS &&
      Boolean(addAddonPlans.length) &&
      Boolean(addAddonPSI.length) ? (
      <Tab label="resources.product.addons" className={classes.tab}>
        <StyledTypography display="inline" variant="h6">
          Added addons
        </StyledTypography>
        <ReferenceManyField
          addLabel={false}
          reference="addons"
          target="productStoreInterval.productStore.product.id"
          source="id"
          pagination={<Pagination />}
          filter={{
            'productStoreInterval.id': parseUrlIdsParam(
              psi.addedProductStoreIntervalsIds
            ),
            properties: ['id', 'mandatory', 'recommended', 'enabledInPortal'],
            'properties[plan]': ['id', 'chargeBeeId'],
            'properties[productStoreInterval]': [
              'id',
              'name',
              'enabledInPortal',
            ],
          }}
        >
          <DataGrid rowClick="edit">
            <TextField
              source="originId"
              label="id"
              sortBy="id"
              sort={{ field: 'id', order: 'DESC' }}
            />
            <ReferenceListFilterField
              listUri="/plans"
              titlePath="plan.chargeBeeId"
              valuePath="plan.id"
              label="resources.addon.plan.label"
            />
            <ReferenceListFilterField
              listUri="/product-store-intervals"
              titlePath="productStoreInterval.name"
              valuePath="productStoreInterval.id"
              label="resources.productStoreInterval.field"
            />
            <FieldGuesser source="mandatory" />
            <FieldGuesser source="recommended" />
            <BooleanField
              label="Enabled in portal"
              source="productStoreInterval.enabledInPortal"
            />
          </DataGrid>
        </ReferenceManyField>
        <Button
          color="primary"
          variant="contained"
          onClick={() => addons.onAddAddonClick(ADDON_TYPE.MAIN)}
        >
          Create Addon
        </Button>
      </Tab>
    ) : (
      Boolean(addAddonPlans.length) && Boolean(addAddonPSI.length) && (
        <Tab label="resources.product.addons" className={classes.tab}>
          <AddonCreate
            type={addons.type}
            plans={addAddonPlans}
            productStoreIntervals={addAddonPSI}
            onSubmit={addons.onAddAddonSubmit}
            onBackClick={addons.onBackClickAddon}
            onChangePSI={psi.onChangePSI}
          />
        </Tab>
      )
    )
  }, [
    loading,
    addons,
    addAddonPlans,
    classes.tab,
    psi.addedProductStoreIntervalsIds,
    psi.onChangePSI,
    addAddonPSI,
  ])

  useEffect(() => {
    if (loading) {
      window.location.reload()
    }
  }, [loading])

  if (loading) return <Loading />

  return (
    <ShowGuesser {...props}>
      <OverflowingTabbedShowLayout>
        <Tab label="resources.product.edit" className={classes.tab}>
          <ProductEdit {...props} />
        </Tab>

        {renderAddedProductStores()}

        {renderPSI()}

        {renderGroups()}

        {renderPlans()}

        {renderAddons()}
      </OverflowingTabbedShowLayout>
    </ShowGuesser>
  )
})
