import SubjectIcon from '@material-ui/icons/Subject'
import { FreshdeskSubjectList } from './FreshdeskSubjectList'
import { FreshdeskSubjectCreate } from './FreshdeskSubjectCreate'
import { FreshdeskSubjectEdit } from './FreshdeskSubjectEdit'
import { ROLES } from 'utils/user'

export default {
  list: FreshdeskSubjectList,
  create: FreshdeskSubjectCreate,
  edit: FreshdeskSubjectEdit,
  icon: SubjectIcon,
  permissions: [ROLES.ROLE_ADMIN, ROLES.ROLE_CUSTOMER_SUPPORT],
}
