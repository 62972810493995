import React from 'react'
import { Toolbar, SaveButton } from 'react-admin'
import styled from 'styled-components'
import DoneIcon from '@material-ui/icons/Done'
import Error from '@material-ui/icons/PriorityHighOutlined'
import { colors } from 'theme/MuiTheme'

const Requirements = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 200px;
  font-size: 14px;
`

const Line = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }
`

const icon = (val) =>
  val ? (
    <DoneIcon style={{ color: colors.primary }} />
  ) : (
    <Error style={{ color: 'rgb(220, 0, 78)' }} />
  )

export const CustomToolbar = (props) => {
  const { hasStore, hasEmail, hasSubscriptions } = props.canSubmit

  return (
    <Toolbar {...props}>
      <Requirements>
        <Line>
          {icon(hasStore)}{' '}
          {hasStore ? 'store selected' : 'store is not selected'}
        </Line>
        <Line>
          {icon(hasEmail)}
          {hasEmail ? 'email entered' : 'email is not entered'}
        </Line>
        <Line>
          {icon(hasSubscriptions)}
          {hasSubscriptions ? 'cart has subscriptions' : 'cart is empty'}
        </Line>
      </Requirements>
      <SaveButton
        label={props.create ? 'create' : 'save'}
        disabled={!hasStore || !hasEmail || !hasSubscriptions}
      />
    </Toolbar>
  )
}
