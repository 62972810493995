import RecentActorsIcon from '@material-ui/icons/RecentActors'

import { CustomerSourceStoreList } from './CustomerSourceStoreList'
import { CustomerSourceStoreCreate } from './CustomerSourceStoreCreate'
import { CustomerSourceStoreEdit } from './CustomerSourceStoreEdit'
import { ROLES } from 'utils/user'

export default {
  list: CustomerSourceStoreList,
  create: CustomerSourceStoreCreate,
  edit: CustomerSourceStoreEdit,
  icon: RecentActorsIcon,
  permissions: [ROLES.ROLE_ADMIN],
}
