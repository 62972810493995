import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  FormControl,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useNotify } from 'react-admin'
import useApi from 'hooks/useApi'
import { getFormattedError } from 'utils/hydra'
import { useReasons } from 'components/pages/customer/contexts/reasons'

const CancelSubscriptionModal = ({
  open,
  subscriptionId,
  onClose,
  onEndEdit,
}) => {
  const notify = useNotify()
  const { api } = useApi()
  const [cancelReason, setCancelReason] = useState(null)
  const [cancelSubreason, setCancelSubreason] = useState(null)
  const { cancelReasons } = useReasons()

  const handleCancel = async () => {
    const body = {
      reasonId: cancelReason?.id,
      subReasonId: cancelSubreason?.id,
    }

    try {
      await api.cancelSubscription(subscriptionId, body)
      notify(
        'subscription cancellation requested, it might take a while for changes to take effect'
      )
      onEndEdit()
    } catch (e) {
      notify(getFormattedError(e?.response?.data, false))
    }
  }

  useEffect(() => {
    !cancelReason && setCancelSubreason(null)
  }, [cancelReason, cancelSubreason])

  return (
    <Dialog fullWidth maxWidth="sm" open={!!open} onClose={onClose}>
      <DialogTitle>Select reason</DialogTitle>
      <DialogContent>
        <FormControl margin="normal" fullWidth>
          <Autocomplete
            options={cancelReasons
              .filter(Boolean)
              .filter((item) => !item.parent)}
            getOptionLabel={(option) => option?.name}
            value={cancelReason}
            onChange={(e, value) => setCancelReason(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Why customer decided to cancel the subscription?"
                variant="outlined"
              />
            )}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <Autocomplete
            options={cancelReasons.filter((item) => {
              if (cancelReason?.id) {
                return item?.parent?.id === cancelReason?.id
              } else {
                return !!item?.parent?.id
              }
            })}
            getOptionLabel={(option) => option?.name}
            onChange={(e, value) => {
              setCancelReason(value?.parent)
              setCancelSubreason(value)
            }}
            renderInput={(params) => (
              <TextField {...params} label="More specific" variant="outlined" />
            )}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCancel} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CancelSubscriptionModal
