import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { parsePhoneMemoized } from 'utils/string'
import { colors } from 'theme/MuiTheme'

const StyledPhoneInput = styled(ReactPhoneInput)`
  .form-control {
    width: 100%;
    border-radius: 8px;
    height: 40px;
    position: relative;

    &:focus {
      border: 1px solid ${colors.primary};
      box-shadow: 0 0 0 1px ${colors.primary};
    }

    &.invalid-number:focus {
      border: 1px solid #f44336;
    }
  }

  .country-list {
    bottom: 40px;
  }
`

const Error = styled.span`
  position: absolute;
  margin: 2px 0 0 16px;
  color: #f14336;
  font-size: 0.75rem;
`

const map = {
  CHDE: 'CH',
  BENL: 'BE',
  BEFR: 'BE',
  CHFR: 'CH',
  INT: 'NL',
}

export const PhoneInput = (props) => {
  const { value, onChange, onBlur, onFocus, defaultCountry, label } = props
  const inputRef = useRef(null)
  const [focused, setFocused] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [error, setError] = useState()

  const handleBlur = useCallback(
    (event) => {
      const inputValue = event.target?.value?.replace(/\s/g, '')
      const parsedPhone = parsePhoneMemoized(inputValue)

      if (parsedPhone?.isValid()) {
        setError()
        if (inputValue !== parsedPhone.number) {
          event.target.value = parsedPhone.number
          onChange({ target: event.target })
        }
      } else if (inputValue?.length > 5) {
        setError('phone is not valid')
      } else {
        setError()
      }
      onBlur && onBlur(event)

      setFocused(false)
    },
    [onBlur, onChange]
  )

  useEffect(() => {
    if (inputRef.current && !mounted) {
      setMounted(true)
      setTimeout(() => {
        handleBlur({ target: inputRef.current }) // validate on mount
      }, 1000)
    }
  }, [handleBlur, mounted])

  return (
    <div style={{ position: 'relative' }}>
      <StyledPhoneInput
        {...props}
        specialLabel={
          <span
            style={{ color: focused ? colors.primary : 'rgba(0, 0, 0, 0.54)' }}
          >
            {label || 'Phone'}
          </span>
        }
        country={(map[defaultCountry] || defaultCountry || 'NL').toLowerCase()}
        value={value}
        onChange={(_, __, event) => {
          // handle input change event when target is list option, not input
          const modifiedEvent = { ...event, target: inputRef.current }

          onChange(modifiedEvent)
        }}
        onBlur={handleBlur}
        onFocus={(event) => {
          setFocused(true)
          onFocus && onFocus(event)
        }}
        inputProps={{ name: props.name, ref: inputRef, inputMode: 'tel' }}
        isValid={!error}
      />

      {!!error && <Error>{error}</Error>}
    </div>
  )
}
