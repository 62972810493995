import { useCustomer } from 'components/pages/customer/contexts/customer'
import React, { useEffect } from 'react'
import { TextField, ReferenceManyField } from 'react-admin'
import { DateField, DataGrid } from 'theme/components'

export const ShortCustomerEventsList = ({ ListStats, setTotal, ...props }) => {
  const { chargeBeeId } = useCustomer()

  useEffect(() => {
    setTotal(0)
  }, [setTotal])

  return (
    <ReferenceManyField
      addLabel={false}
      reference="events"
      source="id"
      perPage={10}
      filter={{
        customerChargeBeeId: chargeBeeId,
        properties: ['id', 'type', 'createdAt', 'user'],
      }}
      target=""
      {...props}
    >
      <DataGrid showEmpty emptyText="No options available" rowClick={() => {}}>
        {ListStats}
        <TextField source="originId" label="resources.id" sortBy="id" />
        <TextField source="type.name" label="Event" />
        <DateField source="createdAt" />
        <TextField source="user" label="Env" />
      </DataGrid>
    </ReferenceManyField>
  )
}
